angular
    .module('klaro')
    .directive('newDimensionValueLink', newDimensionValueLink);
function newDimensionValueLink(dimensionValueEditModal) {
    return {
        restrict: 'E',
        replace: true,
        template: require('@/core/dimension/newDimensionValueLink.html'),
        require: '^board',
        scope: {
            dimension: '=',
            onClose: '&',
        },
        link: function (scope, _elm, _attrs, boardCtrl) {
            scope.kind = 'value';
            scope.$watch('dimension', (newVal) => {
                scope.kind = newVal ? newVal.label.toLowerCase() : 'value';
            });
            scope.newDimensionValue = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                if (scope.dimension.datatype === 'StoryBinaryLink') {
                    const defaults = {};
                    const kindDimension = boardCtrl.getKindDimension();
                    scope.parentKind = Array.isArray(scope.dimension.datatypeOptions.kind) ? scope.dimension.datatypeOptions.kind : [scope.dimension.datatypeOptions.kind];
                    const validDimensionsValues = {
                        [kindDimension.code]: scope.parentKind,
                    };
                    if (scope.parentKind.length === 1) {
                        defaults[kindDimension.code] = scope.parentKind[0];
                    }
                    boardCtrl
                        .openNewStoryModal(defaults, false, false, validDimensionsValues)
                        .then((newStory) => {
                        const dimensionValue = {
                            id: newStory.identifier,
                            label: newStory.title,
                            ordering: newStory.identifier,
                            color: null,
                            semantics: null,
                            description: newStory.specification,
                            deprecated: false,
                        };
                        scope.onClose({
                            event: 'created',
                            dimensionValue: dimensionValue,
                            dimension: scope.dimension.withoutValue({}).addValue(dimensionValue),
                        });
                    });
                }
                else {
                    openDimensionValueModal(scope.dimension, scope.dimension.getNewValueData());
                }
            };
            function openDimensionValueModal(dimension, formData) {
                dimensionValueEditModal
                    .open(dimension, formData)
                    .result
                    .then(scope.onClose);
            }
        },
    };
}
