angular
    .module('klaro')
    .directive('searchWidget', searchWidget);
function searchWidget($document) {
    return {
        restrict: 'E',
        template: require('@/support/searchWidget.html'),
        scope: {
            ngModel: '=',
            placeholder: '=',
            onEnter: '&',
            onInputClear: '&',
            onFocus: '&',
            onMenuOpenRequest: '&',
            autofocus: '=?',
        },
        link: function (scope, elm, attrs) {
            scope.isOpen = !!scope.autofocus;
            scope.alwaysOpen = attrs.alwaysOpen !== undefined;
            scope.$watch('autofocus', () => {
                scope.isOpen = !!scope.autofocus;
            });
            scope.searchClicked = function ($event) {
                scope.open();
                scope.onMenuOpenRequest({ $event: $event });
            };
            scope.open = function () {
                scope.isOpen = true;
                scope.onFocus();
            };
            scope.close = function () {
                scope.isOpen = nonEmptyModel();
            };
            scope.clear = function () {
                scope.ngModel = '';
                $document[0].activeElement.blur();
                scope.isOpen = nonEmptyModel();
                if (scope.onInputClear && !scope.isOpen) {
                    scope.onInputClear();
                }
            };
            scope.checkKey = function ($event) {
                if ($event.keyCode === 27) {
                    scope.clear();
                }
                else if ($event.keyCode === 13 && scope.onEnter) {
                    scope.onEnter({ search: scope.ngModel });
                }
            };
            scope.getPlaceholder = function () {
                return (scope.placeholder !== undefined) ? scope.placeholder : 'Search...';
            };
            function isEmptyModel() {
                return scope.ngModel === '' || scope.ngModel === null || scope.ngModel === undefined;
            }
            function nonEmptyModel() {
                return !isEmptyModel();
            }
        },
    };
}
