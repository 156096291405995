angular
    .module('klaro')
    .directive('contentEditable', contentEditable);
function contentEditable() {
    return {
        restrict: 'AE',
        require: '^ngModel',
        link: function (scope, iElement, iAttrs, ngModelController) {
            // view -> model
            iElement.on('keyup', () => {
                ngModelController.$setViewValue(iElement.html());
            });
            // model -> view
            ngModelController.$render = function () {
                iElement.html(ngModelController.$viewValue);
            };
        },
    };
}
