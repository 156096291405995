const Scalar = {
    /**
      * Overriden to skip dimension values, and use the real scalar
      * for storting stories.
      */
    getStorySorter(reverse, _globalContext) {
        return { name: this.code, reverse: !!reverse };
    },
    /**
      * Overriden to use the semantics of `storyValueMatches`.
      */
    matchingValuesOf(story, globalContext) {
        return this.values.filter((v) => {
            return !!this.storyValueMatches(story, v, globalContext);
        });
    },
    /**
      * Overriden to use the semantics of `storyValueMatches`.
      */
    firstMatchingValueOf(story, globalContext) {
        return this.values.find((v) => {
            return !!this.storyValueMatches(story, v, globalContext);
        });
    },
    /**
      * Overriden: yes this dimension uses the semantics field.
      */
    usesSemantics() {
        return true;
    },
};
export default Scalar;
