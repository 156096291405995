import { Sync } from '@klaro/corejs/state';
const { hardRefreshNeeded } = Sync;
angular
    .module('klaro')
    .directive('boardNotificationBar', boardNotificationBar);
function boardNotificationBar(profileModal) {
    return {
        restrict: 'E',
        replace: false,
        template: require('@/core/board/boardNotificationBar.html'),
        scope: {},
        require: '^board',
        link: function (scope, elm, attrs, boardCtrl) {
            scope.showNotification = true;
            boardCtrl.$watch('board', () => {
                const board = boardCtrl.getBoardState();
                const stories = board.stories;
                if (stories.isLimiting()) {
                    scope.notification = 'isLimiting';
                }
                else if (stories.isLimitedByAPI()) {
                    scope.notification = 'isLimitedByAPI';
                }
                else if (hardRefreshNeeded(board)) {
                    scope.notification = 'hardRefreshNeeded';
                }
                else {
                    scope.notification = undefined;
                }
                scope.showNotification = true;
            }, scope);
            scope.removeCardsLimit = boardCtrl.removeCardsLimit.bind(boardCtrl);
            scope.reloadBoard = boardCtrl.refresh.bind(boardCtrl);
            scope.boardRefreshPreferences = function () {
                profileModal.open({ mode: 'preferences' });
            };
            scope.dismissNotification = function () {
                scope.showNotification = false;
            };
        },
    };
}
