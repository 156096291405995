angular
    .module('klaro')
    .directive('boardExportIcalendarOptions', boardExportIcalendarOptions);
function boardExportIcalendarOptions(TimeDuration, $filter, boardExportServices) {
    return {
        restrict: 'EA',
        template: require('@/core/board/exports/boardExportIcalendarOptions.html'),
        scope: {
            board: '=',
            form: '=',
            close: '=',
        },
        link: function (scope) {
            scope.dateDimensions = scope.board.getDateBoundaryDimensions();
            scope.dateDimensions = $filter('orderBy')(scope.dateDimensions, '+label');
            scope.form.valid = scope.dateDimensions.length;
            scope.event = {
                start: (scope.dateDimensions.length) ? scope.dateDimensions[0].id : null,
                end: (scope.dateDimensions.length > 1) ? scope.dateDimensions[1].id : null,
                selectedDuration: 'full-day',
                duration: {
                    value: 1,
                    unit: 'hours',
                },
                as: 'download',
            };
            scope.eventsDurations = [
                { code: 'full-day', title: 'The whole day', available: true },
                { code: 'duration', title: 'Custom duration', available: true },
                { code: 'dimension', title: 'Until dimension', available: (scope.dateDimensions.length > 1) },
            ];
            scope.formatOptions = [
                { code: 'download', title: 'Download file' },
                { code: 'permanent-link', title: 'Get a permanent link' },
            ];
            scope.isEventDurationSelected = function (code) {
                return scope.event.selectedDuration == code;
            };
            scope.setEventDuration = function (code) {
                if (code == 'dimension') {
                    scope.event.end = scope.dateDimensions[1].id;
                }
                else {
                    scope.event.end = null;
                }
                return scope.event.selectedDuration = code;
            };
            scope.isFormatSelected = function (code) {
                return scope.event.as == code;
            };
            scope.setFormat = function (code) {
                return scope.event.as = code;
            };
            scope.form.export = function () {
                let exportOptions = {
                    filtersOverride: scope.board.filtersOverride().toCnf(true),
                    startDate: scope.event.start,
                };
                const exportData = {
                    name: `${scope.board.label}.ics`,
                    format: 'text/calendar',
                    mode: scope.event.as,
                    options: exportOptions,
                };
                switch (scope.event.selectedDuration) {
                    case 'full-day':
                        exportOptions = angular.extend(exportOptions, {
                            fullDay: true,
                        });
                        break;
                    case 'duration':
                        exportOptions = angular.extend(exportOptions, {
                            duration: TimeDuration.toISO(scope.event.duration),
                        });
                        break;
                    case 'dimension':
                        exportOptions = angular.extend(exportOptions, {
                            endDate: scope.event.end,
                        });
                        break;
                    default:
                        break;
                }
                const footer = '/assets/app/core/board/share/boardShareIcalendarFooter.html';
                boardExportServices.exportBoard(scope.board, exportData, footer)
                    .then(() => {
                    if (scope.event.as == 'permanent-link') {
                        scope.close();
                    }
                });
            };
        },
    };
}
