angular
    .module('klaro')
    .directive('emailModalPart', emailModalPart);
function emailModalPart(auth) {
    return {
        restrict: 'E',
        scope: {
            ctrl: '=',
        },
        template: require('@/auth/profileModal/emailModalPart.html'),
        link: function (scope) {
            scope.pending = false;
            scope.formData = {
                password: null,
                email: null,
            };
            scope.canSave = function () {
                return scope.emailForm.$valid && !scope.pending;
            };
            scope.save = function () {
                scope.ctrl.clearError();
                scope.pending = true;
                auth
                    .initiateEmailChange(scope.formData)
                    .then(() => {
                    scope.pending = false;
                    scope.ctrl.switchMode('emailChangeRequest');
                })
                    .catch((err) => {
                    scope.pending = false;
                    scope.ctrl.onError(err);
                });
            };
        },
    };
}
