export var KlaroErrors;
(function (KlaroErrors) {
    KlaroErrors["UnauthorizedError"] = "Startback::Errors::UnauthorizedError";
    KlaroErrors["ForbiddenError"] = "Startback::Errors::ForbiddenError";
    KlaroErrors["NotFoundError"] = "Startback::Errors::NotFoundError";
})(KlaroErrors || (KlaroErrors = {}));
export class KlaroError extends Error {
    constructor(message, code) {
        super(message);
        this.code = code;
    }
    is(code) {
        return this.code === code;
    }
    static is(error, code) {
        return error instanceof KlaroError && error.is(code);
    }
}
export class OperationRunError extends KlaroError {
    constructor(rootCause, operation, args) {
        super(`A run of operation '${operation.constructor.name}' failed: ${rootCause?.message}`);
        this.rootCause = rootCause;
        this.operation = operation;
        this.args = args;
    }
}
