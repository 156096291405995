angular
    .module('klaro')
    .directive('settingsView', settingsView);
function settingsView() {
    return {
        template: require('@/views/settings/settingsView.html'),
        scope: true,
        transclude: true,
        link: function (scope, elm, attrs) {
            scope.settingsViewClasses = attrs.class;
        },
    };
}
