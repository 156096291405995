export const toCode = (label, except = []) => {
    const normalizeLabel = (aString) => {
        return aString
            .normalize('NFD') // Normalize Unicode to separate accentuated characters
            .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
            .replace(/[^a-zA-Z0-9]/g, '_') // Replace non-alphanumeric characters with underscores
            .toLowerCase(); // Convert to lowercase for snake_case
    };
    const baseCode = normalizeLabel(label);
    let code = baseCode;
    const randomChar = () => Math.random().toString(36).substring(2, 3); // Generate a random lowercase letter or number
    let attempts = 0;
    while (except.includes(code)) {
        code = `${baseCode}_${Array(3).fill(null).map(randomChar).join('')}`;
        attempts++;
        // Safety measure to avoid infinite loops
        if (attempts > 3) {
            throw new Error('Unable to generate a unique code after 3 attempts.');
        }
    }
    return code;
};
