export class LoadMe {
    async run(client) {
        const user = await client.store.connector.auth.me.get();
        // Apply the mutations
        const newSession = client.store.session
            .withUser(user);
        return {
            session: newSession,
        };
    }
}
;
