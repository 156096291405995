angular
    .module('klaro')
    .service('PrintIt', PrintIt);
function PrintIt($http, navigation, SecureDownload) {
    const mediaTypes = {
        pdf: 'application/pdf',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    };
    const toHtml = function (what, options, globalContext) {
        return what.toHtml({
            ...options,
            html5: true,
            footer: true,
            baseUrl: navigation.getHomeUrl(),
            globalContext: globalContext,
            board: globalContext.board,
        });
    };
    const downloadAsHtml = function (what, options, globalContext) {
        const html = toHtml(what, {
            ...options,
            assets: true,
        }, globalContext);
        SecureDownload.downloadBlob([html], {
            name: what.toFileName('.html'),
        });
    };
    const downloadAsPdf = function (what, options, globalContext) {
        return downloadAs(what, options, globalContext, 'pdf');
    };
    const downloadAsDocx = function (what, options, globalContext) {
        return downloadAs(what, options, globalContext, 'docx');
    };
    const downloadAs = function (what, options, globalContext, as) {
        // we don't include the assets since weasyprint will not
        // allow this for security reasons. It includes the stylesheet
        // itself from a trusted source.
        const html = toHtml(what, {
            ...options,
            assets: false,
        }, globalContext);
        return printTo(html, mediaTypes[as])
            .then((pdf) => {
            SecureDownload.downloadBlob([pdf], {
                name: what.toFileName(`.${as}`),
            });
        })
            .catch(navigation.failed);
    };
    const printTo = function (html, contentType) {
        return $http.post('/p/', { html: html }, {
            responseType: 'blob',
            headers: {
                Accept: contentType,
            },
        }).then((res) => {
            return res.data;
        });
    };
    return {
        downloadAsHtml: downloadAsHtml,
        downloadAsPdf: downloadAsPdf,
        downloadAsDocx: downloadAsDocx,
    };
}
