import { Duration, DateTime } from 'luxon';
export default (function () {
    const TimeDuration = {
        toISO: toISO,
        diff: diff,
        diffFromMs: diffFromMs,
    };
    function toISO(object) {
        if (Object.prototype.hasOwnProperty.call(object, 'value') && Object.prototype.hasOwnProperty.call(object, 'unit')) {
            return this.toISO({ [object.unit]: object.value });
        }
        return Duration.fromObject(object).toISO();
    }
    function diff(date1, date2 = null, unit = 'days') {
        const fromISO1 = DateTime.fromISO(date1);
        const fromISO2 = (date2) ? DateTime.fromISO(date2) : DateTime.local();
        return parseInt(Math.abs(fromISO1.diff(fromISO2, [unit]).toObject()[unit]));
    }
    function diffFromMs(date1, date2 = null, unit = 'days') {
        const fromMillis1 = DateTime.fromMillis(date1);
        const fromMillis2 = (date2) ? DateTime.fromMillis(date2) : DateTime.local();
        return parseInt(Math.abs(fromMillis1.diff(fromMillis2, [unit]).toObject()[unit]));
    }
    return TimeDuration;
})();
