import Dimension from '../Dimension';
import StoryDecorator from '../../lang/StoryDecorator';
import Evaluator from '../../lang/Evaluator';
class Derived extends Dimension {
    get category() {
        return 'advanced';
    }
    /**
      * Overriden: this one is not editable.
      */
    canBeEdited() {
        return false;
    }
    /**
      * Overriden to use the semantics of `storyValueMatches` below.
      */
    matchingValuesOf(story, globalContext) {
        return this.values.filter((v) => {
            return this.storyValueMatches(story, v, globalContext);
        });
    }
    /**
      * Overriden to use the semantics of `storyValueMatches` below.
      */
    firstMatchingValueOf(story, globalContext) {
        return this.values.find((v) => {
            return this.storyValueMatches(story, v, globalContext);
        });
    }
    /**
      * Overriden to evaluate the semantics expression on story and return
      * the result.
      */
    storyValueMatches(story, dimValue, globalContext) {
        try {
            return this.semanticsFn(dimValue)(new StoryDecorator(story, globalContext));
        }
        catch (ex) {
            console.log(`Evaluation error: ${ex.message}`);
            console.log(ex.stack);
            console.log(dimValue);
            console.log(this);
            return false;
        }
    }
    /**
      * Overriden, semantics is used.
      */
    usesSemantics() {
        return true;
    }
    semanticsFn(dimValue) {
        if (!dimValue._semanticsFn) {
            try {
                dimValue._semanticsFn = (new Evaluator()).compile(dimValue.semantics || 'false');
            }
            catch (ex) {
                console.log(`Unable to compile expression: ${ex.message}`);
                console.log(ex.stack);
                console.log(dimValue.semantics);
                dimValue._semanticsFn = function () { return false; };
            }
        }
        return dimValue._semanticsFn;
    }
}
export default Derived;
