angular
    .module('klaro')
    .directive('uiSelectRequired', uiSelectRequired);
function uiSelectRequired() {
    return {
        require: '^ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.uiSelectRequired = function (modelValue, viewValue) {
                const isRequired = attrs.uiSelectRequired ? scope.$eval(attrs.uiSelectRequired) : true;
                return !isRequired || modelValue && modelValue.length;
            };
        },
    };
}
