import { Specification } from '../../model';
import { DimensionCandidate } from './DimensionCandidate';
const TYPICAL_NAMES = [
    /details/i,
    /specification/i,
];
const MIN_LENGTH = 50;
export class SpecificationCandidate extends DimensionCandidate {
    _deliver() {
        return [Specification, {}];
    }
    placeBet() {
        if (this.isMultiple) {
            return 0.0;
        }
        let tooShort = 0.0;
        let invalidType = 0.0;
        for (const value of this.values.keys()) {
            if (typeof value !== 'string') {
                invalidType += 1.0;
                continue;
            }
            if (value.length < MIN_LENGTH) {
                tooShort += 1.0;
            }
        }
        const hasASpecName = !!TYPICAL_NAMES.find(r => this.dimName.match(r));
        const initialBet = (1.0 * this.values.size) / this.valuesSeen
            - (invalidType / this.valuesSeen)
            - (tooShort / this.valuesSeen);
        return hasASpecName
            ? initialBet + 1.0
            : initialBet;
    }
}
