angular
    .module('klaro')
    .controller('PasswordResetModalController', PasswordResetModalController);
function PasswordResetModalController($scope, $location, $http, $uibModalInstance, auth, token, obligation, robust, $window) {
    $scope.error = null;
    $scope.success = false;
    $scope.data = {
        mustChange: (obligation !== undefined),
    };
    $scope.passwordIsOld = (obligation !== undefined);
    $scope.submit = function () {
        $scope.error = null;
        $scope.success = false;
        auth.withToken(token, (done) => {
            $http
                .post('/api/auth/password/reset/', $scope.data)
                .then(() => {
                $scope.success = true;
                $scope.passwordIsOld = false;
                obligation = 'back-to-login';
            })
                .catch(onError)
                .finally(done);
        });
    };
    $scope.dismiss = function () {
        $scope.error = null;
        $scope.success = false;
        auth.withToken(token, (done) => {
            $http
                .post('/api/auth/password/reset/', {
                mustChange: false,
            })
                .then(() => {
                $window.location = '/';
            })
                .catch(onError)
                .finally(done);
        });
    };
    $scope.gotoLogin = function () {
        $uibModalInstance.close();
        $location.path('/auth/login');
    };
    $scope.canGotoLogin = function () {
        return obligation === undefined || obligation === 'back-to-login';
    };
    $scope.canWait = function () {
        return obligation === 'should';
    };
    function onError(err) {
        $scope.error = robust.message(err);
    }
}
