import StackTrace from 'stacktrace-js';
angular
    .module('klaro')
    .service('Perfs', Perfs);
function Perfs($timeout, $rootScope, Profiler) {
    let nbDigest = 0;
    const P = {
        enable: enable,
        clear: clear,
        debug: debug,
        measureUserWait: measureUserWait,
    };
    return P;
    function enable() {
        if (Profiler.enabled) {
            return P;
        }
        $rootScope.$watch(() => {
            nbDigest++;
        });
        Profiler.enable();
        clear();
        return P;
    }
    function clear() {
        Profiler.clear();
        nbDigest = 0;
        return P;
    }
    function debug() {
        debugAngularDigestCycles();
        debugAngularApplyDuration();
        debugAngularTimeoutDuration();
        $timeout(debugAngularWatcherCounts);
        $timeout(debugCorejsProfilerTracking);
        $timeout(clear);
        return P;
    }
    function debugAngularDigestCycles() {
        console.log('Angular digest cycles:', nbDigest);
    }
    function debugAngularApplyDuration() {
        $timeout(() => {
            angular.element(document).injector().invoke(['$rootScope', function ($rootScope) {
                    const t1 = Profiler.now();
                    $rootScope.$apply();
                    const t2 = Profiler.now();
                    console.log('Angular $apply() takes ', Profiler.ms(t2 - t1));
                }]);
        });
    }
    function debugAngularTimeoutDuration() {
        const t1 = Profiler.now();
        $timeout(() => {
            const t2 = Profiler.now();
            console.log('Angular $timeout() takes ', Profiler.ms(t2 - t1));
        });
    }
    function debugAngularWatcherCounts() {
        const root = angular.element(document.getElementsByTagName('body'));
        const watchers = [];
        const f = function (element) {
            angular.forEach(['$scope', '$isolateScope'], (scopeProperty) => {
                if (element.data() && Object.prototype.hasOwnProperty.call(element.data(), scopeProperty)) {
                    angular.forEach(element.data()[scopeProperty].$$watchers, (watcher) => {
                        watchers.push(watcher);
                    });
                }
            });
            angular.forEach(element.children(), (childElement) => {
                f(angular.element(childElement));
            });
        };
        f(root);
        // Remove duplicate watchers
        const watchersWithoutDuplicates = [];
        angular.forEach(watchers, (item) => {
            if (watchersWithoutDuplicates.indexOf(item) < 0) {
                watchersWithoutDuplicates.push(item);
            }
        });
        console.log('Angular $watchers count', watchersWithoutDuplicates.length);
    }
    function debugCorejsProfilerTracking() {
        Profiler.debug();
    }
    function measureUserWait(what, fn) {
        if (!Profiler.enabled) {
            return fn();
        }
        const stringifiedStack = StackTrace.getSync()
            .slice(3, 10)
            .map((sf) => {
            return sf.toString();
        })
            .join('\n  ');
        clear();
        const t1 = Profiler.now();
        const after = function (arg) {
            const t2 = Profiler.now();
            $timeout(() => {
                const t3 = Profiler.now();
                console.log(`Measure[${what}] promise:${Profiler.ms(t2 - t1)} timeout:${Profiler.ms(t3 - t1)} digests: ${nbDigest}`);
                console.log(stringifiedStack);
            });
            return arg;
        };
        return fn().then(after);
    }
}
