class StoryDecorator {
    constructor(story, globalContext) {
        const self = this;
        story.__world.dimensions.forEach((dim) => {
            Object.defineProperty(self, dim.code, {
                get: function () {
                    return dim.getStoryHighLevelValue(story, globalContext);
                },
            });
        });
    }
}
export default StoryDecorator;
