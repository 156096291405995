import { blank } from './blank';
import { ecosystem } from './ecosystem';
import { houseRenovation } from './houseRenovation';
import { onboarding } from './onboarding';
import { teamWork } from './teamWork';
import { tasks } from './tasks';
export const examples = {
    blank,
    ecosystem,
    houseRenovation,
    onboarding,
    teamWork,
    tasks,
};
