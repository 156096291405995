import Dimension from '../Dimension';
class Mine extends Dimension {
    get category() {
        return 'advanced';
    }
    canBeUsedOnCards() {
        return false;
    }
    canBeUsedForOrdering() {
        return false;
    }
    canValuesBeEdited() {
        return false;
    }
    getPatchForStoryValueTo(value, globalContext) {
        const { nickname, _, dimension } = this.decodeContext(globalContext, value);
        if (!nickname) {
            return false;
        }
        return dimension.getPatchForStoryValueTo({ id: nickname }, globalContext);
    }
    getStoryRawValue(story) {
        // make little sense
        return undefined;
    }
    storyValueMatches(story, value, globalContext) {
        const { nickname, _, dimension } = this.decodeContext(globalContext, value);
        if (!nickname) {
            return false;
        }
        return dimension.storyValueMatches(story, { id: nickname }, globalContext);
    }
    matchingValuesOf(story, globalContext) {
        return this.getValues().filter((value) => {
            return this.storyValueMatches(story, value, globalContext);
        });
    }
    firstMatchingValueOf(story, globalContext) {
        const matching = this.matchingValuesOf(story, globalContext);
        return matching ? matching[0] : undefined;
    }
    usesSemantics() {
        return true;
    }
    decodeContext(globalContext, value) {
        if (!globalContext) {
            return false;
        }
        if (!globalContext.requester) {
            return false;
        }
        if (!globalContext.board) {
            return false;
        }
        if (!value) {
            return false;
        }
        if (!value.semantics) {
            return false;
        }
        const nickname = globalContext.requester.nickname;
        const board = globalContext.board;
        const dimension = board.dimension(value.semantics);
        if (!dimension) {
            return false;
        }
        return { nickname, board, dimension };
    }
}
export default Mine;
