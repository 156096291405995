angular
    .module('klaro')
    .directive('stopEvent', stopEvent);
function stopEvent() {
    return {
        link: function (scope, elm) {
            elm.on('click', (evt) => {
                evt.stopPropagation();
            });
        },
    };
}
