import { Installer } from '../../../model';
export class ApiInstallersConnector {
    constructor(client) {
        this.client = client;
    }
    async get() {
        const res = await this.client.api.get('/dimensions/installers/');
        return res.data.map(s => Installer.dress(s));
    }
}
