import { PlaygroundInstallers } from '../../../model/Installer/PlaygroundInstallers';
export class LocalInstallersConnector {
    constructor(client, data) {
        this.client = client;
        this.data = data;
    }
    async get() {
        return Promise.resolve(this.client.store.installers || PlaygroundInstallers);
    }
}
