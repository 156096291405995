import * as LiveScript from 'livescript';
import * as Prelude from 'prelude-ls';
const LS_TPL = `
prelude = @

valueOf = (by) ->
  (y) ->
    x = by(y)
    if x && typeof(x) == "object" && x.semantics
      parseInt(x.semantics)
    else
      x || 0

count = (stories) ->
  stories.length

sum = (by, stories) -->
  stories |> prelude.map(valueOf(by)) |> prelude.sum

avg = (by, stories) -->
  stories |> prelude.map(valueOf(by)) |> prelude.mean
mean = avg
average = avg

min = (by, stories) -->
  stories |> prelude.map(valueOf(by)) |> prelude.minimum
minimum = min

max = (by, stories) -->
  stories |> prelude.map(valueOf(by)) |> prelude.maximum
maximum = max

`;
class Summarizer {
    compile(expr) {
        expr = `${LS_TPL}return ${expr}`;
        const compiled = LiveScript.compile(expr, {
            bare: true,
            header: false,
            const: true,
        });
        return Function(compiled).call(Prelude);
    }
    summarize(stories, byExpr) {
        const compiled = this.compile(byExpr);
        return compiled(stories);
    }
}
export default Summarizer;
