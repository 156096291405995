import DataObject from '../../../utils/DataObject';
import { DateTime, Interval } from 'luxon';
import ArrayUtils from '../../../utils/ArrayUtils';
import StoryDecorator from '../../../lang/StoryDecorator';
import { MoveStoryHandler, MoveStartHandler, MoveStopHandler, DrawNewStoryHandler, StartOfCellStoryHandler, DispatchStoryHandler, } from './DragStoryHandler';
const min = (a, b) => { return a && b ? (a < b ? a : b) : (a ? a : b); };
const max = (a, b) => { return a && b ? (a > b ? a : b) : (a ? a : b); };
const dt = (dt) => { return dt.constructor === DateTime ? dt : DateTime.fromISO(dt); };
export default class Gantt extends DataObject {
    constructor(raw, globalContext) {
        super(Object.assign({
            stickyColumnWidth: 320,
            uiMode: 'compact',
            uiModeOptions: {
                minWidthInPixels: 1200,
            },
        }, raw));
        this._today = {};
        this.globalContext = globalContext;
    }
    isConfigured() {
        return this.board.getDimensionsAt('gantt').length > 0;
    }
    get bestScaleKey() {
        if (!this.isConfigured()) {
            return 'monthly';
        }
        const boundaries = this.getViewportFromStories();
        const dist = this.dist(boundaries[1], boundaries[0]);
        if (dist < 30) {
            return 'daily';
        }
        else if (dist < 60) {
            return 'weekly';
        }
        else if (dist < 120) {
            return 'monthly';
        }
        else {
            return 'quarterly';
        }
    }
    get currentScale() {
        return Gantt.Scales[this.board.ganttScale || this.bestScaleKey];
    }
    isCurrentScale(scaleOrKey) {
        if (typeof (scaleOrKey) === 'object') {
            scaleOrKey = scaleOrKey.key;
        }
        return this.currentScale.key == scaleOrKey;
    }
    categorizedBy() {
        return this.board.getDimensionsAt('displayBy')[0];
    }
    getDateDimension(which) {
        const index = ({
            start: 0,
            stop: 1,
        })[which];
        return this.board.getDimensionsAt('gantt')[index];
    }
    hasDateDimension(which) {
        return !!this.getDateDimension(which);
    }
    getStartDateDimension() {
        return this.getDateDimension('start');
    }
    getStopDateDimension() {
        return this.getDateDimension('stop');
    }
    hasStopDateDimension() {
        return !!this.hasDateDimension('stop');
    }
    canChangeStoryDate(which, editable) {
        if (editable === undefined) {
            editable = true;
        }
        const dateDimension = this.getDateDimension(which);
        return !!(editable && dateDimension && dateDimension.canBeEdited());
    }
    canChangeStoryStartDate(editable) {
        return this.canChangeStoryDate('start', editable);
    }
    canChangeStoryStopDate(editable) {
        return this.canChangeStoryDate('stop', editable);
    }
    canChangeInstalledStoryDates(editable) {
        const startEditable = this.canChangeStoryDate('start', editable);
        const hasStop = this.hasStopDateDimension();
        const stopEditable = this.canChangeStoryDate('stop', editable);
        return startEditable && (!hasStop || stopEditable);
    }
    getViewportFromStories(today = this.today()) {
        return this.memoize('getViewportFromStories', '', () => {
            const globalContext = this.globalContext;
            const dim0 = this.getStartDateDimension();
            const dim1 = this.getStopDateDimension() || dim0;
            const pair = this.stories.visible.reduce((interval, story) => {
                const d0 = dim0.getStoryHighLevelValue(story, globalContext);
                const d1 = dim1.getStoryHighLevelValue(story, globalContext);
                const pair = [min(d0, d1), max(d0, d1)];
                return [min(pair[0], interval[0]), max(pair[1], interval[1])];
            }, [null, null]);
            return [pair[0] ? pair[0] : today, pair[1] ? pair[1] : today];
        });
    }
    get windowing() {
        return this.currentScale.windowing;
    }
    applyWindowing(kind, timepoint) {
        const w = this.windowing[this.uiMode || 'compact'];
        if (kind === 'before') {
            return timepoint.startOf(w.align).minus(w.enlarge);
        }
        else {
            const period = this.currentScale.period;
            const start = this.firstChartDay();
            const stop = timepoint.endOf(w.align).plus(w.enlarge);
            const minDays = this.uiModeOptions.minWidthInPixels / period.width;
            const minStop = start.startOf(period.key).plus({ day: minDays }).endOf(period.key);
            return max(stop, minStop);
        }
    }
    firstChartDay() {
        const memoKey = `${this.currentScale.key}-${this.uiMode}`;
        return this.memoize('firstChartDay', memoKey, () => {
            const startOfTime = this.getViewportFromStories()[0];
            return this.applyWindowing('before', startOfTime || this.today());
        });
    }
    withFirstChartDay(day) {
        if (typeof (day) === 'string') {
            day = DateTime.fromISO(day);
        }
        const dup = this.clone({}, false);
        dup.__cache.set('firstChartDay', '', day);
        return dup;
    }
    lastChartDay() {
        const memoKey = `${this.currentScale.key}-${this.uiMode}`;
        return this.memoize('lastChartDay', memoKey, () => {
            const endOfTime = this.getViewportFromStories()[1];
            return this.applyWindowing('after', endOfTime || this.today());
        });
    }
    gridColumnsInBaseUnit(base = 'day') {
        const last = this.lastChartDay();
        const first = this.firstChartDay();
        return this.dist(last, first, base);
    }
    nowIncluded() {
        const first = this.firstChartDay();
        const last = this.lastChartDay();
        const now = this.today();
        return first <= now && now <= last;
    }
    categoryViews() {
        return this.memoize('categoryViews', this.currentScale.key, () => {
            if (!this.isConfigured()) {
                return [];
            }
            const by = this.categorizedBy();
            const storiesPerCategory = this.stories.rollup(by ? [by] : []);
            if (by) {
                return Object
                    .values(storiesPerCategory.children)
                    .sort((a, b) => {
                    return a.dimensionValue.ordering - b.dimensionValue.ordering;
                })
                    .map((cat, i, arr) => {
                    const categoryRowStart = (arr[i - 1]) ? arr[i - 1].gridRowStart + arr[i - 1].stories.length + 1 : 2;
                    const invariant = {};
                    invariant[cat.dimension.code] = cat.dimensionValue.id;
                    return Object.assign(cat, {
                        gridRowStart: categoryRowStart,
                        invariant: invariant,
                        isEmpty: cat.stories.length == 0,
                    });
                });
            }
            else {
                return [{
                        gridRowStart: 2,
                        invariant: null,
                        dimensionValue: { label: '' },
                        stories: Object.values(storiesPerCategory.stories),
                        isEmpty: false,
                    }];
            }
        });
    }
    factorBaseStoryView(from, to, oldView) {
        from = dt(from);
        to = dt(to);
        const interval = [min(from, to), max(from, to)];
        const gridColumnStart = 1 + this.tpOffset(interval[0]);
        const gridWidth = this.dist(interval[1], interval[0]);
        const gridColumnEnd = gridColumnStart + gridWidth;
        return {
            interval,
            gridColumnStart: 1 + gridColumnStart,
            gridWidth,
            gridColumnEnd: 1 + gridColumnEnd,
            cssClasses: {
                'one-day': oldView.cssClasses['one-day'],
                'no-date': false,
                'draggable': true,
            },
        };
    }
    storyViews(editable) {
        return this.memoize(`storyViews-${editable}`, this.currentScale.key, () => {
            if (!this.isConfigured()) {
                return [];
            }
            return this.categoryViews().reduce((rows, cat) => {
                return rows.concat(cat.stories.map((s, i) => {
                    const storyRowStart = cat.gridRowStart + i + 1;
                    const interval = this.getSortedStoryEnds(s);
                    const storyColumnStart = this.getStoryStartPoint(s);
                    const hasBar = !!storyColumnStart;
                    const gridColumnStart = hasBar ? storyColumnStart + 1 : 1;
                    const gridColumnWidth = hasBar ? this.getStoryDuration(s) : this.gridColumnsInBaseUnit();
                    const gridColumnEnd = hasBar ? storyColumnStart + gridColumnWidth + 1 : this.gridColumnsInBaseUnit();
                    return {
                        story: s,
                        interval: interval,
                        hasBar: hasBar,
                        gridRowStart: storyRowStart,
                        gridColumnStart: Math.min(gridColumnStart, gridColumnEnd),
                        gridWidth: gridColumnWidth,
                        gridColumnEnd: Math.max(gridColumnStart, gridColumnEnd),
                        cssClasses: {
                            'one-day': !this.hasStopDateDimension(),
                            'no-date': !hasBar,
                            'draggable': this.canChangeInstalledStoryDates(editable),
                        },
                    };
                }));
            }, []);
        });
    }
    summaryViews() {
        return this.memoize('summaryViews', this.currentScale.key, () => {
            if (!this.isConfigured()) {
                return [];
            }
            const dims = this.board.getDimensionsAt('gantt');
            const dim = dims[dims.length - 1];
            const sum = (this.board.summaries || [])[0];
            if (!sum) {
                return [];
            }
            return this.categoryViews().reduce((cells, cat) => {
                const storiesPerCell = this.timepoints().map((tp) => {
                    return [];
                });
                cat.stories.forEach((s) => {
                    const date = dim.getStoryHighLevelValue(s, this.globalContext);
                    if (!date) {
                        return;
                    }
                    const cell = this.timepointAt(date.endOf('day'));
                    storiesPerCell[cell.index].push(s);
                });
                return cells.concat(this.timepoints().map((tp) => {
                    const ss = storiesPerCell[tp.index].map((s) => new StoryDecorator(s, this.globalContext));
                    const summary = sum.summarize(ss)[sum.code];
                    return ss.length == 0 ? null : {
                        gridRowStart: cat.gridRowStart,
                        gridColumnStart: tp.gridColumnStart,
                        gridColumnEnd: tp.gridColumnEnd,
                        gridWidth: tp.gridWidth,
                        label: sum.label,
                        value: summary,
                        tooltip: `${sum.label}: ${summary}`,
                    };
                }).filter(Boolean));
            }, []);
        });
    }
    /**
     * Calculates the difference between 2 dates in the given base
     */
    dist(to, from, base = 'day') {
        if (!to || !from) {
            return 1;
        }
        const fromDate = from.startOf(base);
        const toDate = to.endOf(base);
        let interval, factor;
        if (fromDate < toDate) {
            interval = Interval.fromDateTimes(fromDate, toDate);
            factor = 1;
        }
        else {
            interval = Interval.fromDateTimes(toDate, fromDate);
            factor = -1;
        }
        if (base == 'quarter') {
            return factor * Math.ceil(interval.count('month')) / 3;
        }
        else {
            return factor * Math.floor(interval.count(base));
        }
    }
    getSortedStoryEnds(story, dim0, dim1) {
        dim0 = (dim0 === undefined ? this.getStartDateDimension() : dim0);
        dim1 = (dim1 === undefined ? this.getStopDateDimension() : dim1) || dim0;
        const d0 = dim0.getStoryHighLevelValue(story, this.globalContext);
        const d1 = dim1.getStoryHighLevelValue(story, this.globalContext);
        return [min(d0, d1), max(d0, d1)].filter(Boolean);
    }
    /**
     * Where on the chart, on which column, does the story starts.
     * aka, the number of days between the beginning of the chart
     * and the beginning of the story
     **/
    getStoryStartPoint(story) {
        const ends = this.getSortedStoryEnds(story);
        return ends[0] ? 1 + this.tpOffset(ends[0]) : undefined;
    }
    /**
     * How many "days" does a story last for
     **/
    getStoryDuration(story, base = 'day') {
        const ends = this.getSortedStoryEnds(story);
        return this.dist(ends[1], ends[0], base);
    }
    parseDate(dateStr) {
        return DateTime.fromISO(dateStr);
    }
    ithDateTime(i, base = 'day') {
        const beginOfTime = this.firstChartDay();
        return beginOfTime.startOf(base).plus({ [base]: i });
    }
    /**
     * Given a base, provides all columns of the grid in terms of:
     *
     * @return An array of DateTime luxon objects, decorated with extra
     * fields gridColumnStart, gridWidth, isCurrentTimepoint.
     */
    allTimepoints(scaleKey) {
        return this.memoize(`allTimepoints-${scaleKey}`, this.currentScale.key, () => {
            if (!this.isConfigured()) {
                return [];
            }
            const scale = Gantt.Scales[scaleKey];
            const unit = scale.period.key;
            const timepoints = [];
            const endIteration = this.gridColumnsInBaseUnit(unit);
            const doFormat = (timepoint, format) => {
                if (typeof (format) === 'function') {
                    return format(timepoint);
                }
                else {
                    return timepoint.toFormat(format);
                }
            };
            for (let i = 0; i < endIteration; i++) {
                const timepoint = this.ithDateTime(i, unit);
                const gridColumnStart = this.tpOffset(timepoint, 'day') + 2; // + 2 because grid index start at 1 and column 1 is the story row
                const gridWidth = this.tpLimit(timepoint, unit, 'day');
                const timepointData = {
                    index: i,
                    start: timepoint,
                    end: timepoint.endOf(unit),
                    endExclusive: timepoint.endOf(unit).plus({ day: 1 }),
                    htmlId: doFormat(timepoint, scale.idFormat),
                    name: doFormat(timepoint, scale.nameFormat),
                    upper: doFormat(timepoint, scale.upperFormat),
                    tooltip: doFormat(timepoint, scale.tooltipFormat),
                    gridColumnStart: gridColumnStart,
                    gridWidth: gridWidth,
                    gridColumnEnd: gridColumnStart + gridWidth,
                    isCurrentTimepoint: this.isToday(timepoint, unit),
                };
                timepoints.push(Object.assign(timepoint, timepointData));
            }
            return Object.assign(timepoints, {
                gridTemplateColumns: timepoints.reduce((pair, timepoint) => {
                    const tdays = pair.totalDays + timepoint.gridWidth;
                    const tpl = `linear-gradient(#eeeeee, #eeeeee) no-repeat calc( var(--grid-column-day-width) * ${tdays})/1px 100%`;
                    return {
                        totalDays: tdays,
                        gridTemplateColumns: pair.gridTemplateColumns.concat(tpl),
                    };
                }, { totalDays: 0, gridTemplateColumns: [] }).gridTemplateColumns.join(','),
            });
        });
    }
    /**
     * How many days between the beginning of the chart and date.
     */
    tpOffset(date, base = 'day') {
        const first = this.firstChartDay();
        if (date < first) {
            return 0;
        }
        return this.dist(date, first, base) - 1;
    }
    /**
     * How many days between date and the end of the following period of time.
     * E.g. when date is 23rd of September, the result of this function is 7.
     */
    tpLimit(date, period, base = 'day') {
        const endOfPeriod = date.endOf(period);
        const fullDist = this.dist(endOfPeriod, date, base);
        if (date < this.firstChartDay()) {
            return fullDist - this.dist(this.firstChartDay(), date, base);
        }
        else {
            return fullDist;
        }
    }
    // Memoize with new pattern
    // Depends on allTimepoints
    timepoints() {
        if (!this.isConfigured()) {
            return [];
        }
        return this.allTimepoints(this.currentScale.key);
    }
    /**
     * Returns the timepoint corresponding to a given datetime.
     * If datetime is smaller than the first chart day, returns
     * the first timepoint. If bigger than last, returns the
     * last.
     *
     * Uses a binarySearch internally, so O(logn)
     */
    timepointAt(datetime) {
        if (typeof (datetime) === 'string') {
            datetime = DateTime.fromISO(datetime);
        }
        const ts = this.timepoints();
        if (datetime <= ts[0].start) {
            return ts[0];
        }
        else if (datetime >= ts[ts.length - 1].endExclusive) {
            return ts[ts.length - 1];
        }
        else {
            const index = ArrayUtils.binarySearch(ts, (t) => {
                if (t.start <= datetime && datetime < t.endExclusive) {
                    return 0;
                }
                else if (t.start < datetime) {
                    return -1;
                }
                else {
                    return 1;
                }
            });
            return index === undefined ? undefined : ts[index];
        }
    }
    // Memoize with new pattern
    // Depends on allTimepoints
    get navigablePeriods() {
        return this.memoize('navigablePeriods', this.currentScale.key, () => {
            const scale = this.currentScale;
            let gridColumnStart = 0;
            return this.allTimepoints(scale.next).map((tp) => {
                gridColumnStart += tp.gridWidth;
                return Object.assign(tp, {
                    currentAt: gridColumnStart * scale.period.width,
                });
            });
        });
    }
    today(base = 'day') {
        if (!(base in this._today)) {
            this._today[base] = DateTime.local().startOf(base);
        }
        return this._today[base];
    }
    isToday(date, base = 'day') {
        return date.startOf(base).equals(this.today(base));
    }
    daysUntilToday(today = null) {
        if (!this.isConfigured()) {
            return 100;
        }
        today = today || this.today('day');
        return this.tpOffset(today, 'day');
    }
    dndStartFactory() {
        return this.memoize('dndStartFactory', '', () => {
            const factory = {
                whole: (s, u) => {
                    if (s.hasBar) {
                        const c1 = new StartOfCellStoryHandler(this, s, u);
                        const c2 = new MoveStoryHandler(this, s, u);
                        return new DispatchStoryHandler(this, s, u, (opts) => {
                            return opts.ctrl ? c2 : c1;
                        });
                    }
                    else {
                        return new DrawNewStoryHandler(this, s, u);
                    }
                },
                start: (s, u) => {
                    return new MoveStartHandler(this, s, u);
                },
                stop: (s, u) => {
                    return new MoveStopHandler(this, s, u);
                },
            };
            return factory;
        });
    }
    dndStart(variant, storyView, uiStateMapper) {
        const factory = this.dndStartFactory()[variant];
        return factory(storyView, uiStateMapper);
    }
    withCompactMode(modeOptions = {}) {
        return this.clone({
            uiMode: 'compact',
            uiModeOptions: modeOptions,
        });
    }
    withDndMode(modeOptions = {}) {
        return this.clone({
            uiMode: 'dnd',
            uiModeOptions: Object.assign({}, this.uiModeOptions, modeOptions),
        });
    }
}
Gantt.Scales = {
    daily: {
        key: 'daily',
        oneLetter: 'D',
        next: 'monthly',
        windowing: {
            compact: {
                align: 'month',
                enlarge: {
                    day: 0,
                },
            },
            dnd: {
                align: 'day',
                enlarge: {
                    day: 3,
                },
            },
        },
        idFormat: 'yyyyMMdd',
        nameFormat: 'dd',
        upperFormat: 'EEEEE',
        tooltipFormat: 'd MMMM yyyy',
        period: {
            key: 'day',
            width: 45,
            nameFormat: 'MMMM yyyy',
        },
    },
    weekly: {
        key: 'weekly',
        oneLetter: 'W',
        next: 'monthly',
        windowing: {
            compact: {
                align: 'week',
                enlarge: {
                    day: 0,
                },
            },
            dnd: {
                align: 'week',
                enlarge: {
                    week: 1,
                },
            },
        },
        idFormat: 'yyyyMM',
        nameFormat: '\'W\'W',
        upperFormat: 'yy',
        tooltipFormat: function (timepoint) {
            const from = timepoint.startOf('week').toFormat('d MMMM');
            const to = timepoint.endOf('week').toFormat('d MMMM');
            return `${from} - ${to}`;
        },
        period: {
            key: 'week',
            width: 20,
            nameFormat: 'MMMM yyyy',
        },
    },
    monthly: {
        key: 'monthly',
        oneLetter: 'M',
        next: 'quarterly',
        windowing: {
            compact: {
                align: 'quarter',
                enlarge: {
                    day: 0,
                },
            },
            dnd: {
                align: 'month',
                enlarge: {
                    month: 1,
                },
            },
        },
        idFormat: 'yyyyMM',
        nameFormat: 'MMMM',
        upperFormat: 'yy',
        tooltipFormat: 'MMMM yyyy',
        period: {
            key: 'month',
            width: 7,
            nameFormat: '\'Q\'q yyyy',
        },
    },
    quarterly: {
        key: 'quarterly',
        oneLetter: 'Q',
        next: 'yearly',
        windowing: {
            compact: {
                align: 'quarter',
                enlarge: {
                    day: 0,
                },
            },
            dnd: {
                align: 'quarter',
                enlarge: {
                    quarter: 1,
                },
            },
        },
        idFormat: 'q-yyyy',
        nameFormat: 'Qq',
        upperFormat: 'yyyy',
        tooltipFormat: function (timepoint) {
            const from = timepoint.startOf('quarter').toFormat('MMMM');
            const to = timepoint.endOf('quarter').toFormat('MMMM');
            return `${from} - ${to}`;
        },
        period: {
            key: 'quarter',
            width: 2.5,
            nameFormat: 'yyyy',
        },
    },
    yearly: {
        key: 'yearly',
        idFormat: 'yyyy',
        nameFormat: 'yyyy',
        tooltipFormat: 'yyyy',
        upperFormat: '',
        period: {
            key: 'year',
        },
    },
};
