angular
    .module('klaro')
    .directive('boardCardsOrdering', boardCardsOrdering);
function boardCardsOrdering($filter) {
    return {
        restrict: 'EA',
        template: require('@/core/board/boardCardsOrdering.html'),
        link: function (scope) {
            scope.orderingDimensions = scope.board.getOrderingDimensions();
        },
    };
}
