import StorySelection from '../../model/Board/StorySelection';
import AbstractBoardListener from '../AbstractBoardListener';
export default class BoardSelectionListener extends AbstractBoardListener {
    /**
     * When the state changes, checks whether the filters have
     * changed since the last known state and clears the selection
     * if they have changed.
     */
    stateChanged(subject, newState, oldState) {
        return new Promise((resolve, reject) => {
            if (oldState === undefined) {
                resolve(this.clearSelection(newState));
            }
            else {
                const oldFilters = oldState.board.getFilters();
                const newFilters = newState.board.getFilters();
                if (oldFilters == newFilters) {
                    resolve(newState);
                }
                else {
                    resolve(this.clearSelection(newState));
                }
            }
        });
    }
    clearSelection(state) {
        return state.clone({
            storySelection: new StorySelection(),
        });
    }
}
