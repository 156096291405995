angular
    .module('klaro')
    .service('transferOwnershipModal', transferOwnershipModal)
    .controller('TransferOwnershipModalController', TransferOwnershipModalController);
function transferOwnershipModal(centerModal) {
    return centerModal.factor({
        template: require('@/core/member/transferOwnershipModal.html'),
        controller: 'TransferOwnershipModalController',
    });
}
function TransferOwnershipModalController($scope, $uibModalInstance, projectRest, robust, options) {
    $scope.formData = {};
    $scope.current = options.members.find((m) => {
        return m.isOwner();
    });
    $scope.selected = $scope.current;
    $scope.candidates = options.members.filter(m => m.couldBeOwner());
    $scope.success = false;
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.close = function () {
        $uibModalInstance.close($scope.selected);
    };
    $scope.selectOwner = function (m) {
        $scope.selected = m;
    };
    $scope.transferOwnership = function () {
        if ($scope.current == $scope.selected) {
            return $scope.cancel();
        }
        else {
            return projectRest
                .transferOwnership($scope.selected)
                .then(onSuccess)
                .catch(onError);
        }
    };
    function onSuccess() {
        $scope.success = true;
    }
    function onError(err) {
        $scope.error = robust.message(err.data);
    }
}
