angular
    .module('klaro')
    .service('Clipboard', Clipboard);
function Clipboard($q) {
    // Credits to https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
    function fallbackCopyTextToClipboard(text) {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        let successful = false;
        try {
            successful = document.execCommand('copy');
        }
        catch (err) {
            successful = false;
        }
        document.body.removeChild(textArea);
        return successful;
    }
    function copyTextToClipboard(text) {
        if (!navigator.clipboard) {
            return $q((s, f) => {
                const ok = fallbackCopyTextToClipboard(text);
                (ok ? s : f)();
            });
        }
        return navigator.clipboard.writeText(text);
    }
    return {
        copy: copyTextToClipboard,
    };
}
