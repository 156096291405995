import { BoardAnchor } from '@klaro/corejs/model';
angular
    .module('klaro')
    .directive('boardAnchorSelect', boardAnchorSelect);
/**
 * This directive implements the widget that allows selecting a single dimension
 * for a given anchor.
 *
 * This directives requires a ng-model on tag or ancestors. The model must correspond
 * to an anchor list, e.g. `[DimensionCode]`.
 */
function boardAnchorSelect() {
    return {
        restrict: 'E',
        scope: {
            board: '=',
            dimensions: '=',
            none: '=',
        },
        require: '^ngModel',
        template: require('@/core/board/boardAnchorSelect.html'),
        link: function (scope, elm, attrs, ngModel) {
            // Widget model
            function factorAnchor(list) {
                return BoardAnchor.list(list, { dimensions: scope.dimensions });
            }
            function getBoardAnchor() {
                const list = ngModel.$modelValue ? ngModel.$modelValue : [];
                return factorAnchor(list);
            }
            // Widget view
            function installView(anchor) {
                scope.model = { selected: anchor.toCodes()[0] };
            }
            ngModel.$render = function format() {
                installView(getBoardAnchor());
            };
            ngModel.$render();
            // ngModel sync
            function syncNgModel(anchor) {
                ngModel.$setViewValue(anchor.toList());
                installView(anchor);
            }
            // Widget operations
            const setOptions = () => {
                const opts = scope.dimensions.map((d) => {
                    return {
                        code: d.code,
                        label: d.label,
                    };
                });
                if (scope.none) {
                    opts.unshift({
                        code: null,
                        label: '[none]',
                    });
                }
                scope.options = opts;
            };
            scope.$watchGroup(['dimensions', 'none'], setOptions);
            scope.$watch('model.selected', (newCode, oldCode) => {
                if (!newCode && !oldCode) {
                    return;
                }
                if (newCode === oldCode) {
                    return;
                }
                if (newCode) {
                    syncNgModel(factorAnchor([newCode]));
                }
                else if (oldCode) {
                    syncNgModel(factorAnchor([]));
                }
            });
        },
    };
}
