class LocalStoragePersistence {
    get(key, defaultValue) {
        let item = localStorage.getItem(key);
        if (item) {
            item = JSON.parse(item);
        }
        else {
            item = defaultValue;
            this.set(key, defaultValue);
        }
        return item;
    }
    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
        return this;
    }
    delete(key) {
        localStorage.removeItem(key);
        return this;
    }
}
export default LocalStoragePersistence;
