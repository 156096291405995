import Dimension from '../Dimension';
import Scalar from './Mixins/Scalar';
const INTEGER_RANGE_RX = /^\s*([-+]?(Inf|\d+))\s*\.\.\s*([-+]?(Inf|\d+))\s*$/;
const NONE = function (x) { return false; };
class Integer extends Dimension {
    static getRawFromHighValue(high) {
        return high;
    }
    /**
      * Overriden to return the raw value as high-level one.
      */
    getStoryHighLevelValue(story, globalContext) {
        return this.getStoryRawValue(story);
    }
    getStoryHumanValue(story, _globalContext) {
        const raw = this.getStoryRawValue(story);
        return raw ? raw : '';
    }
    /**
     * Returns a patch to apply on a story to get it having the specific value.
     */
    getPatchForStoryValueTo(value, _globalContext) {
        const matcher = Integer.getMatcherForExpr(value.semantics);
        const update = {};
        if (matcher && matcher.min != Number.NEGATIVE_INFINITY && matcher.min != Number.POSITIVE_INFINITY) {
            update[this.code] = matcher.min;
        }
        return update;
    }
    /**
      * Overriden to take the left interval value of `dimValue`'s semantics and
      * setting it as story raw dimension value.
      */
    withStoryValueTo(story, dimValue, globalContext) {
        const update = this.getPatchForStoryValueTo(dimValue, globalContext);
        return story.clone(update);
    }
    /**
      * Overriden to implement the integer range semantics: the match occurs when
      * the raw dimension value is within `dimValue`'s range.
      */
    storyValueMatches(story, dimValue, _globalContext) {
        const storyValue = this.getStoryRawValue(story);
        if (storyValue == null) {
            return dimValue.id == null;
        }
        if (dimValue.id == null) {
            return false;
        }
        const matcher = Integer.getMatcherForExpr(dimValue.semantics);
        return matcher(storyValue);
    }
    /**
      * Overriden to use an `integer` input widget.
      */
    getInputWidgetCode() {
        return 'integer';
    }
    /**
     * +1
     */
    swipeRight(story) {
        const data = {};
        data[this.code] = (story[this.code] + 1);
        return story.clone(data);
    }
    /**
     * -1
     */
    swipeLeft(story) {
        const data = {};
        data[this.code] = (story[this.code] - 1);
        return story.clone(data);
    }
    getTableAlignmentClass() {
        return 'align-right';
    }
    static getMatcherForExpr(expr) {
        if (INTEGER_RANGE_RX[expr] === undefined) {
            INTEGER_RANGE_RX[expr] = Integer.compileExpr(expr);
        }
        return INTEGER_RANGE_RX[expr];
    }
    static validExpr(expr) {
        if (!expr) {
            return false;
        }
        return !!expr.match(INTEGER_RANGE_RX);
    }
    static compileExpr(expr) {
        if (!expr) {
            return NONE;
        }
        const compileOne = function (one) {
            if (one == 'Inf' || one == '+Inf') {
                return Number.POSITIVE_INFINITY;
            }
            if (one == '-Inf') {
                return Number.NEGATIVE_INFINITY;
            }
            return parseInt(one);
        };
        const match = expr.match(INTEGER_RANGE_RX);
        if (!match) {
            return NONE;
        }
        const min = compileOne(match[1]);
        const max = compileOne(match[3]);
        const matcher = function (x) {
            return x >= min && x < max;
        };
        matcher.min = min;
        matcher.max = max;
        return matcher;
    }
}
Object.assign(Integer.prototype, Scalar);
export default Integer;
