import { ArrayUtils } from '../utils';
class BoardAnchor {
    constructor(rel, world) {
        this.rel = rel;
        this.world = world;
    }
    isEmpty() {
        return this.rel.length === 0;
    }
    // Axiomatic contract
    get(dimension) {
        const code = dimension.code;
        return this.rel.find((t) => {
            return t.code === code;
        });
    }
    has(dimension) {
        const code = dimension.code;
        return this.rel.some((t) => {
            return t.code === code;
        });
    }
    add(dimension) {
        const code = dimension.code;
        return new BoardAnchor(this.rel.concat([
            { code: code, sign: true, ordering: this.rel.length },
        ]), this.world);
    }
    remove(dimension) {
        const code = dimension.code;
        return new BoardAnchor(this.rel.filter((t) => {
            return t.code != code;
        }), this.world);
    }
    move(dimension, at) {
        if (at >= this.rel.length) {
            at = this.rel.length;
        }
        const code = dimension.code;
        const names = this.toCodes();
        const wasAt = names.indexOf(code);
        const newNames = ArrayUtils.moveAt(names, wasAt, wasAt < at ? at - 1 : at);
        return new BoardAnchor(this.rel.map((t) => {
            return Object.assign({}, t, { ordering: newNames.indexOf(t.code) });
        }), this.world);
    }
    toggle(dimension) {
        return this.has(dimension) ? this.remove(dimension) : this.add(dimension);
    }
    isPositive(dimension) {
        const code = dimension.code;
        const tuple = this.rel.find((t) => { return t.code == code; });
        return tuple && tuple.sign;
    }
    setSign(dimension, sign) {
        const code = dimension.code;
        return new BoardAnchor(this.rel.map((t) => {
            if (t.code == code) {
                return Object.assign({}, t, { sign: sign });
            }
            else {
                return t;
            }
        }), this.world);
    }
    toggleSign(dimension) {
        return this.setSign(dimension, !this.isPositive(dimension));
    }
    // <list> information contract
    static list(list, world) {
        return new BoardAnchor(list.map((l, i) => {
            if (l.match(/^-/)) {
                return { code: l.substring(1), sign: false, ordering: i };
            }
            else {
                return { code: l, sign: true, ordering: i };
            }
        }), world);
    }
    toList() {
        return this.rel
            .sort((t1, t2) => {
            return t1.ordering - t2.ordering;
        })
            .map((t) => {
            return t.sign ? t.code : `-${t.code}`;
        });
    }
    // <rel> information contract
    static rel(rel, world) {
        return new BoardAnchor(rel, world);
    }
    toRel() {
        return this.rel;
    }
    // <queryString> information contract
    static queryString(qs, world) {
        return BoardAnchor.list(qs.split(','), world);
    }
    toQueryString() {
        return this.toList().join(',');
    }
    toQueryParams() {
        return { _orderBy: this.toQueryString() };
    }
    // <selector> semi information contract
    toSelector() {
        const index = this.rel.reduce((memo, t) => {
            memo[t.code] = { index: t.ordering, sign: t.sign, selected: true };
            return memo;
        }, {});
        const one = (d, i) => {
            const base = { dimension: d };
            if (index[d.code]) {
                return Object.assign(base, index[d.code]);
            }
            else {
                return Object.assign(base, { index: 1000000 + i, sign: true, selected: false });
            }
        };
        return this
            .world
            .dimensions
            .map(one)
            .sort((d1, d2) => {
            return d1.index - d2.index;
        });
    }
    // <codes> semi information contract
    toCodes() {
        return this.rel.map((t) => { return t.code; });
    }
    // <dict> semi information contract
    toDict() {
        return this.rel.reduce((dict, t) => {
            dict[t.code] = { ordering: t.ordering, sign: t.sign };
            return dict;
        }, {});
    }
    // <storySorter> semi information contract
    toStorySorter(globalContext) {
        return this
            .toSelector()
            .filter((t) => {
            return t.selected;
        })
            .map((t) => {
            return t.dimension.getStorySorter(!t.sign, globalContext);
        });
    }
    isEqual(other) {
        return this.toQueryString() === other.toQueryString();
    }
}
export default BoardAnchor;
