angular
    .module('klaro')
    .service('cardAppearanceModal', cardAppearanceModal)
    .controller('CardAppearanceController', CardAppearanceController);
function cardAppearanceModal(klaroModal) {
    return {
        open: function (board, dimensions, kind, shownDims, colorDim) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/story/cardAppearanceModal.html'),
                controller: 'CardAppearanceController',
                windowClass: 'klaro-modal klaro-overlay',
                size: 'small',
                resolve: {
                    board: function () {
                        return board;
                    },
                    dimensions: function () {
                        return dimensions;
                    },
                    kind: function () {
                        return kind;
                    },
                    shownDims: function () {
                        return shownDims;
                    },
                    colorDim: function () {
                        return colorDim;
                    },
                },
            });
        },
    };
}
function CardAppearanceController($scope, board, $filter, $uibModalInstance, dimensions, kind, shownDims, colorDim, cardAppearanceRest, failedModal, navigation) {
    $scope.boardDimensions = $filter('orderBy')(dimensions.filter((d) => { return d.canBeUsedOnCards(); }), '+label');
    $scope.linkedCardsDimensions = shownDims;
    $scope.coloredDimension = colorDim.code;
    $scope.coloredDimensionsForCards = $filter('orderBy')(board.getColoredDimensions(), '+label');
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.commit = function (kind, kindSummaryData) {
        $uibModalInstance.close({
            kind: kind,
            summaryDimensions: kindSummaryData.dimensions,
            colorDimension: kindSummaryData.color,
        });
    };
    $scope.save = function () {
        const kindSummaryData = {};
        kindSummaryData.dimensions = $scope.linkedCardsDimensions;
        kindSummaryData.color = $scope.coloredDimension;
        cardAppearanceRest
            .saveKindSummary(kind, kindSummaryData)
            .then(() => {
            $scope.commit(kind, kindSummaryData);
        })
            .catch(navigation.failed);
    };
}
