angular
    .module('klaro')
    .filter('spaceFiltersSummary', spaceFiltersSummary);
function spaceFiltersSummary() {
    return function (input) {
        if (!input || !input.filters) {
            return '';
        }
        const size = Object.keys(input.filters).length;
        if (input.filtersMode == 'whitelist') {
            if (size == 0) {
                return 'All hidden';
            }
            else {
                return `${size} filter${size > 1 ? 's ' : ' '}whitelisted`;
            }
        }
        else {
            if (size == 0) {
                return 'No filtering';
            }
            else {
                return `${size} filter${size > 1 ? 's ' : ' '}apply`;
            }
        }
    };
}
