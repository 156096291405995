import { MarkdownIt, Markdowner } from '@klaro/corejs/utils';
import * as angular from 'angular';
angular
    .module('klaro')
    .constant('MarkdownIt', MarkdownIt({
    html: false,
    linkify: true, // Don't bother the user to write []() all the time...
}))
    .constant('Markdowner', Markdowner)
    .filter('markdown', markdown);
function markdown(MarkdownIt) {
    return function (text) {
        return MarkdownIt.render(text);
    };
}
