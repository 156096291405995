import Node from './Node';
class PrintTable extends Node {
    getDimensionsOnCards(args) {
        if (!this.board) {
            return [];
        }
        if (args && args.shownDimensions) {
            return args.shownDimensions
                .map(dimCode => this.board.dimension(dimCode))
                .filter(Boolean);
        }
        else {
            return this.board.getDimensionsAt('cards');
        }
    }
    toHtml(stories, args) {
        return `
      ${this.before(stories, args)}
      <table>
        <thead>
          <tr>
            <th>Card title</th>
            ${this.thsHtml(args)}
          </tr>
        </thead>
        <tbody>
          ${this.tbodyHtml(stories, args)}
        </tbody>
      </table>
      ${this.after(stories, args)}
    `;
    }
    thsHtml(args) {
        return this.getDimensionsOnCards(args)
            .map(dim => `<th>${dim.label}</th>`)
            .join('\n');
    }
    tbodyHtml(stories, args) {
        return stories
            .map(s => this.storyToHtml(s, args))
            .join('\n');
    }
    storyToHtml(story, args) {
        return [
            '<tr>',
            '<td>',
            ...this.storyTopTitleToHtml(story),
            ...this.storySummaryToHtml(story),
            ...this.storyDescriptionToHtml(story),
            '</td>',
            ...this.columnsHtml(story, args),
            '</tr>',
        ].filter(s => s && s.trim().length > 0).join('');
    }
    storyTopTitleToHtml(story) {
        return [
            '<b>',
            story.toptitle,
            '</b>',
        ];
    }
    storySummaryToHtml(story) {
        const summary = story.summary; // Markdowned, security ok
        if (summary.length === 0) {
            return [];
        }
        ;
        return [
            '<b>',
            summary,
            '</b>'
        ];
    }
    storyDescriptionToHtml(story) {
        if (!this.options.includeCardDescription) {
            return [];
        }
        return [
            story.specToHtml(), // Markdowned, security ok
        ].filter(Boolean);
    }
    columnsHtml(story, args) {
        if (!this.board) {
            return [];
        }
        return this.getDimensionsOnCards(args).map(dim => {
            const text = this.escapeHtml(dim.getStoryHumanValue(story, this.globalContext));
            const clazz = dim.getTableAlignmentClass();
            return `
        <td class="${clazz}">${text}</td>
      `;
        });
    }
}
export default PrintTable;
