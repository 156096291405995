import Dimension from '../Dimension';
let _instance = null;
class Identifier extends Dimension {
    get category() {
        return 'system';
    }
    static instance() {
        if (!_instance) {
            _instance = Identifier.dress({
                id: '98a2db6b-d5da-4b54-be74-5461ed070bd1',
                code: 'identifier',
                label: 'Card n°',
                datatype: 'Identifier',
                datatypeOptions: {},
                userEditable: false,
                attribute: true,
                tagging: false,
                required: true,
                supportsMultiple: false,
                supportsRequired: false,
                supportsTagging: false,
                multiple: false,
                supportsColor: false,
                ordering: -2,
                relevantKinds: [],
                values: [],
            });
        }
        return _instance;
    }
    matchingValuesOf(story, _globalContext) {
        return [{ id: this.getStoryRawValue(story) }];
    }
    getStoryHumanValue(story, _globalContext) {
        return `N° ${this.getStoryRawValue(story)}`;
    }
    isPhantomDimension() {
        return true;
    }
    participatesToTextSearch() {
        return true;
    }
    searchMatchesOn(search, regexp, story, _globalContext) {
        if (search.match(/^\s*\d+\s*$/)) {
            const identifier = this.getStoryRawValue(story);
            return identifier === parseInt(search);
        }
        else {
            return false;
        }
    }
}
export default Identifier;
