export const houseRenovation = async () => {
    return {
        board: {
            label: 'House renovation',
            mode: 'kanban',
            displayDimensionsOnCards: true,
            displayDimensionLabels: true,
            displayNoneOnCards: false,
            decksOpen: true,
            background: 'default',
            filters: {},
            anchors: {
                displayBy: ['state'],
            },
            coloredDimension: 'state',
            dimensions: [{
                    code: 'area',
                    label: 'Area',
                    datatype: 'UserDefined',
                    required: true,
                    userEditable: true,
                    values: [
                        { id: 'kitchen', label: 'Kitchen' },
                        { id: 'bathroom', label: 'Bathroom' },
                        { id: 'garden', label: 'Garden' },
                        { id: 'roof', label: 'Roof' },
                        { id: 'living', label: 'Living' },
                        { id: 'bedroom', label: 'Bedroom' },
                    ],
                }, {
                    code: 'contractor',
                    label: 'Contractor',
                    datatype: 'UserDefined',
                    required: true,
                    userEditable: true,
                    values: [
                        { id: 'electrician', label: 'Electrician', color: '#E01514' },
                        { id: 'builder', label: 'Builder', color: '#FFBA00' },
                        { id: 'gardener', label: 'Gardener', color: '#00B048' },
                    ],
                }, {
                    code: 'state',
                    label: 'Progress',
                    datatype: 'UserDefined',
                    required: true,
                    userEditable: true,
                    values: [
                        { id: 'todo', label: 'Todo', color: '#E01514' },
                        { id: 'ongoing', label: 'Ongoing', color: '#FFBA00' },
                        { id: 'done', label: 'Done', color: '#00B048' },
                    ],
                }],
        },
        stories: [{
                id: 'd655818f-75ff-41a3-8396-d00d2a606c53',
                title: 'Breaking down the wall',
                subtitle: 'for the minibar',
                state: 'todo',
                area: 'kitchen',
                contractor: 'builder',
                specification: 'This task involves carefully demolishing a section of the kitchen wall to create space for a new minibar. It requires precise work to ensure structural integrity while providing the desired layout for the minibar installation.',
            }, {
                id: '66f0711c-16a5-481d-ab8b-283fca0b0f12',
                title: 'Finalise electricity supply',
                subtitle: 'to install ceiling light',
                state: 'todo',
                area: 'roof',
                contractor: 'electrician',
                specification: 'The electrician needs to complete the electrical connections in the roof area, ensuring that the wiring is safe and meets code requirements for the installation of a new ceiling light.',
            }, {
                id: '9d1b3931-600f-401d-841f-260e395ffcd0',
                title: 'Pipework final check',
                subtitle: 'to be ready to plumb in the new shower',
                state: 'todo',
                area: 'bathroom',
                contractor: 'builder',
                specification: 'This involves a thorough inspection and testing of the bathroom\'s pipework to confirm it is ready for the new shower installation. The check ensures all pipes are properly aligned, sealed, and functioning correctly.',
            }, {
                id: 'a79f682a-fb27-4b27-b18c-9f13ff711e85',
                title: 'Fix garden gate',
                subtitle: 'It\'s hanging loose off the hinges and doesn\'t lock properly',
                state: 'todo',
                area: 'garden',
                contractor: 'gardener',
                specification: 'The task includes repairing or replacing the hinges of the garden gate and ensuring it closes and locks properly. This may involve realigning the gate, adjusting the latch, and securing it for safety and security.',
            }],
    };
};
