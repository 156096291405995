let tracking = {};
const Profiler = {
    enabled: false,
    now: now,
    measure: measure,
    clear: clear,
    enable: enable,
    track: track,
    ms: ms,
    debug: debug,
};
export default Profiler;
function now() {
    if (typeof (performance) === 'undefined') {
        return (new Date()).getTime();
    }
    else {
        // eslint-disable-next-line no-undef
        return performance.now();
    }
}
function measure(fn) {
    const t1 = now();
    fn();
    const t2 = now();
    return t2 - t1;
}
function clear() {
    tracking = {};
}
function enable() {
    clear();
    Profiler.enabled = true;
    return this;
}
function track(meth, fn) {
    if (!Profiler.enabled) {
        return fn();
    }
    const t1 = now();
    const result = fn();
    const t2 = now();
    tracking[meth] = tracking[meth] || [0, 0];
    tracking[meth][0] += 1;
    tracking[meth][1] += (t2 - t1);
    return result;
}
function ms(ms) {
    return `${ms.toFixed(2)}ms.`;
}
function debug() {
    Object
        .keys(tracking)
        .map(k => {
        const pair = tracking[k];
        return [k, pair[0], pair[1] / pair[0], pair[1]];
    })
        .sort((a, b) => b[3] - a[3])
        .filter((info) => info[3] >= 5.0)
        // eslint-disable-next-line no-console
        .forEach(info => console.log(info[0], `count: ${info[1]}`, `avg: ${ms(info[2])}`, `total: ${ms(info[3])}`));
}
