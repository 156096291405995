angular
    .module('klaro')
    .directive('togglableSection', togglableSection);
function togglableSection() {
    return {
        template: require('@/views/settings/togglableSection.html'),
        transclude: {
            'header': '?togglableSectionHeader',
            'body': 'togglableSectionBody',
        },
        replace: true,
        link: function (scope, elm, attrs) {
            scope.showEmpty = (attrs.noEmpty === undefined);
        }
    };
}
