angular
    .module('klaro')
    .factory('emailPreferencesRest', emailPreferencesRest);
function emailPreferencesRest($http) {
    return {
        get: get,
        post: post,
    };
    function get() {
        return $http
            .get('/api/my/email-preferences')
            .then((response) => {
            return response.data;
        });
    }
    function post(preferences) {
        return $http
            .post('/api/my/email-preferences', preferences)
            .then((response) => {
            return response.data;
        });
    }
}
