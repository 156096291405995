angular
    .module('klaro')
    .service('confirm', confirmService)
    .controller('ConfirmDialogController', ConfirmDialogController);
function confirmService(klaroModal, navigation) {
    /**
     * The confirmation service can be used to ask for various confirmations
     * to the user. Their are various patterns to use it, illustrated below.
     *
     *     // Simple message and yes callback
     *     confirm("Hello world?", function() {
     *       // called on 'yes'
     *     })
     *
     *     // Simple message and yes & no callbacks
     *     confirm("Hello world?", function() {
     *       // called on 'yes'
     *     }, function() {
     *       // called on 'no'
     *     })
     *
     *     // Configuring message and buttons
     *     confirm({
     *       h2: "Are your sure?",
     *       hint: "This cannot be cancelled",
     *       yesLabel: "YES, I'm sure",
     *       noLabel: "NO, I changed my mind"
     *     }, function() {})
     *
     * In this mode, the service returns a promise that resolves to true if yes
     * is selected and fails otherwise.
     *
     * Advanced options allows showing a done message after the yes callback
     * is executed successfully. This requires it to return a promise, though:
     *
     *     // Keeping the dialog open after action is done
     *     confirm({
     *       showDone: true,
     *       doneMsg: "It worked!!"
     *     }, function() {
     *       // this MUST return a promise, responding to `then` and `catch`
     *     })
     *
     * In this mode, the service returns a promise that resolves to the yes
     * result itself in case of success and fallbacks to navigation.failed in
     * case of failure. This could be changed in the future and should be used
     * with care.
     */
    const service = function (msg, yesCallback, noCallback) {
        const modalInstance = klaroModal.open({
            //animation: false,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: require('@/support/confirm.html'),
            controller: 'ConfirmDialogController',
            windowClass: 'klaro-modal confirm-modal',
            size: 'small',
            resolve: {
                msg: function () {
                    return msg;
                },
                yesCallback: function () {
                    return yesCallback;
                },
            },
        });
        return modalInstance
            .result
            .then(msg && !msg.showDone ? yesCallback : navigation.noop)
            .catch(noCallback || navigation.noop);
    };
    return service;
}
function ConfirmDialogController($scope, msg, yesCallback, $uibModalInstance, MarkdownIt, navigation) {
    msg = angular.isObject(msg) ? msg : { h2: 'Are your sure?', hint: msg };
    $scope.h2 = msg.h2 ? msg.h2 : undefined;
    $scope.hint = msg.hint ? render(msg.hint) : undefined;
    $scope.headerClass = msg.headerClass;
    $scope.yesLabel = msg.yesLabel || 'Yes';
    $scope.yesClass = msg.yesClass || 'btn-success';
    $scope.done = false;
    $scope.doneLabel = msg.doneLabel || 'Close';
    $scope.noLabel = msg.noLabel || 'No';
    $scope.noClass = msg.noClass || 'btn-default';
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.no = function () {
        $uibModalInstance.dismiss(false);
    };
    $scope.yes = function () {
        if (msg.showDone) {
            yesCallback(true)
                .then((result) => {
                $scope.done = result || true;
                if (msg.doneMsg) {
                    $scope.h2 = msg.doneMsg;
                }
                return result;
            })
                .catch(navigation.failed);
        }
        else {
            $uibModalInstance.close(true);
        }
    };
    $scope.close = function () {
        $uibModalInstance.close($scope.done);
    };
    function render(msg) {
        return MarkdownIt.render(msg);
    }
}
