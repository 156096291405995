import { Story } from '../../../model';
import { SetBoardState } from '../Board';
export class PatchStory {
    constructor() {
        this.preconditions = [
            async (client) => {
                return !!client.store.board;
            }
        ];
    }
    async run(client, args) {
        const boardState = client.store.board;
        const board = boardState.board;
        const patcher = Story.Patcher;
        const story = args.patch;
        const { connector } = client.store;
        const original = boardState?.stories.getStoryById(story.id);
        const newStory = story instanceof Story ? story.toRaw() : story;
        const patch = patcher.shallow(original?.toRaw(), newStory);
        if (!original || !original.id) {
            return;
        }
        ;
        // TODO FIX THIS
        delete patch.linked;
        delete patch.attachments;
        const toSend = {
            id: original.id,
            ...patch
        };
        const endpoint = board.isAllCards() ? connector.stories : connector.boards.single(boardState.board.id).stories;
        const storyRaw = await endpoint.single(original.id).patch(toSend);
        const reloaded = original.clone(storyRaw);
        const newState = await client.store.board?.storyChanged(reloaded);
        client.run(new SetBoardState(), { board: newState });
        this._result = reloaded;
    }
    get result() {
        return this._result;
    }
}
;
