// eslint-disable-next-line angular/directive-restrict
angular
    .module('klaro')
    .directive('paneModalNav', paneModalNav)
    .directive('paneModalNavLink', paneModalNavLink)
    .directive('paneModalNavTarget', paneModalNavTarget)
    .directive('paneModalNavContainer', paneModalNavContainer)
    .directive('paneModalNavReset', paneModalNavReset);
function paneModalNav() {
    return {
        restrict: 'A',
        link: function (scope, iElement, iAttrs) {
            scope.paneModalNavDefault = iAttrs.paneModalNav;
            if (iAttrs.paneModalNavDefault) {
                scope.$watch(iAttrs.paneModalNavDefault, (newVal) => {
                    if (newVal) {
                        scope.paneModalNavState = newVal;
                    }
                });
            }
            else {
                scope.paneModalNavState = iAttrs.paneModalNav;
            }
        },
        controller: ['$scope', function ($scope) {
                const $listeners = $scope.$new(true);
                this.reset = function () {
                    this.switchTab(null);
                };
                this.switchTab = function (tab) {
                    $scope.paneModalNavState = tab;
                    $listeners.$emit('paneModalNavChanged');
                };
                this.hasCurrentTab = function () {
                    return !!$scope.paneModalNavState;
                };
                this.isDefaultTab = function () {
                    return $scope.paneModalNavState === $scope.paneModalNavDefault;
                };
                this.getCurrentTab = function () {
                    return $scope.paneModalNavState;
                };
                this.$on = function (event, listener) {
                    $listeners.$on(event, listener);
                };
                $scope.$watch('paneModalNavState', () => {
                    $listeners.$emit('paneModalNavChanged');
                });
            }],
    };
}
function paneModalNavLink() {
    return {
        restrict: 'A',
        require: '^paneModalNav',
        link: function (scope, iElement, iAttrs, paneModalNav) {
            iElement.click(() => {
                scope.$apply(() => {
                    paneModalNav.switchTab(iAttrs.paneModalNavLink);
                });
            });
            function refresh() {
                const newTab = paneModalNav.getCurrentTab();
                if (newTab === iAttrs.paneModalNavLink) {
                    iElement.addClass('active');
                }
                else {
                    iElement.removeClass('active');
                }
            }
            paneModalNav.$on('paneModalNavChanged', refresh);
        },
    };
}
function paneModalNavTarget() {
    return {
        restrict: 'A',
        require: '^paneModalNav',
        link: function (scope, iElement, iAttrs, paneModalNav) {
            function refresh() {
                const newTab = paneModalNav.getCurrentTab();
                if (newTab === iAttrs.paneModalNavTarget) {
                    iElement.show();
                }
                else {
                    iElement.hide();
                }
            }
            paneModalNav.$on('paneModalNavChanged', refresh);
        },
    };
}
function paneModalNavReset() {
    return {
        restrict: 'A',
        require: '^paneModalNav',
        link: function (scope, iElement, iAttrs, paneModalNav) {
            paneModalNav.$on('paneModalNavChanged', () => {
                if (paneModalNav.isDefaultTab()) {
                    iElement.css('visibility', 'hidden');
                }
                else {
                    iElement.css('visibility', 'visible');
                }
            });
            iElement.click(() => {
                scope.$apply(() => {
                    paneModalNav.reset();
                });
            });
        },
    };
}
function paneModalNavContainer() {
    return {
        restrict: 'C',
        require: '^paneModalNav',
        link: function (scope, iElement, iAttrs, paneModalNav) {
            paneModalNav.$on('paneModalNavChanged', () => {
                if (paneModalNav.hasCurrentTab()) {
                    iElement.addClass('has-current-tab');
                }
                else {
                    iElement.removeClass('has-current-tab');
                }
            });
        },
    };
}
