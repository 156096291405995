angular
    .module('klaro')
    .directive('toggle', toggle);
function toggle() {
    return {
        template: require('@/support/toggle.html'),
        scope: {
            label: '@',
            onClick: '&?',
        },
        transclude: true,
        require: '^ngModel',
        link: function (scope, elm, attrs, ngModelController) {
            scope.isOff = function () {
                return !ngModelController.$viewValue;
            };
            scope.isOn = function () {
                return !!ngModelController.$viewValue;
            };
            elm.click((e) => {
                scope.$apply(() => {
                    if (scope.onClick) {
                        scope.onClick({ e: e });
                    }
                    else {
                        ngModelController.$setViewValue(!ngModelController.$viewValue);
                    }
                });
            });
        },
    };
}
