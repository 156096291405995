import Dimension from '../Dimension';
let _instance = null;
// Null object for projects having no Kind dimension (or hidden)
class FakeKind extends Dimension {
    get category() {
        return 'system';
    }
    static instance() {
        if (!_instance) {
            _instance = new FakeKind({
                fakeDimension: true,
                id: '696e8aa0-f933-48c2-a130-04f5fce098e8',
                code: 'fakeKind',
                label: 'Kind',
                datatype: 'FakeKind',
                datatypeOptions: {},
                userEditable: false,
                attribute: false,
                tagging: false,
                required: true,
                supportsMultiple: false,
                supportsRequired: false,
                supportsTagging: false,
                multiple: false,
                supportsColor: true,
                ordering: -1,
                relevantKinds: [],
                values: [{
                        id: 'card',
                        label: 'Card kind',
                        semantics: '',
                        color: '#00b048',
                        ordering: 0,
                        description: 'Card',
                        deprecated: false,
                    }],
            });
        }
        return _instance;
    }
    isPhantomDimension() {
        return true;
    }
    get colored() {
        return true;
    }
    isUserDefinedSemantics() {
        return false;
    }
    usesSemantics() {
        return false;
    }
    canBeUsedOnDecks() {
        return false;
    }
    withStoryValueTo(story, value, globalContext) {
        return story;
    }
    getStoryRawValue() {
        return 'card';
    }
    getStoryHighLevelValue(story, globalContext) {
        return this.values[0];
    }
    getStoryColor(story, globalContext) {
        return this.values[0].color;
    }
    matchingValuesOf(story, globalContext) {
        return this.values;
    }
    firstMatchingValueOf(story, globalContext) {
        return this.values[0];
    }
    storyValueMatches(story, value, globalContext) {
        return true;
    }
    isRelevant() {
        return true;
    }
    clone() {
        return this;
    }
}
export default FakeKind;
