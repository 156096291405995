import { DateTime } from 'luxon';
class TimeUtils {
    static now() {
        return DateTime.now();
    }
    static today() {
        return DateTime.now().startOf('day');
    }
    static parseHumanShortDateStrAsJsDate(dateStr, project) {
        const format = DateTime.parseFormatForOpts(DateTime.DATE_SHORT, { locale: project.locale });
        const got = DateTime.fromFormat(dateStr, format, {
            zone: project.timezone,
            locale: project.locale,
        });
        return got.isValid ? got.toJSDate() : null;
    }
    static jsDateToHumanShortDateStr(date, project) {
        const dt = DateTime.fromJSDate(date);
        if (dt.isValid) {
            return dt.setLocale(project.locale).setZone(project.timezone).toLocaleString();
        }
        else {
            return null;
        }
    }
}
export default TimeUtils;
