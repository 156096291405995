angular
    .module('klaro')
    .directive('storyBigForm', storyBigForm);
function storyBigForm(Colors, Markdowner, $sce, events, robust, debounce, navigation) {
    return {
        restrict: 'E',
        template: require('@/core/story/storyBigForm.html'),
        scope: {
            inBoard: '=',
            story: '=',
            storyData: '=',
            storyColor: '=',
            dimensions: '=',
            readOnly: '=',
            editing: '=',
            editingDescription: '=',
            savingError: '=',
            onValueCreated: '&',
            onDimensionCreated: '&',
            onReloadRequest: '&',
            onScrollChanged: '&',
        },
        link: function (scope, elm, attrs) {
            elm.addClass('story-big-form');
            // Archive handling
            scope.isArchived = function () {
                return scope.story && scope.inBoard && scope.story.isArchived(scope.inBoard.getBoardState());
            };
            scope.unarchiveStory = function () {
                scope.inBoard
                    .toggleArchiveStory(scope.story)
                    .then(scope.onReloadRequest.bind(scope))
                    .catch(navigation.failed);
            };
            // Title handling
            const kindDimension = scope.inBoard.getKindDimension();
            scope.$watch(`storyData.${kindDimension.code}`, () => {
                scope.cardPlaceholder = scope.inBoard.getBoardState().getPlaceholderFor(scope.storyData);
            });
            scope.hasTitle = function () {
                const storyData = scope.storyData;
                return storyData.title && storyData.title.trim() !== '';
            };
            scope.startEditingTitle = function ($event) {
                if (scope.readOnly) {
                    return;
                }
                $event.stopPropagation();
                scope.stopEditing();
                scope.editing = true;
            };
            scope.stopEditing = function () {
                scope.editing = false;
            };
            // Dimensions handling
            scope.$watch('dimensions', () => {
                scope.editableDimensions = scope.dimensions.filter((d) => {
                    return d.canBeEditedAsCardDimension();
                });
            });
            scope.isNew = function () {
                return !scope.storyData.id;
            };
            scope.isRequired = function (dim) {
                return dim.required || (scope.inBoard && scope.inBoard.isRequired(dim));
            };
            scope.isRelevant = function (dim) {
                return dim.isRelevant(scope.storyData || {}, scope.inBoard);
            };
            // New dimension flow
            scope.justCreatedDimension = null;
            scope.dimensionAdded = function (dimension) {
                scope.justCreatedDimension = dimension;
                scope.editableDimensions.push(dimension);
                scope.onDimensionCreated({ dimension: dimension });
            };
            scope.isJustCreated = function (dimension) {
                return dimension === scope.justCreatedDimension;
            };
            // Linked cards
            scope.$watchGroup(['inBoard', 'story'], () => {
                if (!scope.inBoard || !scope.story) {
                    return;
                }
                scope.linkedCards = scope.inBoard.getLinkedStoriesFor(scope.story);
            });
            // Scroll tracking
            const startScrollAt = parseInt(attrs.startScrollAt);
            const main = elm.find('.left')[0];
            scope.scrollToAttachments = function () {
                const container = elm.find('.left').find('.attachment-container')[0];
                if (!container) {
                    return;
                }
                main.scroll({ top: container.offsetTop });
            };
            scope.scrollToCardLinks = function () {
                const container = elm.find('.left').find('.linked-stories-container')[0];
                if (!container) {
                    return;
                }
                main.scroll({ top: container.offsetTop });
            };
            const notifyScrollChanged = debounce((scrolled) => {
                scope.$apply(() => {
                    scope.onScrollChanged({ scrolled: scrolled });
                });
            }, 100);
            function onScrollEventHandler() {
                const scrollY = main.scrollTop || 0;
                const scrolled = scrollY > startScrollAt;
                if (scrolled) {
                    elm.addClass('scrolled');
                }
                else {
                    elm.removeClass('scrolled');
                }
                notifyScrollChanged(scrolled);
            }
            // register on scroll
            events.observeElement(scope, 'scroll', onScrollEventHandler, main);
            // color handling
            const coloredDimension = scope.inBoard.getColoredDimension();
            if (coloredDimension) {
                scope.$watch('storyData', () => {
                    scope.storyColor = scope.inBoard.getStoryColor(scope.storyData);
                    scope.storyTextColor = Colors.cardTextColor(scope.storyColor);
                    scope.storyTextPlaceholderColor = `${Colors.cardTextPlaceholderColor(scope.storyColor)} !important`;
                }, true);
            }
            scope.getStoryColor = function () {
                return scope.storyColor;
            };
            scope.$watch('storyData.title', (newVal) => {
                if (newVal) {
                    scope.html = $sce.trustAsHtml(Markdowner.light.render(newVal));
                }
            });
            scope.$watch('storyData.identifier', (newVal) => {
                elm.addClass(`story-big-form-${newVal}`);
            });
            scope.getError = function () {
                return robust.message(scope.savingError, false);
            };
        },
    };
}
