window.onload = () => {
    angular.element(document).ready(() => {
        const $injector = angular.injector(['ng', 'klaro.kernel']);
        const kernel = $injector.get('Kernel');
        const kernelLoading = angular.element('#kernel-loading');
        kernelLoading.text('Please wait');
        try {
            const subdomain = window.location.host.split('.')[0];
            let modules = null;
            let app = null;
            if (subdomain === 'playground') {
                modules = ['KernelPlayground', 'KernelSession', 'KernelAuth'];
                app = 'klaro.playground';
            }
            else {
                modules = ['KernelClient', 'KernelSession', 'KernelAuth'];
                app = 'klaro.v1';
            }
            kernel
                .init(modules)
                .then(() => {
                kernelLoading.hide();
                console.log(`Booting ${app} now`);
                angular.bootstrap(document, [app], { strictDi: true });
            })
                .catch((err) => {
                console.error('Fatal error on load', err);
                kernelLoading.text(err.message);
            });
        }
        catch (err) {
            console.error('Fatal error on load', err);
        }
    });
};
