angular
    .module('klaro')
    .service('boardIntegrationModal', boardIntegrationModal)
    .controller('BoardIntegrationModalController', BoardIntegrationModalController);
function boardIntegrationModal(klaroModal, integrationService) {
    return {
        open: function (integrationId, board) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/board/boardIntegrationModal.html'),
                controller: 'BoardIntegrationModalController',
                windowClass: `klaro-modal board-integration-modal ${integrationId}`,
                size: 'large',
                resolve: {
                    integrationId: function () {
                        return integrationId;
                    },
                    board: function () {
                        return board;
                    },
                    integrations: function () {
                        return integrationService.ensureList();
                    },
                },
            });
        },
    };
}
function BoardIntegrationModalController($scope, $uibModalInstance, integrationId, board) {
    $scope.integrationId = integrationId;
    $scope.board = board;
    $scope.error = {
        message: null,
    };
    // Will be populated by the integration form with save/canSave/...
    $scope.integrationApi = {};
    $scope.save = () => {
        $scope.integrationApi.save().then($scope.close);
    };
    $scope.close = (arg) => {
        $uibModalInstance.dismiss(arg);
    };
    $scope.cancel = () => {
        $uibModalInstance.dismiss();
    };
}
