angular
    .module('klaro')
    .directive('onboardTemplateList', onboardTemplateList);
function onboardTemplateList() {
    return {
        restrict: 'E',
        scope: {
            templates: '=',
            selected: '=',
            onClick: '&',
        },
        template: require('@/onboard/onboardTemplateList.html'),
    };
}
