import Node from './Node';
import { Markdowner } from '../utils';
class PrintBoard extends Node {
    get boardLabel() {
        return this.escapeHtml(this.board.label);
    }
    get boardObjective() {
        const objective = (this.options.board.objective || '').trim();
        if (objective.length === 0) {
            return '';
        }
        return Markdowner.light.render(objective);
    }
    toHtml(stories, args) {
        const hLevel = this.hLevel(args);
        const body = this.yield(stories, this.withNextHLevel(args));
        return `
      <article>
        <h${hLevel}>${this.boardLabel}</h${hLevel}>
        ${this.boardObjective}
        ${body}
      </article>
    `;
    }
}
export default PrintBoard;
