angular
    .module('klaro')
    .factory('imailerRest', imailerRest);
function imailerRest($http) {
    return {
        setupOnBoard: setupOnBoard,
        getBoardEmail: getBoardEmail,
    };
    function setupOnBoard(board, config) {
        return $http
            .post(`/api/integrations/imailer/boards/${board}`, config)
            .then((response) => {
            return response.data;
        });
    }
    function getBoardEmail(board) {
        return $http
            .get(`/api/integrations/imailer/boards/${board}`)
            .then((response) => {
            return response.data;
        });
    }
}
