angular
    .module('klaro')
    .service('boardExportModal', boardExportModal)
    .controller('BoardExportModalController', BoardExportModalController);
function boardExportModal(klaroModal) {
    return {
        open: function (boardCtrl, format, formatOptions = {}) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/board/exports/boardExportModal.html'),
                controller: 'BoardExportModalController',
                windowClass: 'klaro-modal export-board-modal',
                size: 'small',
                resolve: {
                    boardCtrl: function () {
                        return boardCtrl;
                    },
                    format: function () {
                        return format;
                    },
                    formatOptions: function () {
                        return formatOptions;
                    },
                },
            });
        },
    };
}
function BoardExportModalController($scope, $uibModalInstance, boardCtrl, format, formatOptions) {
    $scope.boardCtrl = boardCtrl;
    $scope.board = boardCtrl.getBoardState();
    $scope.boardName = $scope.board.label;
    $scope.format = format;
    $scope.formatOptions = formatOptions;
    $scope.form = {};
    $scope.form.valid = false;
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.close = $scope.cancel;
    $scope.disabled = function () {
        return !$scope.form.valid;
    };
}
