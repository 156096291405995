export const foldTopCard = (state) => {
    return state.with({
        casinoView: state.casinoView.withTopCardFolded(),
    });
};
export const unfoldAll = (state) => {
    return state.with({
        casinoView: state.casinoView.withAllUnfolded(),
    });
};
export const unfoldOne = (state) => {
    return state.with({
        casinoView: state.casinoView.withOneUnfolded(),
    });
};
export const foldCards = (state, cardIds) => {
    return state.with({
        casinoView: state.casinoView.withCardsFolded(cardIds),
    });
};
