import Dimension from '../Dimension';
class Poll extends Dimension {
    get category() {
        return 'advanced';
    }
    isUserDefinedSemantics() {
        return true;
    }
    usesSemantics() {
        return !!this.semanticsType;
    }
}
export default Poll;
