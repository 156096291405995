import { Integer as IntegerDimension } from '@klaro/corejs/dimensions';
angular
    .module('klaro')
    .directive('integerrangeInput', integerRangeInput);
function integerRangeInput() {
    return {
        restrict: 'E',
        require: '^ngModel',
        template: require('@/core/dimension/dimensionSemanticsWidget/integerRangeInput.html'),
        link: function (scope, elm, attrs, ngModelController) {
            const input = elm.find('input');
            ngModelController.$validators.validIntegerRange = function (modelValue, viewValue) {
                const value = modelValue || viewValue;
                return IntegerDimension.validExpr(value);
            };
            // Specify how UI should be updated
            ngModelController.$render = function () {
                input.val(ngModelController.$viewValue);
            };
            ngModelController.$render();
            // Listen to input change and update the model accordingly
            input.on('blur keyup change', () => {
                scope.$evalAsync(() => {
                    const val = input.val();
                    ngModelController.$setViewValue(val && val.trim().length > 0 ? val.trim() : null);
                });
            });
        },
    };
}
