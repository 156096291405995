angular
    .module('klaro')
    .directive('emailPreferences', emailPreferences);
function emailPreferences(navigation) {
    return {
        restrict: 'E',
        scope: {
            preferences: '=',
        },
        template: require('@/core/my/emailPreferences.html'),
    };
}
