import { TimeUtils } from '@klaro/corejs/utils';
angular
    .module('klaro')
    .service('datepickerModal', datepickerModal)
    .controller('DatepickerModalController', DatepickerModalController);
function datepickerModal(klaroModal) {
    function open(date, withTime) {
        return klaroModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: require('@/support/datepickerModal.html'),
            controller: 'DatepickerModalController',
            windowClass: 'klaro-modal datepicker-modal',
            size: 'small',
            resolve: {
                date: function () { return date; },
                withTime: function () { return withTime; },
            },
        });
    }
    return { open: open };
}
function DatepickerModalController($scope, $uibModalInstance, $filter, date, withTime, Project) {
    const dateObject = (date) ? new Date(date) : new Date();
    const project = Project.getSync();
    $scope.withTime = withTime;
    $scope.state = {
        date: dateObject,
        hours: dateObject.getHours(),
        minutes: dateObject.getMinutes(),
    };
    const syncDateStr = (date = $scope.state.date) => {
        $scope.state.dateStr = TimeUtils.jsDateToHumanShortDateStr(new Date(date), project);
    };
    syncDateStr();
    $scope.$watch('state.date', syncDateStr);
    $scope.$watch('state.dateStr', (dateStr) => {
        const oldStr = TimeUtils.jsDateToHumanShortDateStr($scope.state.date, project);
        const newD = TimeUtils.parseHumanShortDateStrAsJsDate(dateStr, project);
        const newSrt = TimeUtils.jsDateToHumanShortDateStr(newD, project);
        if (oldStr !== newSrt) {
            $scope.state.date = newD;
        }
    });
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.close = function () {
        const newDateObject = new Date($scope.state.date);
        if ($scope.withTime) {
            newDateObject.setHours($scope.state.hours);
            newDateObject.setMinutes($scope.state.minutes);
        }
        $uibModalInstance.close({ date: newDateObject.toISOString() });
    };
}
