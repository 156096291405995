import { Summary } from '../../../model';
export class ApiSummariesConnector {
    constructor(client) {
        this.client = client;
    }
    async get() {
        const res = await this.client.api.get('/summaries/');
        return res.data.map(s => Summary.dress(s));
    }
}
