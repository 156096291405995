angular
    .module('klaro')
    .directive('templateBadge', templateBadge);
function templateBadge() {
    return {
        restrict: 'EA',
        template: require('@/core/template/templateBadge.html'),
        link: function (scope, elm, attrs) {
            if (attrs.templateBadge && attrs.templateBadge != '') {
                elm.find('span').text(attrs.templateBadge);
            }
        },
    };
}
