angular
    .module('klaro')
    .factory('userRest', userRest);
function userRest($http) {
    return {
        search: search,
    };
    function search(string) {
        return $http
            .get('/api/users/search/', { params: { q: string } })
            .then((response) => {
            return response.data;
        });
    }
}
