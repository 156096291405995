angular
    .module('klaro')
    .service('failedModal', failedModal)
    .controller('FailedModalController', FailedModalController);
function failedModal(klaroModal) {
    function open(error) {
        return klaroModal.open({
            //animation: false,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: require('@/support/failedModal.html'),
            controller: 'FailedModalController',
            windowClass: 'klaro-modal',
            size: 'small',
            resolve: {
                error: function () { return error; },
            },
        });
    }
    return { open: open };
}
function FailedModalController($scope, error, $uibModalInstance, navigation, auth, profileModal, Chatbot, $routeParams) {
    $scope.error = error;
    $scope.hasChatbot = Chatbot.hasChatbot();
    $scope.allowReport = function () {
        return error.report === undefined || error.report;
    };
    $scope.reportError = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        Chatbot.open();
    };
    $scope.labelOf = function (action) {
        function capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        return typeof (error[action]) == 'string' ? error[action] : capitalize(action);
    };
    $scope.allowClose = function () {
        return error.close === undefined || error.close;
    };
    $scope.close = function () {
        $uibModalInstance.close();
    };
    $scope.allowHome = function () {
        return error.home !== undefined || error.home;
    };
    $scope.home = function () {
        $uibModalInstance.close();
        window.location = '/';
    };
    $scope.allowBack = function () {
        return error.back !== undefined || error.back;
    };
    $scope.back = function () {
        $uibModalInstance.close();
        navigation.back();
    };
    $scope.allowLogout = function () {
        return auth.isLogged() && (error.logout !== undefined && error.logout);
    };
    $scope.allowLogin = function () {
        return !auth.isLogged() && (error.login !== undefined && error.login);
    };
    $scope.login = function () {
        $uibModalInstance.close();
        navigation.gotoUrl('/auth/login', { redirectTo: $routeParams.redirectTo });
    };
    $scope.allowPreferences = function () {
        return !!error.preferences;
    };
    $scope.preferences = function () {
        profileModal.open({ mode: 'preferences' });
    };
    $scope.allowReportError = function () {
        return error.report === undefined || error.report === true;
    };
}
