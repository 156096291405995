angular
    .module('klaro')
    .directive('projectName', projectName);
function projectName(Project, $rootScope) {
    return {
        restrict: 'EA',
        link: function (scope, elm, attrs, board) {
            function refresh() {
                Project.get().then((p) => {
                    elm.text(p.name);
                });
            }
            refresh();
            $rootScope.$on('klaro.project.changed', refresh);
        },
    };
}
