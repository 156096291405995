import { DataObject } from '../utils';
class Users extends DataObject {
    static dress(raw) {
        if (raw.constructor && raw.constructor === this) {
            return raw;
        }
        return new Users(raw);
    }
    /**
     * Partition users into those who belong to the current project and those who don't
     **/
    partitionByMembership(users) {
        const [members, notMembers] = users.reduce((result, element) => {
            result[element.isProjectMember ? 0 : 1].push(element);
            return result;
        }, [[], []]);
        return { members: members, notMembers: notMembers };
    }
}
export default Users;
