angular
    .module('klaro')
    .directive('newStoryLink', newStoryLink);
function newStoryLink() {
    return {
        restrict: 'E',
        require: '^board',
        template: require('@/core/board/newStoryLink.html'),
        replace: true,
        link: function (scope, elm, attrs, boardCtrl) {
            scope.kind = boardCtrl.getBoardState().getCardKindHumanTitle();
            boardCtrl.$watch('board', (b) => {
                scope.kind = b.getCardKindHumanTitle();
                if (boardCtrl.canWrite()) {
                    elm.show();
                }
                else {
                    elm.hide();
                }
            }, scope);
        },
    };
}
