export var ExportFormat;
(function (ExportFormat) {
    ExportFormat["xlsx"] = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
})(ExportFormat || (ExportFormat = {}));
export class ExportBoard {
    async run(client, args) {
        const { id, label, dimensions } = client.store.board.board;
        const res = await client.store.connector.boards.single(id).exports.post({
            format: args.format,
            mode: 'download',
            name: args.name || label,
            options: {
                dimensions: dimensions.map(d => d.code),
                includeDescription: true
            },
        });
        if (res?.url) {
            window.open(res.url, '_blank');
        }
    }
}
;
