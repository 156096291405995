angular
    .module('klaro')
    .directive('noAnonymous', noAnonymous);
function noAnonymous($rootScope, $compile) {
    return {
        restrict: 'A',
        compile: function (element, attrs) {
            if ($rootScope.isAnonymous) {
                return function (scope, element) {
                    element.remove();
                };
            }
            else {
                attrs.$set('noAnonymous', null);
                return function (scope, element) {
                    $compile(element)(scope);
                };
            }
        },
    };
}
