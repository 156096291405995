angular
    .module('klaro')
    .directive('actionsContextMenu', actionsContextMenu);
function actionsContextMenu() {
    return {
        restrict: 'E',
        template: require('@/support/actionsContextMenu.html'),
        scope: {
            actions: '=',
            position: '=?',
        },
        link: function (scope, elm, attrs) {
            scope.position = attrs.position || 'left';
            scope.executeIt = function ($event, method, param) {
                if (typeof method === 'string') {
                    scope.$parent[method]($event, param);
                }
                else if (typeof method === 'function') {
                    method($event, param);
                }
            };
            scope.cssClassFor = function (action) {
                return [action.icon, action.separator ? 'separator' : null].filter(Boolean);
            };
            scope.labelFor = function (action) {
                if (typeof action.label === 'function') {
                    return action.label();
                }
                else {
                    return action.label;
                }
            };
            scope.enabled = function (action) {
                if (action.enabled === undefined) {
                    return true;
                }
                else if (typeof action.enabled === 'string') {
                    return scope.$parent[action.enabled](action.param);
                }
                else {
                    return action.enabled(action.param);
                }
            };
        },
    };
}
