import { Users } from '@klaro/corejs/model';
angular
    .module('klaro')
    .directive('boardInviteStepSearch', boardInviteStepSearch);
function boardInviteStepSearch(userRest, navigation) {
    return {
        restrict: 'E',
        template: require('@/core/board/invite-steps/boardInviteStepSearch.html'),
        link: function (scope, elm, attrs) {
            scope.matchingUsers = {
                members: [],
                notMembers: [],
            };
            scope.performSearch = function () {
                if (scope.search.length >= 3) {
                    userRest
                        .search(scope.search)
                        .then((matchingUsers) => {
                        scope.matchingUsers = Users.dress({}).partitionByMembership(matchingUsers);
                    });
                }
                else {
                    scope.matchingUsers = {};
                }
            };
            scope.selectUser = function (user) {
                scope.selectedUser = user;
                scope.userWorkspaces = scope.selectedUser.workspaces.map((uw) => {
                    return uw.code;
                });
                scope.selectedWorkspaces = scope.workspaces.reduce((result, w) => {
                    result[w.code] = scope.userWorkspaces.indexOf(w.code) >= 0;
                    return result;
                }, {});
                scope.step = 'configure';
            };
            scope.newUser = function () {
                scope.selectedUser = { email: scope.search };
                scope.selectedWorkspaces = {};
                scope.step = 'configure';
            };
            scope.noResult = function () {
                return scope.search.length >= 3 && Object.keys(scope.matchingUsers).length == 2
                    && scope.matchingUsers.members.length == 0 && scope.matchingUsers.notMembers.length == 0;
            };
        },
    };
}
