angular
    .module('klaro')
    .filter('spaceDimensionsSummary', spaceDimensionsSummary);
function spaceDimensionsSummary() {
    return function (input) {
        if (!input || !input.dimensions) {
            return '';
        }
        const size = input.dimensions.length;
        if (input.dimensionsMode == 'whitelist') {
            if (size == 0) {
                return 'All dimensions hidden';
            }
            else {
                return `${input.dimensions.join(', ')} shown`;
            }
        }
        else {
            if (size == 0) {
                return 'All dimensions shown';
            }
            else {
                return `${input.dimensions.join(', ')} hidden`;
            }
        }
    };
}
