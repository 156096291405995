import { ApiAuthenticationConnector } from '../api/ApiAuthenticationConnector';
export class LocalAuthenticationConnector extends ApiAuthenticationConnector {
    constructor(client) {
        super(client);
        const superMe = this.me;
        this.me = {
            async get() {
                const me = await superMe.get();
                return me;
            }
        };
    }
}
