import { DateTime, Interval, Duration } from 'luxon';
import Dimension from '../Dimension';
import Scalar from './Mixins/Scalar';
import TimeUtils from '../../utils/TimeUtils';
class DateDim extends Dimension {
    inTimezone(date, globalContext) {
        if (date && globalContext && globalContext.project) {
            date = date.setZone(globalContext.project.timezone);
            date = date.setLocale(globalContext.project.locale);
        }
        return date;
    }
    getNow(globalContext) {
        globalContext ?? (globalContext = {});
        return globalContext.today || TimeUtils.today();
    }
    static getRawFromHighValue(high) {
        return high ? high.toISO() : null;
    }
    /**
      * Overriden to return a DateTime for the actual date.
      */
    getStoryHighLevelValue(story, globalContext) {
        const storyValue = this.getStoryRawValue(story);
        const date = storyValue ? story._cached(this.code, () => {
            if (storyValue instanceof Date) {
                return DateTime.fromJSDate(storyValue);
            }
            return DateTime.fromISO(storyValue);
        }) : null;
        return this.inTimezone(date, globalContext);
    }
    getUTCDate(story) {
        const utcDate = this.getStoryHighLevelValue(story, { project: { timezone: 'utc+1' } }) ?? null;
        return utcDate ? utcDate.toISO() : '';
    }
    getStoryHumanValue(story, globalContext) {
        const date = this.getStoryHighLevelValue(story, globalContext);
        return date ? date.toLocaleString(DateTime.DATE_SHORT) : '';
    }
    /**
     * Returns a patch to apply on a story to get it having the specific value.
     */
    getPatchForStoryValueTo(value, globalContext, now) {
        now ?? (now = this.getNow(globalContext));
        const matcher = DateDim.getMatcherForExpr(value.semantics);
        const update = {};
        if (matcher && matcher.getDateFor) {
            update[this.code] = matcher.getDateFor(value, now).toUTC().toISO();
        }
        return update;
    }
    /**
      * Overriden to take the earliest date defining `dimValue`'s date range and
      * setting it as story raw dimension value.
      */
    withStoryValueTo(story, dimValue, globalContext, now) {
        const update = this.getPatchForStoryValueTo(dimValue, globalContext, now);
        return story.clone(update);
    }
    /**
      * Overriden to implement the date range semantics: the match occurs when
      * the raw dimension value is a date within `dimValue`'s range.
      */
    storyValueMatches(story, dimValue, globalContext, now) {
        now ?? (now = this.getNow(globalContext));
        const dateTime = this.getStoryHighLevelValue(story, globalContext);
        if (dateTime == null) {
            return dimValue.id == null;
        }
        if (dimValue.id == null) {
            return false;
        }
        const matcher = DateDim.getMatcherForExpr(dimValue.semantics);
        return matcher(dateTime, now);
    }
    /**
      * Overriden to use a datepicker as input widget.
      */
    getInputWidgetCode() {
        return 'datepicker';
    }
    getTableAlignmentClass() {
        return 'align-right';
    }
    usesTime() {
        return false;
    }
    canBeUsedAsDateBoundary() {
        return true;
    }
    // Static tools on expressions
    static getMatcherForExpr(expr) {
        if (DATE_RANGE_EXPR_CACHE[expr] === undefined) {
            DATE_RANGE_EXPR_CACHE[expr] = DateDim.compileExpr(expr);
        }
        return DATE_RANGE_EXPR_CACHE[expr];
    }
    static validExpr(expr) {
        if (!expr) {
            return false;
        }
        return !!expr.match(DATE_RANGE_RX) || Interval.fromISO(expr).isValid;
    }
    static compileExpr(expr) {
        if (!expr) {
            return FALSE_MATCHER;
        }
        const match = expr.match(DATE_RANGE_RX) || [];
        if (match[1]) {
            return DURATION_MATCHER(match[3], match[2] == '-');
        }
        else if (match[4]) {
            return RANGE_MATCHER(match[6], match[5] == '-', match[8], match[7] == '-');
        }
        else if (match[9]) {
            return EXPLICIT_MATCHER(`${match[10]}/${match[11]}`);
        }
        else if (match[12]) {
            return PAST_MATCHER;
        }
        else if (match[13]) {
            return FUTURE_MATCHER;
        }
        else if (match[17]) {
            return TODAY_MATCHER();
        }
        else {
            return EXPLICIT_MATCHER(expr.replace(/\s*\.\.\.?\s*/, '/'));
        }
    }
}
Object.assign(DateDim.prototype, Scalar);
export default DateDim;
function checkNowIsProvided(now) {
    if (now) {
        return now;
    }
    throw new Error('Now must be provided to matcher');
}
const UNITS = [
    'year',
    'month',
    'day',
    'hour',
    'minute',
    'second',
].map(x => `${x}s?`).join('|');
const DURATION = 'P[YMWDTHMS0-9]+';
const YEAR = '[1-9]\\d{3}';
const DATE = '[1-9]\\d{3}-\\d{2}-\\d{2}';
const DATE_RANGE_RX = new RegExp([
    `([-+])?(${DURATION})` // 1 -> 2? and 3
    ,
    `([-+])?(${DURATION})\\s?\\.\\.\\.?\\s?([-+])?(${DURATION})` // 4 -> 5? and 6 .. 7? and 8
    ,
    `(${YEAR})\\s?\.\.\.?\\s?(${YEAR})` // 9 -> 10 and 11
    ,
    '[\-]?BOT' // 12
    ,
    '[\+]?EOT' // 13
    ,
    `(${DATE})\\s?\\.\\.\\.?\\s?(${DATE})` // 14 -> 15 and 16
    ,
    'TODAY', // 17
].map(x => `(^${x}$)`).join('|'), 'i');
const _100_YEARS = Duration.fromISO('P100Y');
const FALSE_MATCHER = function (d, now) {
    checkNowIsProvided(now);
    return false;
};
const FUTURE_MATCHER = function (d, now) {
    checkNowIsProvided(now);
    return Interval.after(now, _100_YEARS).contains(d);
};
const PAST_MATCHER = function (d, now) {
    checkNowIsProvided(now);
    return Interval.before(now, _100_YEARS).contains(d);
};
// e.g., [+-]P1D
const DURATION_MATCHER = function (exprStr, negative) {
    const expr = Duration.fromISO(exprStr);
    const matcher = function (d, now) {
        checkNowIsProvided(now);
        const interval = negative ? Interval.before(now, expr) : Interval.after(now, expr);
        return interval.contains(d);
    };
    // [NOW .. +P1D[ -> NOW
    // [NOW-P1D .. NOW[ -> NOW-P1D
    matcher.getDateFor = function (dimValue, now) {
        checkNowIsProvided(now);
        return negative ? now.minus(expr) : now;
    };
    return matcher;
};
// e.g., +P1D .. +P3D, which is [NOW+P1D .. NOW+P3D[
// e.g., -P3D .. -P1D, which is [NOW-P3D .. NOW-P1D[
const RANGE_MATCHER = function (left, leftN, right, rightN) {
    left = Duration.fromISO(left);
    right = Duration.fromISO(right);
    const matcher = function (d, now) {
        checkNowIsProvided(now);
        const from = leftN ? now.minus(left) : now.plus(left);
        const to = rightN ? now.minus(right) : now.plus(right);
        return Interval.fromDateTimes(from, to).contains(d);
    };
    // [NOW+P1D .. NOW+P3D[ -> NOW+P1D
    // [NOW-P3D .. NOW-P1D[ -> NOW-P3D
    matcher.getDateFor = function (dimValue, now) {
        checkNowIsProvided(now);
        return leftN ? now.minus(left) : now.plus(left);
    };
    return matcher;
};
// e.g., 2018 .. 2019
const EXPLICIT_MATCHER = function (expr) {
    const interval = Interval.fromISO(expr);
    const matcher = function (d, now) {
        checkNowIsProvided(now);
        return interval.contains(d);
    };
    matcher.getDateFor = function (dimValue, now) {
        return interval.start;
    };
    return matcher;
};
const TODAY_MATCHER = function () {
    const matcher = function (d, now) {
        checkNowIsProvided(now);
        return d.toLocaleString(DateTime.DATE_SHORT) === now.toLocaleString(DateTime.DATE_SHORT);
    };
    matcher.getDateFor = function (dimValue, now) {
        checkNowIsProvided(now);
        return now;
    };
    return matcher;
};
const DATE_RANGE_EXPR_CACHE = {};
