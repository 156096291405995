import { DataObject, equals, Patcher } from '../../utils';
const extractBreadcrumbPatch = (original, board) => {
    return board.getPatchAgainst(original);
};
const activate = (part, active) => {
    return Object.assign({}, part, { active: active });
};
const extractTitle = (patch, previousPatch) => {
    const diff = Object.keys(Patcher.shallow(patch, previousPatch));
    if (diff.length > 1) {
        return 'Multiple changes';
    }
    else {
        const key = diff[0];
        switch (key) {
            case 'filters':
                return 'Filters changed';
            case 'compactDisplay':
            case 'background':
            case 'displayDimensionsOnCards':
            case 'displayDimensionLabels':
            case 'displayNoneOnCards':
                return 'Display changed';
            case 'explorerDecks':
            case 'decksOpen':
            case 'manualDimensionsInDecks':
            case 'anchors':
            case 'mode':
            case 'ganttScale':
                return 'Layout changed';
            default:
                return 'Other settings changed';
        }
    }
};
export default class Breadcrumb extends DataObject {
    static for(board) {
        return new Breadcrumb({
            parts: [
                {
                    index: 0,
                    label: board.label,
                    active: true,
                    patch: {},
                    board: board,
                    title: ''
                }
            ]
        });
    }
    get last() {
        return this.parts[this.parts.length - 1];
    }
    sliceAt(at) {
        const sliced = this.parts.slice(0, 1 + at);
        sliced[sliced.length - 1] = activate(sliced[sliced.length - 1], true);
        return this.clone({ parts: sliced });
    }
    activateAt(at) {
        const activated = this.parts.map(p => activate(p, p.index === at));
        return this.clone({ parts: activated });
    }
    givenNewBoard(original, board) {
        const patch = extractBreadcrumbPatch(original, board);
        // [newParts, sameIndex, activeIndex]
        const init = [[], -1, -1];
        const pair = this.parts.reduce((memo, part, index) => {
            const same = equals(part.patch, patch);
            memo[0].push(activate(part, same));
            const newOne = [
                memo[0],
                same ? index : memo[1],
                part.active ? index : memo[2],
            ];
            return newOne;
        }, init);
        let newParts = pair[0];
        const sameIndex = pair[1];
        const activeIndex = pair[2];
        if (sameIndex === -1) {
            // we haven't found the new one
            // => just push the new one, stripping at old active one
            newParts = newParts.slice(0, 1 + activeIndex);
            newParts.push({
                index: newParts.length,
                label: '...',
                active: true,
                patch: patch,
                board: board,
                title: extractTitle(patch, newParts[newParts.length - 1].patch)
            });
        }
        return this.clone({ parts: newParts });
    }
}
