import DataObject from '../../utils/DataObject';
class OrderingCandidates extends DataObject {
    constructor(boardState) {
        super({});
        this.boardState = boardState;
    }
    isCurrentNgOption(opt) {
        const cur = this.currentNgOption();
        if (!opt || !cur) {
            return (opt === cur);
        }
        return (opt.code && cur.code && opt.code === cur.code)
            || (opt.anchor && cur.anchor && opt.anchor === cur.anchor);
    }
    currentNgOption() {
        const ngOptions = this.toNgOptions().map(o => o.options).flat();
        const anchor = this.boardState.getOrderingAnchor();
        const list = anchor ? anchor.toList() : null;
        if (!list || list.length === 0) {
            return undefined;
        }
        else if (list.length === 1) {
            return ngOptions.find(opt => opt.anchor === list[0]);
        }
        else {
            return ngOptions.find(opt => opt.code === 'useComplex');
        }
    }
    toNgOptions() {
        return this.memoize('toNgOptions', '', () => {
            const dims = this.boardState.getOrderingDimensions();
            const ascDims = dims.map((d) => {
                return { anchor: `${d.code}`, label: `${d.label}` };
            });
            const asc = [{
                    group: '\u21a5 Ascending by',
                    options: ascDims,
                }];
            const descDims = dims.map((d) => {
                return { anchor: `-${d.code}`, label: `${d.label}` };
            });
            const desc = [{
                    group: '\u21a7 Descending by',
                    options: descDims,
                }];
            const other = {
                group: 'Other options',
                options: [],
            };
            // Custom order required the user to open board settings
            // or the Custom order dimension to be already installed
            if (this.boardState.canManage() || this.boardState.hasCustomOrder()) {
                other['options'].push({ code: 'useCustom', label: 'Use custom order' });
            }
            // Complex order requires opening the board settings
            if (this.boardState.canManage()) {
                other['options'].push({ code: 'useComplex', label: 'Use complex order' });
            }
            // Creating a new dimension requires being an Admin
            if (this.boardState.isUsedByAdmin()) {
                other['options'].push({ icon: 'icon-Plus icon-xs', code: 'newDimension', label: 'Create new dimension' });
            }
            return asc.concat(desc).concat(other).filter(group => group.options.length);
        });
    }
}
export default OrderingCandidates;
