angular
    .module('klaro')
    .controller('PasswordForgottenModalController', PasswordForgottenModalController);
function PasswordForgottenModalController($scope, $location, $http, $uibModalInstance, email, robust) {
    $scope.error = null;
    $scope.success = false;
    $scope.data = {
        email: email,
    };
    $scope.submit = function ($event) {
        $scope.error = null;
        $scope.success = false;
        $scope.data.email = $scope.data.email.toLowerCase();
        $http
            .post('/api/auth/password/forgotten/', $scope.data)
            .then(() => {
            $scope.success = true;
            $event.target.querySelector('.back-button').focus();
        })
            .catch((response) => {
            $scope.error = robust.message(response);
        });
    };
    $scope.gotoLogin = function () {
        $uibModalInstance.close();
        $location.path('/auth/login');
    };
}
