angular
    .module('klaro')
    .directive('boardWorkspacesSelector', boardWorkspacesSelector);
function boardWorkspacesSelector($filter, ArrayUtils) {
    return {
        restrict: 'EA',
        scope: {
            board: '=',
            workspaces: '=',
        },
        require: '^ngModel',
        template: require('@/core/board/boardWorkspacesSelector.html'),
        link: function (scope, elm, attrs, ngModel) {
            function higher(permission) {
                if (!permission) {
                    return '';
                }
                if (permission.canManage) {
                    return 'Manage';
                }
                if (permission.canWrite) {
                    return 'Write';
                }
                if (permission.canRead) {
                    return 'Read';
                }
            }
            function installCandidates(model) {
                if (!model) {
                    return;
                }
                // Converts a single dimension to a candidate data structure
                const toCandidate = function (t, index) {
                    const permission = model[t.code];
                    return {
                        workspace: t,
                        index: (permission ? index : 1000000 + index),
                        opened: false,
                        permission: permission ? permission : {
                            workspace: t.code,
                            canRead: false,
                            canWrite: false,
                            canManage: false,
                        },
                        higher: higher(permission),
                    };
                };
                // Sorts the candidates by index.
                const sortFn = function (a, b) {
                    return a.index - b.index;
                };
                // Let's go
                scope.candidates = scope.workspaces
                    .map(toCandidate)
                    .sort(sortFn);
            }
            scope.toggleOpening = function (candidate) {
                candidate.opened = !candidate.opened;
            };
            scope.revokeAllOn = function (candidate, $event) {
                $event.stopPropagation();
                const permission = candidate.permission;
                permission.canRead = false;
                permission.canWrite = false;
                permission.canManage = false;
                candidate.higher = higher(permission);
                synchronizeModel();
            };
            scope.hasToggle = function (candidate, kind) {
                const perms = candidate.permission;
                if (kind == 'Read') {
                    return perms.canRead && !perms.canWrite;
                }
                else if (kind == 'Write') {
                    return perms.canWrite && !perms.canManage;
                }
                else {
                    return perms.canManage;
                }
            };
            scope.setPermission = function (candidate, kind, $event) {
                $event.stopPropagation();
                candidate.permission.canRead = true;
                candidate.permission.canWrite = (kind == 'Write' || kind == 'Manage');
                candidate.permission.canManage = (kind == 'Manage');
                candidate.higher = higher(candidate.permission);
                synchronizeModel();
            };
            function synchronizeModel() {
                const model = scope.candidates
                    .map((c) => { return c.permission; })
                    .filter((p) => { return p.canRead || p.canWrite || p.canManage; });
                ngModel.$setViewValue(model);
            }
            /**
             * Returns the current model, that is, the workspace permissions list.
             *
             * This method is robust to an undefined or null model, and returns an empty list
             * instead.
             *
             * @return [Board.WorkspacePermission] the current workspace permissions
             */
            function getModel() {
                const model = ngModel.$modelValue ? ngModel.$modelValue : [];
                return model.reduce((index, permission) => {
                    index[permission.workspace] = permission;
                    return index;
                }, {});
            }
            scope.getModel = getModel;
            /**
             * When ngModel requires to format the value, we simply reinstall candidates.
             * Angular will take care of the rest.
             */
            function format(value) {
                installCandidates(getModel());
            }
            ngModel.$formatters.push(format);
        },
    };
}
