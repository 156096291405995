import StoriesPatch from '../StoriesPatch';
import ArrayUtils from '../../utils/ArrayUtils';
class StorySelection {
    constructor(storyIds) {
        this.storyIds = storyIds || {};
    }
    size() {
        return Object.keys(this.storyIds).length;
    }
    any() {
        return !this.isEmpty();
    }
    isEmpty() {
        return this.size() === 0;
    }
    add(story) {
        const newone = {};
        newone[story.id] = true;
        return new StorySelection(Object.assign({}, this.storyIds, newone));
    }
    addAll(stories) {
        const newone = Object.assign({}, this.storyIds);
        stories.forEach((s) => newone[s.id] = true);
        return new StorySelection(newone);
    }
    removeAll(stories) {
        const newone = Object.assign({}, this.storyIds);
        stories.forEach((s) => delete newone[s.id]);
        return new StorySelection(newone);
    }
    remove(story) {
        const newone = Object.assign({}, this.storyIds);
        delete newone[story.id];
        return new StorySelection(newone);
    }
    toggle(story) {
        return this.includes(story) ? this.remove(story) : this.add(story);
    }
    clear() {
        return new StorySelection();
    }
    includes(story) {
        return !!this.storyIds[story.id];
    }
    toIds() {
        return Object.keys(this.storyIds).map((id) => id);
    }
    toStoryRefs() {
        return Object.keys(this.storyIds).map((id) => {
            return { id: id };
        });
    }
    toStoriesPatch(patch, currentStories = null) {
        const ssPatch = new StoriesPatch();
        return this.toStoryRefs().reduce((p, s) => {
            const sPatch = this.toStoryPatch(patch, s, currentStories);
            return p.add(Object.assign({}, s, sPatch));
        }, ssPatch);
    }
    toStoryPatch(patch, s, currentStories = null) {
        if (!currentStories) {
            return patch;
        }
        else {
            let story = currentStories.getStoryById(s.id);
            let p = {};
            Object.keys(patch).forEach((k) => {
                // If a key has .selected and .unselected entries, it means
                // it's a three-state patch along a multiple dimension.
                // This is used in bulk patching stories.
                if (patch[k] && patch[k].selected && patch[k].unselected) {
                    p[k] = story[k];
                    p[k] = ArrayUtils.minus(p[k], patch[k].unselected);
                    p[k] = ArrayUtils.union(p[k], patch[k].selected);
                }
                else {
                    // normal case, the patch has the wanted value itself
                    p[k] = patch[k];
                }
            });
            return p;
        }
    }
}
export default StorySelection;
