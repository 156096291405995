import { ArrayUtils } from '../utils';
import { StoryDecorator } from '../lang';
import { toCssClass } from '../views';
class Cube {
    constructor(parent, stories, by, options = {}, invariant = {}, dimension = undefined, dimensionValue = undefined) {
        this.parent = parent;
        this.stories = stories;
        this.options = options;
        this.invariant = invariant;
        this.dimensionValue = dimensionValue;
        this.parent = parent;
        this.stories = stories;
        this.by = by;
        this.invariant = invariant;
        this.dimension = dimension;
        this.dimensionValue = dimensionValue;
        this.summaries = parent.board.summaries || [];
        this.options = options;
        this._computeChildren(by);
    }
    get defaults() {
        return this.invariant;
    }
    get globalContext() {
        return this.parent && this.parent.globalContext;
    }
    get board() {
        return this.parent && this.parent.board;
    }
    get cssClass() {
        return this.dimensionValue ? toCssClass(this.dimensionValue.id) : '';
    }
    getFilterPairs(pairs = []) {
        if (this.dimension && this.dimensionValue) {
            pairs.push([this.dimension, this.dimensionValue]);
        }
        if (this.parent && this.parent.constructor === Cube) {
            return this.parent.getFilterPairs(pairs);
        }
        else {
            return pairs;
        }
    }
    get isNoValue() {
        return this.dimensionValue?.id === null;
    }
    get isEmptyCube() {
        return this.stories.length === 0;
    }
    get isBlackHole() {
        return this.getFilterPairs().some(pair => this.board.isBlackHole(pair[0], pair[1]));
    }
    get blackHoleTooltipText() {
        if (!this.dimensionValue)
            return;
        const decks = this.board.getAnchorAt('decks');
        const label = this.dimensionValue.label;
        if (decks.has(this.dimension)) {
            return `Board filters currently hide all cards with value <strong>${label}</strong>.`;
        }
        else {
            return `The value <strong>${label}</strong> is currently excluded in the filters.`;
        }
    }
    get columns() {
        if (!this._columns) {
            this._columns = Object.values(this.children).sort((a, b) => {
                return a.dimensionValue.ordering - b.dimensionValue.ordering;
            });
        }
        return this._columns;
    }
    getColumnFor(dimensionValue) {
        return this.children[dimensionValue.id];
    }
    get unemptyColumns() {
        return this.columns.filter((c) => {
            return c.stories && c.stories.length > 0;
        });
    }
    isUsingValueOfDimension(value, dimension) {
        const globalContext = this.parent.globalContext;
        return this.stories.some((s) => s.isUsingValueOfDimension(value, dimension, globalContext));
    }
    get summaryValue() {
        if (!this.summary)
            return '';
        const key = Object.keys(this.summary)[0];
        return this.summary[key];
    }
    _computeChildren(by) {
        const globalContext = this.parent.globalContext;
        const invFor = (dim, value) => {
            const x = [];
            x[dim.code] = value.id;
            return x;
        };
        if (by.length > 0) {
            this.children = {};
            const dim = ArrayUtils.head(by).withoutDeprecatedValues(this);
            dim.getValues(true).forEach((value) => {
                const childStories = this.stories.filter((v) => v.hasValue(dim, value, globalContext));
                const isRequired = this.parent.board.isRequired(dim);
                const isNull = value.id === null;
                const isPhantom = isNull && isRequired;
                const isStoryVisible = childStories.length > 0;
                const isCompact = this.parent.board.compactDisplay;
                const isCasino = this.parent.board.isMode('casino');
                if (isStoryVisible || isCasino || (!isCompact && !isPhantom)) {
                    this.children[value.id] = new Cube(this, childStories, ArrayUtils.tail(by), this.options, Object.assign({}, this.invariant, invFor(dim, value)), dim, value);
                }
            });
        }
        if (this.options.summarize) {
            const stories = this.stories.map((s) => new StoryDecorator(s, globalContext));
            this.summary = this.summaries.reduce((memo, s) => {
                return s.summarize(stories, memo);
            }, {});
        }
    }
}
export default Cube;
