import { Workspace } from '@klaro/corejs/model';
angular
    .module('klaro')
    .factory('workspaceRest', workspaceRest);
function workspaceRest($http, auth) {
    const dress = Workspace.dress;
    return {
        list: list,
        listFull: listFull,
        create: create,
        drop: drop,
        addMember: addMember,
        dropMember: dropMember,
        saveWorkspaceSpace: saveWorkspaceSpace,
        update: update,
    };
    function list() {
        return $http
            .get('/api/workspaces/')
            .then((response) => {
            return response.data.map(dress);
        });
    }
    function listFull() {
        return $http
            .get('/api/workspaces/', {
            headers: {
                'Accept': 'application/vnd+klaro.workspaces-full+json',
            },
        })
            .then((response) => {
            return response.data.map(dress);
        });
    }
    function create(workspaceData) {
        return $http
            .post('/api/workspaces/', workspaceData)
            .then((response) => {
            return dress(response.data);
        });
    }
    function update(workspaceData) {
        return $http
            .patch(`/api/workspaces/${workspaceData.id}`, {
            name: workspaceData.name,
            description: workspaceData.description,
        })
            .then((response) => {
            return dress(response.data);
        });
    }
    function drop(workspace) {
        return $http
            .delete(`/api/workspaces/${workspace.id}`);
    }
    function addMember(workspace, member) {
        return $http
            .post(`/api/workspaces/${workspace.code}/members/`, { nickname: member.nickname })
            .then(notify);
    }
    function dropMember(workspace, member) {
        const url = `/api/workspaces/${workspace.code}/members/${member.nickname}`;
        return $http
            .delete(url)
            .then(notify);
    }
    function saveWorkspaceSpace(workspace, spaceData) {
        const url = `/api/workspaces/${workspace.code}/space/`;
        return $http
            .post(url, spaceData);
    }
    function notify(res) {
        return auth.refreshProfile().then(() => {
            return res;
        });
    }
}
