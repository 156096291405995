import { v4 } from 'uuid';
import { Colors } from '../../utils';
import { NullDimValue } from './utils';
const MostUsedColors = Colors.mostUsed();
export class DimensionCandidate {
    constructor(dimName) {
        this.dimName = dimName;
        this.allValues = [];
        this.values = new Map();
        this.valuesSeen = 0;
        this.isMultiple = false;
        this.isRequired = true;
    }
    addValue(value) {
        let values;
        if (Array.isArray(value)) {
            this.isMultiple = true;
            values = value;
        }
        else {
            values = [value];
        }
        for (const value of values) {
            if (value === null || value === undefined) {
                this.isRequired = false;
            }
            const count = this.values.get(value) || 0;
            this.allValues.push(value);
            this.values.set(value, count + 1);
            this.valuesSeen++;
        }
    }
    deliver() {
        const [clazz, raw] = this._deliver();
        const values = [];
        if (!this.isRequired) {
            values.push(NullDimValue);
        }
        const dimRaw = {
            id: v4(),
            code: this.dimName,
            label: this.dimName,
            datatype: 'UserDefined',
            required: this.isRequired,
            multiple: this.isMultiple,
            userEditable: true,
            ...raw,
            values: [
                ...values,
                ...(raw.values || []),
            ],
        };
        // We color dimensions when they
        // don't have too many values
        // if (dimRaw?.values && dimRaw.values.length <= MostUsedColors.length) {
        //   dimRaw.values.forEach((val, i) => {
        //     if (!val?.color) {
        //       val.color = MostUsedColors[i];
        //     }
        //   });
        // }
        return new clazz(dimRaw);
    }
}
