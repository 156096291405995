angular
    .module('klaro')
    .directive('emailPreferencesModalPart', emailPreferencesModalPart);
function emailPreferencesModalPart(navigation, emailPreferencesRest) {
    return {
        restrict: 'E',
        scope: {
            user: '=',
            ctrl: '=',
        },
        template: require('@/auth/profileModal/emailPreferencesModalPart.html'),
        link: function (scope) {
            scope.emailPreferences = null;
            emailPreferencesRest.get().then((data) => {
                scope.emailPreferences = data;
            });
            scope.websiteUrl = navigation.getWebsiteUrl();
            scope.canSave = function () {
                return isDirty();
            };
            scope.save = function () {
                emailPreferencesRest
                    .post(scope.emailPreferences)
                    .then((res) => {
                    return scope.ctrl.close();
                })
                    .catch(scope.ctrl.onError);
            };
            function isDirty() {
                return scope.emailPreferences &&
                    scope.emailPreferencesForm.$dirty;
            }
        },
    };
}
