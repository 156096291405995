import { Story } from '../../../model';
import { SetBoardState } from '../Board';
export class CreateStories {
    constructor() {
        this.preconditions = [
            async (client) => {
                return !!client.store.board?.board.id && !!client.store.board?.stories;
            }
        ];
    }
    async run(client, args) {
        const boardState = client.store.board;
        const board = boardState.board;
        const { connector } = client.store;
        const dataToSend = this.getStoryDataToSend(args.stories);
        const endpoint = board.isAllCards() ? connector.stories : connector.boards.single(boardState.board.id).stories;
        const storiesData = await endpoint.postMany(dataToSend);
        const stories = storiesData.map(storyData => {
            return Story.dress(storyData, {
                dimensions: board.dimensions
            });
        });
        const newBoard = await client.store.board?.storiesAdded(stories);
        client.run(new SetBoardState(), { board: newBoard });
        this._result = stories;
    }
    get result() {
        return this._result;
    }
    getStoryDataToSend(stories) {
        return stories.map(s => {
            const data = s.toRaw();
            delete data._links;
            delete data.linked;
            delete data.q;
            delete data._q;
            return data;
        });
    }
}
;
