import { Dimension } from '@klaro/corejs/model';
angular
    .module('klaro')
    .directive('newDimensionLink', newDimensionLink);
function newDimensionLink(dimensionEditModal) {
    return {
        restrict: 'EA',
        template: require('@/core/dimension/newDimensionLink.html'),
        replace: true,
        require: '?^board',
        scope: {
            onClose: '&',
            category: '=',
            aboveBoard: '@',
        },
        link: function (scope, _elm, _attrs, boardCtrl) {
            scope.newDimension = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                openDimensionModal(Dimension.newOne());
            };
            function openDimensionModal(d) {
                dimensionEditModal.open(d, boardCtrl?.getDimensions(), (i) => !scope.category || i.group === scope.category).result
                    .then(onNewDimension);
            }
            function onNewDimension(dimension) {
                if (boardCtrl) {
                    return boardCtrl
                        .addNewDimension(dimension)
                        .then(() => close(dimension));
                }
                else {
                    close(dimension);
                }
            }
            function close(dimension) {
                scope.onClose({ dimension: dimension });
            }
        },
    };
}
