import DataObject from '../../utils/DataObject';
import TogglableSection from './TogglableSection';
export default class TogglableSectionsList extends DataObject {
    get sections() {
        return this.memoize('sections', '', () => {
            return this.sectionsInfo.map(c => new TogglableSection({
                list: this,
                ...c,
            }));
        });
    }
    // search management
    withSearchText(searchText) {
        return this.clone({
            searchText: searchText,
        }, false);
    }
    // toggling management
    withAutoCollapse() {
        return this.clone({
            collapsed: this.sections.reduce((m, s) => {
                m[s.code] = (s.size === 0);
                return m;
            }, {}),
        }, false);
    }
    isCollapsed(section) {
        return !!(this.collapsed && this.collapsed[section.code]);
    }
    withSectionCollapseToggled(section) {
        if (section.uncollapsable) {
            return this;
        }
        const collapsed = Object.assign({}, this.collapsed);
        collapsed[section.code] = !collapsed[section.code];
        return this.clone({
            collapsed: collapsed,
        }, false);
    }
}
