export class MemoryStore {
    constructor() {
        this.store = {};
    }
    set(key, value) {
        this.store[key] = value;
        return Promise.resolve();
    }
    remove(key) {
        delete this.store[key];
        return Promise.resolve();
    }
    get(key) {
        return Promise.resolve(this.store[key]);
    }
    clear() {
        this.store = {};
    }
}
