import { LoadMyProjects } from '@klaro/corejs/client';
import { Refresher } from '@klaro/corejs/utils';
angular
    .module('klaro')
    .service('MyProjects', MyProjects);
function MyProjects($rootScope, KernelClient, $q) {
    const refresher = new Refresher(() => KernelClient.store.projects, () => KernelClient.run(new LoadMyProjects), $q);
    const onEvent = refresher.refresh.bind(refresher);
    $rootScope.$on('klaro.project.changed', onEvent);
    $rootScope.$on('klaro.user.changed', onEvent);
    $rootScope.$on('klaro.application.reload', onEvent);
    return {
        list: refresher.get.bind(refresher),
    };
}
