angular
    .module('klaro')
    .directive('imailerForm', imailerForm);
function imailerForm(imailerService, integrationService) {
    return {
        restrict: 'E',
        template: require('@/core/board/integrations/imailerForm.html'),
        scope: {
            form: '=',
            integrationApi: '=',
            error: '=',
        },
        link: function (scope, element) {
            scope.isAvailable = integrationService.isAvailable('imailer');
            if (!scope.isAvailable) {
                scope.error.message = 'This feature is currently unavailable for your project.<br/><a class="support" href="mailto:support@klaro.cards">Feel free to contact us</a> if you want this feature enabled.';
            }
            scope.emailSuffix = imailerService.emailSuffix;
            if (imailerService.hasConfig()) {
                scope.imailerIsConfigured = true;
                scope.imailer = { ...imailerService.config };
            }
            else {
                scope.imailerIsConfigured = false;
                scope.imailer = imailerService.getConfigTemplate();
            }
            let originalConfig = { ...scope.imailer };
            scope.canSave = () => {
                return scope.isAvailable &&
                    scope.form.$valid &&
                    originalConfig &&
                    !angular.equals(scope.imailer, originalConfig);
            };
            scope.save = () => {
                return imailerService
                    .saveConfig(scope.imailer)
                    .then((config) => {
                    originalConfig = { ...config };
                    scope.imailerIsConfigured = true;
                })
                    .catch((e) => {
                    if (e.status === 400 && e.data.description) {
                        scope.form.emailPrefix.$setValidity('unique', false);
                        scope.form.emailPrefix.$error.unique = e.data.description;
                    }
                    throw e;
                });
            };
            scope.copyToClipboard = function (e) {
                imailerService.copyToClipboard(e, scope.imailer.emailPrefix);
            };
            const resetFormElements = function () {
                element.find('.copy-to-clipboard')[0].classList.remove('copied');
                delete scope.form.emailPrefix.$error.unique;
                scope.form.emailPrefix.$setValidity('unique', true);
            };
            scope.valueHasChanged = function () {
                resetFormElements();
            };
            scope.refreshEmailAddress = function () {
                scope.imailer.emailPrefix = imailerService.getRandomPrefix();
                resetFormElements();
            };
            scope.showForm = function () {
                if (scope.imailer.enabled && !scope.imailer.emailPrefix) {
                    scope.imailer.emailPrefix = imailerService.getRandomPrefix();
                }
                else if (!scope.imailer.enabled && !scope.imailerIsConfigured) {
                    scope.imailer.emailPrefix = null;
                }
            };
            scope.submitOnEnter = function (e) {
                if (e.which === 13) { // Enter key
                    e.preventDefault();
                    if (scope.canSave()) {
                        scope.save();
                    }
                }
            };
            // Exposing the "standard" integration API
            scope.integrationApi.canSave = scope.canSave;
            scope.integrationApi.save = scope.save;
        },
    };
}
