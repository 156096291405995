angular
    .module('klaro')
    .controller('RememberEmailValidationModalController', RememberEmailValidationModalController);
function RememberEmailValidationModalController($scope, auth, navigation) {
    $scope.user = auth.getUser();
    $scope.sending = false;
    $scope.sentCount = 0;
    $scope.expiredPeriod = function () {
        const today = new Date();
        const createdAt = new Date($scope.user.createdAt);
        return (createdAt.getDate() + 7) < today;
    };
    $scope.canSendEmail = function () {
        return !$scope.sending && $scope.sentCount < 3;
    };
    $scope.sent = function () {
        return $scope.sentCount > 0;
    };
    $scope.gotoHome = function () {
        navigation.gotoHome(true);
    };
    $scope.sendEmail = function () {
        if (!$scope.canSendEmail())
            return;
        $scope.sending = true;
        auth
            .requestEmailValidation()
            .finally(() => {
            $scope.sentCount += 1;
            $scope.sending = false;
        });
    };
}
