angular
    .module('klaro')
    .directive('boardCardsBehavior', boardCardsBehavior);
function boardCardsBehavior() {
    return {
        restrict: 'E',
        template: require('@/core/board/boardCardsBehavior.html'),
        link: function (scope) {
            var _a, _b, _c, _d;
            (_a = scope.boardData).extraSettings || (_a.extraSettings = {});
            (_b = scope.boardData.extraSettings).onCardClick || (_b.onCardClick = {});
            (_c = scope.boardData.extraSettings.onCardClick).type || (_c.type = 'fullEdit');
            (_d = scope.boardData.extraSettings.onCardClick).options || (_d.options = {});
            scope.clickActions = [
                {
                    code: 'nothing',
                    label: 'Nothing',
                },
                {
                    code: 'quickEdit',
                    label: 'Quick edit',
                },
                {
                    code: 'fullEdit',
                    label: 'Full edit',
                },
            ];
        },
    };
}
