/**
 * Get the list of available projects
 */
export class LoadMyProjects {
    async run(client) {
        const projects = await client.store.connector.my.projects.get();
        return {
            projects
        };
    }
}
;
