import Node from './Node';
class PrintList extends Node {
    toHtml(stories, args) {
        return `
      ${this.before(stories, args)}
      <ul>
        ${this.listHtml(stories)}
      </ul>
      ${this.after(stories, args)}
    `;
    }
    listHtml(stories) {
        return stories
            .map(s => this.storyToHtml(s))
            .join('\n');
    }
    storyToHtml(story) {
        return [
            '<li>',
            story.toptitle,
            story.summary,
            ...this.storyDescriptionToHtml(story),
            '</li>',
        ].filter(s => s && s.trim().length > 0).join('');
    }
    storyDescriptionToHtml(story) {
        if (!this.options.includeCardDescription) {
            return [];
        }
        return [
            story.specToHtml(), // Markdowned, security ok
        ].filter(Boolean);
    }
}
export default PrintList;
