import { DataObject, Thumbnails } from '../utils';
import User from './User';
class Project extends DataObject {
    static dress(raw) {
        if (raw.constructor && raw.constructor == this) {
            return raw;
        }
        const owner = raw.owner ? User.dress(raw.owner) : undefined;
        return new Project(Object.assign({}, raw, {
            owner: owner,
        }));
    }
    get logoThumbUrl() {
        return Thumbnails(this.logoUrl, 'logo');
    }
}
export default Project;
