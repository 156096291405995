import Node from './Node';
class PrintLinkedStories extends Node {
    toHtml(story, args) {
        const linked = story.linkedStories();
        if (!linked || !linked.hasSections) {
            return '';
        }
        return linked
            .sections
            .map(section => this.sectionToHtml(linked, section, args))
            .join('\n');
    }
    sectionToHtml(linked, section, args) {
        const hLevel = this.hLevel(args);
        const name = this.escapeHtml(section.name);
        const details = this.sectionDetailsToHtml(linked, section, args);
        if (details.trim().length > 0) {
            return `
        <section>
          <h${hLevel}>${name}</h${hLevel}>
          ${details}
        </section>
      `;
        }
        else {
            return '';
        }
    }
    sectionDetailsToHtml(linked, section, args) {
        const hLevel = 1 + this.hLevel(args);
        return section.cube.unemptyColumns
            .map(column => this.sectionColumnToHtml(linked, section, column, args, hLevel))
            .join('\n');
    }
    sectionColumnToHtml(linked, section, column, args, hLevel) {
        if (column.stories.length === 0) {
            return '';
        }
        const shownDimensions = linked.shownDimensionsForKind(column.dimensionValue.id);
        const body = this.yield(column.stories, {
            shownDimensions: shownDimensions,
            ...this.withNextHLevel(args, 2),
        });
        if (!section.showCardKindLabels) {
            return body;
        }
        const title = this.escapeHtml(column.dimensionValue.label);
        return `
      <h${hLevel}>${title}</h${hLevel}>
      ${body}
    `;
    }
}
export default PrintLinkedStories;
