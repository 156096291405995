import { DateTime } from 'luxon';
const DateTimeLs = function (arg) {
    if (typeof (arg) === 'string') {
        return DateTime.fromISO(arg);
    }
    else {
        return DateTime.fromObject(arg);
    }
};
const Statics = {};
Statics.today = function () {
    return DateTime.local().startOf('day');
};
Statics.now = function () {
    return DateTime.local();
};
Statics.workdayDiff = function (start, end) {
    start = start.startOf('day');
    end = end.startOf('day');
    const first = start.endOf('week');
    const last = end.startOf('week');
    const days = Math.floor(last.diff(first).as('days') * 5 / 7);
    const wfirst = Math.min(first.weekday, 5) - Math.min(start.weekday, 5);
    const wlast = Math.min(end.weekday, 6);
    return wfirst + days + wlast;
};
DateTimeLs.Statics = Statics;
Object.getOwnPropertyNames(Statics).forEach((prop) => {
    DateTimeLs[prop] = Statics[prop];
});
export default DateTimeLs;
