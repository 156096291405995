angular
    .module('klaro')
    .directive('passwordModalPart', passwordModalPart);
function passwordModalPart($http) {
    return {
        restrict: 'E',
        scope: {
            ctrl: '=',
        },
        template: require('@/auth/profileModal/passwordModalPart.html'),
        link: function (scope) {
            scope.pending = false;
            scope.formData = {
                password: null,
                newPassword: {
                    password: null,
                    confirm: null,
                },
            };
            scope.canSave = function () {
                return scope.passwordForm.$valid && !scope.pending;
            };
            scope.save = function () {
                scope.ctrl.clearError();
                scope.pending = true;
                $http
                    .post('/api/auth/password/change/', scope.formData)
                    .then(() => {
                    scope.pending = false;
                    scope.ctrl.switchMode('passwordSuccess');
                })
                    .catch((err) => {
                    scope.pending = false;
                    scope.ctrl.onError(err);
                });
            };
        },
    };
}
