import { DataObject } from '../utils';
import { Summarizer } from '../lang';
class Summary extends DataObject {
    static dress(raw) {
        if (raw.constructor == Summary || raw instanceof Summary) {
            return raw;
        }
        return new Summary(raw);
    }
    static factor(summaryFn, dimension) {
        if (summaryFn && dimension === undefined && summaryFn.constructor === Summary) {
            return summaryFn;
        }
        let data;
        if (summaryFn === 'count') {
            data = { code: 'total-cards', label: '# Cards', expr: 'count' };
        }
        else {
            const summaryFnUp = summaryFn[0].toUpperCase() + summaryFn.slice(1);
            data = {
                code: `${summaryFn}-of-${dimension.code}`,
                label: `${summaryFnUp} of ${dimension.label}`,
                expr: `${summaryFn} (.${dimension.code})`,
            };
        }
        return Summary.dress(data);
    }
    // TODO improve typescript
    summarize(stories, into) {
        (into = into || {})[this.code] = this.summaryFn()(stories);
        return into;
    }
    summaryFn() {
        if (!this.__summaryFn) {
            try {
                this.__summaryFn = (new Summarizer()).compile(this.expr);
                if (!this.__summaryFn) {
                    throw new Error(`Invalid expression \`${this.expr}\``);
                }
            }
            catch (ex) {
                console.log(`Unable to compile ${this.expr}`);
                console.log(ex.message);
                this.__summaryFn = function (_ss) { return undefined; };
            }
        }
        return this.__summaryFn;
    }
    isDimensionUsed(dimension) {
        return this.expr && this.expr.indexOf(`(.${dimension.code})`) !== -1;
    }
    isSameSummaryAs(other) {
        if (!other || other.constructor !== Summary) {
            return false;
        }
        return other.expr === this.expr;
    }
}
export default Summary;
