import Computed from './Computed';
import Integer from './Integer';
class ComputedInteger extends Computed {
    getBase() {
        return Integer;
    }
    getTableAlignmentClass() {
        return 'align-right';
    }
}
export default ComputedInteger;
