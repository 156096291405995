import { DateDim } from '../../model';
import { DimensionCandidate } from './DimensionCandidate';
import { DateTime } from 'luxon';
import { Colors } from '../../utils';
import { ckmeans } from 'simple-statistics';
const MostUsedColors = Colors.mostUsed();
export class DateCandidate extends DimensionCandidate {
    _deliver() {
        return [DateDim, {
                datatype: 'Date',
                values: this.getClustersFilters(),
            }];
    }
    placeBet() {
        const nonNulls = [...this.values.keys()].filter(Boolean);
        let valid = 0;
        for (const value of nonNulls) {
            // Stop early when faced with non strings
            if (!(value instanceof Date) && typeof value !== 'string') {
                return 0.0;
            }
            // Keep track of valid vs invalid date representations
            if (this.isValidDate(value)) {
                valid++;
            }
        }
        return valid / nonNulls.length;
    }
    isValidDate(v) {
        if (v instanceof Date)
            return true;
        if (typeof v !== 'string')
            return false;
        const str = v;
        if (str.trim().match(/[^\d-T:]/)) {
            return false;
        }
        else {
            const date = Date.parse(v);
            return !isNaN(date);
        }
    }
    getClustersFilters() {
        const timestamps = this.allValues
            .filter(d => this.isValidDate(d))
            .map(d => {
            const dt = DateTime.fromJSDate(new Date(d));
            return dt.startOf('day');
        })
            .map(d => d.toJSDate().getTime());
        const k = Math.min(Math.sqrt(this.allValues.length / 4), 20, timestamps.length);
        const clusters = ckmeans(timestamps, k);
        return clusters.map((cluster, i) => {
            const min = DateTime.fromMillis(cluster[0]).toFormat('yyyy-MM-dd');
            const max = DateTime.fromMillis(cluster[cluster.length - 1]).toFormat('yyyy-MM-dd');
            return {
                id: `${min} - ${max}`,
                label: `${min} - ${max}`,
                ordering: 10000,
                color: clusters.length <= MostUsedColors.length
                    ? MostUsedColors[i]
                    : null,
                semantics: `${min} .. ${max}`,
                description: null,
                deprecated: false,
            };
        });
    }
}
