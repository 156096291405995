/* eslint-disable angular/timeout-service */
/* eslint-disable angular/document-service */
angular
    .module('klaro')
    .service('shakeIt', shakeIt);
function shakeIt() {
    return ($event, elmRole, style = 'animate-pulse') => {
        const element = document.querySelector(`[data-role="${elmRole}"]`);
        if (!element) {
            return;
        }
        const stop = () => {
            element.classList.remove(style);
        };
        const start = () => {
            element.classList.add(style);
            element.addEventListener('animationend', stop, { once: true });
            setTimeout(stop, 2500);
            element.addEventListener('click', stop, { once: true });
        };
        if ($event) {
            const o = $event.originalEvent;
            createMovingCircle(o.clientX, o.clientY, element, start);
        }
        else {
            start();
        }
    };
}
function createMovingCircle(clickX, clickY, targetElement, callback) {
    const circle = document.createElement('div');
    circle.classList.add('moving-circle');
    document.body.appendChild(circle);
    // Position the circle at the click location
    circle.style.left = `${clickX - 10}px`;
    circle.style.top = `${clickY - 10}px`;
    // Calculate the target's position
    const targetRect = targetElement.getBoundingClientRect();
    const targetX = targetRect.left + targetRect.width / 2;
    const targetY = targetRect.top + targetRect.height / 2;
    // Use CSS variables to move the circle to the target
    const deltaX = targetX - clickX;
    const deltaY = targetY - clickY;
    const keyframesName = `move-to-${Date.now()}`;
    const styleSheet = document.styleSheets[0];
    const keyframeIndex = styleSheet.cssRules.length; // Track the index for removal later
    styleSheet.insertRule(`
    @keyframes ${keyframesName} {
      0% {
        transform: translate(0, 0) scale(1);
        opacity: 1;
      }
      100% {
        transform: translate(${deltaX}px, ${deltaY}px) scale(1.5);
        opacity: 0;
      }
    }
  `, keyframeIndex);
    circle.style.animation = `${keyframesName} 0.8s ease-out forwards`;
    // Remove the circle after the animation ends
    circle.addEventListener('animationend', () => {
        circle.remove();
        if (styleSheet.cssRules[keyframeIndex] && styleSheet.cssRules[keyframeIndex].name === keyframesName) {
            styleSheet.deleteRule(keyframeIndex);
        }
        callback();
    });
}
