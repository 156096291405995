angular
    .module('klaro')
    .directive('paneModalFeedback', paneModalFeedback);
function paneModalFeedback() {
    return {
        restrict: 'EA',
        require: '^paneModal',
        template: require('@/layout/paneModal/paneModalFeedback.html'),
    };
}
