angular
    .module('klaro')
    .service('MyPinnedStories', MyPinnedStories);
function MyPinnedStories(confirm, pinnnedStoriesRest, navigation, $rootScope, auth) {
    let listeners = [];
    let pinned = null;
    function listen(listener) {
        listeners.push(listener);
        if (pinned) {
            listener(pinned);
        }
        return () => listeners = listeners.filter(l => l != listener);
    }
    function notifyListeners() {
        listeners.forEach(l => l(pinned));
    }
    function fetch(board, force = false) {
        if (pinned && !force) {
            return Promise.resolve(pinned);
        }
        if (auth.isLogged() && auth.isProjectUser()) {
            const world = { dimensions: board.getDimensions() };
            return pinnnedStoriesRest.get(world)
                .then(ss => pinned = ss)
                .then(notifyListeners)
                .catch(navigation.failed);
        }
        else {
            return Promise.resolve([]);
        }
    }
    $rootScope.$on('klaro.user.changed', () => {
        pinned = null;
    });
    function isStoryPinned(story) {
        return pinned && pinned.find(s => s.id === story.id);
    }
    function getActionLabel(story) {
        return `${isStoryPinned(story) ? 'Unpin card' : 'Pin on Dashboard'}`;
    }
    function trackStoriesChanged(stories) {
        if (!pinned)
            return;
        const index = stories.reduce((memo, s) => {
            memo[s.id] = s;
            return memo;
        }, {});
        pinned = pinned.map(s => index[s.id] || s);
        notifyListeners();
        return stories;
    }
    function trackChange(story, wasPinned) {
        if (!pinned) {
            return;
        }
        else if (wasPinned) {
            pinned = pinned.filter(s => s.id !== story.id);
        }
        else {
            pinned = pinned.concat([story]);
        }
        notifyListeners();
    }
    function togglePinStory(story, askForConfirmation = true) {
        const isPinned = isStoryPinned(story);
        const doIt = () => {
            const storyRefs = [{ id: story.id }];
            if (isPinned) {
                return pinnnedStoriesRest
                    .unpin(storyRefs)
                    .then(() => trackChange(story, isPinned))
                    .catch(navigation.failed);
            }
            else {
                return pinnnedStoriesRest
                    .pin(storyRefs)
                    .then(() => trackChange(story, isPinned))
                    .catch(navigation.failed);
            }
        };
        if (askForConfirmation) {
            const label = isPinned ? 'Unpin' : 'Pin';
            return confirm({
                h2: `${label} card?`,
                hint: isPinned ?
                    `Card will be removed from top of your dashboard` :
                    `Card will remain visible at top of your dashboard`,
            }, doIt);
        }
        return new Promise((res, rej) => {
            res(doIt());
        });
    }
    ;
    return {
        listen: listen,
        fetch: fetch,
        isStoryPinned: isStoryPinned,
        togglePinStory: togglePinStory,
        getActionLabel: getActionLabel,
        trackStoriesChanged: trackStoriesChanged,
    };
}
