angular
    .module('klaro')
    .directive('klaroSelect', klaroSelect);
function klaroSelect() {
    return {
        restrict: 'E',
        require: '^ngModel',
        scope: {
            items: '=',
            useIcon: '=',
            onlyOptions: '=',
        },
        template: require('@/support/klaroSelect.html'),
        link: function (scope, elm, attrs, ngModelController) {
            scope.selected = undefined;
            ngModelController.$render = function () {
                if (!scope.items) {
                    return;
                }
                const vv = ngModelController.$viewValue;
                scope.selected = scope.items.find((item) => {
                    return (item.code === vv || (vv === undefined && item.code === null));
                });
            };
            ngModelController.$render();
            scope.$watch('items', ngModelController.$render);
            scope.selectItem = function (item) {
                ngModelController.$setViewValue(item.code);
                ngModelController.$render();
            };
            scope.isSelected = function (item) {
                return scope.selected && (scope.selected.code === item.code);
            };
        },
    };
}
