import { DimensionDeck } from '@klaro/corejs/views';
angular
    .module('klaro')
    .directive('dimensionDeck', dimensionDeck);
/**
 * This directive implements a filter on the main story grid/list.
 */
function dimensionDeck(Config, JsUtils) {
    return {
        restrict: 'E',
        template: require('@/core/dimension/dimensionDeck.html'),
        scope: {
            dimension: '=',
            open: '=',
            explorer: '=',
        },
        require: '^board',
        link: function (scope, elm, attr, board) {
            // Whether the dimension is open, which takes
            // default value from what is specified on the directive
            // use (but allows independent behavior afterwards)
            scope.isOpen = scope.open || scope.explorer;
            // Filter handling
            scope.search = {
                text: undefined,
            };
            function setDeck() {
                scope.deck = new DimensionDeck({
                    dimension: scope.dimension,
                    board: board.getBoardState(),
                    storyCounts: board.storyCounts(),
                    maxValuesShown: Config.decksDimensionsValuesMaxDisplay,
                }).withSearch(scope.search.text);
            }
            setDeck();
            scope.isFilteredBy = function (dimValue) {
                return board.isFilteredBy(scope.dimension, dimValue);
            };
            scope.toggleFilter = function (dimValue, $event) {
                return board.toggleFilter(scope.dimension, dimValue, $event);
            };
            scope.addFilter = function (dimValue, $event) {
                $event.preventDefault();
                $event.stopPropagation();
                if (scope.isFilteredBy(dimValue)) {
                    return board.removeFilter(scope.dimension, dimValue);
                }
                else {
                    return board.addFilter(scope.dimension, dimValue);
                }
            };
            scope.clearFilter = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                return board.removeDimensionFilters(scope.dimension);
            };
            scope.onSummaryClick = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();
                scope.isOpen = !scope.isOpen;
            };
            board.$watch('board', () => {
                scope.hasFilter = board.hasFilter(scope.dimension);
                scope.isOpen = scope.isOpen || scope.explorer;
                setDeck();
            }, scope);
            scope.$watch('search.text', setDeck);
            // Highlighting handling
            scope.isHighlighted = function (value) {
                return board.highlightedMatch(scope.dimension, value);
            };
            scope.getValueClass = function (value) {
                return `klaro-${scope.dimension.code} ${JsUtils.toCssClass(value.id)}`;
            };
            scope.showAllValues = function () {
                scope.deck = scope.deck.withShowAll();
            };
            scope.isDetailsOpen = function () {
                return scope.isOpen;
            };
        },
    };
}
