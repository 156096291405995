angular
    .module('klaro')
    .directive('labsModalPart', labsModalPart);
function labsModalPart(navigation) {
    return {
        restrict: 'E',
        scope: {
            user: '=',
            userData: '=',
            ctrl: '=',
        },
        template: require('@/auth/profileModal/labsModalPart.html'),
        link: function (scope) {
            var _a;
            (_a = scope.userData.preferences).labs || (_a.labs = {});
            scope.labs = scope.userData.preferences.labs;
            scope.canSave = function () {
                return isDirty();
            };
            scope.save = function () {
                scope
                    .ctrl
                    .patchUser()
                    .then(() => {
                    scope.ctrl.switchMode('reloadNeeded');
                })
                    .catch(scope.ctrl.onError);
            };
            function isDirty() {
                return scope.userData.preferences.labs &&
                    scope.labsPreferencesForm.$dirty;
            }
        },
    };
}
