import { Story } from '@klaro/corejs/model';
import { LocalStoragePersistence } from '@klaro/corejs/utils';
angular
    .module('klaro')
    .directive('recentNews', recentNews);
function recentNews(navigation, recentNewsService) {
    return {
        restrict: 'E',
        scope: {},
        template: require('@/news/recentNews.html'),
        link: function (scope) {
            recentNewsService.getOne().then(news => scope.recentNews = news);
            scope.openNews = function (news, $event) {
                recentNewsService.trackNewsClicked(news);
                $event.preventDefault();
                $event.stopPropagation();
                navigation.gotoExternalUrl(news.url, true);
            };
        },
    };
}
