angular
    .module('klaro')
    .directive('mainBodyClass', mainBodyClass);
function mainBodyClass(events, $rootScope, Config, detectIt) {
    return {
        link: function (scope, element) {
            element.addClass(detectIt.primaryInput);
            $rootScope.$watch('mainBodyClassChanged', () => {
                element.addClass(Config.mainBodyClass.join(' '));
            });
            $rootScope.$watch('fullScreen', (newValue) => {
                if (newValue) {
                    element.addClass('full-screen');
                }
                else {
                    element.removeClass('full-screen');
                }
            });
            events.listen(scope, '$ngSpinStart', () => {
                element.addClass('saving');
            });
            events.listen(scope, '$ngSpinStop', () => {
                element.removeClass('saving');
            });
        },
    };
}
