import ArrayUtils from '../../utils/ArrayUtils';
class StoryCounts {
    constructor(stories) {
        this.stories = stories;
        this._countsCache = {};
    }
    partialResults() {
        return this.stories.partialResults();
    }
    getCounts(dimension, value) {
        if (Array.isArray(value)) {
            const counts = ArrayUtils.uniq(value).map(v => this.getCounts(dimension, v));
            return Object.values(counts)
                .reduce((acc, item) => {
                return {
                    all: acc.all + item.all,
                    filtered: acc.filtered + item.filtered,
                };
            }, { all: 0, filtered: 0 });
        }
        if (value && value.id !== undefined) {
            value = value.id;
        }
        if (!this._countsCache[dimension.code]) {
            this._countsCache[dimension.code] = this._computeDimensionCounts(dimension);
        }
        return this._countsCache[dimension.code][value] || { all: 0, filtered: 0 };
    }
    _computeDimensionCounts(dimension) {
        const counts = {};
        const globalContext = this.stories.globalContext;
        this.stories.filtered.forEach((story) => {
            dimension.matchingValuesOf(story, globalContext).forEach((value) => {
                value ?? (value = {});
                counts[value.id] = (counts[value.id] || { all: 0, filtered: 0 });
                counts[value.id].filtered += 1;
            });
        });
        this.stories.all.forEach((story) => {
            dimension.matchingValuesOf(story, globalContext).forEach((value) => {
                value ?? (value = {});
                counts[value.id] = (counts[value.id] || { all: 0, filtered: 0 });
                counts[value.id].all += 1;
            });
        });
        return counts;
    }
}
export default StoryCounts;
