angular
    .module('klaro')
    .service('navigation', navigation)
    .directive('linkTo', linkTo);
function navigation($rootScope, $location, $route, $window, failedModal, robust, auth, $injector, $document, $timeout) {
    function getSubdomain() {
        return $location.host().split('.')[0];
    }
    function isApp() {
        return getSubdomain() === 'app';
    }
    function back() {
        $window.history.back();
    }
    function reload() {
        $rootScope.$emit('klaro.application.reload');
        $route.reload();
    }
    function refresh() {
        $window.location.reload();
    }
    function noop(x) {
        return x;
    }
    function failed(err) {
        // We skip 401 errors, since the interceptor moves to the
        // login page for them
        if (err && err.status && err.status === 401) {
            return;
        }
        //
        // eslint-disable-next-line no-console
        console.error(err);
        if (typeof Sentry !== 'undefined') {
            Sentry.captureException(err);
        }
        return failedModal.open({
            title: 'Whoops',
            description: robust.message(err),
        }).result.then(noop).catch(noop);
    }
    function isActive(url) {
        return $location.path().indexOf(url) === 0;
    }
    function isCurrentBoard(b) {
        return isActive(b._links.self);
    }
    function isBoardUrl() {
        return isActive('/boards/');
    }
    function getHomeUrl() {
        return `${$location.protocol()}://${$location.host()}`;
    }
    function gotoHome(hardWay) {
        if (hardWay) {
            $window.location = getHomeUrl();
        }
        else {
            return gotoUrl('/');
        }
    }
    function getWebsiteUrl() {
        return `${$location.protocol()}://${$location.host().replace(/^[^.]+\./, '')}`;
    }
    function getTemplateWebsiteUrl(t) {
        return t.isPublic ? `${getWebsiteUrl()}/templates/${t.subdomain}` : null;
    }
    function gotoExternalUrl(url, blank) {
        if (blank) {
            $window.open(url, '_blank');
        }
        else {
            $window.location = url;
        }
    }
    function gotoWebsite() {
        return gotoExternalUrl(getWebsiteUrl());
    }
    function gotoUrl(url, search) {
        $location.url(url);
        if (search) {
            $location.search(search);
        }
    }
    function gotoBoard(board, search) {
        const alreadyActive = isActive(board._links.self);
        gotoUrl(board._links.self, search);
        if (alreadyActive) {
            reload();
        }
    }
    function gotoNextPrevBoard(which) {
        $injector.get('MyBoards').inCurrentWorkspace().then((boards) => {
            let index;
            boards.find((x, i) => {
                if (!isCurrentBoard(x)) {
                    return false;
                }
                index = i;
                return true;
            });
            const nextIndex = (which + (index || 0)) % boards.length;
            const nextOne = boards[nextIndex === -1 ? boards.length - 1 : nextIndex];
            if (!nextOne) {
                return;
            }
            gotoBoard(nextOne);
        });
    }
    function gotoNextBoard() {
        return gotoNextPrevBoard(1);
    }
    function gotoPreviousBoard() {
        return gotoNextPrevBoard(-1);
    }
    function gotoNewProject() {
        const mainHost = $location.host().replace(/^[^.]+\./, '');
        const url = `${$location.protocol()}://app.${mainHost}/new`;
        $window.open(url, '_blank');
    }
    function gotoNewSubdomain(subdomain) {
        const url = new URL(`${$location.protocol()}://${$location.host()}`);
        const hostSplitted = url.host.split('.');
        hostSplitted[0] = subdomain;
        url.host = hostSplitted.join('.');
        $window.location = url.toString();
    }
    function getPath() {
        return $location.path();
    }
    // Returns the current # in navigation URL, with a fallback to the default
    // value provided.
    function getHash(def) {
        return $location.hash() || def;
    }
    // Set the current # in navigation URL, taking care if possible of preserving
    // the scroll for when the hash will later be poped (see popHash).
    //
    // This method is provided for handling of modals opening on top of a screen
    // that want to track their state using the browser hash. It must be followed
    // by a clearHash or popHash when the modal is closed later. Please do not use
    // the method in any other untested cases for now.
    //
    // On old browsers, we fallback to a simple angular overriding using $location.
    //
    // The method does not return anything specified.
    function setHash(arg) {
        if ('replaceState' in history) {
            // Save the scroll position on browser history
            const oldState = { scrollX: $window.scrollX, scrollY: $window.scrollY };
            history.replaceState(oldState, $document[0].title);
            // Push the new state and reset the scroll
            const newPath = new URL($window.location.href);
            newPath.hash = arg;
            const newState = { scrollX: 0, scrollY: 0 };
            history.pushState(newState, $document[0].title, newPath.toString());
            $window.dispatchEvent(new HashChangeEvent('hashchange'));
        }
        else {
            $location.hash(`${arg}`);
        }
    }
    // When the current browser navigation # is already set, does nothing and
    // returns false, otherwise call setHash and returns what it returns.
    function ensureHash(hash) {
        const current = getHash('');
        if (current.indexOf(hash) !== 0) {
            return setHash(hash);
        }
        else {
            return false;
        }
    }
    // Pop the current navigation hash installed using setHash, and preserve the
    // previous screen state (scroll) if possible.
    function popHash(arg) {
        $location.hash(null);
        return arg;
    }
    $window.addEventListener('popstate', (e) => {
        if (e && e.state && (e.state.scrollX || e.state.scrollY)) {
            // Restore saved scrolling. Under some browsers, it seems we need to wait
            // a little bit for this to work. 25ms has been found empirically...
            $timeout(() => {
                $window.scrollTo(e.state.scrollX || 0, e.state.scrollY || 0);
            }, 25);
        }
    });
    // Remove the current navigation hash without changing browser history and/or
    // scroll.
    function clearHash(arg) {
        if ('replaceState' in history) {
            history.replaceState({}, $document[0].title);
        }
        $location.hash(null);
        return arg;
    }
    function urlInProject(url) {
        return `${getHomeUrl()}/${url}`;
    }
    return {
        back: back,
        reload: reload,
        refresh: refresh,
        noop: noop,
        failed: failed,
        isActive: isActive,
        isCurrentBoard: isCurrentBoard,
        isBoardUrl: isBoardUrl,
        getSubdomain: getSubdomain,
        isApp: isApp,
        getHomeUrl: getHomeUrl,
        gotoNextBoard: gotoNextBoard,
        gotoPreviousBoard: gotoPreviousBoard,
        gotoHome: gotoHome,
        gotoUrl: gotoUrl,
        gotoExternalUrl: gotoExternalUrl,
        getWebsiteUrl: getWebsiteUrl,
        getTemplateWebsiteUrl: getTemplateWebsiteUrl,
        gotoWebsite: gotoWebsite,
        gotoBoard: gotoBoard,
        gotoNewProject: gotoNewProject,
        gotoNewSubdomain: gotoNewSubdomain,
        getHash: getHash,
        setHash: setHash,
        popHash: popHash,
        clearHash: clearHash,
        ensureHash: ensureHash,
        getPath: getPath,
        urlInProject,
    };
}
function linkTo(navigation) {
    return {
        link: function (scope, element, attributes) {
            element.click(() => {
                if (!attributes.disabled) {
                    scope.$apply(() => {
                        navigation.gotoUrl(attributes.linkTo);
                    });
                }
            });
        },
    };
}
