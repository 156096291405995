import { KlaroError, OperationRunError } from '../errors';
export class OperationRunner {
    constructor(client) {
        this.client = client;
        this.errorHandlers = [];
    }
    async load(operation, args = undefined) {
        if (!await this.checkPreconditions(operation, args)) {
            throw new Error(`Invalid operation run, operation ${operation.constructor.name} refuses running with args`);
        }
        console.info('[OperationRunner#run] Running operation', operation.constructor.name, args);
        try {
            return await operation.load(this.client, args);
        }
        catch (err) {
            console.info('[OperationRunner#run] Failed to run operation', operation.constructor.name, err);
            let error;
            if (err instanceof KlaroError) {
                error = err;
            }
            else {
                console.trace(err);
                error = new OperationRunError(err, operation, args);
            }
            return await this.handleError(error);
        }
    }
    async run(operation, args = undefined) {
        if (!await this.checkPreconditions(operation, args)) {
            throw new Error(`Invalid operation run, operation ${operation.constructor.name} refuses running with args`);
        }
        console.info('[OperationRunner#run] Running operation', operation.constructor.name, args);
        try {
            const patch = await operation.run(this.client, args);
            if (!patch) {
                return this.client.store;
            }
            return this.client.store.with(patch);
        }
        catch (err) {
            console.info('[OperationRunner#run] Failed to run operation', operation.constructor.name, err);
            let error;
            if (err instanceof KlaroError) {
                error = err;
            }
            else {
                console.trace(err);
                error = new OperationRunError(err, operation, args);
            }
            return await this.handleError(error);
        }
    }
    addErrorHandler(handler) {
        this.errorHandlers.push(handler);
    }
    async checkPreconditions(operation, args = undefined) {
        const promises = operation.preconditions?.map(p => p(this.client, args)) || [];
        const results = await Promise.all(promises);
        return results.every(b => b === true);
    }
    async handleError(err) {
        let cont = false;
        for (const handler of this.errorHandlers) {
            handler(err, () => {
                cont = true;
            });
            if (!cont) {
                return;
            }
        }
        // no one took care of it, we throw
        throw err;
    }
}
