import { Dimension } from '@klaro/corejs/model';
angular
    .module('klaro')
    .directive('boardOrderingSelect', boardOrderingSelect);
function boardOrderingSelect(dimensionEditModal) {
    return {
        restrict: 'E',
        template: require('@/core/board/boardOrderingSelect.html'),
        require: '^board',
        scope: true,
        replace: true,
        link: function (scope, elm, attrs, board) {
            let candidates;
            board.$watch('board', () => {
                candidates = board.getOrderingCandidates();
                scope.options = candidates.toNgOptions();
                scope.selected = candidates.currentNgOption();
            }, scope);
            scope.setOrdering = function (opt) {
                if (!candidates) {
                    return;
                }
                if (!candidates.isCurrentNgOption(opt)) {
                    if (opt.code === 'newDimension') {
                        const onDimensionAdded = function (d) {
                            return board.addNewDimension(d).then(() => {
                                board.sortBy(d);
                            });
                        };
                        dimensionEditModal.open(Dimension.newOne(), board.getDimensions()).result.then(onDimensionAdded);
                    }
                    else if (opt.code === 'useComplex') {
                        board.toggleBoardSettings('cards-ordering', {
                            skipDirtyCover: true,
                        });
                    }
                    else if (opt.code === 'useCustom') {
                        board.ensureSortByCustomOrder();
                    }
                    else if (opt.anchor) {
                        board.sortBy(opt.anchor);
                    }
                }
            };
            scope.cssClassFor = function (option, selected) {
                const clazz = [];
                if ((option.anchor && selected?.anchor === option.anchor) || (option.code && selected?.code === option.code)) {
                    clazz.push('selected');
                }
                if (option.icon) {
                    clazz.push(option.icon);
                }
                return clazz.join(' ');
            };
        },
    };
}
