angular
    .module('klaro')
    .directive('klaroAsides', klaroAsides);
function klaroAsides($rootScope, klaroUi, $window, Project) {
    return {
        restrict: 'E',
        template: require('@/layout/asides/klaroAsides.html'),
        link: function (scope, elm) {
            scope.asideIsClosed = function () {
                if (asidesClosedForAnonymous()) {
                    return true;
                }
                else {
                    return $window.localStorage.getItem('hide-aside') === 'true';
                }
            };
            function asidesClosedForAnonymous() {
                return !$window.localStorage.getItem('hide-aside') && // the user hasn't made a choice yet
                    $rootScope.isAnonymous && // this is an anonymous user
                    elm.find('.my-boards li').length < 2 && // there is only one board available
                    elm.find('view-as-selector option').length < 2 && // there is only 1 workspace available
                    !klaroUi.isMobile(); // asides are already closed by default on mobile
            }
            Project.get().then((p) => {
                scope.projectName = p.name;
                scope.projectLogo = p.logoThumbUrl;
            });
        },
    };
}
