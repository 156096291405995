import { ApiConnector } from './api';
import { LocalConnector } from './local';
export * from './abstract';
export * from './api';
export * from './local';
export * from './types';
export var KlaroConnector;
(function (KlaroConnector) {
    KlaroConnector["api"] = "api";
    KlaroConnector["local"] = "local";
})(KlaroConnector || (KlaroConnector = {}));
const KlaroConnectors = {
    [KlaroConnector.api]: ApiConnector,
    [KlaroConnector.local]: LocalConnector
};
export const factorConnector = (client, type, args) => {
    if (!KlaroConnectors[type]) {
        throw new Error(`Unknown connector type: ${type}`);
    }
    return new KlaroConnectors[type](client, args);
};
