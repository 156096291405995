angular
    .module('klaro')
    .directive('boardEmptyNotice', boardEmptyNotice);
function boardEmptyNotice(boardImportModal) {
    return {
        restrict: 'A',
        template: require('@/core/board/boardEmptyNotice.html'),
        require: '^board',
        scope: {},
        link: function (scope, elm, attrs, boardCtrl) {
            scope.canWrite = function () {
                return boardCtrl.canWrite();
            };
            scope.createFirstCard = function () {
                return boardCtrl.openNewStoryModal({});
            };
            scope.fileUploaded = function (promise) {
                boardImportModal.open(boardCtrl, 'xlsx', null, promise);
            };
            scope.startImport = function () {
                boardImportModal.open(boardCtrl, 'xlsx', null);
            };
        },
    };
}
