/**
 * Add a target="_blank" and stop event propagation on all links.
 */
function MarkdownExternalLinks(md) {
    const linker = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
    };
    md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
        tokens[idx].attrPush(['target', '_blank']);
        tokens[idx].attrPush(['onclick', 'event.stopPropagation();']);
        const link = linker(tokens, idx, options, env, self);
        return link;
    };
}
export default MarkdownExternalLinks;
