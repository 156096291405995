import { v4 } from 'uuid';
export const teamWork = async () => {
    return {
        board: {
            id: v4(),
            label: 'Team work',
            mode: 'kanban',
            filters: {},
            anchors: {
                displayBy: ['state'],
            },
            displayDimensionsOnCards: true,
            displayDimensionLabels: false,
            displayNoneOnCards: false,
            coloredDimension: 'state',
            background: 'default',
            decksOpen: true,
            dimensions: [
                {
                    id: v4(),
                    code: 'state',
                    label: 'State',
                    required: true,
                    tagging: true,
                    userEditable: true,
                    supportsColor: true,
                    ordering: 100000,
                    values: [
                        { id: null, label: 'No value', color: '#ffffff' },
                        { id: 'todo', label: 'Todo', ordering: 100000, color: '#e01514' },
                        { id: 'ongoing', label: 'Ongoing', ordering: 200000, color: '#f77d00' },
                        { id: 'done', label: 'Done', ordering: 300000, color: '#00b048' },
                    ],
                },
                {
                    id: v4(),
                    code: 'assignee',
                    label: 'Assignee',
                    required: false,
                    tagging: true,
                    userEditable: true,
                    supportsColor: true,
                    ordering: 200000,
                    values: [
                        { id: null, label: 'No value', color: '#ffffff' },
                        { id: 'adrien', label: 'Adrien', ordering: 100000, color: '#F77D00' },
                        { id: 'bernard', label: 'Bernard', ordering: 200000, color: '#B7137E' },
                        { id: 'victor', label: 'Victor', ordering: 300000, color: '#99CC2D' },
                    ],
                },
                {
                    id: v4(),
                    code: 'scope',
                    label: 'Scope',
                    required: false,
                    tagging: true,
                    userEditable: true,
                    supportsColor: true,
                    ordering: 300000,
                    values: [
                        { id: null, label: 'No value' },
                        { id: 'marketing', label: 'Marketing', ordering: 100000 },
                        { id: 'product', label: 'Product', ordering: 200000 },
                    ],
                },
            ],
            extraSettings: {
                onCardClick: {
                    type: 'quickEdit',
                    options: {},
                },
            },
        },
        stories: [{
                id: v4(),
                title: 'Create an advertisement\non Facebook, LinkedIn, Twitter.',
                state: 'todo',
                scope: 'marketing',
            }, {
                id: v4(),
                title: 'Prepare a workshop\nexplaining the best practices in agility.',
                state: 'todo',
                scope: 'marketing',
            }, {
                id: v4(),
                title: 'Fix the frontend bug\nwhen opening a new pop-up.',
                state: 'todo',
                scope: 'product',
            }],
    };
};
