import DraggedStoryView from './DraggedStoryView';
class DragStoryHandler {
    constructor(gantt, storyView, uiStateMapper) {
        this.gantt = gantt;
        this.dragged = new DraggedStoryView(gantt, storyView);
        this.uiStateMapper = uiStateMapper;
    }
    uiStateFor(storyView) {
        return this.uiStateMapper(storyView);
    }
    dragStart(at, opts) {
    }
    dragging(at, opts) {
    }
    drop(at, opts) {
    }
}
class MoveStoryHandler extends DragStoryHandler {
    dragStart(at, opts) {
        this.grabedAt = at;
        this.originalInterval = this.dragged.interval;
        return this.updateView(at);
    }
    dragging(at, opts) {
        return this.updateView(at);
    }
    drop(at, opts) {
        return this.updateView(at);
    }
    updateView(at) {
        const period = this.gantt.currentScale.period;
        const sStart = this.originalInterval[0].startOf('day');
        const sStop = this.originalInterval[1].endOf('day');
        const grabedAt = this.grabedAt.startOf(period.key);
        const nowAt = at.startOf(period.key);
        const dist = this.gantt.dist(nowAt, grabedAt, period.key);
        const move = dist < 0 ? dist + 1 : dist - 1;
        const newStart = sStart.plus({ [period.key]: move });
        const newStop = sStop.plus({ [period.key]: move }).endOf('day');
        this.dragged.setBoundaries(newStart, newStop);
        return this.dragged;
    }
}
class StartOfCellStoryHandler extends DragStoryHandler {
    dragStart(at, opts) {
        const i = this.dragged.interval;
        const dist = this.gantt.dist(i[1], i[0], 'day');
        this.originalDist = dist < 0 ? dist + 1 : dist - 1;
        return this.updateView(at);
    }
    dragging(at, opts) {
        return this.updateView(at);
    }
    drop(at, opts) {
        return this.updateView(at);
    }
    updateView(at) {
        const start = at.startOf('day');
        const stop = at.plus({ days: this.originalDist }).startOf('day');
        this.dragged.setBoundaries(start, stop);
        return this.dragged;
    }
}
class MoveStartHandler extends DragStoryHandler {
    dragStart(at, opts) {
        this.originalEnds = this.dragged.interval;
        return this.updateView(at);
    }
    dragging(at, opts) {
        return this.updateView(at);
    }
    drop(at, opts) {
        return this.updateView(at);
    }
    updateView(at) {
        if (at > this.originalEnds[1]) {
            this.dragged.setBoundaries(at, this.originalEnds[1]);
        }
        else {
            this.dragged.setBoundaries(this.originalEnds[1], at);
        }
        return this.dragged;
    }
}
class MoveStopHandler extends DragStoryHandler {
    dragStart(at, opts) {
        this.originalEnds = this.dragged.interval;
    }
    dragging(at, opts) {
        return this.updateView(at);
    }
    drop(at, opts) {
        return this.updateView(at);
    }
    updateView(at) {
        const period = this.gantt.currentScale.period.key;
        if (at < this.originalEnds[0].startOf('day')) {
            this.dragged.setBoundaries(at, this.originalEnds[0]);
        }
        else {
            this.dragged.setBoundaries(this.originalEnds[0], at.endOf(period));
        }
        return this.dragged;
    }
}
class DrawNewStoryHandler extends DragStoryHandler {
    dragStart(at, opts) {
        this.startedAt = at;
        return this.updateView(at);
    }
    dragging(at, opts) {
        return this.updateView(at);
    }
    drop(at, opts) {
        return this.updateView(at);
    }
    updateView(at) {
        const period = this.gantt.currentScale.period.key;
        this.dragged.setBoundaries(this.startedAt.startOf(period), at.endOf(period));
        return this.dragged;
    }
}
class DispatchStoryHandler extends DragStoryHandler {
    constructor(gantt, storyView, uiStateMapper, candidateFn) {
        super(gantt, storyView, uiStateMapper);
        this.candidateFn = candidateFn;
        this.candidate = null;
    }
    uiStateFor(storyView, opts) {
        return this.ensureCandidate(opts).uiStateFor(storyView);
    }
    dragStart(at, opts) {
        this.replay = {
            dragStart: at,
        };
        return this.ensureCandidate(opts).dragStart(at, opts);
    }
    dragging(at, opts) {
        return this.ensureCandidate(opts).dragging(at, opts);
    }
    drop(at, opts) {
        return this.ensureCandidate(opts).drop(at, opts);
    }
    ensureCandidate(opts) {
        const shouldBe = this.candidateFn(opts);
        if (this.candidate != shouldBe) {
            this.candidate = shouldBe;
            this.candidate.dragStart(this.replay.dragStart, opts);
        }
        return this.candidate;
    }
}
export { DragStoryHandler, MoveStoryHandler, StartOfCellStoryHandler, MoveStartHandler, MoveStopHandler, DrawNewStoryHandler, DispatchStoryHandler, };
