/**
 * **Mutable** class to help with drag & drop in Gantt
 * view.
 */
class DraggedStoryView {
    constructor(gantt, storyView) {
        this.gantt = gantt;
        Object.assign(this, storyView);
    }
    setBoundaries(start, stop) {
        const override = this.gantt.factorBaseStoryView(start, stop, this);
        return Object.assign(this, override);
    }
    setStart(start) {
        return this.setBoundaries(start, this.interval[1]);
    }
    setStop(stop) {
        return this.setBoundaries(this.interval[0], stop);
    }
    move(dist, base = 'day') {
        const start = this.interval[0].plus({ [base]: dist });
        const stop = this.interval[1].plus({ [base]: dist });
        return this.setBoundaries(start, stop);
    }
}
export default DraggedStoryView;
