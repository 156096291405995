angular
    .module('klaro')
    .controller('WorkspacesListController', WorkspacesListController);
function WorkspacesListController($scope, $rootScope, $filter, workspaces, dimensions, newWorkspaceModal, workspaceRest, Title, $location, dimensionRest, robust, events) {
    Title.setCurrentPage('Manage workspaces');
    $rootScope.view = 'workspaces';
    setWorkspaces(workspaces);
    $scope.dimensions = dimensions;
    $scope.search = {
        text: ($location.search() || {}).q,
    };
    function reload() {
        workspaceRest.listFull()
            .then((workspacesList) => {
            workspaces = workspacesList;
            setWorkspaces(workspaces);
        });
        dimensionRest.list()
            .then((dims) => {
            $scope.dimensions = dims;
        });
    }
    $scope.reload = reload;
    $scope.editWorkspace = function (workspace) {
        newWorkspaceModal.open(workspace)
            .result
            .then(reload)
            .catch(robust.noop);
    };
    function setWorkspaces(workspaces) {
        if ($scope.search && $scope.search.text) {
            $scope.workspaces = $filter('filter')(workspaces, $scope.search.text);
        }
        else {
            $scope.workspaces = workspaces;
        }
    }
    $scope.$watch('search.text', () => {
        setWorkspaces(workspaces);
    });
    events.listen($scope, 'klaro.viewAs.changed', reload);
}
