angular
    .module('klaro')
    .directive('baseHeader', baseHeader);
function baseHeader() {
    return {
        template: require('@/layout/header/baseHeader.html'),
        scope: true,
        transclude: true,
        link: function (scope, elm, attrs, ctrl, transclude) {
            elm.find('.to-replace').replaceWith(transclude());
        },
    };
}
