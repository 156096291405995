const requireAll = (r) => r.keys().forEach(r);
import { TimeDuration, DateParser, ArrayUtils, } from '@klaro/corejs/utils';
const dependencies = [
    'klaro.kernel',
    'ngRoute',
    'ngSanitize',
    'ngMessages',
    'templates',
    'dndLists',
    'ui.bootstrap',
    'ui.gravatar',
    'yaru22.angular-timeago',
    'focus-if',
    'ngFileUpload',
    'cfp.hotkeys',
    'puigcerber.countryPicker',
    'tmh.dynamicLocale',
    '720kb.datepicker',
    'bootstrapLightbox',
];
if (process.env.SENTRY_DSN) {
    dependencies.push('ngSentry');
}
angular
    .module('klaro', dependencies)
    .constant('ArrayUtils', ArrayUtils)
    .constant('TimeDuration', TimeDuration)
    .constant('DateParser', DateParser);
requireAll(require.context('./support', true, /\.(js|ts)$/));
requireAll(require.context('./rest', true, /\.(js|ts)$/));
requireAll(require.context('./core', true, /\.(js|ts)$/));
requireAll(require.context('./auth', true, /\.(js|ts)$/));
requireAll(require.context('./sync', true, /\.(js|ts)$/));
requireAll(require.context('./onboard', true, /\.(js|ts)$/));
requireAll(require.context('./layout', true, /\.(js|ts)$/));
requireAll(require.context('./views', true, /\.(js|ts)$/));
requireAll(require.context('./news', true, /\.(js|ts)$/));
