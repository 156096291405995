angular
    .module('klaro')
    .directive('seshatDropZoneButton', seshatDropZoneButton);
function seshatDropZoneButton() {
    return {
        restrict: 'E',
        template: require('@/support/seshat/seshatDropZoneButton.html'),
        transclude: true,
        require: '^seshatDropZone',
        scope: {},
        link: function (scope, elm, attrs, seshatCtrl) {
            scope.cssClass = attrs.cssClass;
            scope.seshat = seshatCtrl.getSeshatScope();
        },
    };
}
