angular
    .module('klaro')
    .directive('valuesWidget', valuesWidget);
function valuesWidget(Colors, events) {
    return {
        restrict: 'E',
        scope: {
            dimension: '=',
        },
        require: '^ngModel',
        template: require('@/core/dimension/dimensionEditModal/valuesWidget.html'),
        link: function (scope, elm, attr, ngModelController) {
            scope.newValue = '';
            scope.values = [];
            scope.cardColors = Colors.cards();
            ngModelController.$render = function () {
                scope.values = ngModelController.$viewValue;
            };
            ngModelController.$render();
            function syncModel(values) {
                ngModelController.$setViewValue(scope.values);
            }
            scope.addValue = function (e) {
                scope.error = '';
                if ((e.keyCode == 13) || (e.keyCode == 9) || (e.keyCode == undefined)) { //enter or click on button
                    const newDimensionValue = scope.newValue.trim();
                    if (newDimensionValue.length) {
                        const alreadyExists = scope.values.find((dv) => {
                            return dv.label.toLowerCase() == newDimensionValue.toLowerCase();
                        });
                        if (alreadyExists) {
                            scope.error = 'A value with this label already exists.';
                        }
                        else {
                            scope.values.push({
                                label: newDimensionValue,
                                color: defaultColor(),
                            });
                            scope.newValue = '';
                            syncModel();
                        }
                    }
                    e.preventDefault();
                }
            };
            scope.deleteValue = function (value) {
                scope.values = scope.values.filter((dv) => {
                    return dv != value;
                });
                syncModel();
            };
            scope.drop = function (srcList, srcIndex, targetList, targetIndex) {
                // Copy the item from source to target.
                targetList.splice(targetIndex, 0, srcList[srcIndex]);
                // Remove the item from the source, possibly correcting the index first.
                // We must do this immediately, otherwise ng-repeat complains about duplicates.
                if (srcList == targetList && targetIndex <= srcIndex) {
                    srcIndex++;
                }
                srcList.splice(srcIndex, 1);
                // By returning true from dnd-drop we signalize we already inserted the item.
                syncModel();
                return true;
            };
            const closeColorsDropdown = function (e) {
                if (!angular.element(e.target)[0].closest('.color-input-container.open')) {
                    angular.element(elm)[0].querySelectorAll('.color-input-container.open').forEach((el) => {
                        el.classList.remove('open');
                    });
                }
            };
            scope.toggleColorsDropdown = function (e) {
                closeColorsDropdown(e);
                angular.element(e.target)[0].closest('.color-input-container').classList.toggle('open');
            };
            events.observeElement(scope, 'click', closeColorsDropdown, angular.element(elm)[0].closest('.modal-content'));
            function defaultColor() {
                const mostUsedColors = Colors.mostUsed();
                if (mostUsedColors.length) {
                    return mostUsedColors.shift();
                }
                else {
                    const usedColors = scope.values.map((usedColor) => {
                        return usedColor.color;
                    });
                    const notUsedColor = scope.cardColors.find((color) => {
                        return usedColors.indexOf(color.id) === -1;
                    });
                    if (notUsedColor) {
                        return notUsedColor.id;
                    }
                    else {
                        return scope.cardColors[Math.floor(Math.random() * scope.cardColors.length)].id;
                    }
                }
            }
        },
    };
}
