import { AbstractCollectionConnector, AbstractSingleResourceConnector } from '../abstract';
export class ApiDimensionsConnector extends AbstractCollectionConnector {
    single(id) {
        return new ApiDimensionConnector(this, id);
    }
    async post(dimensionData) {
        const res = await this.client.api.post(`${this.baseUrl}/dimensions`, dimensionData);
        return res.data;
    }
}
export class ApiDimensionConnector extends AbstractSingleResourceConnector {
    constructor(parent, id) {
        super(parent.client, parent.baseUrl);
        this.parent = parent;
        this.id = id;
    }
    async saveValue(dimId, value) {
        const res = await this.client.api.post(`${this.baseUrl}/dimensions/${dimId}/values/`, value);
        return res.data;
    }
}
