import { SetBoardState } from '@klaro/corejs/client';
import { boardResolver as BoardResolver } from './boardResolver';
angular
    .module('klaro.v1')
    .config(['$routeProvider', '$locationProvider', 'singleModalPage', 'failedModalPage',
    function ($routeProvider, $locationProvider, singleModalPage, failedModalPage) {
        function dimensionsResolver(dimensionRest) {
            return dimensionRest.list();
        }
        function membersResolver(memberRest) {
            return memberRest.list();
        }
        function boardResolver(which, mode) {
            const resolver = BoardResolver(which, mode);
            return ['$route', '$location', 'auth', 'klaroUi', 'MyPinnedStories', 'KernelClient',
                async function ($route, $location, auth, klaroUi, MyPinnedStories, KernelClient) {
                    const bs = await resolver($route, $location, auth, klaroUi, MyPinnedStories, KernelClient);
                    await KernelClient.run(new SetBoardState(), { board: bs });
                    return bs;
                },
            ];
        }
        function workspacesResolver(workspaceRest) {
            return workspaceRest.listFull();
        }
        function projectResolver(Project) {
            return Project.get();
        }
        function projectCategoriesResolver(projectRest) {
            return projectRest.categories();
        }
        function templatesResolver(templateRest) {
            return templateRest.list();
        }
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false,
        });
        $routeProvider
            .when('/', {
            isPublic: true,
            template: '',
            controller: 'HomeController',
        })
            .when('/auth/login', singleModalPage({
            template: require('@/auth/loginModal.html'),
            controller: 'LoginModalController',
            resolve: {
                login: ['$routeParams', function ($routeParams) { return $routeParams.login; }],
            },
        }, { showRecentNews: true }))
            .when('/auth/password/forgotten', singleModalPage({
            template: require('@/auth/password/forgottenModal.html'),
            controller: 'PasswordForgottenModalController',
            resolve: {
                email: ['$routeParams', function ($routeParams) { return $routeParams.email; }],
            },
        }))
            .when('/auth/password/reset/:token', singleModalPage({
            template: require('@/auth/password/resetModal.html'),
            controller: 'PasswordResetModalController',
            resolve: {
                token: ['$routeParams', function ($routeParams) { return $routeParams.token; }],
                obligation: function () { return undefined; }, // on intent
            },
        }))
            .when('/auth/password/change/:obligation/:token', singleModalPage({
            template: require('@/auth/password/resetModal.html'),
            controller: 'PasswordResetModalController',
            resolve: {
                token: ['$routeParams', function ($routeParams) { return $routeParams.token; }],
                obligation: ['$routeParams', function ($routeParams) { return $routeParams.obligation; }],
            },
        }))
            .when('/auth/join/:token', singleModalPage({
            template: require('@/auth/joinModal.html'),
            controller: 'JoinModalController',
            resolve: {
                token: ['$routeParams', function ($routeParams) { return $routeParams.token; }],
            },
        }))
            .when('/auth/validate/:token', singleModalPage({
            template: require('@/auth/validateEmailModal.html'),
            controller: 'ValidateEmailModalController',
        }))
            .when('/auth/email/change/validate/:token', singleModalPage({
            template: require('@/auth/validateEmailChangeModal.html'),
            controller: 'ValidateEmailChangeController',
            resolve: {
                token: ['$routeParams', function ($routeParams) { return $routeParams.token; }],
            },
        }))
            .when('/auth/remember-email-confirmation/', singleModalPage({
            template: require('@/auth/rememberEmailValidationModal.html'),
            controller: 'RememberEmailValidationModalController',
        }))
            .when('/auth/no-board-access', failedModalPage({
            title: 'No board access',
            description: 'It looks like you don’t have access to any board in this\
            project. This may occur when your default workspace has no board. Please\
            check your preferences below.\n\nIf the problem persists, contact the\
            project owner and make sure your workspace has at least one board.',
            report: false,
            close: false,
            home: 'Retry',
            logout: false,
            preferences: true,
        }))
            .when('/auth/forbidden', failedModalPage({
            title: 'Access forbidden',
            description: 'It looks like you don’t have access to the requested\
            page. Please contact your project owner.',
            report: false,
            close: false,
            back: 'Retry',
            logout: true,
            login: true,
            home: 'Go home',
        }))
            .when('/not-found', failedModalPage({
            title: 'Page not found',
            description: 'It looks like the requested page does not exist.\
            Please check, try again, or contact your project owner.',
            report: false,
            close: false,
            home: 'Go home',
            logout: true,
        }))
            .when('/stories/:id/', {
            template: require('@/views/stories/view.html'),
            controller: 'ViewStoryController',
            isPublic: true,
            resolve: {
                board: boardResolver('all', 'single-story'),
            },
        })
            .when('/boards/:id', {
            template: require('@/views/boards/view.html'),
            controller: 'BoardViewController',
            reloadOnSearch: false,
            isPublic: true,
            resolve: {
                project: projectResolver,
                board: boardResolver(null, 'full'),
            },
        })
            .when('/dashboard', {
            template: require('@/views/dashboard/index.html'),
            controller: 'DashboardController',
            reloadOnSearch: false,
            isPublic: false,
            resolve: {
                project: projectResolver,
                board: boardResolver('all', 'dashboard'),
            },
        })
            .when('/settings/dimensions/', {
            bodyClass: 'settings',
            template: require('@/views/dimensions/list.html'),
            controller: 'DimensionsListController',
            reloadOnSearch: false,
            resolve: {
                dimensions: dimensionsResolver,
            },
        })
            .when('/settings/members/', {
            bodyClass: 'settings',
            template: require('@/views/members/list.html'),
            controller: 'MembersListController',
            resolve: {
                members: membersResolver,
            },
        })
            .when('/settings/workspaces/', {
            bodyClass: 'settings',
            template: require('@/views/workspaces/list.html'),
            controller: 'WorkspacesListController',
            resolve: {
                workspaces: workspacesResolver,
                dimensions: dimensionsResolver,
            },
        })
            .when('/settings/project/', {
            bodyClass: 'settings',
            template: require('@/views/project/info.html'),
            controller: 'ProjectInfoController',
            resolve: {
                project: projectResolver,
            },
        })
            .when('/settings/documentation/', {
            bodyClass: 'settings',
            template: require('@/views/documentation/index.html'),
            controller: 'ProjectDocController',
            resolve: {
                project: projectResolver,
                projectCategories: projectCategoriesResolver,
            },
        })
            .when('/new', singleModalPage({
            template: require('@/onboard/newProjectModal.html'),
            controller: 'NewProjectModalController',
            size: 'sm',
            windowClass: 'klaro-modal new-project-modal',
        }))
            .when('/onboard/', {
            isPublic: false,
            template: require('@/onboard/onboard.html'),
            controller: 'OnboardController',
            resolve: {
                templates: templatesResolver,
                projectCategories: projectCategoriesResolver,
                project: projectResolver,
            },
        })
            .when('/error/', {
            template: '<h1>Error</h1>',
            controller: ['failedModal', function (failedModal) {
                    failedModal.open({
                        title: 'Wnoops',
                        description: 'This is an error message',
                    });
                }],
        })
            .otherwise({
            redirectTo: '/',
        });
    },
]);
