import AbstractBoardListener from '../AbstractBoardListener';
export default class BoardDecksCurrentlyOpenListener extends AbstractBoardListener {
    stateChanged(subject, newState, _oldState) {
        return new Promise((resolve, _reject) => {
            if (newState.decksCurrentlyOpen === undefined) {
                resolve(newState.clone({
                    decksCurrentlyOpen: newState.board.decksOpen
                }));
            }
            else {
                resolve(newState);
            }
        });
    }
}
