import Dimension from '../Dimension';
import Scalar from './Mixins/Scalar';
class Text extends Dimension {
    participatesToTextSearch() {
        return true;
    }
    searchMatchesOn(search, regexp, story, _globalContext) {
        const text = this.getStoryRawValue(story);
        return text && text.match(regexp);
    }
    getInputWidgetCode() {
        return 'text';
    }
    isUserDefinedSemantics() {
        return true;
    }
    usesSemantics() {
        return true;
    }
    /**
      * Overriden to implement the text range semantics: the match occurs when
      * the raw dimension value matches dimValue's associated regexp.
      */
    storyValueMatches(story, dimValue, _globalContext) {
        const storyValue = this.getStoryRawValue(story);
        if (storyValue == null) {
            return dimValue.id == null;
        }
        if (dimValue.id == null) {
            return false;
        }
        const matcher = Text.getMatcherForExpr(dimValue.semantics);
        return matcher && matcher.test(storyValue);
    }
    getStoryHumanValue(story, _globalContext) {
        const raw = this.getStoryRawValue(story);
        return raw ? raw : '';
    }
    static getMatcherForExpr(expr) {
        if (!expr) {
            return null;
        }
        let m;
        if (m = expr.match(/^(.*?)\s*(\.\.\.?)\s*(.*?)$/)) {
            const start = m[1];
            const mid = m[2];
            const stop = m[3];
            return {
                test: function (str) {
                    if (!str || !str.toString)
                        return false;
                    str = str.toString();
                    if (mid === '..') {
                        return str >= start && str <= stop;
                    }
                    else {
                        return str >= start && str < stop;
                    }
                }
            };
        }
        else {
            return new RegExp(expr);
        }
    }
}
Object.assign(Text.prototype, Scalar);
export default Text;
