import { Project as ProjectModel } from '@klaro/corejs/model';
angular
    .module('klaro')
    .factory('projectRest', projectRest);
function projectRest($http, $filter, auth, Project) {
    const dress = ProjectModel.dress;
    return {
        get: get,
        list: list,
        patch: patch,
        reset: reset,
        lock: lock,
        categories: categories,
        transferOwnership: transferOwnership,
        deleteAllCards: deleteAllCards,
        deleteProject: deleteProject,
        convertProjectToTemplate: convertProjectToTemplate,
        duplicateProject: duplicateProject,
        forkProjectAsTemplate: forkProjectAsTemplate,
    };
    function get() {
        return $http
            .get('/api/')
            .then((response) => {
            return dress(response.data);
        });
    }
    function list() {
        return $http
            .get('/api/my/projects/')
            .then((response) => {
            return response.data.map(dress);
        });
    }
    function categories() {
        return $http
            .get('/api/project-categories/')
            .then((response) => {
            return ($filter('orderBy')(response.data, '+name'));
        });
    }
    function patch(patch) {
        return $http
            .patch('/api/', patch)
            .then(onProjectChangedResponse);
    }
    function reset(configuration) {
        return $http
            .post('/api/reset', configuration);
    }
    function lock(configuration) {
        return $http
            .post('/api/lock', configuration)
            .then(onProjectChangedResponse);
    }
    function transferOwnership(member) {
        return $http
            .patch('/api/ownership', { nickname: member.nickname })
            .then(onProjectChangedResponse)
            .then(auth.refreshProfile);
    }
    function deleteAllCards() {
        return $http
            .post('/api/danger-zone/delete-all-stories');
    }
    function deleteProject() {
        return $http
            .post('/api/danger-zone/delete-project');
    }
    function convertProjectToTemplate() {
        return $http
            .post('/api/danger-zone/convert-project-to-template')
            .then(onProjectChangedResponse);
    }
    function duplicateProject(configuration) {
        return $http
            .post('/api/danger-zone/duplicate-project', configuration);
    }
    function forkProjectAsTemplate(configuration) {
        return $http
            .post('/api/danger-zone/fork-project-as-template', configuration);
    }
    function onProjectChangedResponse(response) {
        const project = dress(response.data);
        if (project.url.indexOf(window.location.origin) != 0) {
            window.location = project.url;
        }
        else {
            return Project.set(project);
        }
    }
}
