import Integer from './Integer';
class StoryBinaryCount extends Integer {
    get category() {
        return 'links';
    }
    canBeEdited() {
        return false;
    }
    getTableAlignmentClass() {
        return 'align-right';
    }
}
export default StoryBinaryCount;
