const EARTH_CIR_METERS = 40075016.686;
const TILE_SIZE = 256;
const degreesPerMeter = 360 / EARTH_CIR_METERS;
const LIMIT_Y = toDegrees(Math.atan(Math.sinh(Math.PI))); // around 85.0511...
export function toRadians(degrees) {
    return degrees * Math.PI / 180;
}
export function toDegrees(radians) {
    return (radians / Math.PI) * 180;
}
export function tile2long(x, z) {
    return (x / Math.pow(2, z) * 360 - 180);
}
export function lonOnTile(lon, zoom) {
    return ((lon + 180) / 360) * Math.pow(2, zoom);
}
export function tile2lat(y, z) {
    const n = Math.PI - 2 * Math.PI * y / Math.pow(2, z);
    return (180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))));
}
function latOnTile(lat, zoom) {
    return (((1 -
        Math.log(Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)) /
            Math.PI) /
        2) *
        Math.pow(2, zoom));
}
// width and height must correspond to the iframe width/height
export function latLngToBounds(lat, lng, zoom, width, height) {
    const metersPerPixelEW = EARTH_CIR_METERS / Math.pow(2, zoom + 8);
    const shiftMetersEW = width / 2 * metersPerPixelEW;
    const shiftDegreesEW = shiftMetersEW * degreesPerMeter;
    const southTile = (TILE_SIZE * latOnTile(lat, zoom) + height / 2) / TILE_SIZE;
    const northTile = (TILE_SIZE * latOnTile(lat, zoom) - height / 2) / TILE_SIZE;
    return {
        south: Math.max(tile2lat(southTile, zoom), -LIMIT_Y),
        west: lng - shiftDegreesEW,
        north: Math.min(tile2lat(northTile, zoom), LIMIT_Y),
        east: lng + shiftDegreesEW
    };
}
