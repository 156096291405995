import { Text } from '../../model';
import { DimensionCandidate } from './DimensionCandidate';
export const MAX_LENGTH = 100;
export class TextCandidate extends DimensionCandidate {
    _deliver() {
        // @ts-expect-error we need to migrate all dimensions to typescript
        return [Text, {
                datatype: 'Text',
                values: [],
            }];
    }
    placeBet() {
        const nonNulls = [...this.values.keys()].filter(Boolean);
        let invalid = 0.0;
        let tooLong = 0.0;
        for (const value of nonNulls) {
            // Stop early when faced with non strings
            if (typeof value !== 'string') {
                invalid += 1.0;
                continue;
            }
            if (value.length) {
                tooLong += 1.0;
            }
        }
        return 1.0
            // repetitions
            - (this.values.size / this.valuesSeen)
            //
            - tooLong / this.valuesSeen
            //
            - invalid / this.valuesSeen;
    }
}
