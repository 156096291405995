import { Sync } from '@klaro/corejs/state';
const { BoardSyncModes } = Sync;
import * as angular from 'angular';
angular
    .module('klaro')
    .directive('preferencesModalPart', preferencesModalPart);
function preferencesModalPart(boardRest) {
    return {
        restrict: 'E',
        scope: {
            user: '=',
            userData: '=',
            ctrl: '=',
        },
        template: require('@/auth/profileModal/preferencesModalPart.html'),
        link: function (scope) {
            scope.langs = [
                { code: 'en', name: 'English' },
                { code: 'fr', name: 'Français' },
                { code: 'nl', name: 'Dutch' },
            ];
            scope.syncModes = Object.values(BoardSyncModes);
            function refreshBoards() {
                boardRest.list(scope.userData.preferences.homeWorkspace)
                    .then((bs) => {
                    scope.boards = bs;
                })
                    .catch(scope.ctrl.onError);
            }
            scope.$watch('userData.preferences.homeWorkspace', refreshBoards);
            scope.canSave = function () {
                return isDirty();
            };
            scope.save = function () {
                scope
                    .ctrl
                    .patchUser()
                    .then((_res) => {
                    return scope.ctrl.close();
                })
                    .catch(scope.ctrl.onError);
            };
            function isDirty() {
                return scope.preferencesForm.$dirty;
            }
        },
    };
}
