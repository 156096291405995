angular
    .module('klaro')
    .directive('errorMessages', errorMessages);
function errorMessages() {
    return {
        scope: {
            field: '=',
        },
        transclude: true,
        template: require('@/support/errorMessages.html'),
    };
}
