angular
    .module('klaro')
    .service('Config', Config);
function Config(Project, $rootScope) {
    const default_settings = {
        max_upload_size: 5,
        seshat_max_upload_size: 100,
        max_concurrent_uploads: 20,
    };
    const config = {
        upload: {
            maxSize: default_settings.max_upload_size * 1024 * 1024,
            maxConcurrentUploads: default_settings.max_concurrent_uploads,
        },
        multiselectMaxOptionsDisplay: 5,
        asideProjectsMaxDisplay: 10,
        boardLabelMaxLength: 25,
        decksDimensionsValuesMaxDisplay: 15,
        myKlaroTemplateId: '3a1036c0-0efc-4713-8e79-03404e01d397',
        mainBodyClass: [],
    };
    Project.get()
        .then(configureUpload)
        .then(configureMyKlaro);
    function configureUpload(p) {
        let maxUploadSize = null;
        maxUploadSize = setting(p, 'max_upload_size');
        maxUploadSize = min(maxUploadSize, default_settings.seshat_max_upload_size);
        maxUploadSize = maxUploadSize * 1024 * 1024;
        config.upload.maxSize = maxUploadSize;
        config.upload.maxConcurrentUploads = setting(p, 'max_concurrent_uploads');
        return p;
    }
    function configureMyKlaro(p) {
        if (p.template && p.template.id === config.myKlaroTemplateId) {
            config.mainBodyClass.push('my-klaro');
        }
        $rootScope.$emit('mainBodyClassChanged');
        return p;
    }
    function setting(project, which) {
        const settings = project.settings || {};
        return settings[which] || default_settings[which];
    }
    function min(x, y) {
        return (x > y) ? y : x;
    }
    return config;
}
