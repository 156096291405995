import { Story } from '@klaro/corejs/model';
angular
    .module('klaro')
    .factory('pinnnedStoriesRest', pinnnedStoriesRest);
function pinnnedStoriesRest($http) {
    return {
        get: get,
        pin: pin,
        unpin: unpin,
    };
    function get(world) {
        return $http
            .get('/api/my/stories/pinned/')
            .then((response) => {
            return response.data.map((data) => {
                return Story.dress(data, world);
            });
        });
    }
    function unpin(stories) {
        return togglePin(stories, false);
    }
    function pin(stories, world) {
        return togglePin(stories, true);
    }
    function togglePin(stories, pinOrNot) {
        const url = '/api/my/stories/pinned/actions/';
        const action = pinOrNot ? 'pin' : 'unpin';
        return $http
            .post(url + action + '/', { stories: stories })
            .then(response => response);
    }
}
