angular
    .module('klaro')
    .directive('dimensionCheck', dimensionCheck);
function dimensionCheck() {
    return {
        restrict: 'E',
        template: require('@/core/dimension/dimensionList/dimensionCheck.html'),
        scope: {
            dimension: '=',
            disabled: '=',
        },
        require: '^dimensionList',
        link: function (scope, elm, attrs, dimensionList) {
            scope.toggle = function ($event) {
                if (isDisabled()) {
                    return;
                }
                $event.preventDefault();
                $event.stopPropagation();
                const patch = {};
                patch[attrs.check] = !scope.isChecked();
                dimensionList.submitPatch(scope.dimension, patch);
            };
            scope.isChecked = function () {
                return scope.dimension[attrs.check];
            };
            scope.isLock = function () {
                return attrs.check === 'tagging';
            };
            function isDisabled() {
                return scope.dimension.deleted || scope.disabled;
            }
            scope.isDisabled = isDisabled;
        },
    };
}
