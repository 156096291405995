import { ApiAttachmentsConnector } from './ApiAttachmentsConnector';
import { ApiStoryCoverConnector } from './ApiStoryCoverConnector';
export class ApiStoriesConnector {
    constructor(client, baseUrl = '') {
        this.client = client;
        this.baseUrl = baseUrl;
    }
    async get(params) {
        const storiesRes = await this.client.api
            .get(`${this.baseUrl}/stories`, params);
        return storiesRes.data;
    }
    async delete(params) {
        await this.client.api.delete(`${this.baseUrl}/stories`, {
            data: {
                stories: params.stories
            }
        });
    }
    async patch(patch) {
        const storiesRes = await this.client.api.patch(`${this.baseUrl}/stories`, patch);
        return storiesRes.data;
    }
    async post(story) {
        const storyRes = await this.client.api.post(`${this.baseUrl}/stories`, story);
        return storyRes.data;
    }
    async postMany(stories) {
        const storyRes = await this.client.api.post(`${this.baseUrl}/stories`, stories);
        return storyRes.data;
    }
    async create(stories) {
        const storyRes = await this.client.api.post(`${this.baseUrl}/stories`, stories);
        return storyRes.data;
    }
    single(id) {
        const client = this.client;
        const baseUrl = this.baseUrl;
        return {
            attachments: new ApiAttachmentsConnector(client, `${baseUrl}/stories/${id}/attachments/`),
            cover: new ApiStoryCoverConnector(client, `${baseUrl}/stories/${id}/cover/`),
            async get(params) {
                const storiesRes = await client.api.get(`${baseUrl}/stories/${id}`, params);
                return storiesRes.data;
            },
            async patch(patch) {
                const id = patch.id;
                const storyRes = await client.api.patch(`${baseUrl}/stories/${id}`, patch);
                return storyRes.data;
            },
            delete(_params) {
                throw new Error('ApiStoriesConnector.single#delete() not implemented.');
            }
        };
    }
    search(_filters) {
        throw new Error('ApiStoriesConnector#search() not implemented.');
    }
}
