import { default as BoardSyncModes } from './BoardSyncModes';
import * as BSC from './BoardSyncController';
import AbstractBoardListener from '../AbstractBoardListener';
export default class BoardSyncListener extends AbstractBoardListener {
    stateChanged(subject, newState, oldState) {
        return new Promise((resolve, reject) => {
            // 1. initial state
            if (oldState === undefined) {
                return resolve(BSC.initialState(newState));
            }
            // 2. mode changed
            else if (BSC.getMode(newState) !== BSC.getMode(oldState)) {
                switch (BSC.getMode(newState)) {
                    case BoardSyncModes.disabled:
                        resolve(newState);
                        break;
                    case BoardSyncModes.onDemand:
                    case BoardSyncModes.realTime:
                        newState.refresh().then(resolve).catch(reject);
                        break;
                    default:
                        resolve(newState);
                }
            }
            // 3. events to process => switch on mode
            else if (BSC.hasSyncEvents(newState)) {
                switch (BSC.getMode(newState)) {
                    case BoardSyncModes.disabled:
                        resolve(BSC.cleanSyncEvents(newState));
                        break;
                    case BoardSyncModes.onDemand:
                        resolve(BSC.checkHardRefreshNeeded(newState));
                        break;
                    case BoardSyncModes.realTime:
                        resolve(BSC.processSyncEvents(newState));
                        break;
                    default:
                        resolve(newState);
                }
            }
            // 4. outdated stories
            else if (BSC.hasOutdatedStories(newState)) {
                switch (BSC.getMode(newState)) {
                    case BoardSyncModes.disabled:
                        resolve(BSC.cleanOutdatedStories(newState));
                        break;
                    case BoardSyncModes.onDemand:
                        resolve(BSC.checkHardRefreshNeeded(newState));
                        break;
                    case BoardSyncModes.realTime:
                        newState.refresh().then(resolve).catch(reject);
                        break;
                    default:
                        resolve(newState);
                }
            }
            // 5. nothing to do
            else {
                resolve(newState);
            }
        });
    }
}
