import ArrayUtils from '../../utils/ArrayUtils';
import Story from '../../model/Story';
import BoardSyncModes from './BoardSyncModes';
// Initial state
export const initialState = (state) => {
    return state.clone({
        boardSyncMode: state.boardSyncMode || BoardSyncModes.onDemand,
        boardSyncHardRefreshNeeded: false,
        boardSyncEvents: [],
        boardSyncOutdated: [],
    });
};
// Mode
export const isSyncOn = (state) => {
    return state.boardSyncMode !== BoardSyncModes.disabled;
};
export const getMode = (state) => {
    return state.boardSyncMode;
};
export const withMode = async (state, modeOrCode) => {
    const mode = (typeof modeOrCode === 'string')
        ? BoardSyncModes[modeOrCode]
        : modeOrCode;
    if (!mode || !BoardSyncModes[mode.code]) {
        console.warn(new Error(`Invalid mode: ${modeOrCode}`));
        return state;
    }
    return state.with({
        boardSyncMode: mode,
        boardSyncHardRefreshNeeded: false,
        boardSyncEvents: [],
        boardSyncOutdated: [],
    });
};
// Backend events
export const withEventReceived = async (state, event) => {
    if (canBeProcessedSynchronously(event)) {
        const e = _processEvent(state, event);
        return state.with({
            boardSyncEvents: ArrayUtils.dup(state.boardSyncEvents, e ? [e] : []),
        });
    }
    else {
        return state.with({
            boardSyncOutdated: ArrayUtils.union(state.boardSyncOutdated || [], event.data.predicate.id || []),
        });
    }
};
// Hard refresh
export const hardRefreshNeeded = (state) => {
    return state.boardSyncHardRefreshNeeded;
};
export const checkHardRefreshNeeded = (state) => {
    return state.clone({
        boardSyncHardRefreshNeeded: state.boardSyncEvents.length > 0 || state.boardSyncOutdated.length > 0,
        boardSyncEvents: [],
        boardSyncOutdated: [],
    });
};
// Outdated stories
export const hasOutdatedStories = (state) => {
    return state.boardSyncOutdated && state.boardSyncOutdated.length > 0;
};
export const outdatedStoryIds = (state) => {
    return state.boardSyncOutdated;
};
export const cleanOutdatedStories = (state) => {
    return state.clone({
        boardSyncOutdated: [],
    });
};
// Events to process
export const hasSyncEvents = (state) => {
    return !!state.boardSyncEvents && state.boardSyncEvents.length > 0;
};
export const cleanSyncEvents = (state) => {
    return state.clone({
        boardSyncEvents: [],
    });
};
export const processSyncEvents = (state) => {
    const processed = state.boardSyncEvents.reduce((s, event) => event(s), state);
    return processed.clone({
        boardSyncHardRefreshNeeded: false,
        boardSyncEvents: [],
    });
};
// processing events
function canBeProcessedSynchronously(event) {
    switch (event.type) {
        case 'Klaro::Stories::Event::StoriesDeleted':
            return true;
        case 'Klaro::Stories::Event::StoriesCreated':
            return !!event.data.stories;
        case 'Klaro::Stories::Event::StoriesChanged':
            return !!event.data.stories;
        default:
            return false;
    }
}
function _processEvent(state, event) {
    switch (event.type) {
        case 'Klaro::Stories::Event::StoriesDeleted':
            return _processStoriesDeletedEvent(state, event);
        case 'Klaro::Stories::Event::StoriesCreated':
            return _processStoriesCreatedEvent(state, event);
        case 'Klaro::Stories::Event::StoriesChanged':
            return _processStoriesChangedEvent(state, event);
    }
}
function _processStoriesCreatedEvent(state, event) {
    const predicate = state.storiesPredicate;
    const dims = state.board.dimensions;
    const globalContext = state.stories.globalContext;
    const stories = event.data.stories.map(s => Story.dress(s, { dimensions: dims }));
    const [toKeep, rejected] = ArrayUtils.partition(stories, s => predicate.accept(s, dims, globalContext));
    const toRemove = rejected.filter(s => state.stories.knows(s));
    if (toKeep.length > 0 || toRemove.length > 0) {
        return (state) => state.clone({
            stories: state.stories.storiesAdded(toKeep, false).storiesDeleted(toRemove, false),
        });
    }
}
function _processStoriesDeletedEvent(state, event) {
    const ids = event.data.predicate.id;
    const kept = state.stories.getKnownIds(ids).map(id => ({ id }));
    if (kept.length > 0) {
        return (state) => state.clone({
            stories: state.stories.storiesDeleted(kept, false),
        });
    }
}
function _processStoriesChangedEvent(state, event) {
    return _processStoriesCreatedEvent(state, event);
}
