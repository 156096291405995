import Dimension from '../Dimension';
class Archived extends Dimension {
    get category() {
        return 'system';
    }
    getBestInstallerAmong(installers) {
        return installers.find((i) => {
            return i.datatype === 'Archived';
        });
    }
    canBeEdited() {
        return true;
    }
    canBeEditedAsCardDimension() {
        return false;
    }
    canBeUsedOnCards() {
        return true;
    }
    canBeUsedOnDecks() {
        return true;
    }
    get nonArchived() {
        return this.values.find(v => v.semantics === 'false');
    }
    shouldBeShownOnDecks(board) {
        const along = board.filterValuesAlong(this);
        if (along && along.length === 1) {
            return along[0].id !== this.nonArchived.id;
        }
        else {
            return true;
        }
    }
    // We do actually use it, but don't let people see it
    usesSemantics() {
        return false;
    }
    /**
      * Overriden to use the semantics of `storyValueMatches` below.
      */
    matchingValuesOf(story, globalContext) {
        return this.values.filter((v) => {
            return this.storyValueMatches(story, v, globalContext);
        });
    }
    /**
      * Overriden to use the semantics of `storyValueMatches` below.
      */
    firstMatchingValueOf(story, globalContext) {
        return this.values.find((v) => {
            return this.storyValueMatches(story, v, globalContext);
        });
    }
    /**
      * Overriden to return false. We don't provide values for now,
      * so none matches.
      */
    storyValueMatches(story, dimValue) {
        // bypass [none]
        if (dimValue.semantics === null) {
            return false;
        }
        const requires = (dimValue.semantics === 'true');
        const value = this.getStoryRawValue(story);
        return (value === requires || (value === undefined && !requires));
    }
    canValuesBeDeleted() {
        return false;
    }
    getPatchForStoryValueTo(value) {
        if (value.semantics === 'false') {
            return { [this.code]: false };
        }
        return { [this.code]: true };
    }
}
export default Archived;
