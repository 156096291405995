import { ArrayUtils } from '@klaro/corejs/utils';
angular
    .module('klaro')
    .directive('boardAnchorSelectOne', boardAnchorSelectOne);
/**
 * Similar to <board-anchor-select, but for a case where we want to
 * set a single dimension at a specific place of the anchor (as
 * described by the index attribute)
 */
function boardAnchorSelectOne($filter, ArrayUtils) {
    return {
        restrict: 'EA',
        scope: {
            board: '=',
            dimensions: '=',
        },
        require: '^ngModel',
        template: require('@/core/board/boardAnchorSelectOne.html'),
        link: function (scope, elm, attrs, ngModel) {
            const index = parseInt(attrs.anchorIndex);
            // Widget model
            function getBoardAnchor() {
                return ngModel.$modelValue ? ngModel.$modelValue : [];
            }
            // Widget view
            function installView(anchor) {
                scope.model = { selected: anchor[index] };
            }
            function format(value) {
                installView(getBoardAnchor());
            }
            ngModel.$formatters.push(format);
            // ngModel sync
            function syncNgModel(anchor) {
                let model = ArrayUtils.dup(getBoardAnchor());
                model[index] = anchor;
                model = model.filter((x) => { return !!x; });
                ngModel.$setViewValue(model);
                installView(model);
            }
            // Widget operations
            scope.$watch('dimensions', () => {
                const opts = ArrayUtils.dup(scope.dimensions);
                if (attrs.none === 'true') {
                    opts.unshift({ code: null, label: '[none]' });
                }
                scope.options = opts;
            });
            scope.$watch('model.selected', (newCode, oldCode) => {
                if (!newCode && !oldCode) {
                    return;
                }
                if (newCode == oldCode) {
                    return;
                }
                if (newCode) {
                    syncNgModel(newCode);
                }
                else if (oldCode) {
                    syncNgModel(undefined);
                }
            });
        },
    };
}
