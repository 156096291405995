angular
    .module('klaro')
    .directive('gravatar', gravatar);
function gravatar() {
    return {
        scope: {
            'user': '=',
        },
        restrict: 'E',
        template: require('@/auth/gravatar.html'),
    };
}
