angular
    .module('klaro')
    .service('getDateOptions', getDateOptions)
    .filter('dateFormat', dateFormat);
function getDateOptions(withTime) {
    return {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: (withTime) ? '2-digit' : undefined,
        minute: (withTime) ? '2-digit' : undefined,
    };
}
function dateFormat(tmhDynamicLocale) {
    return function (date, withTime = true, locale = tmhDynamicLocale.get()) {
        return new Date(date).toLocaleDateString(locale, getDateOptions(withTime));
    };
}
