angular
    .module('klaro')
    .controller('SingleModalPageController', SingleModalPageController)
    .constant('singleModalPage', singleModalPage)
    .constant('failedModalPage', failedModalPage);
function SingleModalPageController($scope, modalData, $uibModal, routeInfo) {
    $scope.showRecentNews = routeInfo && routeInfo.showRecentNews;
    function openDialog() {
        modalData = angular.extend({}, {
            animation: false,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            windowClass: 'klaro-modal',
            size: 'small',
            backdrop: null,
        }, modalData);
        const modal = $uibModal.open(modalData);
        modal.result.catch(openDialog);
    }
    openDialog();
}
function singleModalPage(modalData, routeInfo) {
    if (routeInfo === undefined) {
        routeInfo = {};
    }
    return angular.extend({}, {
        isPublic: true,
        template: require('@/layout/singleModalPage/singleModalPage.html'),
        controller: 'SingleModalPageController',
        resolve: {
            routeInfo: function () { return routeInfo; },
            modalData: function () { return modalData; },
        },
    }, routeInfo);
}
function failedModalPage(error) {
    return singleModalPage({
        template: require('@/support/failedModal.html'),
        controller: 'FailedModalController',
        resolve: {
            error: ['robust', function (robust) {
                    return angular.extend({}, error, {
                        description: robust.message(error.description),
                    });
                }],
        },
    });
}
