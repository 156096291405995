angular
    .module('klaro')
    .directive('spaceModeSelector', spaceModeSelector);
function spaceModeSelector() {
    return {
        restrict: 'EA',
        template: require('@/core/space/spaceModeSelector.html'),
        require: '^ngModel',
        scope: true,
        link: function (scope, elm, attrs, ngModelController) {
            scope.isWhitelist = function () {
                return ngModelController.$viewValue == 'whitelist';
            };
            scope.isBlacklist = function () {
                return ngModelController.$viewValue == 'blacklist';
            };
            scope.setWhitelist = function () {
                return ngModelController.$setViewValue('whitelist');
            };
            scope.setBlacklist = function () {
                return ngModelController.$setViewValue('blacklist');
            };
        },
    };
}
