angular
    .module('klaro')
    .directive('timeDuration', timeDuration);
function timeDuration() {
    return {
        restrict: 'E',
        require: '^ngModel',
        template: require('@/support/timeDuration.html'),
        link: function (scope, elm, attrs, ngModelController) {
            const durationValue = elm.find('input');
            const durationUnit = elm.find('select');
            // Specify how UI should be updated
            ngModelController.$render = function () {
                const vv = ngModelController.$viewValue;
                durationValue.val(vv && vv.value);
                durationUnit.val(vv && vv.unit);
            };
            ngModelController.$render();
            // Listen to input change and update the model accordingly
            durationValue.on('blur keyup change', () => {
                scope.$evalAsync(() => {
                    let val = durationValue.val();
                    val = val && val.trim().length > 0 ? val.trim() : null;
                    ngModelController.$setViewValue({
                        value: parseFloat(val),
                        unit: ngModelController.$viewValue.unit,
                    });
                });
            });
            durationUnit.on('change', () => {
                scope.$evalAsync(() => {
                    const val = durationUnit.val();
                    ngModelController.$setViewValue({
                        value: ngModelController.$viewValue.value,
                        unit: val,
                    });
                });
            });
        },
    };
}
