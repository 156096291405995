angular
    .module('klaro')
    .directive('binaryCountGroup', binaryCountGroup);
function binaryCountGroup($filter) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            formData: '=',
            dimensions: '=',
        },
        template: require('@/core/dimension/dimensionEditModal/binaryCountGroup.html'),
        link: function (scope, elm, attr) {
            scope.candidates = [];
            scope.relevantChildDimensions = [];
            scope.$watch('dimensions', (dims) => {
                if (!dims) {
                    return;
                }
                scope.candidates = dims.filter((d) => {
                    return d.datatype == 'StoryBinaryLink';
                });
            });
            scope.$watch('formData.datatypeOptions.filters', (fs) => {
                scope.filtersCount = Object.keys(fs || {}).length;
            });
            scope.$watch('formData.datatypeOptions.along', (along) => {
                if (!along) {
                    return;
                }
                const binDim = scope.dimensions.find((d) => {
                    return d.id == along;
                });
                scope.relevantChildDimensions = $filter('orderBy')(binDim
                    .getCoRelevantDimensions(scope.dimensions)
                    .filter((d) => { return d.canBeUsedOnDecks(); }), '+label');
            });
        },
    };
}
