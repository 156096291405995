angular
    .module('klaro')
    .service('klaroUi', klaroUi);
function klaroUi($window) {
    return {
        isMobile: isMobile,
        browser: browser,
    };
    function isMobile() {
        return $window.innerWidth <= 768;
    }
    function browser() {
        const userAgent = $window.navigator.userAgent;
        const browsers = { chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i };
        for (const key in browsers) {
            if (browsers[key].test(userAgent)) {
                return key;
            }
        }
        return 'unknown';
    }
}
