/* eslint-disable angular/directive-restrict */
angular
    .module('klaro')
    .directive('menuHover', menuHover);
/**
 * <ul class="menu-hover"> directive.
 *
 * Use it to activate the (jQuery-menu-aim plugin)[https://github.com/kamens/jQuery-menu-aim]
 * menu-aim is a jQuery plugin for dropdown menus that can differentiate between
 * a user trying hover over a dropdown item vs trying to navigate into a submenu'scontents.
 *
 * This directive is expected to be applied to a <ul> element, altough it can be
 * applied to any element, but the base CSS code assumes that the element is a <ul>.
 */
function menuHover($timeout) {
    return {
        restrict: 'C',
        scope: {
            'submenuDirection': '@',
        },
        link: function (scope, element) {
            let $menu;
            $timeout(() => {
                $menu = element;
                $menu.menuAim({
                    activate: this.activateSubmenu,
                    deactivate: this.deactivateSubmenu,
                    exitMenu: this.exitMenu,
                    submenuDirection: scope.submenuDirection || 'right',
                });
            }, 0);
            this.exitMenu = function () {
                return true;
            };
            this.deactivateSubmenu = function (row) {
                $(row).removeClass('hovering');
            };
            this.activateSubmenu = function (row) {
                $(row).addClass('hovering');
            };
        },
    };
}
