angular
    .module('klaro')
    .service('storyLinkModal', storyLinkModal)
    .controller('StoryLinkModalController', StoryLinkModalController);
function storyLinkModal(klaroModal) {
    return {
        open: function (board, binaryLinkDimension, parentStory) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/story/storyLinkModal.html'),
                controller: 'StoryLinkModalController',
                windowClass: 'klaro-modal story-link-modal',
                size: 'full-mobile modal-large',
                resolve: {
                    board: function () {
                        return board;
                    },
                    binaryLinkDimension: function () {
                        return binaryLinkDimension;
                    },
                    parentStory: function () {
                        return parentStory;
                    },
                },
            });
        },
    };
}
function StoryLinkModalController($scope, navigation, $uibModalInstance, board, binaryLinkDimension, parentStory, searchStoriesRest, ArrayUtils, storyRest) {
    const kindDimension = board.getKindDimension().withRelevantValuesFor(binaryLinkDimension);
    $scope.selectedStories = [];
    $scope.kindDimension = kindDimension;
    $scope.selectedKind = kindDimension.values.length === 1 ? kindDimension.values[0].id : undefined;
    const alreadyLinked = (parentStory.linked || []).map((linkedStory) => {
        return linkedStory.identifier;
    });
    $scope.getInBoard = function () {
        return board;
    };
    $scope.performSearch = function () {
        const kinds = $scope.selectedKind || binaryLinkDimension.relevantKinds;
        const world = { dimensions: board.getDimensions() };
        searchStoriesRest
            .searchStories($scope.searchedText, kinds, world)
            .then((matchingStories) => {
            $scope.matchingStories = matchingStories;
        });
    };
    $scope.$watch('searchedText', () => {
        $scope.performSearch();
    });
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.createLinkedCard = function ($event) {
        $event.preventDefault();
        const globalContext = board.getGlobalContext();
        let value = { id: parentStory.identifier };
        let defs = binaryLinkDimension.getPatchForStoryValueTo(value, globalContext);
        if ($scope.selectedKind) {
            value = { id: $scope.selectedKind };
            defs = angular.extend(defs, kindDimension.getPatchForStoryValueTo(value, globalContext));
        }
        board.openNewStoryModal(defs, false, true)
            .then(newStory => $uibModalInstance.close([newStory]));
    };
    $scope.select = function (story) {
        if ($scope.isSelected(story)) {
            $scope.selectedStories = ArrayUtils.without($scope.selectedStories, story.identifier);
        }
        else {
            $scope.selectedStories.push(story.identifier);
        }
    };
    $scope.isSelected = function (story) {
        return $scope.selectedStories.includes(story.identifier);
    };
    $scope.linkExistingCard = function () {
        const storiesToLink = ArrayUtils.diff(ArrayUtils.without($scope.selectedStories, parentStory.identifier), alreadyLinked);
        if (!storiesToLink.length) {
            $uibModalInstance.dismiss();
        }
        else {
            storyRest
                .linkStories({
                children: storiesToLink,
                parent: parentStory.identifier,
                code: binaryLinkDimension.code,
            })
                .then((stories) => $uibModalInstance.close(stories))
                .catch(navigation.failed);
        }
    };
}
