angular
    .module('klaro')
    .directive('boardDirtyMenu', boardDirtyMenu);
function boardDirtyMenu() {
    return {
        restrict: 'EA',
        template: require('@/core/board/header/boardDirtyMenu.html'),
        replace: true,
    };
}
