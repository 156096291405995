import { BoardAnchor } from '@klaro/corejs/model';
angular
    .module('klaro')
    .directive('boardAnchorSelector', boardAnchorSelector);
/**
 * This directive implements the widget that allows selecting a subset of dimensions
 * in a particular order. The widget uses checkboxes to select/unselect dimensions,
 * and drag&drop for choosing the order of selected ones.
 *
 * This directive requires a ng-anchor on tag or ancestors. The anchor must correspond
 * to an anchor list, e.g. `[DimensionCode]`. Such an anchor list corresponds to an
 * ordered list of dimension codes, that capture the selected dimensions, and their
 * order.
 */
function boardAnchorSelector($filter, ArrayUtils) {
    return {
        restrict: 'EA',
        scope: {
            dimensions: '=',
        },
        require: '^ngModel',
        template: require('@/core/board/boardAnchorSelector.html'),
        link: function (scope, elm, attrs, ngModel) {
            elm.addClass('selector-list-sortable');
            scope.signed = (attrs.signed !== undefined);
            scope.sortable = (attrs.sortable === undefined || attrs.sortable == 'true');
            // Widget model
            function getBoardAnchor() {
                const anchor = ngModel.$modelValue ? ngModel.$modelValue : [];
                return BoardAnchor.list(anchor, { dimensions: scope.dimensions });
            }
            // Widget view
            function installView(anchor) {
                scope.candidates = anchor.toSelector();
            }
            function format(value) {
                installView(getBoardAnchor());
            }
            ngModel.$formatters.push(format);
            // ngModel sync
            function syncNgModel(anchor) {
                ngModel.$setViewValue(anchor.toList());
                installView(anchor);
            }
            // Widget operations
            scope.switchToggle = function (candidate) {
                const anchor = getBoardAnchor().toggle(candidate.dimension);
                syncNgModel(anchor);
            };
            scope.onCandidateDrop = function (index, candidate) {
                const anchor = getBoardAnchor().move(candidate.dimension, index);
                syncNgModel(anchor);
            };
            scope.toggleOrdering = function (candidate, $event) {
                $event.stopPropagation();
                const anchor = getBoardAnchor().toggleSign(candidate.dimension);
                syncNgModel(anchor);
            };
        },
    };
}
