import { DateTime } from 'luxon';
export default (function () {
    const DateParser = {
        fromLocaleDateString: fromLocaleDateString,
    };
    function fromLocaleDateString(string, locale, options) {
        const format = getDateFormatString(locale, options);
        return new Date(DateTime.fromFormat(string, format));
    }
    function getDateFormatString(locale, options) {
        const formatObj = new Intl.DateTimeFormat(locale, options).formatToParts(new Date());
        return formatObj
            .map(obj => {
            switch (obj.type) {
                case 'day':
                    return 'dd';
                case 'month':
                    return 'MM';
                case 'year':
                    return 'yyyy';
                case 'hour':
                    return 'hh';
                case 'minute':
                    return 'mm';
                case 'dayPeriod':
                    return 'a';
                default:
                    return obj.value.replace(' ', ' ');
            }
        })
            .join('');
    }
    return DateParser;
})();
