angular
    .module('klaro')
    .service('projectSwitcherModal', projectSwitcherModal)
    .controller('ProjectSwitcherModalController', ProjectSwitcherModalController);
function projectSwitcherModal(klaroModal, projectRest, Project) {
    return {
        open: function (board, story, options) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/project/projectSwitcherModal.html'),
                controller: 'ProjectSwitcherModalController',
                windowClass: 'klaro-modal project-switcher-modal',
                size: 'full-page',
                resolve: {
                    projects: projectRest.list,
                    currentProject: Project.get,
                },
            });
        },
    };
}
function ProjectSwitcherModalController($scope, $uibModalInstance, navigation, projects, currentProject) {
    $scope.search = { text: '' };
    $scope.projects = projects.filter((p) => { return !p.isTemplate; });
    $scope.templates = projects.filter((p) => { return p.isTemplate; });
    $scope.gotoNewProject = function () {
        $uibModalInstance.dismiss();
        navigation.gotoNewProject();
    };
    $scope.close = function () {
        $uibModalInstance.dismiss();
    };
    $scope.isActive = function (p) {
        return p.id == currentProject.id;
    };
}
