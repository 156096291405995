import Node from './Node';
import { DateTime } from 'luxon';
class Html5 extends Node {
    get currentTimeInLocale() {
        const context = this.globalContext || {};
        let date = DateTime.local();
        if (context.project) {
            date = date.setZone(context.project.timezone);
            date = date.setLocale(context.project.locale);
        }
        date = date.toLocaleString(DateTime.DATETIME_SHORT);
        return this.escapeHtml(date);
    }
    toHtml(stories, args) {
        return `
      <html>
        ${this.headHtml()}
        <body>
          ${this.yield(stories, args)}
          ${this.footerHtml()}
        </body>
      </html>
    `;
    }
    headHtml() {
        if (!this.options.assets) {
            return '';
        }
        let klaroprint = '/p/klaroprint.css';
        if (this.options.baseUrl) {
            klaroprint = `${this.options.baseUrl}${klaroprint}`;
        }
        return `
      <head>
        <meta charset="utf-8">
        <link rel="stylesheet" href="${klaroprint}">
      </head>
    `;
    }
    footerHtml() {
        if (!this.options.footer) {
            return '';
        }
        return `
      <footer>
        <p class="bottom-left">
          ${this.currentTimeInLocale}
        </p>
      </footer>`;
    }
}
export default Html5;
