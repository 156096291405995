angular
    .module('klaro')
    .controller('ProjectDocController', ProjectDocController);
function ProjectDocController($scope, $rootScope, navigation, Title, project, projectRest, Project, Patcher, seshat, projectCategories, Clipboard, $timeout, events) {
    let _project = project;
    Title.setCurrentPage('Project documentation');
    $rootScope.view = 'project-doc';
    $scope.forms = {};
    $scope.projectCategories = projectCategories;
    function reset(p) {
        if (!p) {
            p = _project;
        }
        _project = p;
        $scope.project = _project.toRaw();
        for (const key in $scope.forms) {
            $scope.forms[key].$setPristine();
        }
        return $scope.project;
    }
    $scope.reset = reset;
    reset();
    function refresh() {
        Project.get().then((p) => {
            $scope.project = p.toRaw();
        });
    }
    events.listen($scope, 'klaro.project.changed', refresh);
    $scope.uiState = {
        uploaderTab: 'imageUrl',
    };
    $scope.isUploaderTabActive = function (tab) {
        return $scope.uiState.uploaderTab === tab;
    };
    $scope.setUploaderTabActive = function (tab) {
        $scope.uiState.uploaderTab = tab;
    };
    function upload(file, errFiles, callback) {
        if (errFiles && errFiles[0]) {
            navigation.failed('Your image is too large, please use a smaller icon image.');
        }
        else if (file) {
            seshat({ data: { file: file }, noRename: true })
                .then(callback)
                .catch(navigation.failed);
        }
    }
    $scope.setImage = function (file, errFiles) {
        const saveImageLocation = function (location) {
            $scope.project.imageUrl = location.absolute;
            $scope.forms.documentationForm.$setDirty();
        };
        upload(file, errFiles, saveImageLocation);
    };
    $scope.setThumbnail = function (file, errFiles) {
        const saveThumbnailLocation = function (location) {
            $scope.project.thumbUrl = location.absolute;
            $scope.forms.documentationForm.$setDirty();
        };
        upload(file, errFiles, saveThumbnailLocation);
    };
    $scope.copied = false;
    $scope.uploadScreenshot = function (file, errFiles) {
        const copied = function () {
            $scope.copied = true;
            $timeout(() => {
                $scope.copied = false;
            }, 2500);
        };
        const copyUrl = function (location) {
            Clipboard
                .copy(location.absolute)
                .then(copied)
                .catch(navigation.failed);
        };
        upload(file, errFiles, copyUrl);
    };
    $scope.couldSave = function (form) {
        return form && form.$dirty;
    };
    $scope.canSave = function (form) {
        return $scope.couldSave(form) && form.$valid;
    };
    $scope.save = function () {
        const patch = Patcher.shallow(_project.toRaw(), $scope.project);
        if (patch) {
            projectRest
                .patch(patch)
                .then(reset)
                .catch(navigation.failed);
        }
    };
}
