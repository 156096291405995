angular
    .module('klaro')
    .directive('title', title)
    .service('Title', Title);
function title(Title) {
    return {
        restrict: 'E',
        link: function (scope, element, attributes) {
            Title.onChange(() => {
                element.text(Title.getCurrentTitle());
            });
        },
    };
}
function Title(Project, $rootScope) {
    let _project = null;
    let _page = null;
    const _listeners = $rootScope.$new();
    Project.get().then((p) => {
        _project = p.name;
        fire();
    });
    function getCurrentTitle() {
        return _page ? `${_project} - ${_page}` : _project;
    }
    function setCurrentPage(page) {
        _page = page;
        fire();
    }
    function onChange(listener) {
        return _listeners.$on('titleChanged', listener);
    }
    function fire() {
        _listeners.$emit('titleChanged');
    }
    $rootScope.$on('$routeChangeStart', (event, current) => {
        _page = null;
        fire();
    });
    return {
        getCurrentTitle: getCurrentTitle,
        setCurrentPage: setCurrentPage,
        onChange: onChange,
    };
}
