export const tasks = async () => {
    return {
        board: {
            label: 'Tracking chapters',
            mode: 'kanban',
            filters: {},
            anchors: {
                displayBy: ['state'],
            },
            coloredDimension: 'state',
            dimensions: [{
                    code: 'assignee',
                    label: 'Assignee',
                    required: true,
                    values: [
                        { id: 'julie', label: 'Julie', color: '#E01514' },
                        { id: 'bernard', label: 'Bernard', color: '#FFBA00' },
                    ],
                }, {
                    code: 'state',
                    label: 'Progress',
                    required: true,
                    values: [
                        { id: 'towrite', label: 'To write', color: '#E01514' },
                        { id: 'ongoing', label: 'Writing', color: '#FFBA00' },
                        { id: 'review', label: 'Under review', color: '#00B048' },
                        { id: 'ready', label: 'Ready', color: '#00B048' },
                    ],
                }],
        },
        stories: [{
                id: 'fc86d95d-54c1-4806-af26-d4fab8fa0bc2',
                title: 'Chapter 3',
                subtitle: '',
                state: 'ongoing',
                assignee: 'julie',
            }],
    };
};
