import { Story } from '../../../model';
export class LoadStory {
    constructor() {
        this.preconditions = [];
    }
    async run(client, args) {
        this.load(client, args);
    }
    async load(client, args) {
        const boardState = client.store.board;
        const board = boardState.board;
        const boardId = board.isAllCards() ? 'all' : boardState.board.id;
        const { connector } = client.store;
        const storyRaw = await connector.boards.single(boardId).stories.single(args.id.toString()).get();
        const story = Story.dress(storyRaw, {
            dimensions: board.dimensions
        });
        return story;
    }
}
;
