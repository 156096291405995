// eslint-disable-next-line angular/directive-restrict
angular
    .module('klaro')
    .directive('boardExportsLink', boardExportsLink);
function boardExportsLink() {
    return {
        restrict: 'C',
        template: require('@/core/board/boardExportsLink.html'),
    };
}
