angular
    .module('klaro')
    .directive('projectLogo', projectLogo);
function projectLogo(Project, navigation) {
    return {
        restrict: 'EA',
        scope: true,
        template: require('@/core/project/projectLogo.html'),
        link: function (scope, iElement, iAttrs) {
            Project.get().then((p) => {
                scope.project = p;
                scope.subdomain = p.subdomain;
            });
            scope.gotoHome = navigation.gotoHome;
        },
    };
}
