angular
    .module('klaro')
    .directive('boardDescription', boardDescription);
function boardDescription() {
    return {
        restrict: 'EA',
        template: require('@/core/board/boardDescription.html'),
        link: function (scope) { },
    };
}
