import { Story } from '@klaro/corejs/model';
import { LocalStoragePersistence } from '@klaro/corejs/utils';
angular
    .module('klaro')
    .service('recentNewsService', recentNewsService);
function recentNewsService($http, $filter) {
    let _refreshing;
    let _allNews = [];
    let _unreadNews = [];
    function ready() {
        return _refreshing ? _refreshing : fetchNews();
    }
    function fetchNews() {
        if (_refreshing) {
            return _refreshing;
        }
        else {
            _refreshing = $http
                .get('https://blog.klaro.cards/api/boards/news/stories/', {
                skipKlaroAuth: true,
            })
                .then((response) => {
                _allNews = response.data;
                computeUnreadNews();
                return _allNews;
            })
                .catch((data) => {
                console.error(data);
                // simply ignore, not a big deal if something goes wrong
                // with news
            });
        }
        return _refreshing;
    }
    function hasNews() {
        return _allNews && _allNews.length > 0;
    }
    function unreadCount() {
        return (_unreadNews && _unreadNews.length) || 0;
    }
    function getOne() {
        return ready().then(_allNews => {
            return extractOne();
        });
    }
    function computeUnreadNews() {
        const read = getReadNews();
        _unreadNews = _allNews.filter(s => !read[s.id]);
        return Promise.resolve(_unreadNews);
    }
    function extractOne() {
        if (_unreadNews.length === 0) {
            return;
        }
        const chosen = _unreadNews[Math.floor(Math.random() * _unreadNews.length)];
        const story = Story.dress(chosen, {});
        const date = story['publication-date'];
        const dateStr = $filter('date')(date, 'yyyy/MM/dd');
        return {
            id: story.id,
            title: story.toptitle,
            summary: story.summary,
            url: `https://klaro.cards/blog/${dateStr}/${story.urlHash}`,
        };
    }
    const storage = new LocalStoragePersistence();
    const storageKey = 'Klaro-RecentNewsClicked';
    function trackNewsClicked(news) {
        const read = getReadNews();
        read[news.id] = true;
        storage.set(storageKey, read);
        return computeUnreadNews();
    }
    function getReadNews() {
        return storage.get(storageKey, {});
    }
    return {
        ready: ready,
        hasNews: hasNews,
        getOne: getOne,
        unreadCount: unreadCount,
        trackNewsClicked: trackNewsClicked,
    };
}
