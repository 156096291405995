import { ApiDimensionConnector, ApiDimensionsConnector } from '../api/ApiDimensionsConnector';
import { PatchableStorage } from './PatchableStorage';
import { MemoryPatchStorage } from './MemoryPatchStorage';
import { v4 } from 'uuid';
import { toCode } from '../../../utils';
export class LocalDimensionsConnector extends ApiDimensionsConnector {
    constructor(client, data, baseUrl) {
        super(client, baseUrl);
        this.client = client;
        this.data = data;
    }
    single(id) {
        return new LocalDimensionConnector(this, id);
    }
    async post(dimension) {
        return this.create([dimension]);
    }
    async create(dimensions) {
        const installers = await this.client.store.installers;
        const existing = await this.data.dimensions.values();
        const toCreate = dimensions.map((d) => {
            const installer = installers?.find(i => i.datatype === d.datatype);
            d.id = d.id || v4();
            d.code = toCode(d.label, existing.map(d => d.code));
            d.ordering = existing.length * 1000000;
            d.deleted = false;
            d.deletedAt = null;
            d.deletedBy = null;
            d.description = '';
            d.supportsColor = true; // TODO
            d.semanticsType = null; // TODO
            d.relevantKinds = [];
            d.attribute = false; // TODO
            d.supportsMultiple = !!installer?.supportsMultiple; // TODO
            d.supportsRequired = !!installer?.supportsRequired;
            d.supportsTagging = !!installer?.supportsTagging;
            d.supportsAlphabeticalOrdering = false; // TODO
            d.supportsColor = true; // TODO
            d.userEditable = true; // TODO
            d.values = d.values.map((v, i) => {
                return {
                    ...v,
                    id: toCode(v.label),
                    ordering: 100000 * i,
                    deprecated: false,
                };
            });
            d.values.unshift({
                id: null,
                label: 'No value',
                semantics: null,
                color: '#ffffff',
                ordering: 0,
                deprecated: false,
            });
            return d;
        });
        const saved = await this.data.dimensions.store(toCreate);
        return saved[0];
    }
}
export class LocalDimensionConnector extends ApiDimensionConnector {
    constructor(parent, id) {
        super(parent, id);
        this.parent = parent;
        this.id = id;
    }
    saveValue(dimId, value) {
        var _a;
        const newValue = {
            id: value.id || value.label,
            ...value
        };
        const data = this.parent.data;
        (_a = data.dimensionValues)[dimId] || (_a[dimId] = new PatchableStorage(new Map(), new MemoryPatchStorage()));
        data.dimensionValues[dimId].store([newValue]);
        const oldValue = this.parent.client.store.board?.board.dimensionById(dimId)?.getValueById(value.id) || {};
        return Promise.resolve({ ...oldValue, ...newValue });
    }
}
