import { DomainUtils } from '@klaro/corejs/utils';
angular
    .module('klaro')
    .service('copyProjectModal', copyProjectModal)
    .controller('CopyProjectModalController', CopyProjectModalController);
function copyProjectModal(klaroModal, navigation) {
    const service = function (msg, yesCallback) {
        const modalInstance = klaroModal.open({
            //animation: false,
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            template: require('@/core/project/copyProjectModal.html'),
            controller: 'CopyProjectModalController',
            windowClass: 'klaro-modal duplicate-project-modal',
            size: 'small',
            resolve: {
                msg: function () {
                    return msg;
                },
                yesCallback: function () {
                    return yesCallback;
                },
            },
        });
        return modalInstance.result;
    };
    return service;
}
function CopyProjectModalController($scope, msg, yesCallback, $uibModalInstance, $location, navigation, robust) {
    const normalizeSubdomain = DomainUtils.normalizeSubdomain;
    $scope.msg = msg;
    $scope.h2 = msg.title;
    $scope.done = false;
    $scope.formData = {
        name: undefined,
        subdomain: undefined,
        keepCards: false,
        keepMembers: false,
    };
    $scope.requestResponse = null;
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.no = function () {
        $uibModalInstance.dismiss(false);
    };
    $scope.yes = function () {
        $scope.error = null;
        yesCallback($scope.formData)
            .then((result) => {
            $scope.done = result || true;
            $scope.h2 = msg.successTitle;
            $scope.requestResponse = result;
        })
            .catch(onError);
    };
    function onError(err) {
        $scope.error = robust.message(err.data);
    }
    $scope.projectUrl = function () {
        return `${$location.protocol()}://${$scope.formData.subdomain}.${$location.host().replace(/^[^\.]+\./, '')}`;
    };
    $scope.normalizeSubdomain = function () {
        $scope.formData.subdomain = normalizeSubdomain($scope.formData.name);
    };
    $scope.close = function () {
        $uibModalInstance.close($scope.done);
    };
}
