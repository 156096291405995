angular
    .module('klaro')
    .directive('manageDimensionsLink', manageDimensionsLink);
function manageDimensionsLink(navigation) {
    return {
        restrict: 'EA',
        template: require('@/core/dimension/manageDimensionsLink.html'),
        scope: {
            before: '&',
        },
        link: function (scope, elm, attrs) {
            scope.gotoDimensions = function ($event) {
                scope.before();
                $event.preventDefault();
                $event.stopPropagation();
                navigation.gotoUrl('/settings/dimensions/');
            };
        },
    };
}
