import { SetProject } from '@klaro/corejs/client';
angular
    .module('klaro')
    .service('Project', Project);
function Project(KernelClient, $rootScope, $q) {
    function get() {
        return $q.resolve(KernelClient.store.project);
    }
    function getSync() {
        return KernelClient.store.project;
    }
    async function set(project) {
        await KernelClient.run(new SetProject, { project: project });
        $rootScope.$emit('klaro.project.changed', { project: project });
        return project;
    }
    async function onEvent(event, args) {
        if (args?.project) {
            set(args?.project);
        }
    }
    $rootScope.$on('klaro.user.changed', onEvent);
    $rootScope.$on('klaro.application.reload', onEvent);
    return {
        get: get,
        set: set,
        getSync: getSync,
    };
}
