angular
    .module('klaro')
    .service('boardPermissionsModal', boardPermissionsModal)
    .controller('BoardPermissionsModalController', BoardPermissionsModalController);
function boardPermissionsModal(klaroModal, workspaceRest) {
    return {
        open: function (board, options) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/board/boardPermissionsModal.html'),
                controller: 'BoardPermissionsModalController',
                windowClass: 'klaro-modal shared-board-modal',
                size: 'medium',
                resolve: {
                    board: function () {
                        return board;
                    },
                    options: function () {
                        return options;
                    },
                    workspaces: function () {
                        return workspaceRest.list();
                    },
                },
            });
        },
    };
}
function BoardPermissionsModalController($scope, $uibModalInstance, navigation, board, workspaces) {
    $scope.modalInstance = $uibModalInstance;
    $scope.workspaces = workspaces;
    /// Loading
    function load(board, success) {
        $scope.board = board;
        $scope.boardData = angular.copy(board.toBoardSaveRaw());
        $scope.working = false;
        $scope.error = null;
        $scope.success = (success === undefined ? 'Board saved' : success);
        $scope.warning = null;
        if ($scope.boardForm) {
            $scope.boardForm.$setPristine();
        }
        return board;
    }
    load(board, false);
    // Navigation
    $scope.gotoMembers = function () {
        navigation.gotoUrl('/settings/members/');
        $uibModalInstance.dismiss();
    };
    // Board actions
    $scope.save = function () {
        $scope.commit({ action: 'saveBoard', boardData: $scope.boardData });
    };
    $scope.commit = function (result) {
        $scope.closing = true;
        $uibModalInstance.close(result);
    };
    $scope.close = function () {
        $uibModalInstance.dismiss();
    };
    function isDirty() {
        return $scope.boardForm.$dirty;
    }
    $scope.isDirty = isDirty;
}
