import { DataObject } from '../utils';
import { User } from '.';
import Permissions from './Session/Permissions';
class Session extends DataObject {
    get permissions() {
        return this.memoize2('permissions', () => {
            return new Permissions({ session: this });
        }, ['user', 'viewAs', 'authToken']);
    }
    get viewAsCode() {
        return this.viewAs ? this.viewAs.code : null;
    }
    withAuthToken(authToken) {
        return this.clone({
            authToken,
        });
    }
    withUser(user) {
        const viewAsCode = this.viewAsCode;
        return this.clone({
            user: User.dress(user),
            viewAs: user.workspaces.find((t) => {
                return t.code === viewAsCode;
            }) || Session.defaultViewAs(user),
        });
    }
    withViewAs(workspace) {
        return this.clone({
            viewAs: workspace,
        });
    }
    withDefaultViewAs() {
        return this.clone({
            viewAs: Session.defaultViewAs(this.user),
        });
    }
    disconnected() {
        return this.clone({
            authToken: null,
            user: null,
            viewAs: null,
        });
    }
    isDisconnected() {
        return !this.authToken && !this.user;
    }
    isConnected() {
        return !(this.isDisconnected());
    }
    static defaultViewAs(user) {
        if (!user) {
            console.error('No user...', new Error());
            return;
        }
        const homeWorkspace = (user.preferences && user.preferences.homeWorkspace);
        const workspace = user.workspaces.find((t) => {
            return t.code === homeWorkspace;
        });
        return workspace || user.workspaces[0];
    }
}
export default Session;
