angular
    .module('klaro')
    .directive('qrLoginModalPart', qrLoginModalPart);
function qrLoginModalPart(auth, $window) {
    return {
        restrict: 'E',
        scope: {
            user: '=',
            userData: '=',
            ctrl: '=',
        },
        template: require('@/auth/profileModal/qrLoginModalPart.html'),
        link: function (scope) {
            const protocol = $window.location.hostname.indexOf('klaro.cards') >= 0
                ? 'https'
                : 'klaro';
            scope.error = null;
            scope.url = null;
            scope.formData = {
                password: null,
            };
            scope.confirmPassword = async () => {
                scope.error = null;
                auth.getQuickLoginToken(scope.formData.password)
                    .then((token) => {
                    scope.url = `${protocol}://${$window.location.host}${$window.location.pathname}?token=${token}`;
                })
                    .catch((err) => {
                    scope.error = err;
                    scope.token = undefined;
                });
            };
            scope.close = function () {
                scope.ctrl.close();
            };
        },
    };
}
