angular
    .module('klaro')
    .controller('LoginModalController', LoginModalController);
function LoginModalController($scope, $location, navigation, $uibModalInstance, login, $routeParams) {
    const ERROR_MSG = {
        'invalid-user-or-password': 'Invalid user or password',
        'account-unavailable': 'Your account is currently unavailable. Please check your email or contact Klaro Cards support.',
    };
    function grantType() {
        if (navigation.isApp()) {
            return 'auto_login';
        }
        else {
            return 'client_credentials';
        }
    }
    $scope.credentials = {
        login: login,
        grant_type: grantType(),
        redirect_to: $routeParams.redirectTo,
    };
    $scope.error = ERROR_MSG[$location.search().err];
    $scope.showPassword = false;
    $scope.gotoPasswordForgotten = function () {
        $uibModalInstance.close();
        let url = '/auth/password/forgotten';
        if ($scope.credentials.login && $scope.credentials.login.length > 0) {
            url += `?email=${$scope.credentials.login}`;
        }
        $location.url(url);
    };
    $scope.togglePassword = function () {
        $scope.showPassword = !$scope.showPassword;
    };
}
