import { ApiStoriesConnector } from './ApiStoriesConnector';
export class ApiBoardConnector {
    constructor(client) {
        this.client = client;
    }
    single(id) {
        const client = this.client;
        return {
            async get(_params) {
                const boardRes = await client.api.get(`/boards/${id}`);
                return boardRes.data;
            },
            async patch(patch) {
                const boardRes = await client.api.patch(`/boards/${patch.id}`, patch);
                return boardRes.data;
            },
            async delete() {
                await client.api.delete(`/boards/${id}`);
            },
            stories: new ApiStoriesConnector(client, `/boards/${id}`),
            exports: {
                async post(options) {
                    const res = await client.api.post(`/boards/${id}/exports/`, options);
                    return res.data;
                }
            }
        };
    }
    async delete(_params) {
        throw new Error('Method not implemented.');
    }
    search(_filters) {
        throw new Error('Method not implemented.');
    }
    get(_params) {
        throw new Error('Method not implemented.');
    }
    create(_resources) {
        throw new Error('Method not implemented.');
    }
    post(_resource) {
        throw new Error('Method not implemented.');
    }
    patch(_patch) {
        throw new Error('Method not implemented.');
    }
}
