angular
    .module('klaro')
    .directive('boardImportMenu', boardImportMenu);
function boardImportMenu(ActionsForHelper) {
    return {
        restrict: 'E',
        template: require('@/core/board/boardImportMenu.html'),
        require: '^board',
        scope: true,
        replace: true,
        link: function (scope, elm, attrs, boardCtrl) {
            boardCtrl.$watch('board', (boardState) => {
                scope.actions = ActionsForHelper.actionsForBoardImport(boardCtrl);
            }, scope);
            scope.labelFor = function (action) {
                if (typeof action.label === 'function') {
                    return action.label();
                }
                else {
                    return action.label;
                }
            };
            scope.executeIt = function ($event, method, param) {
                if (typeof method === 'function') {
                    method($event, param);
                }
            };
            scope.cssClassFor = function (action) {
                return [action.icon, action.separator ? 'separator' : null, action.children ? 'submenu' : null].filter(Boolean);
            };
            scope.enabled = function (action) {
                if (action.enabled === undefined) {
                    return true;
                }
                else {
                    return action.enabled(action.param);
                }
            };
        },
    };
}
