import { Interval } from 'luxon';
const IntervalLs = function (arg) {
    if (typeof (arg) === 'string') {
        return Interval.fromISO(arg);
    }
    else {
        return Interval;
    }
};
IntervalLs.fromISO = Interval.fromISO;
IntervalLs.fromDateTimes = Interval.fromDateTimes;
IntervalLs.before = Interval.before;
IntervalLs.after = Interval.after;
export default IntervalLs;
