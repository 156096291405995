import { version } from '@klaro/corejs';

require('jquery');
require('jquery-menu-aim');
require('bootstrap');
require('angular');
require('angular-route');
require('angular-sanitize');
require('angular-messages');
require('angular-dynamic-locale');
require('angular-drag-and-drop-lists');
require('angular-ui-bootstrap');
require('angular-gravatar');
require('angular-timeago');
require('angular-country-picker');
require('ng-file-upload');
require('dndtouch2');
require('angularjs-datepicker');
require('angular-bootstrap-lightbox');
require('stacktrace-js');

// Load sentry async, only if sentry configured in env vars
const lazyLoadSentry = async (sentryDsn) => {
  const Sentry = await import(/* webpackChunkName: "optional-vendor" */ '@sentry/browser');
  const { Integrations } = await import(/* webpackChunkName: "optional-vendor" */ '@sentry/tracing');
  const { Angular: AngularIntegration } = await import(/* webpackChunkName: "optional-vendor" */ '@sentry/integrations');

  Sentry.init({
    environment: process.env.KLARO_DOMAIN_NAME,
    release: version,
    dsn: sentryDsn,
    integrations: [
      new AngularIntegration(),
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });
  global.Sentry = Sentry;
};
if (process.env.SENTRY_DSN) {
  lazyLoadSentry(process.env.SENTRY_DSN);
}
