angular
    .module('klaro')
    .factory('SecureDownload', SecureDownload);
function SecureDownload($http) {
    return {
        getBlob: getBlob,
        downloadBlob: downloadBlob,
    };
    function getBlob(url, downloadOptions) {
        return $http.get(url, { responseType: 'blob' }).then((res) => {
            downloadBlob([res.data], downloadOptions);
        });
    }
    function downloadBlob(data, options) {
        const blob = new Blob(data, options);
        var a = document.createElement('a');
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = options.name;
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    }
}
