angular
    .module('klaro')
    .directive('viewAsSelector', viewAsSelector);
function viewAsSelector(auth, events, $route) {
    return {
        restrict: 'E',
        template: require('@/core/workspace/viewAsSelector.html'),
        scope: true,
        link: function (scope, elm, attrs) {
            function sync() {
                const user = auth.getUser();
                if (user) {
                    scope.selected = auth.getViewAs();
                    scope.workspaces = user.workspaces || [];
                    scope.hasManyWorkspaces = scope.workspaces.length > 1;
                }
            }
            sync();
            scope.$watch('selected', (newWorkspace) => {
                if (!newWorkspace) {
                    return;
                }
                auth.setViewAs(newWorkspace);
            });
            events.listen(scope, 'klaro.user.changed', sync);
            events.listen(scope, 'klaro.viewAs.changed', sync);
            scope.isDisabled = function () {
                return $route.current && !!$route.current.viewAs;
            };
        },
    };
}
