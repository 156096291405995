angular
    .module('klaro')
    .service('newWorkspaceModal', newWorkspaceModal)
    .controller('NewWorkspaceModalController', NewWorkspaceModalController);
function newWorkspaceModal(klaroModal) {
    return {
        open: function (workspace) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/workspace/newWorkspaceModal.html'),
                controller: 'NewWorkspaceModalController',
                windowClass: 'klaro-modal center-modal',
                size: 'small',
                resolve: {
                    workspace: function () {
                        return workspace;
                    },
                },
            });
        },
    };
}
function NewWorkspaceModalController($scope, $uibModalInstance, workspaceRest, robust, workspace) {
    const workspaceData = (workspace !== undefined) ? workspace.toRaw() : {};
    $scope.formData = workspaceData;
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.close = function (workspace) {
        $uibModalInstance.close(workspace);
    };
    $scope.save = function () {
        const result = ($scope.isNew) ? workspaceRest.create($scope.formData) : workspaceRest.update($scope.formData);
        result
            .then($scope.close)
            .catch(onError);
    };
    function onError(err) {
        $scope.error = robust.message(err.data);
    }
    $scope.modalTitle = function () {
        return ($scope.isNew) ? 'New workspace' : 'Edit workspace';
    };
    function isNew() {
        return $scope.formData.id === null || $scope.formData.id === undefined;
    }
    $scope.isNew = isNew();
    $scope.canSave = function (newWorkspaceForm) {
        return newWorkspaceForm.$valid && !newWorkspaceForm.$pristine;
    };
}
