import { BoardAnchor } from '@klaro/corejs/model';
angular
    .module('klaro')
    .directive('boardCardsContent', boardCardsContent);
function boardCardsContent($filter, ArrayUtils) {
    return {
        restrict: 'E',
        template: require('@/core/board/boardCardsContent.html'),
        link: function (scope) {
            scope.coloredDimensionsForCards = $filter('orderBy')(scope.board.coloredDimensions(), '+label');
            scope.cardDimensions = $filter('orderBy')(scope.board.dimensions.filter((d) => { return d.canBeUsedOnCards() && !d.isCoverImage(); }), '+label');
            // Display cover image
            let coverImageDimension = scope.board.getCoverImageDimension();
            const cardsAnchor = scope.boardData.anchors.cards ?? [];
            scope.showCoverImage = coverImageDimension && cardsAnchor.includes(coverImageDimension.code);
            scope.toggleDisplayCoverImage = function () {
                const anchor = scope.boardData.anchors.cards ?? []; // an array of dimensions' codes to use in the "cards" anchor
                const boardAnchor = BoardAnchor.list(anchor, { dimensions: scope.dimensions });
                scope.trackPending(() => {
                    return scope.boardCtrl.ensureCoverImageDimension(false).then((dim) => {
                        if (dim) {
                            coverImageDimension = dim;
                            scope.boardData.anchors.cards = boardAnchor.toggle(coverImageDimension).toList();
                            scope.showCoverImage = scope.boardData.anchors.cards.includes(coverImageDimension.code);
                        }
                    });
                });
            };
        },
    };
}
