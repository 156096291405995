export class DefaultEventuallizer {
    constructor(eventually, client) {
        this.eventually = eventually;
        this.client = client;
    }
    get summaries() {
        if (!this.eventually.summaries || !this.client) {
            return Promise.resolve([]);
        }
        return this.client.run(this.eventually.summaries())
            .then(() => this.client?.store.summaries || []);
    }
    get installers() {
        if (!this.eventually.installers || !this.client) {
            return Promise.resolve([]);
        }
        return this.client.run(this.eventually.installers())
            .then(() => this.client?.store.installers || []);
    }
    bind(client) {
        return new DefaultEventuallizer(this.eventually, client);
    }
}
