import { inject, unref } from 'vue';
export const toLocalStore = (defaults, args) => {
    return Object.keys(defaults)
        .reduce((store, key) => {
        // @ts-ignore
        store[key] = args?.[key] || inject(key) || defaults[key];
        return store;
    }, {});
};
export const pickRequired = (store, keys) => {
    return Object.values(keys)
        .reduce((picked, key) => {
        picked[key] = unref(store[key]);
        if (!picked[key]) {
            throw new Error(`useStoryDimensionValue was not provided with a ${key} and none could be injected.`);
        }
        return picked;
    }, {});
};
export const useInjectableArgs = (defaults, args) => {
    const store = toLocalStore(defaults, args);
    return {
        store,
        pickRequired(keys) {
            return pickRequired(store, keys);
        },
    };
};
