/* eslint-disable angular/directive-restrict */
angular
    .module('klaro')
    .directive('boardImportsLink', boardImportsLink);
function boardImportsLink() {
    return {
        restrict: 'C',
        template: require('@/core/board/boardImportsLink.html'),
        scope: true,
        link: function (scope) {
            scope.importers = scope.board.board.importers;
        },
    };
}
