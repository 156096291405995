export const areDecksCurrentlyOpen = (state) => {
    return state.decksCurrentlyOpen;
};
export const openDecks = (state) => {
    return state.with({
        decksCurrentlyOpen: true,
    });
};
export const closeDecks = (state) => {
    return state.with({
        decksCurrentlyOpen: false,
    });
};
export const toggleDecks = (state) => {
    return state.with({
        decksCurrentlyOpen: !state.decksCurrentlyOpen,
    });
};
