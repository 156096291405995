class Cache {
    constructor(entries = {}) {
        this.entries = entries;
    }
    hasEntry(name) {
        return Object.prototype.hasOwnProperty.call(this.entries, name);
    }
    isValid(name, key) {
        return this.hasEntry(name) && this.equalKeys(this.entries[name].key, key);
    }
    get(name) {
        return this.entries[name] && this.entries[name].value;
    }
    set(name, key, value) {
        this.entries[name] = { key: key, value: value };
        return this;
    }
    dup() {
        return new Cache(Object.assign({}, this.entries));
    }
    equalKeys(k1, k2) {
        if (k1 === k2) {
            return true;
        }
        if (k1 === null || k1 === undefined) {
            return k2 === null || k2 === undefined;
        }
        if (k2 === null || k2 === undefined) {
            return k1 === null || k1 === undefined;
        }
        if (typeof (k1) === 'object' && typeof (k2) === 'object') {
            return Object.keys(k1).every(k => k1[k] === k2[k]);
        }
        return false;
    }
}
export default Cache;
