import { Title } from '../model';
import { klarifyDimensions } from './autodim';
import { v4 } from 'uuid';
const InvalidConstructors = [Date, Map, Set];
const InvalidTypeError = new Error('Incompatible data type');
export class Klarifier {
    static klarify(data) {
        if (data === null || data === undefined) {
            throw InvalidTypeError;
        }
        if (data?.constructor && InvalidConstructors.includes(data.constructor)) {
            throw InvalidTypeError;
        }
        if (Array.isArray(data)) {
            return Klarifier.klarifyArray(data);
        }
        if (typeof data === 'string') {
            return Klarifier.klarifyString(data);
        }
        throw InvalidTypeError;
    }
    static klarifyArray(data) {
        const dimensions = klarifyDimensions(data);
        console.log(dimensions);
        const stories = new Map(structuredClone(data).map((story, i) => {
            const id = v4();
            return [id, {
                    id,
                    identifier: i,
                    ...story,
                }];
        }));
        return {
            dimensions,
            stories,
        };
    }
    static klarifyString(data) {
        const titleDimension = Title.instance;
        const dimensions = [titleDimension];
        const stories = new Map(data.split('\n').map(title => {
            return [
                v4(),
                { [titleDimension.code]: title }
            ];
        }));
        return {
            dimensions,
            stories,
        };
    }
}
