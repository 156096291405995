import { Board } from '@klaro/corejs/model';
angular
    .module('klaro')
    .factory('boardRest', boardRest);
function boardRest($rootScope, $http, $filter, dimensionRest) {
    return {
        new: newBoard,
        list: list,
        create: create,
        ordering: ordering,
        invite: invite,
        exportBoard: exportBoard,
        //
        dress: dress,
    };
    function newBoard() {
        return dimensionRest.list().then((dims) => {
            return dress({
                label: 'New Board',
                location: 'new-board',
                mode: 'list',
                dimensions: dims,
                decksOpen: true,
                filters: {},
                anchors: {},
                summaries: [],
                workspacePermissions: [],
                stories: [],
            });
        });
    }
    function create(board) {
        const url = '/api/boards/';
        const tosend = board.toBoardSaveRaw();
        delete tosend.id;
        return $http
            .post(url, tosend)
            .then((res) => {
            $rootScope.$emit('klaro.board.changed');
            return dress(res.data);
        });
    }
    function list(viewAsCode) {
        const config = {};
        if (viewAsCode) {
            config.headers = { 'X-Klaro-ViewAs': viewAsCode };
        }
        return $http
            .get('/api/my/boards/', config)
            .then((response) => {
            return ($filter('orderBy')(response.data, '+label')).map(dress);
        });
    }
    function ordering(boardIds) {
        return $http
            .post('/api/boards/ordering', { boards: boardIds })
            .then((bs) => {
            $rootScope.$emit('klaro.boards.changed', bs);
            return bs;
        });
    }
    function invite(board, member) {
        return $http
            .post(`/api/boards/${board.id}/invitations/`, member);
    }
    function exportBoard(board, exportData) {
        return $http
            .post(`/api/boards/${board.id}/exports/`, exportData);
    }
    //
    function dress(data) {
        return Board.dress(data);
    }
}
