angular
    .module('klaro')
    .directive('ifOwner', ifOwner);
function ifOwner($rootScope, $compile) {
    return {
        restrict: 'A',
        compile: function (element, attrs) {
            if ($rootScope.isOwner) {
                attrs.$set('ifOwner', null);
                return function (scope, element) {
                    $compile(element)(scope);
                };
            }
            else {
                return function (scope, element) {
                    element.remove();
                };
            }
        },
    };
}
