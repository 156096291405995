angular
    .module('klaro')
    .directive('workspaceMultiSelect', workspaceMultiSelect);
/**
 * This directive implements a selector for a list of workspaces.
 */
function workspaceMultiSelect() {
    return {
        restrict: 'E',
        template: require('@/core/workspace/workspaceMultiSelect.html'),
        require: '^ngModel',
        scope: {
            workspaces: '=',
            member: '=',
            isnew: '=',
        },
        link: function ($scope, elm, attrs, ngModel) {
            $scope.selectedWorkspaces = {};
            if (!$scope.isnew) {
                initWorkspacesMember();
            }
            function initWorkspacesMember() {
                $scope.member.workspaces.map(w => {
                    $scope.selectedWorkspaces[w.code] = true;
                });
            }
            function getSelectedWorkspaceCodes() {
                return Object
                    .keys($scope.selectedWorkspaces)
                    .filter((cur) => {
                    return $scope.selectedWorkspaces[cur];
                })
                    .map(e => { return { code: e }; });
            }
            $scope.inMemberWorkspace = function (code) {
                if (!$scope.isnew) {
                    return $scope.member.workspaces.find(w => {
                        return w.code == code;
                    });
                }
            };
            $scope.$watch('selectedWorkspaces', () => {
                ngModel.$setViewValue(getSelectedWorkspaceCodes());
            }, true);
            ngModel.$setViewValue(getSelectedWorkspaceCodes());
        },
    };
}
