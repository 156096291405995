import Dimension from '../Dimension';
class Specification extends Dimension {
    get category() {
        return 'system';
    }
    getValues(_) {
        return [];
    }
    matchingValuesOf(_story, _globalContext) {
        return [];
    }
    isPhantomDimension() {
        return true;
    }
    canBeUsedOnDecks() {
        return false;
    }
    canBeUsedOnCards() {
        return false;
    }
    canBeUsedAsDateBoundary() {
        return false;
    }
    canBeEdited() {
        return true;
    }
    canBeEditedAsCardDimension() {
        return false;
    }
    canBeUsedForOrdering() {
        return false;
    }
    canValuesBeEdited() {
        return false;
    }
    participatesToTextSearch() {
        return true;
    }
    searchMatchesOn(search, regexp, story, _globalContext) {
        const spec = this.getStoryRawValue(story);
        return spec && spec.match(regexp);
    }
}
export default Specification;
