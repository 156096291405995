import { ArrayUtils } from '../utils';
class StoriesPatch {
    constructor(patches = []) {
        this.patches = patches;
    }
    static dress(patches) {
        return new StoriesPatch(patches);
    }
    add(patch) {
        if (patch.constructor === StoriesPatch) {
            return this.addAll(patch.patches);
        }
        else {
            const index = this.patches.findIndex((s) => s.id === patch.id);
            let patches;
            if (index >= 0) {
                const merged = Object.assign({}, this.patches[index], patch);
                patches = ArrayUtils.replaceAt(this.patches, index, merged);
            }
            else {
                patches = this.patches.concat([patch]);
            }
            return new StoriesPatch(patches);
        }
    }
    addAll(patches) {
        return patches.reduce((sp, p) => {
            return sp.add(p);
        }, this);
    }
    toRaw() {
        return this.patches;
    }
}
export default StoriesPatch;
