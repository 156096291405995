export class SetSession {
    constructor() {
        this.preconditions = [
            async (_client, args) => {
                return !!args?.session;
            }
        ];
    }
    /**
     * Set the token
     */
    async run(client, args) {
        return {
            session: args.session,
        };
    }
}
;
