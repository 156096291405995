angular
    .module('klaro')
    .directive('highlight', highlight);
function highlight($location, $rootScope) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            const h = $rootScope.highlight;
            if (h && attrs.highlight == h) {
                element.addClass('highlight');
                $rootScope.highlight = undefined;
            }
        },
    };
}
