angular
    .module('klaro')
    .service('inviteMemberModal', inviteMemberModal)
    .controller('InviteMemberModalController', InviteMemberModalController);
function inviteMemberModal(centerModal) {
    return centerModal.factor({
        template: require('@/core/member/inviteMemberModal.html'),
        controller: 'InviteMemberModalController',
    });
}
function InviteMemberModalController($scope, $uibModalInstance, memberRest, robust, entity, options) {
    $scope.entity = entity;
    $scope.memberData = entity.toRaw();
    $scope.success = false;
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.close = function (result) {
        $uibModalInstance.close(result);
    };
    $scope.canSave = function () {
        return $scope.inviteMemberForm.$valid;
    };
    $scope.save = function () {
        angular.forEach($scope.inviteMemberForm.$error, (field) => {
            angular.forEach(field, (errorField) => {
                errorField.$setDirty();
            });
        });
        if ($scope.inviteMemberForm.$valid) {
            return memberRest
                .invite($scope.entity, $scope.memberData.user)
                .then(notify)
                .then($scope.close)
                .catch(onError);
        }
    };
    function notify(member) {
        if (options.onSubmit) {
            options.onSubmit(member);
        }
    }
    function onError(err) {
        $scope.error = robust.message(err.data);
    }
}
