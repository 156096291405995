angular
    .module('klaro')
    .directive('colorInput', colorInput);
function colorInput() {
    return {
        restrict: 'AE',
        require: '^ngModel',
        scope: {
            ngDisabled: '=',
            colors: '=',
            showName: '=?',
        },
        template: require('@/support/colorInput.html'),
        link: function (scope, iElement, iAttrs, ngModelController) {
            scope.color2style = function (color) {
                const border = color.style == 'white' ? '1px solid #eeeeee' : 'none';
                return `background: ${color.color}; `
                    + `border: ${border};`;
            };
            scope.checkmarkStyle = function (color) {
                const style = (color.style == 'white' || color.style == 'light') ? '#424242' : '#ffffff';
                return `color: ${style};`;
            };
            scope.$watch('colors', (cs) => {
                if (!cs) {
                    return;
                }
                scope.selected = cs.find((c) => {
                    return c.id == ngModelController.$viewValue;
                });
            });
            scope.selectColor = function (color) {
                scope.selected = color;
                ngModelController.$setViewValue(color.id);
            };
            scope.isSelected = function (color) {
                return ngModelController.$viewValue == color.id;
            };
        },
    };
}
