angular
    .module('klaro')
    .directive('boardExportSpreadsheetOptions', boardExportSpreadsheetOptions);
function boardExportSpreadsheetOptions(boardExportServices) {
    return {
        restrict: 'EA',
        template: require('@/core/board/exports/boardExportSpreadsheetOptions.html'),
        scope: {
            board: '=',
            form: '=',
            close: '=',
            extension: '@',
            contentType: '@',
        },
        link: function (scope) {
            scope.candidateDimensions = scope.board.getDimensions()
                .filter(d => d.datatype !== 'Specification');
            scope.exportAs = 'download';
            scope.exportOptions = {
                includeDescription: false,
                filtersOverride: scope.board.filtersOverride().toCnf(true),
                dimensions: angular.copy(scope.board.getDefaultExportDimensions().map((d) => { return d.code; })),
            };
            scope.form.valid = true;
            scope.formatOptions = [
                { code: 'download', title: 'Download file' },
                { code: 'permanent-link', title: 'Get a permanent link' },
            ];
            scope.isFormatSelected = function (code) {
                return scope.exportAs == code;
            };
            scope.setFormat = function (code) {
                return scope.exportAs = code;
            };
            scope.form.export = function () {
                const exportData = {
                    name: scope.board.label + scope.extension,
                    format: scope.contentType,
                    mode: scope.exportAs,
                    options: scope.exportOptions,
                };
                boardExportServices.exportBoard(scope.board, exportData)
                    .then(() => {
                    if (scope.exportAs != 'permanent-link') {
                        scope.close();
                    }
                });
            };
        },
    };
}
