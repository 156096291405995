angular
    .module('klaro')
    .service('lockProjectModal', lockProjectModal)
    .controller('LockProjectModalController', LockProjectModalController);
function lockProjectModal(centerModal) {
    return centerModal.factor({
        template: require('@/onboard/lockProjectModal.html'),
        controller: 'LockProjectModalController',
    });
}
function LockProjectModalController($scope, $uibModalInstance, projectRest, robust) {
    $scope.strategy = 'keep';
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    function commit(result) {
        $uibModalInstance.close(result);
    }
    $scope.lockProject = function () {
        projectRest
            .lock({
            lock: true,
        })
            .then(commit)
            .catch(onError);
    };
    function onError(err) {
        $scope.error = robust.message(err.data);
    }
}
