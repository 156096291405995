angular
    .module('klaro')
    .directive('emailChangeRequestModalPart', emailChangeRequestModalPart);
function emailChangeRequestModalPart() {
    return {
        restrict: 'E',
        scope: {
            ctrl: '=',
        },
        template: require('@/auth/profileModal/emailChangeRequestModalPart.html'),
    };
}
