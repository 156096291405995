angular
    .module('klaro')
    .directive('dimensionDatepicker', dimensionDatepicker);
/**
 * This directive implements a selector for a value of type date.
 *
 * It receives a dimension and makes the mapping with an input widget.
 */
function dimensionDatepicker(navigation, datepickerModal, tmhDynamicLocale, DateParser, $filter) {
    return {
        restrict: 'E',
        template: require('@/core/dimension/dimensionDatepicker.html'),
        scope: {
            dimension: '=',
            disabled: '=',
            isRequired: '=',
        },
        require: '^ngModel',
        link: function (scope, elm, attrs, ngModelController, getDateOptions) {
            elm.addClass('dimension-datepicker');
            const locale = tmhDynamicLocale.get();
            const withTime = scope.dimension.usesTime();
            scope.state = { date: undefined, formatted: undefined };
            function setFormatted() {
                const f = scope.state.date
                    ? $filter('dateFormat')(scope.state.date, withTime, locale)
                    : null;
                scope.state.formatted = f;
            }
            function sameDate(d1, d2) {
                if (!d1) {
                    return !d2;
                }
                const d1Str = new Date(d1).toISOString();
                const d2Str = new Date(d2).toISOString();
                return d1Str === d2Str;
            }
            ngModelController.$render = function () {
                const viewValue = ngModelController.$viewValue;
                scope.state.date = viewValue;
                setFormatted();
            };
            ngModelController.$render();
            scope.$watch('state.date', (newVal, oldVal) => {
                if (newVal === oldVal) {
                    return;
                }
                if (!sameDate(ngModelController.$viewValue, newVal)) {
                    const viewValue = newVal ? new Date(newVal).toISOString() : null;
                    ngModelController.$setViewValue(viewValue);
                    setFormatted();
                }
            });
            scope.setDate = function () {
                const dateFromString = DateParser.fromLocaleDateString(scope.state.formatted, locale, getDateOptions(withTime));
                if (dateFromString instanceof Date && !isNaN(dateFromString)) {
                    scope.state.date = dateFromString;
                }
            };
            scope.openModal = function () {
                datepickerModal
                    .open(scope.state.date, withTime)
                    .result
                    .then((result) => {
                    scope.state.date = new Date(result.date);
                });
            };
            scope.clearDate = function () {
                scope.state.date = null;
            };
        },
    };
}
