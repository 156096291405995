import ArrayUtils from '../../utils/ArrayUtils';
export var UserKind;
(function (UserKind) {
    UserKind["Forbidden"] = "forbidden";
    UserKind["Viewer"] = "viewer";
    UserKind["Contributor"] = "contributor";
    UserKind["Manager"] = "manager";
})(UserKind || (UserKind = {}));
/**
 * Implements [Board.WorkspacePermission] in board.fio
 */
class WorkspacePermissions {
    constructor(raw) {
        this.raw = raw;
    }
    static dress(raw) {
        if (raw.constructor && raw.constructor == this) {
            return raw;
        }
        return new WorkspacePermissions(raw);
    }
    userLevels(includeForbidden = false) {
        const allUserLevels = {
            forbidden: { code: 'forbidden', title: 'No access', canRead: false, canWrite: false, canManage: false, description: 'Can not access this board' },
            viewer: { code: 'viewer', title: 'Viewer', canRead: true, canWrite: false, canManage: false, description: 'See everything, but cannot add or edit' },
            contributor: { code: 'contributor', title: 'Contributor', canRead: true, canWrite: true, canManage: false, description: 'Can edit or add cards' },
            manager: { code: 'manager', title: 'Manager', canRead: true, canWrite: true, canManage: true, description: 'Can change board settings and invite people' },
        };
        if (includeForbidden) {
            return allUserLevels;
        }
        else {
            return Object.keys(allUserLevels)
                .filter(k => k != 'forbidden')
                .reduce((acc, key) => {
                acc[key] = allUserLevels[key];
                return acc;
            }, {});
        }
    }
    getLevelDetailsFor(perms) {
        if (perms && perms.canManage) {
            return this.userLevels().manager;
        }
        else if (perms && perms.canWrite) {
            return this.userLevels().contributor;
        }
        else if (perms && perms.canRead) {
            return this.userLevels().viewer;
        }
        else {
            return this.userLevels(true).forbidden;
        }
    }
    // Computes the highest level of permissions of a user given his
    // possible membership into many workspaces.
    static usedBy(workspacePermissions, user) {
        const orEmptyArray = (x) => { return x ? x : []; };
        const base = { canRead: true, canWrite: false, canManage: false };
        const workspaces = orEmptyArray(user.workspaces).map((t) => { return t.code; });
        const perms = orEmptyArray(workspacePermissions.raw).filter((tp) => {
            return workspaces.indexOf(tp.workspace) >= 0;
        });
        return perms.reduce((memo, tp) => {
            return {
                canRead: memo.canRead || tp.canRead,
                canWrite: memo.canWrite || tp.canWrite,
                canManage: memo.canManage || tp.canManage,
            };
        }, base);
    }
    getLevelDetailsForWorkspace(workspace) {
        const perms = this.raw.find((perm) => {
            return perm.workspace == workspace.code;
        });
        return this.getLevelDetailsFor(perms);
    }
    add(perm) {
        return new WorkspacePermissions(this.raw.concat(perm));
    }
    change(newPerm) {
        const wsp = ArrayUtils.replace(this.raw, (p) => {
            return p.workspace == newPerm.workspace;
        }, newPerm);
        return new WorkspacePermissions(wsp);
    }
    ensure(perms) {
        if (this.getWorkspaceCodes().indexOf(perms.workspace) == -1) {
            return this.add(perms);
        }
        else {
            return this.change(perms);
        }
    }
    getWorkspaceCodes() {
        return this.raw.map((w) => {
            return w.workspace;
        });
    }
    toRaw() {
        return this.raw;
    }
}
export default WorkspacePermissions;
