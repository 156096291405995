import { toCssClass } from '@klaro/corejs/views';
angular
    .module('klaro')
    .directive('storyGalleryFilters', storyGalleryFilters);
function storyGalleryFilters() {
    return {
        restrict: 'E',
        template: require('@/core/story/storyGalleryFilters.html'),
        require: '^board',
        link: function (scope, elm, attrs, board) {
            function getGalleryFilters() {
                const displayBy = board.getDimensionsAt('displayBy');
                if (displayBy.length !== 1) {
                    return;
                }
                scope.dimension = displayBy[0];
                const storyCounts = board.storyCounts();
                const values = scope.dimension.getValues().filter((v) => {
                    return storyCounts.getCounts(scope.dimension, v).all > 0;
                });
                scope.filters = [
                    { id: '__all__', label: 'All' },
                ].concat(values);
            }
            board.$watch('board', getGalleryFilters, scope);
            scope.cssClass = function (filter) {
                return toCssClass(filter.id);
            };
            scope.isSelected = function (filter) {
                if (!scope.dimension) {
                    return false;
                }
                if (filter.id === '__all__') {
                    return !board.hasFilter(scope.dimension);
                }
                else {
                    return board.isFilteredBy(scope.dimension, filter);
                }
            };
            scope.filterBy = function (dimValue) {
                if (dimValue.id === '__all__') {
                    board.removeDimensionFilters(scope.dimension);
                }
                else {
                    board.setFilter(scope.dimension, dimValue);
                }
            };
        },
    };
}
