angular
    .module('klaro')
    .service('boardSettingsModal', boardSettingsModal)
    .controller('BoardSettingsModalController', BoardSettingsModalController);
function boardSettingsModal(klaroModal, KernelClient, navigation) {
    let settingsModalIsOpen = false;
    let modalInstance;
    return {
        open: function (board, options, boardCtrl) {
            modalInstance = klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/board/boardSettingsModal.html'),
                controller: 'BoardSettingsModalController',
                windowClass: 'klaro-modal board-settings pane-modal',
                backdropClass: 'board-settings-backdrop',
                openedClass: 'board-settings-open',
                size: 'large',
                resolve: {
                    board: function () {
                        return board;
                    },
                    options: function () {
                        return options;
                    },
                    summaries: function () {
                        return KernelClient.eventually.summaries;
                    },
                    boardCtrl: function () {
                        return boardCtrl;
                    },
                },
            });
            modalInstance.opened.then(() => {
                settingsModalIsOpen = true;
                navigation.setHash('settings');
            });
            // we want to update state whether the modal closed or was dismissed,
            // so use finally to handle both resolved and rejected promises.
            modalInstance.result.finally(() => {
                settingsModalIsOpen = false;
            });
            return modalInstance;
        },
        isOpen: function () {
            return settingsModalIsOpen;
        },
        close: function () {
            return modalInstance.close();
        },
    };
}
function BoardSettingsModalController($scope, $uibModalInstance, navigation, board, options, summaries, boardCtrl, Patcher, $timeout) {
    $scope.defaultTab = options.tab || 'nav';
    $scope.modalInstance = $uibModalInstance;
    $scope.summaries = summaries;
    $scope.asNewBoard = options.asNewBoard || false;
    /// Title
    $scope.getBoardTitle = function () {
        return $scope.board && $scope.board.label;
    };
    /// Loading
    function load(board, isInitiallyDirty, success) {
        $scope.board = (options.asNewBoard) ? options.newBoard : board;
        $scope.boardData = angular.copy($scope.board.toBoardSaveRaw());
        $scope.boardData.anchors = $scope.boardData.anchors || {};
        $scope.boardData.anchors.displayBy = $scope.boardData.anchors.displayBy || [];
        $scope.boardCtrl = boardCtrl;
        $scope.working = false;
        $scope.error = null;
        $scope.success = (success === undefined ? 'Board saved' : success);
        $scope.warning = null;
        $scope.isInitiallyDirty = isInitiallyDirty;
        if ($scope.boardForm) {
            $scope.boardForm.$setPristine();
        }
        return board;
    }
    load(board, options && options.isDirty, false);
    // Counts
    function formatItemsCountLabel(items) {
        if (items) {
            const count = Object.keys(items).length;
            if (count > 0) {
                return `${count} filter${count > 1 ? 's' : ''}`;
            }
        }
    }
    $scope.filtersCount = function () {
        return formatItemsCountLabel($scope.boardData.filters);
    };
    $scope.requiredDimensionsCount = function () {
        return formatItemsCountLabel($scope.boardData.anchors.required);
    };
    // Navigation
    $scope.gotoDimensions = function () {
        $uibModalInstance.dismiss();
        $timeout(() => navigation.gotoUrl('/settings/dimensions/'));
    };
    // Board actions
    $scope.canManage = function () {
        return $scope.board.canManage();
    };
    $scope.close = function () {
        const action = 'updateBoard';
        $scope.commit({ action: action, boardData: $scope.boardData });
        navigation.clearHash();
    };
    $scope.save = function () {
        $scope.commit({ action: 'saveBoard', boardData: $scope.boardData });
    };
    $scope.saveNewBoard = function () {
        $scope.commit({ action: 'saveNewBoard', boardData: $scope.boardData });
    };
    $scope.reset = function () {
        $scope.commit({ action: 'resetAllChanges' });
    };
    $scope.delete = function () {
        $scope.commit({ action: 'deleteBoard' });
    };
    $scope.commit = function (result) {
        $scope.closing = true;
        $uibModalInstance.close(result);
    };
    $scope.keepExploring = function () {
        $scope.showDirtyCover = false;
    };
    $scope.$on('modal.closing', (event) => {
        if (!$scope.closing) {
            event.preventDefault();
            $scope.close();
        }
    });
    function isDirty() {
        function hasPatch() {
            const patch = Patcher.shallow($scope.board.toBoardSaveRaw(), $scope.boardData);
            return Object.keys(patch).length > 0;
        }
        return $scope.isInitiallyDirty || $scope.boardForm.$dirty || hasPatch();
    }
    $scope.isDirty = isDirty;
    $scope.isPending = false;
    $scope.trackPending = function (callback) {
        const oldPending = $scope.isPending;
        $scope.isPending = true;
        return callback().then(() => {
            $scope.isPending = oldPending;
        });
    };
    $scope.shouldDisplayAllSelectors = !$scope.asNewBoard;
    $scope.displayAllSelectors = function () {
        $scope.shouldDisplayAllSelectors = true;
    };
}
