const toFormData = (files) => {
    const formData = new FormData();
    files.forEach((file) => {
        const rawFile = new File([file.blob], file.name, {
            type: file.mimeType,
        });
        formData.append(file.name, rawFile, file.name);
    });
    return formData;
};
export class ApiSeshatConnector {
    constructor(client) {
        this.client = client;
    }
    async create(objects) {
        // TODO: I'm amused at the fact that the relative URL is working
        // but please, for the love of god, fix this
        const res = await this.client.api({
            method: 'post',
            url: '../s/',
            data: toFormData(objects),
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return res.data;
    }
}
