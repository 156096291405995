export const PlaygroundInstallers = [
    {
        'id': 'userDefined',
        'label': 'Track your own list',
        'group': 'Base dimensions',
        'ordering': 10100,
        'proposedName': null,
        'placeholder': 'Department, Size, Effort, ...',
        'supportsMultiple': true,
        'supportsRequired': true,
        'supportsTagging': true,
        'tagging': true,
        'datatype': 'UserDefined',
        'description': 'Allows tracking values from your own domain-specific list',
        'exampleValues': [],
        'valuesAtCreation': true,
        'installable': true
    },
    {
        'id': 'integer',
        'label': 'Track a Number',
        'group': 'Base dimensions',
        'ordering': 10200,
        'proposedName': null,
        'placeholder': 'Budget, Hours, Likes, ...',
        'supportsMultiple': false,
        'supportsRequired': true,
        'supportsTagging': true,
        'tagging': false,
        'datatype': 'Integer',
        'description': 'Track any business specific number.',
        'exampleValues': [
            {
                'id': 'negative',
                'label': 'Negative',
                'semantics': '-1000000000 .. 0',
                'color': '#202020',
                'ordering': 100000,
                'deprecated': false,
            },
            {
                'id': 'inFuture',
                'label': 'Positive',
                'semantics': '0 .. +1000000000',
                'color': '#ffffff',
                'ordering': 200000,
                'deprecated': false,
            }
        ],
        'valuesAtCreation': false,
        'installable': true
    },
    {
        'id': 'date',
        'label': 'Track a Date',
        'group': 'Base dimensions',
        'ordering': 10300,
        'proposedName': null,
        'placeholder': 'What date does it track?',
        'supportsMultiple': false,
        'supportsRequired': true,
        'supportsTagging': true,
        'tagging': false,
        'datatype': 'Date',
        'description': 'Track any business specific date.',
        'exampleValues': [
            {
                'id': 'inPast',
                'label': 'In past',
                'color': '#202020',
                'semantics': '-BOT',
                'ordering': 100000,
                'deprecated': false,
            },
            {
                'id': 'inFuture',
                'label': 'In future',
                'color': '#202020',
                'semantics': '+EOT',
                'ordering': 200000,
                'deprecated': false,
            }
        ],
        'valuesAtCreation': false,
        'installable': true
    },
    {
        'id': 'dateTime',
        'label': 'Track a Date & Time',
        'group': 'Base dimensions',
        'ordering': 10400,
        'proposedName': null,
        'placeholder': 'What date does it track?',
        'supportsMultiple': false,
        'supportsRequired': true,
        'supportsTagging': true,
        'tagging': false,
        'datatype': 'DateTime',
        'description': 'Track any business specific date and time.',
        'exampleValues': [
            {
                'id': 'inPast',
                'label': 'In past',
                'color': '#202020',
                'semantics': '-BOT',
                'ordering': 100000,
                'deprecated': false,
            },
            {
                'id': 'inFuture',
                'label': 'In future',
                'color': '#202020',
                'semantics': '+EOT',
                'ordering': 200000,
                'deprecated': false,
            }
        ],
        'valuesAtCreation': false,
        'installable': true
    },
    {
        'id': 'text',
        'label': 'Track a Free text',
        'group': 'Base dimensions',
        'ordering': 10500,
        'proposedName': null,
        'placeholder': 'Reference, ...',
        'supportsMultiple': false,
        'supportsRequired': true,
        'supportsTagging': true,
        'tagging': false,
        'datatype': 'Text',
        'description': 'Allows tracking short texts whose list is not known in advance',
        'exampleValues': [
            {
                'id': 'notEmpty',
                'label': 'Not empty',
                'semantics': '//',
                'color': '#202020',
                'ordering': 100000,
                'deprecated': false,
            }
        ],
        'valuesAtCreation': false,
        'installable': true
    },
    {
        'id': 'progress',
        'label': 'Track a Progress',
        'group': 'Base dimensions',
        'ordering': 10600,
        'proposedName': 'Progress',
        'placeholder': '',
        'supportsMultiple': false,
        'supportsRequired': true,
        'supportsTagging': true,
        'tagging': true,
        'datatype': 'Progress',
        'description': 'Track the status of each card within a workflow or process.',
        'exampleValues': [
            {
                'id': 'todo',
                'label': 'Todo',
                'color': '#e01514',
                'semantics': null,
                'ordering': 100000,
                'deprecated': false,
            },
            {
                'id': 'ongoing',
                'label': 'Ongoing',
                'color': '#f77d00',
                'semantics': null,
                'ordering': 200000,
                'deprecated': false,
            },
            {
                'id': 'done',
                'label': 'Done',
                'color': '#00b048',
                'semantics': null,
                'ordering': 300000,
                'deprecated': false,
            }
        ],
        'valuesAtCreation': true,
        'installable': true
    },
    {
        'id': 'projectMember',
        'label': 'Track a Member',
        'group': 'Base dimensions',
        'ordering': 10700,
        'proposedName': null,
        'placeholder': 'Assignee, Reporter, Reviewer, ...',
        'supportsMultiple': true,
        'supportsRequired': true,
        'supportsTagging': true,
        'tagging': true,
        'datatype': 'ProjectMember',
        'description': 'Track members of your project on your cards.',
        'exampleValues': [],
        'valuesAtCreation': true,
        'installable': true
    }
];
