export default class Refresher {
    constructor(provider, reloader, promiser = Promise) {
        this.provider = provider;
        this.reloader = reloader;
        this.promiser = promiser;
    }
    get() {
        if (this.refreshing) {
            return this.refreshing;
        }
        else {
            return this.promiser.resolve(this.provider());
        }
    }
    refresh() {
        if (!this.refreshing) {
            const r = this.reloader();
            this.refreshing = r.then(() => {
                this.refreshing = null;
                return this.get();
            });
        }
        return this.refreshing;
    }
}
