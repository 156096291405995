import { v4 } from 'uuid';
import { ApiStoriesConnector } from '../api/ApiStoriesConnector';
export class LocalStoriesConnector extends ApiStoriesConnector {
    constructor(client, data, baseUrl) {
        super(client, baseUrl);
        this.client = client;
        this.data = data;
    }
    async get(params) {
        const stories = await super.get(params);
        await this.data.stories.addOriginals(stories);
        return this.data.stories.values();
    }
    async delete(params) {
        params.stories.forEach((s) => {
            this.data.stories.delete(s.id);
        });
    }
    async patch(patch) {
        return await this.data.stories.store(patch.patch);
    }
    async post(story) {
        return this.create([story]);
    }
    async postMany(stories) {
        const toCreate = stories.map(s => {
            s.id = s.id || v4();
            return s;
        });
        return await this.data.stories.store(toCreate);
    }
    async create(stories) {
        const toCreate = stories.map((s) => {
            s.id = s.id || v4();
            return s;
        });
        const saved = await this.data.stories.store(toCreate);
        return saved[0];
    }
    single(id) {
        const parentSingle = super.single(id);
        const data = this.data;
        return {
            cover: {
                async set() {
                    throw new Error('LocalStoriesCoverConnector not implemented.');
                },
                async unset() {
                    throw new Error('LocalStoriesCoverConnector not implemented.');
                }
            },
            async get(params) {
                const fullStory = await parentSingle.get(params);
                data.stories.addOriginals([fullStory]);
                return data.stories.get(fullStory.id);
            },
            async patch(patch) {
                await data.stories.store([patch]);
                return data.stories.get(patch.id);
            },
            delete(_params) {
                throw new Error('LocalStoriesConnector.single#delete() not implemented.');
            }
        };
    }
    search(_filters) {
        throw new Error('LocalStoriesConnector#search() not implemented.');
    }
}
