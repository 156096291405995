import Stories from '../../model/Board/Stories';
import { Patcher, equals } from '../../utils';
import AbstractBoardListener from '../AbstractBoardListener';
const smartStoriesLimit = (state) => {
    const board = state.board;
    const dimsCount = 1 + board.getCardDimensions().length;
    return (1000 / dimsCount).toFixed();
};
export default class BoardStoriesListener extends AbstractBoardListener {
    constructor(loader) {
        super();
        this.loader = loader;
        this.prefetch = null;
    }
    prepare(canPrefetch) {
        if (!canPrefetch) {
            return;
        }
        this.prefetch = this.fetch({});
    }
    stateChanged(subject, newState, oldState) {
        // Case 1: original loading, stories have never been
        // loaded yet, load them and keep the board predicate
        // as current stories collection predicate.
        if (!oldState || !newState.stories || !newState.storiesPredicate) {
            return this.loadStories(newState).then((stories) => {
                return newState.clone({
                    stories: stories,
                    storiesPredicate: newState.board.getFilters(),
                });
            });
        }
        // Case 2: the viewAs workspace selector has been used, so that
        // a different space might have been selected. We must reload...
        const oldViewAs = oldState.viewAs && oldState.viewAs.id;
        const newViewAs = newState.viewAs && newState.viewAs.id;
        const mustReloadBecauseOfViewAs = (oldViewAs !== newViewAs);
        // Case 2': check whether the current board predicate actually implies
        // the stories collection predicate.
        // If it does not, a reload is required since some stories meeting
        // the current board predicate might not be present in the browser.
        const dimensions = oldState.board.getDimensions();
        const storiesPredicate = newState.storiesPredicate;
        const boardPredicate = newState.board.getFilters();
        const mustReloadBecauseOfFilters = !boardPredicate.implies(storiesPredicate, dimensions);
        // Case 2'': the current stories have been limited by the backend and the
        // predicate changed. We must reload.
        const predicateChanged = !oldState.board.getFilters().equiv(newState.board.getFilters());
        const mustReloadBecauseOfLimited = oldState.stories.partialResults() && predicateChanged;
        // Case 3: search changed on a limited board
        const searchChanged = oldState.board.search !== newState.board.search;
        const mustReloadBecauseOfSearch = oldState.stories.partialResults() && searchChanged;
        // Case 4: info depth has changed
        const infoDepthChanged = oldState.infoDepth !== newState.infoDepth;
        const mustReloadBecauseOfInfoDepth = infoDepthChanged;
        // Case 5: ordering has changed on a limited board
        const oldOrderingOverride = this.orderingOverrideParams(oldState);
        const newOrderingOverride = this.orderingOverrideParams(newState);
        const mustReloadBecauseOfOrdering = oldState.stories.partialResults()
            && !equals(oldOrderingOverride, newOrderingOverride);
        // Case 6: page size has changed
        const pageSizeChanged = oldState.board.pageSize !== newState.board.pageSize;
        const mustReloadBecauseOfPageSizeChanged = pageSizeChanged;
        const mustReload = mustReloadBecauseOfViewAs
            || mustReloadBecauseOfFilters
            || mustReloadBecauseOfLimited
            || mustReloadBecauseOfSearch
            || mustReloadBecauseOfInfoDepth
            || mustReloadBecauseOfOrdering
            || mustReloadBecauseOfPageSizeChanged;
        if (mustReload) {
            // The diff here defines the predicate override to send to the
            // backend to load all stories corresponding to the current
            // predicate
            // Reload the stories with that overrides and save the current board
            // predicate as new stories predicate for the next round.
            return this.loadStories(newState).then((stories) => {
                return newState.clone({
                    stories: stories,
                    storiesPredicate: boardPredicate,
                });
            });
        }
        // Case 3: filtering can actually be made on the currently loaded
        // stories, but the filtered stories or they order might have
        // changed => simply reset the collection, its predicate does not
        // change.
        const mustReset = this.mustReset(newState, oldState);
        if (mustReset) {
            return new Promise((resolve) => {
                resolve(newState.clone({
                    stories: newState.stories.clone({
                        board: newState.board,
                        limitTo: smartStoriesLimit(newState),
                    }, false),
                }));
            });
        }
        // Case 4: no change to anything related to filtered stories.
        // Let simply keep the new state unchanged
        return new Promise((resolve) => {
            resolve(newState.clone({
                stories: newState.stories.clone({
                    board: newState.board,
                }, true),
            }));
        });
    }
    // Whether the stories must be reset
    mustReset(newState, oldState) {
        const keyOf = (s) => {
            return {
                search: s.board.search,
                ordering: s.board.getOrderingAnchor().toList(),
                filters: s.board.getFilters().toCnf(),
            };
        };
        const oldKey = keyOf(oldState);
        const newKey = keyOf(newState);
        const patch = Patcher.shallow(oldKey, newKey);
        return Object.keys(patch).length > 0;
    }
    loadStories(state) {
        const params = this.fetchParams(state);
        return this.fetch(params, state.infoDepth).then((ss) => {
            return Stories.dress(ss, {
                board: state.board,
                globalContext: {
                    requester: state.user,
                    board: state.board,
                },
            }).withLimitTo(smartStoriesLimit(state));
        });
    }
    fetch(params = {}, infoDepth = undefined) {
        infoDepth = infoDepth || 'short';
        if (this.prefetch && Object.keys(params).length === 0) {
            const p = this.prefetch;
            this.prefetch = null;
            return p;
        }
        else {
            return this.loader.get({
                headers: {
                    'Accept': `application/vnd+klaro.stories.${infoDepth}+json`,
                },
                params: params || {},
            });
        }
    }
    // Tools to convert a state for query params
    fetchParams(state) {
        const filters = this.filtersOverrideParams(state);
        const ordering = this.orderingOverrideParams(state);
        const search = this.searchParams(state);
        const pageSize = this.toPageSizeParams(state);
        return {
            ...filters,
            ...ordering,
            ...search,
            ...pageSize,
        };
    }
    filtersOverrideParams(state) {
        return state.filtersOverride().toQueryParams();
    }
    orderingOverrideParams(state) {
        const boardOrdering = state.original.getOrderingAnchor();
        const boardCurOrdering = state.board.getOrderingAnchor();
        if (!boardCurOrdering.isEqual(boardOrdering)) {
            return boardCurOrdering.toQueryParams();
        }
        else {
            return {};
        }
    }
    toPageSizeParams(state) {
        if (state.board.pageSize) {
            return { _pageSize: state.board.pageSize };
        }
        else {
            return {};
        }
    }
    searchParams(state) {
        if (state.board.search) {
            return { _q: state.board.search };
        }
        else {
            return {};
        }
    }
}
