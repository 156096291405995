angular
    .module('klaro')
    .directive('boardSearch', boardSearch);
function boardSearch(navigation, events, $timeout) {
    return {
        restrict: 'E',
        template: require('@/core/board/boardSearch.html'),
        require: '^board',
        scope: {
            onFocus: '&',
        },
        link: function (scope, elm, attrs, board) {
            scope.placeholder = attrs.placeholder || 'Search board...';
            scope.search = {
                text: board && board.getSearch(),
            };
            scope.$watch('search.text', (newS, oldS) => {
                // to avoid first time, where both are undefined...
                if (newS === oldS) {
                    return;
                }
                board.setSearch(scope.search.text);
            });
            scope.searchEnter = function () {
                if (board.getBoardState().stories.visible.length === 1) {
                    const story = board.getBoardState().stories.visible[0];
                    board
                        .openFullEditStoryModal(story.identifier);
                }
            };
            scope.expandToAll = function () {
                navigation.gotoUrl('/dashboard', { _q: scope.search.text });
            };
            scope.searchOptionsOpen = false;
            scope.toggleSearchOptions = function () {
                scope.searchOptionsOpen = !scope.searchOptionsOpen;
            };
            scope.closeSearchOptions = function () {
                scope.searchOptionsOpen = false;
            };
            events.observeElement(scope, 'click', (event) => {
                const myself = event.target.closest('.board-search');
                if (myself) {
                    return;
                }
                $timeout(() => scope.$apply(scope.closeSearchOptions));
            }, document);
        },
    };
}
