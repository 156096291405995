export default {
    'af-na': 'Afrikaans (Namibia)',
    'af-za': 'Afrikaans (South Africa)',
    'af': 'Afrikaans',
    'ak-gh': 'Akan (Ghana)',
    'ak': 'Akan',
    'sq-al': 'Albanian (Albania)',
    'sq': 'Albanian',
    'am-et': 'Amharic (Ethiopia)',
    'am': 'Amharic',
    'ar-dz': 'Arabic (Algeria)',
    'ar-bh': 'Arabic (Bahrain)',
    'ar-eg': 'Arabic (Egypt)',
    'ar-iq': 'Arabic (Iraq)',
    'ar-jo': 'Arabic (Jordan)',
    'ar-kw': 'Arabic (Kuwait)',
    'ar-lb': 'Arabic (Lebanon)',
    'ar-ly': 'Arabic (Libya)',
    'ar-ma': 'Arabic (Morocco)',
    'ar-om': 'Arabic (Oman)',
    'ar-qa': 'Arabic (Qatar)',
    'ar-sa': 'Arabic (Saudi Arabia)',
    'ar-sd': 'Arabic (Sudan)',
    'ar-sy': 'Arabic (Syria)',
    'ar-tn': 'Arabic (Tunisia)',
    'ar-ae': 'Arabic (United Arab Emirates)',
    'ar-ye': 'Arabic (Yemen)',
    'ar': 'Arabic',
    'hy-am': 'Armenian (Armenia)',
    'hy': 'Armenian',
    'as-in': 'Assamese (India)',
    'as': 'Assamese',
    'asa-tz': 'Asu (Tanzania)',
    'asa': 'Asu',
    'az-cyrl': 'Azerbaijani (Cyrillic)',
    'az-cyrl-az': 'Azerbaijani (Cyrillic, Azerbaijan)',
    'az-latn': 'Azerbaijani (Latin)',
    'az-latn-az': 'Azerbaijani (Latin, Azerbaijan)',
    'az': 'Azerbaijani',
    'bm-ml': 'Bambara (Mali)',
    'bm': 'Bambara',
    'eu-es': 'Basque (Spain)',
    'eu': 'Basque',
    'be-by': 'Belarusian (Belarus)',
    'be': 'Belarusian',
    'bem-zm': 'Bemba (Zambia)',
    'bem': 'Bemba',
    'bez-tz': 'Bena (Tanzania)',
    'bez': 'Bena',
    'bn-bd': 'Bengali (Bangladesh)',
    'bn-in': 'Bengali (India)',
    'bn': 'Bengali',
    'bs': 'Bosnian',
    'bg-bg': 'Bulgarian (Bulgaria)',
    'bg': 'Bulgarian',
    'my-mm': 'Burmese (Myanmar [Burma])',
    'my': 'Burmese',
    'ca-es': 'Catalan (Spain)',
    'ca': 'Catalan',
    'tzm': 'Central Morocco Tamazight',
    'chr-us': 'Cherokee (United States)',
    'chr': 'Cherokee',
    'cgg-ug': 'Chiga (Uganda)',
    'cgg': 'Chiga',
    'zh-hans': 'Chinese (Simplified Han)',
    'zh-hans-cn': 'Chinese (Simplified Han, China)',
    'zh-hans-hk': 'Chinese (Simplified Han, Hong Kong SAR China)',
    'zh-hans-mo': 'Chinese (Simplified Han, Macau SAR China)',
    'zh-hans-sg': 'Chinese (Simplified Han, Singapore)',
    'zh-hant': 'Chinese (Traditional Han)',
    'zh-hant-hk': 'Chinese (Traditional Han, Hong Kong SAR China)',
    'zh-hant-mo': 'Chinese (Traditional Han, Macau SAR China)',
    'zh-hant-tw': 'Chinese (Traditional Han, Taiwan)',
    'zh': 'Chinese',
    'kw-gb': 'Cornish (United Kingdom)',
    'kw': 'Cornish',
    'hr-hr': 'Croatian (Croatia)',
    'hr': 'Croatian',
    'cs-cz': 'Czech (Czech Republic)',
    'cs': 'Czech',
    'da-dk': 'Danish (Denmark)',
    'da': 'Danish',
    'nl-be': 'Dutch (Belgium)',
    'nl-nl': 'Dutch (Netherlands)',
    'nl': 'Dutch',
    'ebu-ke': 'Embu (Kenya)',
    'ebu': 'Embu',
    'en-as': 'English (American Samoa)',
    'en-au': 'English (Australia)',
    'en-be': 'English (Belgium)',
    'en-bz': 'English (Belize)',
    'en-bw': 'English (Botswana)',
    'en-ca': 'English (Canada)',
    'en-gu': 'English (Guam)',
    'en-hk': 'English (Hong Kong SAR China)',
    'en-in': 'English (India)',
    'en-ie': 'English (Ireland)',
    'en-il': 'English (Israel)',
    'en-jm': 'English (Jamaica)',
    'en-mt': 'English (Malta)',
    'en-mh': 'English (Marshall Islands)',
    'en-mu': 'English (Mauritius)',
    'en-na': 'English (Namibia)',
    'en-nz': 'English (New Zealand)',
    'en-mp': 'English (Northern Mariana Islands)',
    'en-pk': 'English (Pakistan)',
    'en-ph': 'English (Philippines)',
    'en-sg': 'English (Singapore)',
    'en-za': 'English (South Africa)',
    'en-tt': 'English (Trinidad and Tobago)',
    'en-um': 'English (U.S. Minor Outlying Islands)',
    'en-vi': 'English (U.S. Virgin Islands)',
    'en-gb': 'English (United Kingdom)',
    'en-us': 'English (United States)',
    'en-zw': 'English (Zimbabwe)',
    'en': 'English',
    'eo': 'Esperanto',
    'et-ee': 'Estonian (Estonia)',
    'et': 'Estonian',
    'ee-gh': 'Ewe (Ghana)',
    'ee-tg': 'Ewe (Togo)',
    'ee': 'Ewe',
    'fo-fo': 'Faroese (Faroe Islands)',
    'fo': 'Faroese',
    'fil-ph': 'Filipino (Philippines)',
    'fil': 'Filipino',
    'fi-fi': 'Finnish (Finland)',
    'fi': 'Finnish',
    'fr-be': 'French (Belgium)',
    'fr-bj': 'French (Benin)',
    'fr-bf': 'French (Burkina Faso)',
    'fr-bi': 'French (Burundi)',
    'fr-cm': 'French (Cameroon)',
    'fr-ca': 'French (Canada)',
    'fr-cf': 'French (Central African Republic)',
    'fr-td': 'French (Chad)',
    'fr-km': 'French (Comoros)',
    'fr-cg': 'French (Congo - Brazzaville)',
    'fr-cd': 'French (Congo - Kinshasa)',
    'fr-ci': 'French (Côte d’Ivoire)',
    'fr-dj': 'French (Djibouti)',
    'fr-gq': 'French (Equatorial Guinea)',
    'fr-fr': 'French (France)',
    'fr-ga': 'French (Gabon)',
    'fr-gp': 'French (Guadeloupe)',
    'fr-gn': 'French (Guinea)',
    'fr-lu': 'French (Luxembourg)',
    'fr-mg': 'French (Madagascar)',
    'fr-ml': 'French (Mali)',
    'fr-mq': 'French (Martinique)',
    'fr-mc': 'French (Monaco)',
    'fr-ne': 'French (Niger)',
    'fr-rw': 'French (Rwanda)',
    'fr-re': 'French (Réunion)',
    'fr-bl': 'French (Saint Barthélemy)',
    'fr-mf': 'French (Saint Martin)',
    'fr-sn': 'French (Senegal)',
    'fr-ch': 'French (Switzerland)',
    'fr-tg': 'French (Togo)',
    'fr': 'French',
    'ff-sn': 'Fulah (Senegal)',
    'ff': 'Fulah',
    'gl-es': 'Galician (Spain)',
    'gl': 'Galician',
    'lg-ug': 'Ganda (Uganda)',
    'lg': 'Ganda',
    'ka-ge': 'Georgian (Georgia)',
    'ka': 'Georgian',
    'de-at': 'German (Austria)',
    'de-be': 'German (Belgium)',
    'de-de': 'German (Germany)',
    'de-li': 'German (Liechtenstein)',
    'de-lu': 'German (Luxembourg)',
    'de-ch': 'German (Switzerland)',
    'de': 'German',
    'el-cy': 'Greek (Cyprus)',
    'el-gr': 'Greek (Greece)',
    'el': 'Greek',
    'gu-in': 'Gujarati (India)',
    'gu': 'Gujarati',
    'guz-ke': 'Gusii (Kenya)',
    'guz': 'Gusii',
    'ha': 'Hausa',
    'haw-us': 'Hawaiian (United States)',
    'haw': 'Hawaiian',
    'he-il': 'Hebrew (Israel)',
    'he': 'Hebrew',
    'hi-in': 'Hindi (India)',
    'hi': 'Hindi',
    'hu-hu': 'Hungarian (Hungary)',
    'hu': 'Hungarian',
    'is-is': 'Icelandic (Iceland)',
    'is': 'Icelandic',
    'ig-ng': 'Igbo (Nigeria)',
    'ig': 'Igbo',
    'id-id': 'Indonesian (Indonesia)',
    'id': 'Indonesian',
    'ga-ie': 'Irish (Ireland)',
    'ga': 'Irish',
    'it-it': 'Italian (Italy)',
    'it-ch': 'Italian (Switzerland)',
    'it': 'Italian',
    'ja-jp': 'Japanese (Japan)',
    'ja': 'Japanese',
    'kea-cv': 'Kabuverdianu (Cape Verde)',
    'kea': 'Kabuverdianu',
    'kab-dz': 'Kabyle (Algeria)',
    'kab': 'Kabyle',
    'kl-gl': 'Kalaallisut (Greenland)',
    'kl': 'Kalaallisut',
    'kln-ke': 'Kalenjin (Kenya)',
    'kln': 'Kalenjin',
    'kam-ke': 'Kamba (Kenya)',
    'kam': 'Kamba',
    'kn-in': 'Kannada (India)',
    'kn': 'Kannada',
    'kk': 'Kazakh',
    'km-kh': 'Khmer (Cambodia)',
    'km': 'Khmer',
    'ki-ke': 'Kikuyu (Kenya)',
    'ki': 'Kikuyu',
    'rw-rw': 'Kinyarwanda (Rwanda)',
    'rw': 'Kinyarwanda',
    'kok-in': 'Konkani (India)',
    'kok': 'Konkani',
    'ko-kr': 'Korean (South Korea)',
    'ko': 'Korean',
    'khq-ml': 'Koyra Chiini (Mali)',
    'khq': 'Koyra Chiini',
    'ses-ml': 'Koyraboro Senni (Mali)',
    'ses': 'Koyraboro Senni',
    'lag-tz': 'Langi (Tanzania)',
    'lag': 'Langi',
    'lv-lv': 'Latvian (Latvia)',
    'lv': 'Latvian',
    'lt-lt': 'Lithuanian (Lithuania)',
    'lt': 'Lithuanian',
    'luo-ke': 'Luo (Kenya)',
    'luo': 'Luo',
    'luy-ke': 'Luyia (Kenya)',
    'luy': 'Luyia',
    'mk-mk': 'Macedonian (Macedonia)',
    'mk': 'Macedonian',
    'jmc-tz': 'Machame (Tanzania)',
    'jmc': 'Machame',
    'kde-tz': 'Makonde (Tanzania)',
    'kde': 'Makonde',
    'mg-mg': 'Malagasy (Madagascar)',
    'mg': 'Malagasy',
    'ms-bn': 'Malay (Brunei)',
    'ms-my': 'Malay (Malaysia)',
    'ms': 'Malay',
    'ml-in': 'Malayalam (India)',
    'ml': 'Malayalam',
    'mt-mt': 'Maltese (Malta)',
    'mt': 'Maltese',
    'gv': 'Manx',
    'mr-in': 'Marathi (India)',
    'mr': 'Marathi',
    'mas-ke': 'Masai (Kenya)',
    'mas-tz': 'Masai (Tanzania)',
    'mas': 'Masai',
    'mer-ke': 'Meru (Kenya)',
    'mer': 'Meru',
    'mfe-mu': 'Morisyen (Mauritius)',
    'mfe': 'Morisyen',
    'naq-na': 'Nama (Namibia)',
    'naq': 'Nama',
    'ne-in': 'Nepali (India)',
    'ne-np': 'Nepali (Nepal)',
    'ne': 'Nepali',
    'nd-zw': 'North Ndebele (Zimbabwe)',
    'nd': 'North Ndebele',
    'nb-no': 'Norwegian Bokmål (Norway)',
    'nb': 'Norwegian Bokmål',
    'nn-no': 'Norwegian Nynorsk (Norway)',
    'nn': 'Norwegian Nynorsk',
    'nyn-ug': 'Nyankole (Uganda)',
    'nyn': 'Nyankole',
    'or-in': 'Oriya (India)',
    'or': 'Oriya',
    'om-et': 'Oromo (Ethiopia)',
    'om-ke': 'Oromo (Kenya)',
    'om': 'Oromo',
    'ps-af': 'Pashto (Afghanistan)',
    'ps': 'Pashto',
    'fa-af': 'Persian (Afghanistan)',
    'fa-ir': 'Persian (Iran)',
    'fa': 'Persian',
    'pl-pl': 'Polish (Poland)',
    'pl': 'Polish',
    'pt-br': 'Portuguese (Brazil)',
    'pt-gw': 'Portuguese (Guinea-Bissau)',
    'pt-mz': 'Portuguese (Mozambique)',
    'pt-pt': 'Portuguese (Portugal)',
    'pt': 'Portuguese',
    'pa-arab': 'Punjabi (Arabic)',
    'pa-arab-pk': 'Punjabi (Arabic, Pakistan)',
    'pa-guru': 'Punjabi (Gurmukhi)',
    'pa-guru-in': 'Punjabi (Gurmukhi, India)',
    'pa': 'Punjabi',
    'ro-md': 'Romanian (Moldova)',
    'ro-ro': 'Romanian (Romania)',
    'ro': 'Romanian',
    'rm-ch': 'Romansh (Switzerland)',
    'rm': 'Romansh',
    'rof-tz': 'Rombo (Tanzania)',
    'rof': 'Rombo',
    'ru-md': 'Russian (Moldova)',
    'ru-ru': 'Russian (Russia)',
    'ru-ua': 'Russian (Ukraine)',
    'ru': 'Russian',
    'rwk-tz': 'Rwa (Tanzania)',
    'rwk': 'Rwa',
    'saq-ke': 'Samburu (Kenya)',
    'saq': 'Samburu',
    'sg-cf': 'Sango (Central African Republic)',
    'sg': 'Sango',
    'seh-mz': 'Sena (Mozambique)',
    'seh': 'Sena',
    'sr-cyrl': 'Serbian (Cyrillic)',
    'sr-cyrl-ba': 'Serbian (Cyrillic, Bosnia and Herzegovina)',
    'sr-cyrl-me': 'Serbian (Cyrillic, Montenegro)',
    'sr-cyrl-rs': 'Serbian (Cyrillic, Serbia)',
    'sr-latn': 'Serbian (Latin)',
    'sr-latn-ba': 'Serbian (Latin, Bosnia and Herzegovina)',
    'sr-latn-me': 'Serbian (Latin, Montenegro)',
    'sr-latn-rs': 'Serbian (Latin, Serbia)',
    'sr': 'Serbian',
    'sn-zw': 'Shona (Zimbabwe)',
    'sn': 'Shona',
    'ii-cn': 'Sichuan Yi (China)',
    'ii': 'Sichuan Yi',
    'si-lk': 'Sinhala (Sri Lanka)',
    'si': 'Sinhala',
    'sk-sk': 'Slovak (Slovakia)',
    'sk': 'Slovak',
    'sl-si': 'Slovenian (Slovenia)',
    'sl': 'Slovenian',
    'xog-ug': 'Soga (Uganda)',
    'xog': 'Soga',
    'so-dj': 'Somali (Djibouti)',
    'so-et': 'Somali (Ethiopia)',
    'so-ke': 'Somali (Kenya)',
    'so-so': 'Somali (Somalia)',
    'so': 'Somali',
    'es-ar': 'Spanish (Argentina)',
    'es-bo': 'Spanish (Bolivia)',
    'es-cl': 'Spanish (Chile)',
    'es-co': 'Spanish (Colombia)',
    'es-cr': 'Spanish (Costa Rica)',
    'es-do': 'Spanish (Dominican Republic)',
    'es-ec': 'Spanish (Ecuador)',
    'es-sv': 'Spanish (El Salvador)',
    'es-gq': 'Spanish (Equatorial Guinea)',
    'es-gt': 'Spanish (Guatemala)',
    'es-hn': 'Spanish (Honduras)',
    'es-419': 'Spanish (Latin America)',
    'es-mx': 'Spanish (Mexico)',
    'es-ni': 'Spanish (Nicaragua)',
    'es-pa': 'Spanish (Panama)',
    'es-py': 'Spanish (Paraguay)',
    'es-pe': 'Spanish (Peru)',
    'es-pr': 'Spanish (Puerto Rico)',
    'es-es': 'Spanish (Spain)',
    'es-us': 'Spanish (United States)',
    'es-uy': 'Spanish (Uruguay)',
    'es-ve': 'Spanish (Venezuela)',
    'es': 'Spanish',
    'sw-ke': 'Swahili (Kenya)',
    'sw-tz': 'Swahili (Tanzania)',
    'sw': 'Swahili',
    'sv-fi': 'Swedish (Finland)',
    'sv-se': 'Swedish (Sweden)',
    'sv': 'Swedish',
    'gsw-ch': 'Swiss German (Switzerland)',
    'gsw': 'Swiss German',
    'shi-latn': 'Tachelhit (Latin)',
    'shi-latn-ma': 'Tachelhit (Latin, Morocco)',
    'shi-tfng': 'Tachelhit (Tifinagh)',
    'shi-tfng-ma': 'Tachelhit (Tifinagh, Morocco)',
    'shi': 'Tachelhit',
    'dav-ke': 'Taita (Kenya)',
    'dav': 'Taita',
    'ta-in': 'Tamil (India)',
    'ta-lk': 'Tamil (Sri Lanka)',
    'ta': 'Tamil',
    'te-in': 'Telugu (India)',
    'te': 'Telugu',
    'teo-ke': 'Teso (Kenya)',
    'teo-ug': 'Teso (Uganda)',
    'teo': 'Teso',
    'th-th': 'Thai (Thailand)',
    'th': 'Thai',
    'bo-cn': 'Tibetan (China)',
    'bo-in': 'Tibetan (India)',
    'bo': 'Tibetan',
    'ti-er': 'Tigrinya (Eritrea)',
    'ti-et': 'Tigrinya (Ethiopia)',
    'ti': 'Tigrinya',
    'to-to': 'Tonga (Tonga)',
    'to': 'Tonga',
    'tr-tr': 'Turkish (Turkey)',
    'tr': 'Turkish',
    'uk-ua': 'Ukrainian (Ukraine)',
    'uk': 'Ukrainian',
    'ur-in': 'Urdu (India)',
    'ur-pk': 'Urdu (Pakistan)',
    'ur': 'Urdu',
    'uz-arab': 'Uzbek (Arabic)',
    'uz-arab-af': 'Uzbek (Arabic, Afghanistan)',
    'uz-cyrl': 'Uzbek (Cyrillic)',
    'uz-cyrl-uz': 'Uzbek (Cyrillic, Uzbekistan)',
    'uz-latn': 'Uzbek (Latin)',
    'uz-latn-uz': 'Uzbek (Latin, Uzbekistan)',
    'uz': 'Uzbek',
    'vi-vn': 'Vietnamese (Vietnam)',
    'vi': 'Vietnamese',
    'vun-tz': 'Vunjo (Tanzania)',
    'vun': 'Vunjo',
    'cy-gb': 'Welsh (United Kingdom)',
    'cy': 'Welsh',
    'yo-ng': 'Yoruba (Nigeria)',
    'yo': 'Yoruba',
    'zu-za': 'Zulu (South Africa)',
    'zu': 'Zulu',
};
