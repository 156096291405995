import { openDecks, toggleDecks } from '@klaro/corejs/state';
angular
    .module('klaro')
    .directive('boardDecksToggle', decksToggle);
function decksToggle(hotkeys) {
    return {
        template: require('@/core/board/decksToggle.html'),
        require: '^board',
        link: function (scope, elm, attrs, boardCtrl) {
            boardCtrl.$watch('board', (bs) => {
                if (!bs) {
                    return;
                }
                scope.tooltipText = bs.decksOpen ? 'Hide filters' : 'Open filters';
            }, scope);
            scope.openDecks = function () {
                boardCtrl.applyStateAction((s) => openDecks(s));
            };
            scope.toggleDecks = function () {
                boardCtrl.applyStateAction((s) => toggleDecks(s));
            };
            hotkeys.bindTo(scope)
                .add({
                combo: 'f',
                description: 'Toggle filters',
                callback: function () {
                    scope.toggleDecks();
                },
            });
        },
    };
}
