import Dimension from '../Dimension';
class Kind extends Dimension {
    get category() {
        return 'kind';
    }
    static dress(raw) {
        if (raw.constructor && raw.constructor == this) {
            return raw;
        }
        return new Kind(raw);
    }
    isUserDefinedSemantics() {
        return false;
    }
    usesSemantics() {
        return !!this.semanticsType;
    }
    withAppearanceFor(kindId, appearance) {
        const oldValue = this.getValueById(kindId);
        const newValue = Object.assign({}, oldValue, appearance);
        return this.withChangedValue(newValue, oldValue);
    }
    /**
     * Return an instance of this dimension with values
     * filtered to only those relevant for a given dimension.
     */
    withRelevantValuesFor(dim, withNull = false) {
        const rv = dim.relevantKinds;
        if (!rv || rv.length == 0) {
            return withNull ? this : this.clone({ values: this.getValues(withNull) });
        }
        return this.clone({
            values: this.getValues(withNull).filter((val) => {
                return rv.indexOf(val.id) >= 0;
            }),
        });
    }
    suppportsPlaceholder() {
        return true;
    }
}
export default Kind;
