//
// Copy-pasted from https://github.com/hiebj/ng-focus-if,
// distributed under MIT licence
//
(function () {
    'use strict';
    angular
        .module('focus-if', [])
        .directive('focusIf', focusIf);
    function focusIf($timeout) {
        function link($scope, $element, $attrs) {
            const dom = $element[0];
            if ($attrs.focusIf) {
                $scope.$watch($attrs.focusIf, focus);
            }
            else {
                focus(true);
            }
            function focus(condition) {
                if (condition) {
                    $timeout(() => {
                        dom.focus();
                    }, $scope.$eval($attrs.focusDelay) || 100);
                }
            }
        }
        return {
            restrict: 'A',
            link: link,
        };
    }
})();
