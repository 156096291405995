import { Story } from '../../../model';
import { SetBoardState } from '../Board';
export class CreateStory {
    constructor() {
        this.preconditions = [
            async (client) => {
                return !!client.store.board?.board.id && !!client.store.board?.stories;
            }
        ];
    }
    async run(client, args) {
        const boardState = client.store.board;
        const board = boardState.board;
        const { connector } = client.store;
        const dataToSend = this.getStoryDataToSend(args.story);
        const endpoint = board.isAllCards() ? connector.stories : connector.boards.single(boardState.board.id).stories;
        const storyRaw = await endpoint.post(dataToSend);
        const story = Story.dress(storyRaw, {
            dimensions: board.dimensions
        });
        const newBoard = await client.store.board?.storyAdded(story);
        client.run(new SetBoardState(), { board: newBoard });
        this._result = story;
    }
    get result() {
        return this._result;
    }
    getStoryDataToSend(story) {
        const data = story.toRaw();
        delete data._links;
        delete data.linked;
        delete data.q;
        delete data._q;
        return data;
    }
}
;
