import { Story } from '../../../model';
export class ApiPinnedStoriesConnector {
    constructor(client) {
        this.client = client;
    }
    async get() {
        const res = await this.client.api.get('/my/stories/pinned/');
        return res.data.map(s => Story.dress(s, { dimensions: this.client.store.board?.dimensions }));
    }
    async pin(stories) {
        return await this.client.api.post('/my/stories/pinned/actions/pin/', {
            stories: stories.map(s => ({ id: s.id }))
        });
    }
    async unpin(stories) {
        return await this.client.api.post('/my/stories/pinned/actions/unpin/', {
            stories: stories.map(s => ({ id: s.id }))
        });
    }
    get actions() {
        return {
            pin: (stories) => this.pin(stories),
            unpin: (stories) => this.unpin(stories),
        };
    }
}
