import Integer from './Integer';
import StoriesPatch from '../StoriesPatch';
import ArrayUtils from '../../utils/ArrayUtils';
import { v4 } from 'uuid';
class CustomOrder extends Integer {
    static get instance() {
        return CustomOrder.dress({
            'id': v4(),
            'code': 'custom_order',
            'ordering': 9000000,
            'label': 'Custom order',
            'tagging': false,
            'multiple': false,
            'required': true,
            'deleted': false,
            'deletedAt': null,
            'deletedBy': null,
            'description': '',
            'semanticsType': 'IntegerRange',
            'datatype': 'CustomOrder',
            'datatypeOptions': {},
            'relevantKinds': [],
            'values': [
                {
                    'id': null,
                    'label': 'No value',
                    'semantics': null,
                    'ordering': -1,
                    'color': '#ffffff',
                    'description': null,
                    'deprecated': false
                },
                {
                    'id': 'top3',
                    'label': 'Top 3',
                    'ordering': 100000,
                    'color': '#ffffff',
                    'semantics': '0 .. 3',
                    'description': null,
                    'deprecated': false
                },
                {
                    'id': 'beyondTop3',
                    'label': 'And the rest',
                    'ordering': 100000,
                    'color': '#ffffff',
                    'semantics': '3 .. 100000',
                    'description': null,
                    'deprecated': false
                },
            ],
            'attribute': false,
            'supportsMultiple': false,
            'supportsRequired': false,
            'supportsTagging': true,
            'supportsAlphabeticalOrdering': false,
            'supportsColor': true,
            'userEditable': true
        });
    }
    get category() {
        return 'system';
    }
    canBeEditedAsCardDimension() {
        return false;
    }
    shouldBeHiddenOnCardsByDefault() {
        return true;
    }
    getEnsureStoriesPatch(stories, story, index) {
        const isAt = stories.findIndex((s) => s.id == story.id);
        const reordered = ArrayUtils.moveAt(stories, isAt, index);
        return new StoriesPatch(reordered.map((s, i) => {
            const oldIndex = s[this.code];
            return oldIndex === i ? undefined : { id: s.id, [this.code]: i };
        }).filter(Boolean));
    }
}
export default CustomOrder;
