import { v4 } from 'uuid';
import { utils, read } from 'xlsx';
import { Klarifier } from '@klaro/corejs/klarifier';
import { jsonToState } from '@klaro/corejs/state';
import { SetBoardState } from '@klaro/corejs/client';
import { DisplayModes } from '@klaro/corejs/model';
angular
    .module('klaro')
    .service('quickImportModal', quickImportModal)
    .controller('QuickImportModalController', QuickImportModalController);
function quickImportModal(klaroModal) {
    return {
        open: function (boardCtrl) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/board/import/quickImportModal.html'),
                controller: 'QuickImportModalController',
                windowClass: 'klaro-modal import-board-modal',
                size: 'medium',
                resolve: {
                    boardCtrl: function () {
                        return boardCtrl;
                    },
                },
            });
        },
    };
}
function QuickImportModalController($scope, $uibModalInstance, KernelClient) {
    $scope.onFileSelected = async function (file) {
        if (!file) {
            return;
        }
        const xlsx = await read(await file.arrayBuffer(), { cellDates: true });
        const firstSheet = xlsx.Sheets[xlsx.SheetNames[0]];
        const rows = utils.sheet_to_json(firstSheet, {});
        const klaroData = Klarifier.klarify(rows);
        const boardState = await jsonToState({
            board: {
                id: v4(),
                location: 'all',
                label: 'All cards',
                mode: 'grid',
                background: 'greenwich',
                displayDimensionLabels: false,
                displayDimensionsOnCards: true,
                displayNoneOnCards: false,
                availableDisplayModes: DisplayModes.filter(m => m.includeInPlayground),
                anchors: {
                    displayBy: [],
                },
                workspacePermissions: [{
                        workspace: 'admins',
                        canRead: true,
                        canWrite: true,
                        canManage: true,
                    }],
                filters: {},
                dimensions: klaroData.dimensions,
                summaries: [
                    {
                        code: 'count',
                        label: '# Cards',
                        expr: 'count',
                        ordering: 1000000,
                    },
                ],
                extraSettings: {
                    swipeGestures: {},
                    onCardClick: {
                        type: 'quickEdit',
                        options: {},
                    },
                },
            },
            stories: [...klaroData.stories.values()],
        });
        await KernelClient.run(new SetBoardState(), { board: boardState });
        $scope.close();
    };
    $scope.close = function () {
        $uibModalInstance.dismiss();
    };
}
