class Persistence {
    constructor() {
        this.state = {};
    }
    get(key, defaultValue) {
        if (!this.state[key]) {
            this.set(key, defaultValue);
        }
        return this.state[key];
    }
    set(key, value) {
        this.state[key] = value;
        return this;
    }
}
export default Persistence;
