import { areDecksCurrentlyOpen } from '@klaro/corejs/state';
angular
    .module('klaro')
    .directive('boardDecks', boardDecks);
function boardDecks() {
    return {
        restrict: 'E',
        template: require('@/core/board/boardDecks.html'),
        require: '^board',
        scope: true,
        link: function (scope, elm, attrs, boardCtrl) {
            scope.deckDimensions = [];
            scope.openDimension = null;
            scope.clearFilters = boardCtrl.clearFilters.bind(boardCtrl);
            scope.$watch('board', (bs) => {
                if (!bs) {
                    return;
                }
                scope.areDecksOpen = bs && areDecksCurrentlyOpen(bs);
                scope.deckDimensions = bs.getDeckDimensions();
                scope.hasNoDimension = scope.deckDimensions.length === 0 && bs.getDeckDimensionCandidates().length === 0;
                scope.allOpen = scope.deckDimensions.length <= 3;
                scope.openDimension = scope.deckDimensions.find((d) => {
                    return !boardCtrl.hasFilter(d);
                });
            });
            scope.isDimensionOpen = function (dim) {
                return scope.allOpen || scope.openDimension && scope.openDimension === dim;
            };
            scope.isCompact = function () {
                return boardCtrl.isCompactDecks();
            };
            scope.toggleDecksOpenOnLoad = function () {
                boardCtrl.toggleDecks();
            };
            scope.toggleDecksCompactness = function () {
                boardCtrl.toggleDeckCompactness();
            };
            scope.toggleDecksExplorer = function () {
                boardCtrl.toggleDecksExplorer();
            };
            scope.isExplorerDecks = function () {
                return boardCtrl.isExplorerDecks();
            };
            scope.filtersCount = function () {
                const filters = boardCtrl.visibleFilters();
                return filters.size();
            };
            scope.filtersCountText = function () {
                const filtersCount = scope.filtersCount();
                return filtersCount > 1 ?
                    `${filtersCount} FILTERS` :
                    `${filtersCount} FILTER`;
            };
            scope.filterVisible = function () {
                return boardCtrl.isDecksOpenOnLoad();
            };
        },
    };
}
