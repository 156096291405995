angular
    .module('klaro')
    .directive('dimensionList', dimensionList);
function dimensionList(navigation, dimensionRest, ArrayUtils, detectIt) {
    return {
        template: require('@/core/dimension/dimensionList/dimensionList.html'),
        scope: {
            dimensions: '=',
            onClick: '&',
            onDimensionChange: '&',
        },
        controller: ['$scope', function ($scope) {
                const self = this;
                const tooltipTexts = {
                    lock: 'Block anyone from adding new values to the dimension. Only admins can unlock dimensions.',
                    required: 'Members must set the dimension value to save a card',
                    multiple: 'Members can select multiple values for a card',
                    color: 'Associate colors to dimension values to distinguish cards on boards',
                };
                $scope.tooltipText = function (column) {
                    return tooltipTexts[column];
                };
                $scope.isTouchDevice = detectIt.primaryInput === 'touch';
                ///
                $scope.onDimensionDrop = function (index, dim) {
                    const patchIt = function (item, ordering) {
                        const patch = { ordering: ordering };
                        return self.submitPatch(item, patch);
                    };
                    ArrayUtils.moveInOrdering($scope.dimensions, dim, index, patchIt);
                };
                ///
                this.dimensionChanged = function (dimension) {
                    $scope.onDimensionChange({ dimension: dimension });
                };
                this.submitPatch = function (dimension, patch) {
                    return dimensionRest.patch(dimension, patch)
                        .then((dim) => {
                        self.dimensionChanged(dim);
                        return dim;
                    })
                        .catch(navigation.failed);
                };
            }],
    };
}
