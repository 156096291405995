import DataObject from '../../../utils/DataObject';
import Forest from './Hierarchy/Forest';
export default class Hierarchy extends DataObject {
    constructor(data) {
        super(Object.assign({
            closedRows: {},
        }, data));
    }
    get hierarchyDimensions() {
        return this.board
            .getDimensionsAt('hierarchyBy')
            .filter((dim) => dim.datatype === 'StoryBinaryLink');
    }
    get noHierarchyDimension() {
        return this.hierarchyDimensions.length === 0;
    }
    get hasHierarchyDimension() {
        return this.hierarchyDimensions.length > 0;
    }
    get hierarchyDimension() {
        return this.hierarchyDimensions[0];
    }
    hierarchyDimensionFor() {
        return this.hierarchyDimension;
    }
    get cardDimensions() {
        return this.board.getCardDimensions();
    }
    get canWrite() {
        return this.canWrite;
    }
    get showSelectionCheckboxes() {
        if (!this.canWrite) {
            return false;
        }
        // We show the checkboxes in simple list view
        // We show the checkboxes if a selection is active
        return this.noHierarchyDimension || this.storySelection.any();
    }
    get selectionColumnCount() {
        return this.showSelectionCheckboxes || this.hasHierarchyDimension ? 1 : 0;
    }
    showAdditionIcon(story) {
        if (!this.hasHierarchyDimension) {
            return false;
        }
        const kindDimension = this.board.getKindDimension();
        const kind = kindDimension.firstMatchingValueOf(story);
        return this.canWrite && this.hierarchyDimension.isTargetKind(kind.id);
    }
    get newCardLinkColumnCount() {
        return this.selectionColumnCount + this.forest.maxDepth;
    }
    get totalColumnCount() {
        return 1 + this.newCardLinkColumnCount + this.cardDimensions.length;
    }
    getIndentColumnsFor(row) {
        return [].constructor(row.depth - 2);
    }
    getTitleColspanFor(row) {
        return 2 + this.forest.maxDepth - row.depth;
    }
    isClosed(row) {
        if (!this.hasHierarchyDimension) {
            return false;
        }
        return this.closedRows[row.id];
    }
    isParent(row) {
        if (!this.hasHierarchyDimension) {
            return false;
        }
        const kindDimension = this.board.getKindDimension();
        const kind = kindDimension.firstMatchingValueOf(row.story);
        return this.hierarchyDimension.isTargetKind(kind.id) && row.hasChild();
    }
    isChild(row) {
        if (!this.hasHierarchyDimension) {
            return false;
        }
        return row.depth > 2;
    }
    isOpen(row) {
        if (!this.hasHierarchyDimension) {
            return false;
        }
        return this.isParent(row) && !this.isClosed(row);
    }
    isOpenable(row) {
        if (!this.hasHierarchyDimension) {
            return false;
        }
        return this.isParent(row) && this.isClosed(row);
    }
    get rows() {
        return this.memoize2('rows', () => {
            return this.forest.dfs((memo, node) => {
                memo.push(node);
                return this.isClosed(node) ? undefined : memo;
            }, [], false);
        }, ['board', 'stories', 'closedRows']);
    }
    get dimensionColumns() {
        return this.memoize2('dimensionColumns', () => {
            return this.cardDimensions.map((dim) => {
                return { dimension: dim };
            });
        }, ['board']);
    }
    get forest() {
        return this.memoize2('forest', () => {
            const stories = this.stories.visible;
            const forest = Forest.build(stories, {
                dataOf: (story) => {
                    return { story: story };
                },
                idOf: (story) => {
                    return story.identifier;
                },
                parentIdsOf: (story) => {
                    if (this.hasHierarchyDimension) {
                        const values = this.hierarchyDimension.matchingValuesOf(story);
                        return values.map((v) => v.id).filter((id) => id !== story.identifier);
                    }
                    else {
                        return [];
                    }
                },
            });
            return forest;
        }, ['board', 'stories']);
    }
    // Actions
    toggleOpening(row) {
        if (!this.hasHierarchyDimension) {
            return this;
        }
        const closed = Object.assign({}, this.closedRows);
        if (closed[row.id]) {
            delete (closed[row.id]);
        }
        else {
            closed[row.id] = true;
        }
        return this.clone({ closedRows: closed });
    }
    expandAll() {
        if (!this.hasHierarchyDimension) {
            return this;
        }
        return this.clone({ closedRows: {} });
    }
    collapseAll() {
        if (!this.hasHierarchyDimension) {
            return this;
        }
        const closed = {};
        this.forest.dfs((_, node) => {
            if (this.isParent(node)) {
                closed[node.id] = true;
            }
        }, true);
        return this.clone({ closedRows: closed });
    }
    getDefaultValuesFor(row) {
        if (!this.hasHierarchyDimension) {
            return {};
        }
        return this.hierarchyDimension.getPatchForStoryValueTo({
            id: row.story.identifier,
        });
    }
}
