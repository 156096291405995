import { SetSession } from '@klaro/corejs/client';
angular
    .module('klaro.kernel')
    .service('KernelSession', KernelSession);
/**
 * This service encapsulates the kernel logic of loading the current project
 * through a backend call and handling any error response.
 *
 * It is aimed at being `init`ed *before* starting the application, since the
 * initialization process requires an async call to the backend.
 */
function KernelSession(KernelClient) {
    function init() {
        return Promise.resolve(get());
    }
    function get() {
        return KernelClient.store.session;
    }
    function set(session) {
        return KernelClient.run(new SetSession, { session: session });
    }
    // This is the service API
    return {
        init: init,
        get: get,
        set: set,
    };
}
