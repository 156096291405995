import { DataObject, Colors } from '../utils';
class LinkedStories extends DataObject {
    // public API
    get totalCardsCount() {
        return this.children.all.length;
    }
    get hasSections() {
        return this.sections.length > 0;
    }
    get sections() {
        const storyIdentifier = this.identifierDim.getStoryRawValue(this.parent);
        return this.memoize('sections', '', () => {
            return this.linkingDimensions.map((dim) => {
                const reboard = this.board.setFilter(dim, { id: storyIdentifier });
                const relevant = dim.relevantKinds || [];
                return {
                    dimension: dim,
                    showCardKindLabels: relevant.length !== 1,
                    name: dim.datatypeOptions.childCardsRole || 'Child cards',
                    cube: this.children.reset(reboard).rollup([this.kindDim]),
                };
            });
        });
    }
    storyKind(story) {
        return this.kindDim.getStoryRawValue(story);
    }
    colorDimOf(story) {
        const value = this.kindDim.getValueById(this.storyKind(story));
        const dimCode = value && value.colorDimension;
        return dimCode ? this.getDimension(dimCode) : this.kindDim;
    }
    colorOf(story, globalContext) {
        const colorDim = this.colorDimOf(story);
        const values = colorDim.matchingValuesOf(story, globalContext);
        return Colors.textColor(values.length > 0 ? values[0].color : null);
    }
    shownDimensions(story) {
        const kind = this.kindDim.getStoryRawValue(story);
        return this.shownDimensionsForKind(kind);
    }
    shownDimensionsForKind(kind) {
        const sums = this.kindDim.getValueById(kind).summaryDimensions;
        return sums && sums.length > 0 ? sums : ['identifier'];
    }
    // private utils
    get dimensions() {
        return this.board.getDimensions();
    }
    getDimension(code) {
        return this.dimensions.find((dim) => {
            return dim.code === code;
        });
    }
    getDimensionsOf(datatype) {
        return this.dimensions.filter((dim) => {
            return dim.datatype === datatype;
        });
    }
    get identifierDim() {
        return this.board.getIdentifierDimension();
    }
    get kindDim() {
        return this.board.getKindDimension();
    }
    get linkingDimensions() {
        const storyKind = this.kindDim.getStoryRawValue(this.parent);
        return this.memoize('binaryLinkDimensions', '', () => {
            return this.getDimensionsOf('StoryBinaryLink')
                .filter((dim) => {
                return dim.isTargetKind(storyKind);
            })
                .sort((d1, d2) => {
                return d1.ordering - d2.ordering;
            });
        });
    }
    boardChanged(board) {
        return this.clone({
            board: this.board.clone({
                dimensions: board.getDimensions(),
            }, false),
        }, false);
    }
}
export default LinkedStories;
