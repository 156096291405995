import { ArrayUtils } from '../../../utils';
/**
 * Get the list of available boards
 */
export class LoadMyBoards {
    async run(client) {
        let list = await client.store.connector.my.boards.get();
        list = list.sort(ArrayUtils.sorterBy(['ordering']));
        return {
            boards: list
        };
    }
}
;
