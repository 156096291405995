import Dimension from '../Dimension';
class Title extends Dimension {
    static get instance() {
        return new Title({
            id: '2387c2e7-8eee-4d2b-ae41-4e2a2a416f65',
            code: 'title',
            label: 'Card title',
            description: 'Title',
            required: true,
            tagging: false,
            multiple: false,
            datatype: 'Title',
            datatypeOptions: {},
            persistence: 'Title',
            persistenceOptions: {},
            semanticsType: null,
            relevantKinds: [],
            values: [],
            ordering: 10000,
            deleted: false,
            deletedAt: null,
            deletedBy: null
        });
    }
    get category() {
        return 'system';
    }
    getValues(_) {
        return [];
    }
    matchingValuesOf(_story, _globalContext) {
        return [];
    }
    isPhantomDimension() {
        return true;
    }
    canBeUsedOnDecks() {
        return false;
    }
    canBeUsedOnCards() {
        return false;
    }
    canBeUsedAsDateBoundary() {
        return false;
    }
    canBeEdited() {
        return true;
    }
    canBeEditedAsCardDimension() {
        return false;
    }
    canBeUsedForOrdering() {
        return true;
    }
    canValuesBeEdited() {
        return false;
    }
    participatesToTextSearch() {
        return true;
    }
    searchMatchesOn(search, regexp, story, _globalContext) {
        const title = this.getStoryRawValue(story);
        return title && title.match(regexp);
    }
}
export default Title;
