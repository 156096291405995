angular
    .module('klaro')
    .directive('settingsHeader', settingsHeader);
function settingsHeader($rootScope, navigation, Project) {
    return {
        template: require('@/layout/header/settingsHeader.html'),
        scope: true,
        link: function (scope) {
            function refreshProjectInfo() {
                Project.get().then((p) => {
                    scope.projectName = p.name;
                });
            }
            refreshProjectInfo();
            $rootScope.$on('klaro.project.changed', refreshProjectInfo);
        },
    };
}
