export default {
    disabled: {
        code: 'disabled',
        name: 'Disabled',
    },
    onDemand: {
        code: 'onDemand',
        name: 'On Demand',
    },
    realTime: {
        code: 'realTime',
        name: 'Real-time',
    },
};
