angular
    .module('klaro')
    .directive('body', closePseudoSelects);
/**
 * This directive closes all elements with the "pseudo-select" class
 * when the user click outsides of it, or when s/he uses the escape key.
 */
function closePseudoSelects($document) {
    return {
        restrict: 'E',
        link: function (scope, elm) {
            const closePseudoSelects = function () {
                $document[0]
                    .querySelectorAll('details.pseudo-select[open]')
                    .forEach((openedDetail) => {
                    openedDetail.removeAttribute('open');
                });
            };
            const bodyClick = (event) => {
                if (!event.target.closest('details.pseudo-select[open]') || event.target.closest('.close-on-click')) {
                    closePseudoSelects();
                }
            };
            const escapeKey = (event) => {
                if (event.target.closest('details.pseudo-select[open]') && (event.key === 'Escape' || event.key === 'Esc')) {
                    closePseudoSelects();
                    event.stopPropagation();
                }
            };
            const clickHandler = (event) => bodyClick(event);
            const keyHandler = (event) => escapeKey(event);
            elm.on('click', clickHandler);
            elm.on('keydown', keyHandler);
            scope.$on('$destroy', () => {
                elm.off('click', clickHandler);
                elm.off('keydown', keyHandler);
            });
        },
    };
}
