export class SetProject {
    constructor() {
        this.preconditions = [];
    }
    async run(client, args) {
        return {
            project: args.project,
        };
    }
}
