import { sliceBreadcrumbAt, activateBreadcrumbAt } from '@klaro/corejs/state';
angular
    .module('klaro')
    .directive('boardHeader', boardHeader);
function boardHeader($rootScope, boardObjectiveModal, Project, imailerService, auth, klaroUi, $window) {
    return {
        template: require('@/core/board/boardHeader.html'),
        restrict: 'E',
        scope: true,
        require: '^board',
        link: function (scope, elm, attrs, boardCtrl) {
            scope.view = $rootScope.view;
            function refreshProjectInfo() {
                Project.get().then((p) => {
                    scope.projectName = p.name;
                    scope.projectLogo = p.logoThumbUrl;
                });
            }
            refreshProjectInfo();
            $rootScope.$on('klaro.project.changed', refreshProjectInfo);
            scope.toggleBoards = function () {
                const klarosAsides = elm.closest('.main-container')[0];
                klarosAsides.classList.add('clicked');
                klarosAsides.classList.toggle('closed');
                if (!klaroUi.isMobile()) {
                    $window.localStorage.setItem('hide-aside', (klarosAsides.classList.contains('closed')));
                }
            };
            scope.breadcrumb = function () {
                return boardCtrl.getBoardState().breadcrumb;
            };
            scope.sliceBreadcrumbAt = function (at) {
                boardCtrl.applyStateAction((s) => sliceBreadcrumbAt(s, at));
            };
            scope.activateBreadcrumbAt = function (at) {
                boardCtrl.applyStateAction((s) => activateBreadcrumbAt(s, at));
            };
            scope.boardIsDirty = function () {
                return boardCtrl && boardCtrl.boardIsDirty();
            };
            scope.boardHasObjective = function () {
                return this.boardCtrl.getBoardState() && this.boardCtrl.getBoardState().objective;
            };
            scope.showObjective = function (event) {
                event.stopPropagation();
                boardObjectiveModal.open(boardCtrl);
            };
            scope.getBoardName = function () {
                if (!this.boardCtrl) {
                    return '';
                }
                return this.boardCtrl.getBoardState().label;
            };
            scope.getBoardObjective = function () {
                if (!this.boardCtrl) {
                    return '';
                }
                return this.boardCtrl.getBoardState().shortObjectiveHtml();
            };
            scope.canWrite = function () {
                return this.boardCtrl && this.boardCtrl.canWrite();
            };
            scope.saveBoard = function () {
                if (this.boardCtrl) {
                    this.boardCtrl.saveBoard();
                }
            };
            scope.resetAllChanges = function () {
                if (this.boardCtrl) {
                    this.boardCtrl.resetAllChanges();
                }
            };
            scope.openBoardSettings = function () {
                if (!this.boardCtrl) {
                    return;
                }
                if (auth.isAnonymous()) {
                    return;
                }
                scope.toggleBoardSettings('filters');
            };
            scope.imailer = imailerService;
        },
    };
}
