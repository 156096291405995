export const SyncModes = {
    off: 'off',
    inform: 'inform',
    realTime: 'realTime',
};
angular
    .module('klaro')
    .constant('SyncModes', SyncModes)
    .service('syncService', syncService);
function syncService(pusher, auth, $rootScope, Project) {
    let listeners = [];
    const onWorkspaceEvent = (listener) => {
        // Easy way to stop syncing
        return {
            listen(on = true) {
                const func = on ? this.start : this.stop;
                func();
            },
            start() {
                if (!listeners.includes(listener)) {
                    listeners.push(listener);
                }
                ensureWorkspaceChannel();
            },
            stop() {
                listeners = listeners.filter(l => l !== listener);
                ensureWorkspaceChannel();
            },
        };
    };
    // We only support one workspace channel at a time
    let workspaceChannel;
    const disconnectWorkspaceChannel = () => {
        if (!workspaceChannel) {
            return;
        }
        console.log('Disconnecting from', workspaceChannel.name);
        pusher.unsubscribe(workspaceChannel.name);
        workspaceChannel = null;
    };
    const ensureWorkspaceChannel = async () => {
        const workspace = auth.getViewAs().code;
        const { subdomain } = await Project.get();
        const channelName = `private-${workspace}@${subdomain}`;
        if (!listeners.length) {
            return disconnectWorkspaceChannel();
        }
        // already connected to the proper channel
        if (workspaceChannel && workspaceChannel.name === channelName) {
            return;
        }
        // Disconnect from previous channel, if not adequate
        if (workspaceChannel) {
            disconnectWorkspaceChannel();
        }
        // Reconnect unless anonymous
        if (auth.isLogged()) {
            try {
                workspaceChannel = await pusher.subscribe(channelName);
                console.log('connected from pusher channel', workspaceChannel.name);
                workspaceChannel.bind_global(onChannelEvent);
            }
            catch (ex) {
                console.log('unable to connect to pusher channel', ex);
            }
        }
    };
    const onChannelEvent = (event, data) => {
        // Ignore non klaro events
        if (event.indexOf('Klaro::') < 0) {
            return;
        }
        listeners.forEach((l) => {
            try {
                l(event, data);
            }
            catch (err) {
                console.error(err);
            }
        });
    };
    // Change channel when workspace changes
    $rootScope.$on('klaro.viewAs.changed', () => {
        ensureWorkspaceChannel();
    });
    return {
        onWorkspaceEvent,
    };
}
