angular
    .module('klaro')
    .directive('statusBar', statusBar);
function statusBar() {
    return {
        restrict: 'E',
        transclude: true,
        template: '<ng-transclude></ng-transclude>',
    };
}
