import { DataObject, Thumbnails } from '../utils';
const IMAGE_EXTENSION_REGEX = /\.(gif|jpg|jpeg|jpg|tiff|png|bin)$/i;
const EXTENSION_TO_ICON = {
    'doc': 'DOC',
    'docx': 'DOC',
    'xls': 'XLS',
    'xlsx': 'XLS',
    'ppt': 'PPT',
    'pptx': 'PPT',
    'pdf': 'PDF',
    'txt': 'Text',
    'csv': 'Text',
    'md': 'Text',
    'mp4': 'Movie',
    'avi': 'Movie',
};
class Attachment extends DataObject {
    static dress(raw) {
        return new Attachment(raw);
    }
    get thumbUrl() {
        return Thumbnails(this.url, 'attachment');
    }
    get fileIcon() {
        const url = this.url;
        if (!url) {
            return 'icon-Type-Text';
        }
        const fileExtension = url.slice(url.lastIndexOf('.') + 1);
        const found = EXTENSION_TO_ICON[fileExtension.toLowerCase()];
        return `icon-Type-${found ? found : 'Text'}`;
    }
    get isImage() {
        return !!IMAGE_EXTENSION_REGEX.test(this.url.toLowerCase());
    }
}
export default Attachment;
