import Pusher from 'pusher-js';
angular
    .module('klaro')
    .service('pusher', pusher);
const SUBSCRIBE_TIMEOUT = 3000;
function pusher($window, $timeout) {
    const service = {
        connect() {
            this.promise = new Promise((resolve, reject) => {
                this.client = new Pusher(process.env.PUSHER_APP_KEY, {
                    channelAuthorization: {
                        endpoint: '/api/pusher/auth',
                    },
                    wsHost: $window.location.host,
                    wsPort: $window.location.protocol === 'http:' ? 80 : 443,
                    wsPath: '/sock',
                    forceTLS: $window.location.protocol === 'https:',
                    encrypted: true,
                    disableStats: true,
                    enabledTransports: ['ws', 'wss'],
                    cluster: 'eu',
                });
                this.client.connection.bind('connected', () => {
                    this.promise = null;
                    resolve(this.client);
                });
                this.client.connection.bind('disconnected', () => {
                    this.promise = null;
                    this.client = null;
                });
            });
        },
        isConnected() {
            return this.client && this.client.connection.state === 'connected';
        },
        ensureConnected() {
            if (this.promise) {
                return this.promise;
            }
            return this.connect();
        },
        getSocketId() {
            return this.client.connection.socket_id;
        },
        async subscribe(...args) {
            await this.ensureConnected();
            const channel = this.client.subscribe(...args);
            return new Promise((resolve, reject) => {
                const successHandler = function () { resolve(channel); };
                const errorHandler = function () { reject(channel); };
                const timer = $timeout(() => {
                    unbindHandlersThen(errorHandler)();
                }, SUBSCRIBE_TIMEOUT);
                const unbindHandlersThen = function (cb) {
                    return function () {
                        channel.unbind('pusher:subscription_succeeded', successHandler);
                        channel.unbind('pusher:subscription_error', errorHandler);
                        $timeout.cancel(timer);
                        cb();
                    };
                };
                channel.bind('pusher:subscription_succeeded', unbindHandlersThen(successHandler));
                channel.bind('pusher:subscription_error', unbindHandlersThen(successHandler));
            });
        },
        unsubscribe(...args) {
            return this.client.unsubscribe(...args);
        },
    };
    return service;
}
