angular
    .module('klaro')
    .factory('cardAppearanceRest', cardAppearanceRest);
function cardAppearanceRest($http) {
    return {
        saveKindSummary: saveKindSummary,
    };
    function saveKindSummary(kind, kindSummaryData) {
        return $http
            .post(`/api/dimensions/kind-summary/${kind}/`, kindSummaryData)
            .then((response) => {
            return response.data;
        });
    }
}
