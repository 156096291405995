angular
    .module('klaro')
    .factory('syncInterceptor', syncInterceptor);
/**
 * This interceptor decorates HTTP requests going to the API
 * to add pusher's socket.id in the headers (when connected)
 *
 * This allows the backend to avoid streaming events
 * back to us unnecessarily
 */
function syncInterceptor(pusher) {
    return {
        request: function (request) {
            if (pusher.isConnected()) {
                request.headers = request.headers || {};
                request.headers['X-Klaro-Socket-Id'] = pusher.getSocketId();
            }
            return request;
        },
    };
}
