angular
    .module('klaro')
    .directive('workspaceSelect', workspaceSelect);
/**
 * This directive implements a selector for a given workspace.
 *
 * It receives a dimension and makes the mapping with a select widget.
 */
function workspaceSelect() {
    return {
        restrict: 'E',
        template: require('@/core/workspace/workspaceSelect.html'),
        require: '^ngModel',
        scope: {
            workspaces: '=',
        },
        link: function (scope, elm, attrs, ngModel) {
            elm.find('select').attr('id', attrs.id);
            elm.addClass('workspace-select');
            const none = { id: undefined, code: null, name: 'Select a workspace...' };
            scope.model = { selected: none };
            function refreshCandidates() {
                scope.candidates = [none].concat(scope.workspaces.sort((t1, t2) => {
                    const n1 = t1.name, n2 = t2.name;
                    return (n1 < n2) ? -1 : (n1 > n2 ? 1 : 0);
                }));
            }
            scope.$watch('workspaces', refreshCandidates);
            scope.$watch('model.selected', (newT, oldT) => {
                if (newT || !oldT) {
                    ngModel.$setViewValue(newT.code ? newT : undefined);
                }
            });
            function format(value) {
                scope.model = { selected: value ? value : none };
            }
            ngModel.$formatters.push(format);
        },
    };
}
