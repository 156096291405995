import { Attachment } from '@klaro/corejs/model';
angular
    .module('klaro')
    .factory('attachmentRest', attachmentRest);
function attachmentRest($http) {
    return {
        addAttachment: addAttachment,
        deleteAttachment: deleteAttachment,
        setCover: setCover,
        dropCover: dropCover,
    };
    function addAttachment(attachment) {
        return $http
            .post(`/api/stories/${attachment.story}/attachments/`, attachment)
            .then((response) => {
            return Attachment.dress(response.data);
        });
    }
    function deleteAttachment(storyId, attachmentId) {
        return $http
            .delete(`/api/stories/${storyId}/attachments/${attachmentId}`);
    }
    function setCover(storyId, attachmentId) {
        return $http
            .post(`/api/stories/${storyId}/cover/${attachmentId}`)
            .then(dressStory);
    }
    function dropCover(storyId) {
        return $http
            .delete(`/api/stories/${storyId}/cover/`)
            .then(dressStory);
    }
    function dressStory(response) {
        return response.data;
    }
}
