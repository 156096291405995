export const ecosystem = async () => {
    return {
        board: {
            label: 'Our ecosystem',
            mode: 'kanban',
            filters: {},
            background: 'default',
            anchors: {
                displayBy: ['company'],
            },
            coloredDimension: 'domain',
            dimensions: [{
                    code: 'user-story',
                    label: 'User stories',
                    required: true,
                    values: [
                        { id: 'null', label: 'No value', color: '#ffffff' },
                        { id: 'jeanne', label: 'Jeanne', color: '#00b048' },
                        { id: 'benjamin-nicolas', label: 'Benjamin-Nicolas', color: '#99cc2d' },
                        { id: 'olivier', label: 'Olivier', color: '#a85319' },
                        { id: 'marie-louise', label: 'Marie Louise', color: '#ffba00' },
                    ],
                }, {
                    code: 'domain',
                    label: 'Domain',
                    required: true,
                    values: [
                        { id: 'null', label: 'No value', color: '#ffffff' },
                        { id: 'architectural-project', label: 'Architectural project', color: '#d0098b' },
                        { id: 'science-led-healthcare', label: 'Science-led healthcare', color: '#00A2DC' },
                        { id: 'technology', label: 'Technology', color: '#F77D00' },
                        { id: 'marketing', label: 'Marketing', color: '#7505f9' },
                    ],
                }, {
                    code: 'company',
                    label: 'Company',
                    required: false,
                    values: [
                        { id: null, label: 'No value', color: '#ffffff' },
                        { id: 'devenco', label: 'Devenco SRL' },
                        { id: 'gsk', label: 'GSK' },
                        { id: 'slack', label: 'Slack' },
                        { id: 'desigual', label: 'Desigual' },
                    ],
                },
            ],
        },
        stories: [{
                id: 'a20cff04-ef33-4581-a515-22c8cd8b7a95',
                title: 'As a CEO & Founder\nI’d like a flexible tool that makes project management simple and easy, while offering new ways of working, communicating and making decisions with partners and clients.',
                subtitle: '',
                image: './images/jeanne.png',
                domain: 'architectural-project',
                'user-story': 'jeanne',
                company: 'devenco',
            }, {
                id: 'fe32d320-1428-481c-8d48-6959a270fd20',
                title: 'As a Analytical Network Ops & Strategy Manager\nI’d like a tool for my teams to achieve a much clearer process in different areas for a fraction of the cost of usual IT solutions.',
                subtitle: '',
                image: './images/benjamin-nicolas.png',
                domain: 'science-led-healthcare',
                'user-story': 'benjamin-nicolas',
                company: 'gsk',
            }, {
                id: '3d6d5d9e-fb8c-40fc-83b4-f969646341ce',
                title: 'As a CTO\nI’d like a flexible tool that makes project management simple and easy, while offering new ways of working, communicating and making decisions with partners and clients.',
                subtitle: '',
                image: './images/olivier.png',
                domain: 'technology',
                'user-story': 'olivier',
                company: 'slack',
            }, {
                id: 'dbefd3fa-40c0-453d-8b5a-56be44bbe412',
                title: 'As a head of marketing\nI’d like a tool wich help me to de-dramatize: to transform the feeling of being overwhelmed into a feeling of accomplishment.',
                subtitle: '',
                image: './images/marie-louise.png',
                domain: 'marketing',
                'user-story': 'marie-louise',
                company: 'desigual',
            }],
    };
};
