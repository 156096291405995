(function () {
    angular
        .module('klaro')
        .service('events', events);
    function events($rootScope) {
        return {
            listen: listen,
            emit: emit,
            observeElement: observeElement,
        };
        function listen($scope, event, handler) {
            const dereg = $rootScope.$on(event, handler);
            $scope.$on('$destroy', dereg);
        }
        function emit(event, args) {
            $rootScope.$emit(event, args);
        }
        function observeElement(scope, event, handler, element) {
            element.addEventListener(event, handler);
            scope.$on('$destroy', () => {
                element.removeEventListener(event, handler, false);
            });
        }
    }
})();
