angular
    .module('klaro')
    .directive('resetCasino', resetCasino);
function resetCasino(confirm) {
    return {
        restrict: 'E',
        template: require('@/core/board/resetCasino.html'),
        require: '^board',
        scope: true,
        link: function (scope, elm, attrs, board) {
            scope.resetAllCards = function () {
                return confirm({
                    h2: 'Sort cards again?',
                    hint: 'All cards will be moved to the Unsorted deck. Are you sure?',
                }, () => {
                    board.resetAllCasinoCards();
                });
            };
        },
    };
}
