import { latLngToBounds } from '../utils';
export const toLatLongZoom = (lat, long, zoom = '19') => {
    return {
        lat: parseFloat(lat),
        long: parseFloat(long),
        zoom: parseInt(zoom),
    };
};
export var MapsProvider;
(function (MapsProvider) {
    MapsProvider["Openstreetmap"] = "openstreetmap";
})(MapsProvider || (MapsProvider = {}));
const defaultOptions = {
    url: mapsUrl,
    [MapsProvider.Openstreetmap]: { width: 640, height: 390 },
};
// Process @[openstreeetmap](Geo URI)
// eg: geo:41.38024,2.15193?z=19
const openSMRegex = /^((geo:(\d+\.\d+),(\d+\.\d+)(\?z=(\d+))?).*)$/;
const openstreetmapParser = (url) => {
    const match = url.match(openSMRegex);
    return match ? toLatLongZoom(match[3], match[4], match[6]) : null;
};
export const MapsParsers = {
    [MapsProvider.Openstreetmap]: openstreetmapParser
};
//
const EMBED_REGEX = /@\[([a-zA-Z].+)]\([\s]*(.*?)[\s]*[)]/im;
function mapsEmbed(md, _options) {
    function mapsReturn(state, silent) {
        let token;
        let mapsLatLong;
        const theState = state;
        const oldPos = state.pos;
        if (state.src.charCodeAt(oldPos) !== 0x40 /* @ */ ||
            state.src.charCodeAt(oldPos + 1) !== 0x5B /* [ */) {
            return false;
        }
        const match = EMBED_REGEX.exec(state.src.slice(state.pos, state.src.length));
        if (!match || match.length < 3) {
            return false;
        }
        const service = match[1];
        mapsLatLong = match[2];
        const serviceLower = service.toLowerCase();
        if (serviceLower === 'openstreetmap') {
            mapsLatLong = openstreetmapParser(mapsLatLong);
        }
        else {
            return false;
        }
        // If the mapsLatLong field is empty, regex currently make it the close parenthesis.
        if (mapsLatLong === ')') {
            mapsLatLong = '';
        }
        const serviceStart = oldPos + 2;
        const serviceEnd = md.helpers.parseLinkLabel(state, oldPos + 1, false);
        //
        // We found the end of the link, and know for a fact it's a valid link;
        // so all that's left to do is to call tokenizer.
        //
        if (!silent) {
            theState.pos = serviceStart;
            theState.service = theState.src.slice(serviceStart, serviceEnd);
            const newState = new theState.md.inline.State(service, theState.md, theState.env, []);
            newState.md.inline.tokenize(newState);
            token = theState.push('maps', '');
            token.mapsLatLong = mapsLatLong;
            token.service = service;
            token.level = theState.level;
        }
        theState.pos += theState.src.indexOf(')', theState.pos);
        return true;
    }
    return mapsReturn;
}
function mapsUrl(service, mapsLatLong) {
    switch (service) {
        case 'openstreetmap':
            const box = latLngToBounds(mapsLatLong.lat, mapsLatLong.long, mapsLatLong.zoom, 600, 400);
            const bbox = encodeURI([box.east, box.north, box.west, box.south].join(','));
            return `https://www.openstreetmap.org/export/embed.html?bbox=${bbox}&amp;layer=mapnik`;
        default:
            return service;
    }
}
function tokenizeMap(md, options) {
    function tokenizeReturn(tokens, idx) {
        const mapsLatLong = md.utils.escapeHtml(tokens[idx].mapsLatLong);
        const service = md.utils.escapeHtml(tokens[idx].service).toLowerCase();
        const svcOptions = options[service];
        return mapsLatLong === '' ? '' :
            `<iframe
      frameborder="0"
      width="${svcOptions.width}"
      height="${svcOptions.height}"
      src="${defaultOptions.url(service, mapsLatLong)}"
    ></iframe>`;
    }
    return tokenizeReturn;
}
export default function videoPlugin(md, options) {
    let theOptions = options;
    const theMd = md;
    if (theOptions) {
        Object.keys(defaultOptions).forEach((key) => {
            if (typeof theOptions[key] === 'undefined') {
                theOptions[key] = defaultOptions[key];
            }
        });
    }
    else {
        theOptions = defaultOptions;
    }
    theMd.renderer.rules.maps = tokenizeMap(theMd, theOptions);
    theMd.inline.ruler.before('emphasis', 'maps', mapsEmbed(theMd, theOptions));
}
