import { ApiProjectConnector } from '../api/ApiProjectConnector';
export class LocalProjectConnector {
    constructor(client, data) {
        this.client = client;
        this.data = data;
    }
    async get(params) {
        const apiC = new ApiProjectConnector(this.client);
        return apiC.get(params);
    }
}
