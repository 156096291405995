export class AbstractCollectionConnector {
    constructor(client, baseUrl = '') {
        this.client = client;
        this.baseUrl = baseUrl;
    }
    async get(_params) {
        throw new Error('ApiDimensionsConnector#get() not implemented.');
    }
    search(_filters) {
        throw new Error('Method not implemented.');
    }
    async delete(_params) {
        throw new Error('ApiDimensionsConnector#delete() not implemented.');
    }
    async patch(_patch) {
        throw new Error('ApiDimensionsConnector#patch() not implemented.');
    }
    async post(_item) {
        throw new Error('ApiDimensionsConnector#post() not implemented.');
    }
    async create(_items) {
        throw new Error('ApiDimensionsConnector#post() not implemented.');
    }
}
