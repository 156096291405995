angular
    .module('klaro')
    .directive('matchingUser', matchingUser);
function matchingUser() {
    return {
        scope: {
            'user': '=',
        },
        restrict: 'A',
        template: require('@/core/board/invite-steps/matchingUser.html'),
    };
}
