import { Board, StoriesPatch } from '../model';
import { ArrayUtils, isEmpty } from '../utils';
import Dsl from '../print/Dsl';
import { ActiveState, Persistence, Profiler, } from '../utils';
import { BoardRest } from '../rest';
import { OrderingCandidates } from './utils';
import BoardStoriesListener from './stories/BoardStoriesListener';
import EmptyStoriesListener from './stories/EmptyStoriesListener';
import BoardPermissionsListener from './permissions/BoardPermissionsListener';
import BoardDirtyListener from './dirty/BoardDirtyListener';
import BoardSelectionListener from './selection/BoardSelectionListener';
import BoardSyncListener from './sync/BoardSyncListener';
import BoardBreadcrumbListener from './breadcrumb/BreadcrumbListener';
import BoardDecksOpenListener from './decks/DecksCurrentlyOpenListener';
import BoardGanttViewListener from './views/gantt/BoardGanttViewListener';
import BoardCasinoViewListener from './views/casino/CasinoViewListener';
import BoardChartViewListener from './views/chart/ChartViewListener';
import BoardListViewListener from './views/list/BoardListViewListener';
import BoardMatrixViewListener from './views/matrix/MatrixViewListener';
import Stories from '../model/Board/Stories';
// TODO: fix typescript
// @ts-expect-error The BoardState uses mechanisms to respect the interface
// that typescript cannot recognise
class BoardState extends ActiveState {
    factorInstance(raw) {
        raw.stories || (raw.stories = new Stories([]));
        return new BoardState(raw);
    }
    static setPersistence(p) {
        this.persistence = p;
    }
    static getPersistence() {
        if (!this.persistence) {
            BoardState.setPersistence(new Persistence());
        }
        return this.persistence;
    }
    static listeners(boardRest, mode) {
        switch (mode) {
            case 'single-story':
                return [
                    new EmptyStoriesListener(),
                    new BoardPermissionsListener(),
                ];
            case 'dashboard':
                return [
                    new BoardStoriesListener(boardRest.stories),
                    new BoardSyncListener(),
                    new BoardDirtyListener(),
                    new BoardSelectionListener(),
                    new BoardPermissionsListener(),
                ];
            default:
                return [
                    new BoardStoriesListener(boardRest.stories),
                    new BoardDecksOpenListener(),
                    new BoardSyncListener(),
                    new BoardPermissionsListener(),
                    new BoardDirtyListener(),
                    new BoardBreadcrumbListener(),
                    new BoardSelectionListener(),
                    new BoardGanttViewListener(),
                    new BoardCasinoViewListener(),
                    new BoardListViewListener(),
                    new BoardChartViewListener(),
                    new BoardMatrixViewListener(),
                ];
        }
    }
    static load(url, httpHandler = undefined, canPrefetch = true, preparer = undefined, mode = 'full') {
        let boardRest;
        if (httpHandler !== undefined) {
            boardRest = BoardRest(url, httpHandler);
        }
        else if (typeof url === 'string') {
            throw new Error('Invalid usage of Board.load');
        }
        else {
            boardRest = url;
        }
        // prepare
        const listeners = BoardState.listeners(boardRest, mode);
        listeners.forEach(l => l.prepare(canPrefetch));
        // actual loading
        return boardRest.get().then((boardData) => {
            const board = Board.dress(boardData);
            const boardState = new BoardState({
                original: board,
                board: board,
                persistence: this.getPersistence(),
            }, { boardRest: boardRest });
            if (!preparer) {
                preparer = (bs) => Promise.resolve(bs);
            }
            const init = preparer(boardState);
            return listeners.reduce((bs, l) => {
                return bs.then((bs2) => l.registerOn(bs2));
            }, init);
        });
    }
    // Persistence handling
    withPersistence(p) {
        return this.with({
            persistence: p,
        });
    }
    // Info depth
    withInfoDepth(depth) {
        return this.with({
            infoDepth: depth,
        });
    }
    // Getters over the board itself
    get dimensions() {
        return this.board.dimensions;
    }
    // @ts-expect-error we need to fix IBoard and differenciate
    // between the board raw served by the API and the Board instance
    get filters() {
        return this.board.filters;
    }
    get _links() {
        return this.board && this.board._links;
    }
    filtersOverride() {
        // The board filters are the ones of the original board
        // The board cur filters are the ones of the current board
        const boardFilters = this.original.getFilters();
        const boardCurFilters = this.board.getFilters();
        // The diff here defines the predicate override to send to the
        // backend to load all stories corresponding to the current
        // predicate
        return boardCurFilters.diff(boardFilters);
    }
    // List view
    toggleListViewOpening(row) {
        return this.with({
            listView: this.listView.toggleOpening(row),
        });
    }
    collapseAllListView() {
        return this.with({
            listView: this.listView.collapseAll(),
        });
    }
    expandAllListView() {
        return this.with({
            listView: this.listView.expandAll(),
        });
    }
    // Ordering tools
    getOrderingCandidates() {
        return new OrderingCandidates(this);
    }
    /* Needed because a BoardState instance can be used as context in
     * Dimension.withoutDeprecatedValues(context).
     */
    isUsingValueOfDimension(value, dimension) {
        const usedInFilter = this.filters.isUsingValueOfDimension(value, dimension);
        const usedInStories = this.stories.storyCount(dimension, value.id).filtered > 0;
        return usedInFilter || usedInStories;
    }
    // Stories
    getStoriesToDisplay() {
        return this.stories.visible;
    }
    storyChanged(story, mustExist = true) {
        return this.with({
            stories: this.stories.storyChanged(story, mustExist),
        });
    }
    storiesChanged(stories, mustExist = true) {
        return this.with({
            stories: this.stories.storiesChanged(stories, mustExist),
        });
    }
    storyDataChanged(storyData, mustExist = true) {
        return this.with({
            stories: this.stories.storyDataChanged(storyData, mustExist),
        });
    }
    storyAdded(story) {
        return this.with({
            stories: this.stories.storyAdded(story),
        });
    }
    storiesAdded(stories) {
        return this.with({
            stories: this.stories.storiesAdded(stories),
        });
    }
    storyDeleted(story, mustExist = true) {
        return this.with({
            stories: this.stories.storyDeleted(story, mustExist),
        });
    }
    storiesDeleted(stories, mustExist = true) {
        return this.with({
            stories: this.stories.storiesDeleted(stories, mustExist),
        });
    }
    withStoriesPatch(patch, mustExist = true) {
        return this.with({
            stories: this.stories.withStoriesPatchApplied(patch, mustExist),
            storySelection: this.storySelection ? this.storySelection.clear() : undefined,
        });
    }
    // apply
    applyStoriesPatch(patch, mustExist = true) {
        if (!this.canWrite()) {
            return this.with({});
        }
        return this.__world.boardRest.stories.patch({ patch: patch.toRaw() }).then((res) => {
            const appliedPatch = StoriesPatch.dress(res);
            return this.withStoriesPatch(appliedPatch, mustExist);
        });
    }
    applyStoriesArchive(request, mustExist = true) {
        if (!this.canWrite()) {
            return this.with({});
        }
        return this.__world.boardRest.stories.action('archive').post(request).then((res) => {
            const appliedPatch = StoriesPatch.dress(res);
            return this.withStoriesPatch(appliedPatch, mustExist);
        });
    }
    removeCardsLimit() {
        return this.with({
            stories: this.stories.withNoLimit(),
        });
    }
    moveStoriesToMultiple(dimensions, values, storyIds, globalContext) {
        const patch = storyIds.reduce((memo, storyId) => {
            const storyPatch = dimensions.reduce((memo, dim, index) => {
                const value = values[index];
                const dimPatch = dim.getPatchForStoryValueTo(value, globalContext);
                return Object.assign(memo, dimPatch);
            }, { id: storyId });
            return memo.add(storyPatch);
        }, new StoriesPatch());
        return this.applyStoriesPatch(patch, false);
    }
    moveStoryTo(dimension, value, story, index, globalContext) {
        const rawPatch = dimension.getPatchForStoryValueTo(value, globalContext);
        const patch = new StoriesPatch([
            Object.assign({ id: story.id }, rawPatch),
        ]);
        return this.applyStoriesPatch(patch, false);
    }
    moveStoryInCustomOrder(story, index) {
        // @ts-expect-error not sure where this comes from
        if (this.usesCustomOrder()) {
            const patch = this
                // @ts-expect-error not sure where this comes from
                .getCustomOrder()
                .getEnsureStoriesPatch(this.stories.filtered, story, index);
            return this.applyStoriesPatch(patch);
        }
        else {
            return Promise.resolve(this);
        }
    }
    // Story selection
    addStoryToSelection(story) {
        if (!this.canWrite()) {
            return this.with({});
        }
        return this.with({
            storySelection: this.storySelection.add(story),
        });
    }
    addStoriesToSelection(stories) {
        if (!this.canWrite()) {
            return this.with({});
        }
        return this.with({
            storySelection: this.storySelection.addAll(stories),
        });
    }
    removeStoriesFromSelection(stories) {
        if (!this.canWrite()) {
            return this.with({});
        }
        return this.with({
            storySelection: this.storySelection.removeAll(stories),
        });
    }
    toggleStorySelection(story) {
        if (!this.canWrite()) {
            return this.with({});
        }
        return this.with({
            storySelection: this.storySelection.toggle(story),
        });
    }
    clearStorySelection() {
        if (!this.canWrite()) {
            return this.with({});
        }
        return this.with({
            storySelection: this.storySelection.clear(),
        });
    }
    // Permissions
    accessibleInThisWorkspace(viewAs) {
        return !this.board.isForbiddenTo(viewAs);
    }
    getWorkspaceCodes() {
        return this.board.getWorkspaceCodes();
    }
    usedBy(user, viewAs) {
        return this.with({ user: user, viewAs: viewAs });
    }
    isUsedByAdmin() {
        return this.user && this.user.workspaces && !!this.user.workspaces.find((w) => {
            return w.code === 'admins';
        });
    }
    canRead() {
        return !this.permissions || this.permissions.canRead;
    }
    canWrite() {
        return this.permissions && this.permissions.canWrite;
    }
    canManage() {
        return this.permissions && this.permissions.canManage;
    }
    // Documentation handling
    /**
     * The modal showing the board's objective should be displayed if
     *  - there is an objective set
     *  - the administrator wants the users to read it
     *  - the user hasn't read it yet in it's current form
     *
     * returns Boolean
     **/
    shouldShowObjective() {
        const obj = this.board.objective;
        const showModal = this.board.objectiveModal;
        const os = this.persistence.get('objectiveShown', {});
        const seen = os[this.board.id];
        return !!(showModal && obj && obj !== '' && (!seen || (seen !== obj)));
    }
    markObjectiveShown() {
        const obj = this.board.objective;
        const os = this.persistence.get('objectiveShown', {});
        os[this.board.id] = obj;
        this.persistence.set('objectiveShown', os);
        return this;
    }
    markObjectiveUnshown() {
        const os = this.persistence.get('objectiveShown', {});
        delete os[this.board.id];
        this.persistence.set('objectiveShown', os);
        return this;
    }
    // Kanban columns collapsing
    isDimensionValueCollapsed(dimension, dimensionValue) {
        return this.persistence.get(`board-${this.board.id}-dimension-${dimension.id}-value-${dimensionValue.id}-collapsed`, false);
    }
    toggleDimensionValueCollapsed(dimension, dimensionValue) {
        this.persistence.set(`board-${this.board.id}-dimension-${dimension.id}-value-${dimensionValue.id}-collapsed`, !this.isDimensionValueCollapsed(dimension, dimensionValue));
    }
    // toHtml
    toHtml(options) {
        const style = options.documentStyle || 'table';
        const by = ArrayUtils.dress(options.groupBy || []);
        let print = new Dsl({
            ...options,
            board: this.board,
        }).print({
            as: 'board',
            yield: (dsl) => dsl.groupBy({
                by: by,
                yield: (dsl) => dsl.print({
                    as: style,
                }),
            }),
        });
        if (options && options.html5) {
            print = print.print({
                as: 'html5',
                yield: () => print,
            });
        }
        return print.toHtml(this.stories.filtered, options);
    }
    // REST-based methods
    reload() {
        return this.__world.boardRest.get().then((boardData) => {
            const board = Board.dress(boardData);
            return this.with({
                original: board,
                board: board,
            });
        });
    }
    refresh() {
        return this.with({
            stories: null,
            storiesPredicate: null,
            boardSyncHardRefreshNeeded: false,
            boardSyncEvents: [],
            boardSyncOutdated: [],
        });
    }
    save(overrides = {}) {
        if (!this.canManage()) {
            return this.with({});
        }
        const patch = this.board.getPatchAgainst(this.original, overrides);
        if (isEmpty(patch)) {
            return this.with({});
        }
        return this.__world.boardRest.patch(patch).then((boardData) => {
            const board = Board.dress(boardData);
            return this.with({
                original: board,
                board: board,
            });
        });
    }
    delete() {
        if (!this.canManage()) {
            return false;
        }
        return this.__world.boardRest.delete().then(() => undefined);
    }
    /**
     * The modal inviting to create a Kind value should be displayed if
     *  - the user canWrite()
     *  - a "Kind" dimension exists and
     *    - it is required
     *    - it is not locked
     *    - it has no value
     *
     * @returns Boolean
     */
    shouldCreateKindValue() {
        if (!this.canWrite()) {
            return false;
        }
        const kindDim = this.dimensions.find(d => d.datatype === 'Kind');
        return typeof (kindDim) !== 'undefined'
            && !!kindDim.required
            && !!kindDim.tagging
            && kindDim.getValues(false).length === 0;
    }
}
// Dynamically add the getters based on the Board properties
const props = Board.properties.reduce((props, prop) => {
    props[prop] = {
        get: function () {
            return this.board[prop];
        },
    };
    return props;
}, {});
Object.defineProperties(BoardState.prototype, props);
// Dynamically add the methods, based on the Board methods
Object.getOwnPropertyNames(Board.prototype).forEach((meth) => {
    if (BoardState.prototype[meth] === undefined && meth !== '_links') {
        BoardState.prototype[meth] = function (...args) {
            const result = Profiler.track(`Board#${meth}`, () => this.board[meth](...args));
            if (result && result.constructor && result.constructor === Board) {
                return this.with({ board: result });
            }
            else {
                return result;
            }
        };
    }
});
export default BoardState;
