import { KlaroErrors, OperationRunError, LoadMyProjects, } from '@klaro/corejs/client';
angular
    .module('klaro.kernel', [
    'tmh.dynamicLocale',
])
    .config(['$logProvider', function ($logProvider) {
        $logProvider.debugEnabled(true);
    }])
    .factory('Kernel', Kernel);
/**
 * Tiny Kernel module, in charge of initializing everything before actually
 * starting the application. Indeed, the initialization process requires a
 * few asynchronous calls, that need to be finished before angular routing
 * starts.
 *
 * The actual initialization job is done by the various KernelXXX services.
 * Please refer to them. The order in which those modules are initialized
 * is hardcoded here, though.
 *
 * This service is aimed at being used in the root .html file, with a manual
 * bootstrapping process. Typically something like the following code (replace
 * 'xxx' by your own application module):
 *
 * ```
 * angular.element(document).ready(function() {
 *   var $injector = angular.injector(['ng','klaro.kernel']);
 *   $injector
 *     .get('Kernel')
 *     .init()
 *     .then(function() {
 *       angular.bootstrap(document, ['xxx'], { strictDi: true });
 *     })
 *     .catch(function() {
 *       // User-friendly error handling should be done here, the Kernel
 *       // may refuse to load for some reason...
 *     })
 *   ;
 * });
 * ```
 */
function Kernel($q, $log, $window, $timeout, $injector) {
    /**
     * Starts the initialization process.
     */
    function init(toBeStarted, appName) {
        $log.info('Klaro kernel initializing...');
        return toBeStarted
            .map((mod) => $injector.get(mod))
            .reduce((promise, mod) => {
            return promise.then(() => mod.init(appName));
        }, start())
            .then(done)
            .catch(onError);
    }
    function start() {
        // For the animation...
        const deferred = $q.defer();
        deferred.resolve();
        return deferred.promise;
    }
    function done() {
        $log.info('Kernel initialized without error.');
    }
    function onError(error) {
        $log.error('Unable to load the Kernel', error);
        const response = error.rootCause?.response;
        const location = response?.headers['location'];
        if (error instanceof OperationRunError &&
            error.operation instanceof LoadMyProjects &&
            (error.is(KlaroErrors.NotFoundError) || response?.status === 404) &&
            !!location) {
            $timeout(() => {
                $window.location = location;
            }, 500);
            throw new Error('This project does not exist yet. Let me help you');
        }
        else {
            throw new Error('Klaro Cards was unable to start. Please contact support');
        }
    }
    return {
        init: init,
    };
}
