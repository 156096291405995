export var DisplayModeCode;
(function (DisplayModeCode) {
    DisplayModeCode["Kanban"] = "kanban";
    DisplayModeCode["List"] = "list";
    DisplayModeCode["Grid"] = "grid";
    DisplayModeCode["Gallery"] = "gallery";
    DisplayModeCode["Gantt"] = "gantt";
    DisplayModeCode["Casino"] = "casino";
    DisplayModeCode["Chart"] = "chart";
    DisplayModeCode["Matrix"] = "matrix";
})(DisplayModeCode || (DisplayModeCode = {}));
export var SwipeActionType;
(function (SwipeActionType) {
    SwipeActionType["AssignDimensionValue"] = "AssignDimensionValue";
    SwipeActionType["Fold"] = "Fold";
    SwipeActionType["Skip"] = "Skip";
    SwipeActionType["AskMe"] = "AskMe";
})(SwipeActionType || (SwipeActionType = {}));
export var ChartType;
(function (ChartType) {
    ChartType["bar"] = "bar";
    ChartType["line"] = "line";
    ChartType["radar"] = "radar";
})(ChartType || (ChartType = {}));
