import BoardState from './BoardState';
export const jsonToState = async (json) => {
    if (typeof json === 'string') {
        json = JSON.parse(json);
    }
    /** Hack permissions */
    json.board.workspacePermissions = [
        { workspace: 'admins', canRead: true, canWrite: true, canManage: true },
    ];
    const user = {
        workspaces: [{ code: 'admins' }],
    };
    const boardRest = {
        get() {
            return Promise.resolve(json.board);
        },
        stories: {
            get() {
                return Promise.resolve(json.stories);
            },
            patch(body) {
                return Promise.resolve(body.patch);
            },
        },
    };
    const state = await BoardState.load(boardRest);
    return await state.usedBy(user, 'admins');
};
