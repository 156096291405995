import { Template } from '@klaro/corejs/model';
angular
    .module('klaro')
    .factory('templateRest', templateRest);
function templateRest($http, $filter, $rootScope, auth) {
    const dress = Template.dress;
    return {
        list: list,
        get: get,
    };
    function list() {
        return $http
            .get('/api/templates/')
            .then((response) => {
            return response.data.map(dress);
        });
    }
    function get(id) {
        return $http
            .get(`/api/templates/${id}`)
            .then((response) => {
            return dress(response.data);
        });
    }
}
