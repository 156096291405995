import ProjectMember from './ProjectMember';
class Assignee extends ProjectMember {
    /**
      * Overriden to return the Workspace dimension if installed.
      */
    getDeterminants(dimensions) {
        return dimensions ? dimensions.filter((d) => {
            return d.datatype == 'Workspace';
        }) : [];
    }
    /**
      * Filters the members to those of the workspace, if the latter is installed and
      * a workspace has been selected.
      */
    getCandidateValuesFor(determinants, theirValues, withNull = undefined) {
        const values = this.getValues(withNull);
        if (determinants.length == 1) {
            const workspaces = determinants[0].values;
            const workspace = workspaces.find((t) => {
                return t.id == theirValues[0];
            });
            if (workspace && workspace.id !== null) {
                const members = workspace.members;
                return values.filter((a) => {
                    return members.indexOf(a.id) >= 0;
                });
            }
        }
        return values;
    }
}
export default Assignee;
