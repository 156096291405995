angular
    .module('klaro')
    .directive('boardInviteStepConfigure', boardInviteStepConfigure);
function boardInviteStepConfigure() {
    return {
        restrict: 'E',
        template: require('@/core/board/invite-steps/boardInviteStepConfigure.html'),
        link: function (scope) {
            scope.getPermissionLevelFor = function (workspace) {
                const board = scope.boardCtrl.getBoardState();
                return board.getLevelDetailsForWorkspace(workspace);
            };
            scope.unselect = function () {
                scope.selectedUser = {};
                scope.step = 'search';
            };
        },
    };
}
