// eslint-disable-next-line angular/directive-restrict
angular
    .module('klaro')
    .directive('boardIntegrations', boardIntegrations);
function boardIntegrations(navigation, boardIntegrationModal, imailerService) {
    return {
        restrict: 'C',
        template: require('@/core/board/boardIntegrations.html'),
        scope: {
            board: '=',
        },
        link: function (scope) {
            scope.multipleIntegrations = false;
            scope.openIntegrationModal = function (integrationId) {
                boardIntegrationModal
                    .open(integrationId, scope.board);
            };
            scope.integrations = [
                {
                    id: 'imailer',
                    active: true,
                    icon: 'imailer',
                    label: 'Send emails to board',
                    service: imailerService,
                },
            ];
        },
    };
}
