/* eslint-disable angular/directive-restrict */
angular
    .module('klaro')
    .service('centerModal', centerModal)
    .directive('modalHeader', modalHeader);
function centerModal(klaroModal) {
    function factor(modalOptions) {
        return {
            open: function (entity, options) {
                if (options === undefined) {
                    // backward compatibility
                    entity = options;
                }
                const resolvers = angular.extend({}, modalOptions.resolve, {
                    entity: function () {
                        return entity;
                    },
                    options: function () {
                        return options;
                    },
                });
                return klaroModal.open(angular.extend({
                    animation: false,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    windowClass: 'klaro-modal center-modal',
                    size: 'small',
                }, modalOptions, {
                    resolve: resolvers,
                }));
            },
        };
    }
    return {
        factor: factor,
    };
}
function modalHeader() {
    return {
        restrict: 'C',
        transclude: true,
        template: '<ng-transclude></ng-transclude>',
    };
}
