angular
    .module('klaro')
    .directive('boardImportDryRun', boardImportDryRun);
function boardImportDryRun($http, SecureDownload) {
    return {
        restrict: 'E',
        template: require('@/core/board/import/boardImportDryRun.html'),
        scope: {
            fileType: '=',
            modalCtrl: '=',
            importer: '=',
        },
        link: function (scope, elm) {
            scope.acceptedFileTypes = {
                'csv': 'text/csv',
                'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            };
            scope.acceptedFileMimes = Object.values(scope.acceptedFileTypes).join(',');
            scope.downloadImportTemplate = function (extension) {
                const board = scope.modalCtrl.getBoardState();
                const url = `/api/boards/${board.id}/import/template.${extension}`;
                return SecureDownload.getBlob(url, {
                    type: extension,
                    name: `${board.label}.${extension}`
                });
            };
        },
    };
}
