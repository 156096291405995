export class ApiStoryCoverConnector {
    constructor(client, baseUrl = '') {
        this.client = client;
        this.baseUrl = baseUrl;
    }
    async set(attachmentId) {
        const { data } = await this.client.api.post(`${this.baseUrl}${attachmentId}`);
        return data;
    }
    async unset() {
        const { data } = await this.client.api.delete(`${this.baseUrl}`);
        return data;
    }
}
