export class AbstractSingleResourceConnector {
    constructor(client, baseUrl = '') {
        this.client = client;
        this.baseUrl = baseUrl;
    }
    get(_params) {
        throw new Error('Method not implemented.');
    }
    patch(_patch) {
        throw new Error('Method not implemented.');
    }
    delete(_params) {
        throw new Error('Method not implemented.');
    }
}
