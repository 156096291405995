import { Identifier } from '../../model';
import { DimensionCandidate } from './DimensionCandidate';
const TYPICAL_NAMES = [
    /^ID$/i,
    /\sID$/i,
    /\sID\s/i,
    /identifier/i,
];
export class IdentifierCandidate extends DimensionCandidate {
    _deliver() {
        // @ts-expect-error migrate all dimension to typescript
        return [Identifier, {
                userEditable: false,
                required: true,
            }];
    }
    placeBet() {
        if (this.isMultiple || !this.isRequired) {
            return 0.0;
        }
        for (const value of this.values.keys()) {
            if (typeof value !== 'number' && typeof value !== 'string') {
                return 0;
            }
            if (typeof value === 'string' && !value.match(/^\d+$/)) {
                return 0;
            }
        }
        // Repetitions are not ok for ids
        if (this.values.size !== this.valuesSeen) {
            return 0.0;
        }
        const hasAnIdentifierName = !!TYPICAL_NAMES.find(r => this.dimName.match(r));
        if (!hasAnIdentifierName) {
            return 0.0;
        }
        return 1.0;
    }
}
