angular
    .module('klaro')
    .directive('hierarchyButtons', hierarchyButtons);
function hierarchyButtons() {
    return {
        restrict: 'E',
        template: require('@/core/board/hierarchy/hierarchyButtons.html'),
        require: '^board',
        scope: true,
        link: function (scope, elm, attrs, board) {
            scope.collapseAll = board.collapseAllListView.bind(board);
            scope.expandAll = board.expandAllListView.bind(board);
        },
    };
}
