import { DataObject } from '../utils';
class Workspace extends DataObject {
    static dress(raw) {
        if (raw.constructor && raw.constructor == this) {
            return raw;
        }
        return new Workspace(raw);
    }
}
export default Workspace;
