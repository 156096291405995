angular
    .module('klaro')
    .service('boardImportService', boardImportService);
function boardImportService($http, SecureDownload) {
    return {
        call: call,
        saveAsImporter: saveAsImporter,
        saveImporter: saveImporter,
        dropImporter: dropImporter,
        downloadPrelens: downloadPrelens,
    };
    function call(board, input, options = {}) {
        const url = importUrl(board, input);
        const filters = board.filtersOverride().toCnf();
        const data = Object.assign({
            source: "seshat",
            dryRun: true,
            options: Object.assign({
                filters: filters
            }, options)
        }, input);
        return $http.post(url, data);
    }
    function saveAsImporter(board, configuration) {
        const url = boardUrl(board) + "/importers/";
        return $http.post(url, configuration);
    }
    function dropImporter(board, importer) {
        const url = boardUrl(board) + `/importers/${importer.code}`;
        return $http.delete(url);
    }
    function saveImporter(board, importer, configuration) {
        const url = boardUrl(board) + `/importers/${importer.code}`;
        return $http.patch(url, configuration);
    }
    function downloadPrelens(importOptions) {
        const url = importOptions.prelens.url;
        return SecureDownload.getBlob(url, {
            type: 'yml',
            name: importOptions.prelens.name
        });
    }
    function boardUrl(board) {
        const id = board.id;
        return `/api/boards/${id}`;
    }
    function importUrl(board, input) {
        const importer = input.importer;
        if (importer && importer.length > 0) {
            return boardUrl(board) + `/importers/${importer}`;
        }
        else {
            return boardUrl(board) + `/import`;
        }
    }
}
