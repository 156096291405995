import { Timezones, Locales } from '@klaro/corejs/utils';
angular
    .module('klaro')
    .controller('ProjectInfoController', ProjectInfoController);
function ProjectInfoController($scope, $rootScope, navigation, Title, project, projectRest, Patcher, confirm, seshat, tmhDynamicLocale, copyProjectModal) {
    Title.setCurrentPage('Project info');
    $rootScope.view = 'project-info';
    $scope.forms = {};
    $scope.today = new Date();
    function reset(p) {
        let form;
        if (!p) {
            p = project;
        }
        project = p;
        $scope.project = project.toRaw();
        for (const key in $scope.forms) {
            form = $scope.forms[key];
            if (form) {
                form.$setPristine();
            }
        }
        tmhDynamicLocale.set(project.locale);
        return $scope.project;
    }
    $scope.reset = reset;
    reset();
    $scope.isSubdomainChanged = function () {
        return project.subdomain !== $scope.project.subdomain;
    };
    $scope.timezones = Timezones;
    $scope.locales = Locales;
    $scope.$watch('project.locale', (newLocale) => {
        tmhDynamicLocale.set(newLocale);
    });
    $scope.setLogo = function (file, errFiles) {
        const saveLogoLocation = function (location) {
            $scope.project.logoUrl = location.absolute;
            $scope.forms.basicForm.$setDirty();
        };
        if (errFiles && errFiles[0]) {
            navigation.failed('Your image is too large, please use a smaller icon image.');
        }
        else if (file) {
            seshat({ data: { file: file } })
                .then(saveLogoLocation)
                .catch(navigation.failed);
        }
    };
    $scope.couldSave = function (form) {
        return form && form.$dirty;
    };
    $scope.canSave = function (form) {
        return $scope.couldSave(form) && form.$valid;
    };
    $scope.save = function () {
        const patch = Patcher.shallow(project.toRaw(), $scope.project);
        if (patch) {
            projectRest
                .patch(patch)
                .then((project) => {
                if ($scope.isSubdomainChanged()) {
                    navigation.gotoNewSubdomain($scope.project.subdomain);
                }
                else {
                    reset(project);
                }
            })
                .catch(navigation.failed);
        }
    };
    // Operations
    $scope.duplicateProject = function () {
        copyProjectModal({
            kind: 'project',
            title: 'Duplicate project',
            successTitle: 'Project duplicated!',
            yesButton: 'Yes, DUPLICATE...',
        }, (data) => {
            return projectRest
                .duplicateProject(data);
        });
    };
    $scope.createTemplateFromProject = function () {
        copyProjectModal({
            kind: 'template',
            title: 'Create template from project',
            successTitle: 'Template created!',
            explanation: 'The new template will be available in the list used to kick off new projects.',
            yesButton: 'Yes, CREATE...',
        }, (data) => {
            return projectRest
                .forkProjectAsTemplate(data);
        });
    };
    $scope.convertProjectToTemplate = function () {
        confirmModal({
            h2: 'Convert project to template',
            hint: 'This project will become a reusable template, included in the list used to kick-off new projects.\n\nAll project data is preserved.',
            showDone: true,
            headerClass: '',
            yesClass: 'btn-success',
            yesLabel: 'Yes, CONVERT...',
            noLabel: 'Cancel',
            doneMsg: 'Your project is now a template',
        }, () => {
            return projectRest
                .convertProjectToTemplate()
                .then(reset)
                .then(navigation.gotoUrl('settings/documentation/'));
        });
    };
    // Danger zone
    $scope.dangerZone = { enabled: false };
    $scope.switchTemplate = function () {
        confirmModal({
            hint: 'Resetting the project template will remove ALL data and history.',
            showDone: false,
            yesLabel: 'Yes, CHANGE...',
        }, () => {
            navigation
                .gotoUrl('/onboard/');
        });
    };
    $scope.deleteAllCards = function () {
        confirmModal({
            hint: 'This will remove all cards and all history and cannot be cancelled.',
            showDone: true,
            doneMsg: 'All cards deleted!',
        }, () => {
            return projectRest
                .deleteAllCards()
                .then(navigation.gotoHome);
        });
    };
    $scope.deleteProject = function () {
        confirmModal({
            hint: 'Your project will be entirely removed from our servers.',
            showDone: true,
            doneMsg: 'Your project is deleted! We hope to see you later, though.',
            doneLabel: 'Bye bye!',
        }, () => {
            return projectRest
                .deleteProject()
                .then(navigation.gotoWebsite);
        });
    };
    function confirmModal(info, callback) {
        info = angular.merge({
            h2: 'Danger zone!',
            hint: 'This cannot be cancelled.',
            headerClass: 'modal-header-error',
            yesClass: 'btn-danger',
            yesLabel: 'Yes, DELETE',
            noLabel: 'No, back to safety',
        }, info);
        return confirm(info, callback);
    }
    $scope.blankProject = function () {
        return $scope.project.isTemplate && $scope.project.subdomain === 'blank';
    };
}
