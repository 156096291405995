export const sliceBreadcrumbAt = (state, at) => {
    if (!state.breadcrumb)
        return state;
    const newBreadcrumb = state.breadcrumb.sliceAt(at);
    const newBoard = newBreadcrumb.last.board;
    return state.with({
        breadcrumb: newBreadcrumb,
        board: newBoard,
    });
};
export const activateBreadcrumbAt = (state, at) => {
    if (!state.breadcrumb)
        return state;
    const newBreadcrumb = state.breadcrumb.activateAt(at);
    const newBoard = newBreadcrumb.parts[at].board;
    return state.with({
        breadcrumb: newBreadcrumb,
        board: newBoard,
    });
};
