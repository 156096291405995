import { DataObject } from '../utils';
class ProjectMember extends DataObject {
    static dress(raw) {
        if (raw.constructor && raw.constructor == this) {
            return raw;
        }
        return new ProjectMember(raw);
    }
    clone(override) {
        return new ProjectMember(Object.assign({}, this.__raw, override));
    }
    get status() {
        if (this.deleted) {
            return 'deleted';
        }
        else if (!this.isLinked()) {
            return 'unlinked';
        }
        else if (this.hasJoined()) {
            return 'active';
        }
        else if (this.hasBeenInvited()) {
            return 'invited';
        }
        else {
            // linked && !joined && !invited
            // should be impossible because of the invariant (linked => joined || invited)
            // (joined || invited) && (!joined && !invited) is FALSE
            throw new 'Unexpected case';
        }
    }
    isOwner() {
        return !!this.__raw.user
            && !!this.__raw.user.isOwner;
    }
    isAdmin() {
        return !!this.__raw.user
            && !!this.__raw.workspaces
            && !!this.__raw.workspaces.find((t) => { return t.code == 'admins'; });
    }
    couldBeOwner() {
        return this.memberType === 'user' && !this.isOwner() && this.isAdmin() && this.hasJoined();
    }
    /**
     * A user can be linked if the member is not already linked
     */
    canBeLinked() {
        return !this.isLinked();
    }
    /**
     * Returns whether the project member is attached to a user
     */
    isLinked() {
        return !!(this.__raw.user && this.__raw.user.id);
    }
    /**
     * Returns whether the user member has officially joined the project
     */
    hasJoined() {
        return !!(this.__raw.user && this.__raw.joinedAt);
    }
    /**
     * A member can be invited when he did not already join
     */
    canBeInvited() {
        return !this.hasJoined();
    }
    /**
     * Returns whether the user member has been invited to the project
     */
    hasBeenInvited() {
        return !!(this.__raw.user && this.__raw.invitedAt);
    }
    /**
     * Returns whether the member has been deleted.
     */
    get deleted() {
        return !!(this.__raw && this.__raw.deletedAt);
    }
}
export default ProjectMember;
