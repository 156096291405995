angular
    .module('klaro')
    .service('storySelectionEditModal', storySelectionEditModal)
    .controller('StorySelectionEditModalController', StorySelectionEditModalController);
function storySelectionEditModal(klaroModal) {
    return {
        open: function (boardCtrl, options) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/story/storySelectionEditModal.html'),
                controller: 'StorySelectionEditModalController',
                windowClass: 'klaro-modal story-selection-edit-modal',
                size: 'small full-mobile',
                resolve: {
                    boardCtrl: function () {
                        return boardCtrl;
                    },
                    options: function () {
                        return options;
                    },
                },
            });
        },
    };
}
function StorySelectionEditModalController($scope, $uibModalInstance, boardCtrl, options, navigation) {
    $scope.selectedDimension = null;
    $scope.editedDimensions = [];
    $scope.patch = {};
    $scope.editableDimensions = boardCtrl.getDimensions()
        .filter(d => d.canBeEditedAsCardDimension());
    $scope.selectionSize = boardCtrl.selectionSize.bind(boardCtrl);
    $scope.$watch('selectedDimension', (dim) => {
        if (!dim) {
            return;
        }
        if ($scope.editedDimensions.find(d => d.id == dim.id)) {
            return;
        }
        $scope.editedDimensions.push(dim);
        $scope.selectedDimension = null;
    });
    $scope.removeDimension = function (dimension) {
        $scope.editedDimensions = $scope.editedDimensions.filter(d => d.id !== dimension.id);
        delete $scope.patch[dimension.code];
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.close = function () {
        $uibModalInstance.close();
    };
    $scope.canSave = function () {
        return Object.keys($scope.patch).length > 0;
    };
    $scope.save = function () {
        boardCtrl
            .applyPatchToSelection($scope.patch)
            .then($scope.close);
    };
}
