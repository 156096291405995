angular
    .module('klaro')
    .filter('escapeHtml', escapeHtml);
function escapeHtml() {
    const entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        '\'': '&#39;',
        '/': '&#x2F;',
    };
    return function (str) {
        return String(str).replace(/[&<>"'\/]/g, (s) => {
            return entityMap[s];
        });
    };
}
