import Dimension from '../Dimension';
class CoverImage extends Dimension {
    get category() {
        return 'system';
    }
    /**
      * Overriden: this one is not editable.
      */
    canBeEdited() {
        return false;
    }
    canBeUsedOnCards() {
        return true;
    }
    canBeUsedOnDecks() {
        return true;
    }
    // We do actually use it, but don't let people see it
    usesSemantics() {
        return false;
    }
    /**
      * Overriden to use the semantics of `storyValueMatches` below.
      */
    matchingValuesOf(story, globalContext) {
        return this.values.filter((v) => {
            return this.storyValueMatches(story, v, globalContext);
        });
    }
    /**
      * Overriden to use the semantics of `storyValueMatches` below.
      */
    firstMatchingValueOf(story, globalContext) {
        return this.values.find((v) => {
            return this.storyValueMatches(story, v, globalContext);
        });
    }
    /**
      * Overriden to return false. We don't provide values for now,
      * so none matches.
      */
    storyValueMatches(story, dimValue, globalContext) {
        // bypass [none]
        if (dimValue.semantics === null) {
            return false;
        }
        const requires = (dimValue.semantics === 'true');
        const value = this.getStoryRawValue(story);
        const has = !!(value && value.url);
        return has === requires;
    }
    canValuesBeDeleted() {
        return false;
    }
}
export default CoverImage;
