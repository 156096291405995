angular
    .module('klaro')
    .directive('storyTitle', storyTitle);
function storyTitle() {
    return {
        restrict: 'E',
        scope: {
            'story': '=',
        },
        link: function (scope, elm, attrs) {
            elm.addClass('title');
            elm.attr('data-role', 'title');
            scope.$watch('story.title', () => {
                if (attrs.titleOnly) {
                    elm.html(scope.story.toptitle);
                }
                else {
                    elm.html(`${scope.story.toptitle}\n${scope.story.summary}`);
                }
            });
        },
    };
}
