import { Filters } from '@klaro/corejs/model';
angular
    .module('klaro')
    .directive('boardFiltersSelector', boardFiltersSelector);
/**
 * This directive implements the widget that allows selecting the board filters.
 *
 * This directives requires a ng-model on tag or ancestors. The model must correspond
 * to an filters object.
 */
function boardFiltersSelector() {
    return {
        restrict: 'EA',
        scope: {
            dimensions: '=',
        },
        require: '^ngModel',
        template: require('@/core/board/boardFiltersSelector.html'),
        link: function (scope, elm, attrs, ngModel) {
            function installCandidates(filters) {
                if (!filters) {
                    return;
                }
                // Converts a single dimension to a candidate data structure
                const toCandidate = function (d, index) {
                    const filtered = filters.hasFilter(d);
                    d = d.withoutDeprecatedValues(filters);
                    const values = d.getValues().map((v) => {
                        return angular.extend({}, v, {
                            selected: filters.isFilteredBy(d, v),
                        });
                    });
                    return {
                        dimension: d,
                        filtered: filtered,
                        opened: false,
                        index: (filtered ? index : 100000 + index),
                        values: values,
                        selectedCount: selectedCount(values),
                    };
                };
                // Sorts the candidates by index.
                const sortFn = function (a, b) {
                    return a.index - b.index;
                };
                // Let's go
                scope.candidates = scope.dimensions
                    .map(toCandidate)
                    .sort(sortFn);
            }
            function syncCandidates(filters) {
                scope.candidates.forEach((c) => {
                    c.filtered = filters.hasFilter(c.dimension);
                    c.values.forEach((v) => {
                        v.selected = filters.isFilteredBy(c.dimension, v);
                    });
                    c.selectedCount = selectedCount(c.values);
                });
            }
            function selectedCount(values) {
                return values.filter((x) => { return x.selected; }).length;
            }
            scope.toggleOpening = function (candidate) {
                candidate.opened = !candidate.opened;
            };
            scope.$on('toggleOpeningAll', (event, currentState) => {
                scope.candidates = scope.candidates.map((c) => {
                    c.opened = currentState;
                    return c;
                });
            });
            scope.clearFiltersOn = function (event, candidate) {
                event.preventDefault();
                event.stopPropagation();
                const model = getModel();
                const newModel = model.removeAll(candidate.dimension);
                candidate.opened = false;
                ngModel.$setViewValue(newModel.toCnf());
                syncCandidates(newModel);
            };
            scope.toggleFilter = function (candidate, value, $event) {
                //$event.preventDefault(); // We don't use this one because it unchecks the box twice otherwise
                $event.stopPropagation(); // We do keep this one to avoid closing the panel
                const model = getModel();
                const newModel = model.toggle(candidate.dimension, value);
                ngModel.$setViewValue(newModel.toCnf());
                syncCandidates(newModel);
            };
            /**
             * Returns the current model, that is, the filters.
             *
             * This method is robust to an undefined or null model, and returns an empty filters
             * instead.
             *
             * @return [Filters] the current anchor, list of dimension codes.
             */
            function getModel() {
                const filters = ngModel.$modelValue ? ngModel.$modelValue : [];
                return Filters.dress(filters, { dimensions: scope.dimensions });
            }
            scope.getModel = getModel;
            /**
             * When ngModel requires to format the value, we simply reinstall candidates.
             * Angular will take care of the rest.
             */
            function format(value) {
                installCandidates(getModel());
            }
            ngModel.$formatters.push(format);
            scope.$watch('dimensions', format);
        },
    };
}
