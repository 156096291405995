import { OperationRunError } from '@klaro/corejs/client';
angular
    .module('klaro')
    .service('robust', robust);
function robust(MarkdownIt) {
    return {
        noop: noop,
        message: message,
    };
    function noop() {
    }
    function message(err, markdownIt) {
        const error = errorMessage(err);
        if (markdownIt || markdownIt === undefined) {
            return MarkdownIt.render(error);
        }
        else {
            return error;
        }
    }
    function errorMessage(err) {
        let error;
        // eslint-disable-next-line no-undef
        if (err instanceof OperationRunError && err.rootCause?.response?.status === 400) {
            return errorMessage(err.rootCause.response);
        }
        else if (err === null || err === undefined) {
            error = 'An unexpected error occured, is the network on?';
        }
        else if (typeof (err) === 'string') {
            error = err;
        }
        else if (err.data?.description) {
            error = err.data?.description;
        }
        else if (err.data?.message) {
            error = err.data?.message;
        }
        else if (err.status && err.status >= 400 && err.statusText) {
            error = err.statusText;
        }
        else if (err.status && err.status >= 500) {
            error = 'Our server failed for an unknown reason ! If you want to help us to fix this, please report this error and describe what happened before it happens.';
        }
        else {
            error = 'We are sorry ! If you want to help us to fix this, please report this error and describe what happened before it happens.';
        }
        if (error === null || error === undefined || error.length === 0) {
            error = 'We are sorry ! If you want to help us to fix this, please report this error and describe what happened before it happens.';
        }
        return error;
    }
}
