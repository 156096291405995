angular
    .module('klaro')
    .factory('imailerService', imailerService);
function imailerService($rootScope, imailerRest, $location, navigation, Clipboard, $timeout, auth, Project, boardIntegrationModal) {
    const scope = $rootScope.$new();
    scope.isLoaded = false;
    Project.get().then(p => scope.active = !p.isTemplate);
    const setConfig = (config) => {
        scope.config = config;
        scope.isLoaded = true;
    };
    scope.emailSuffix = `@${$location.host()}`;
    scope.getFullEmail = (emailPrefix = null) => {
        emailPrefix || (emailPrefix = scope.config.emailPrefix);
        return `${emailPrefix}${scope.emailSuffix}`;
    };
    scope.isAvailable = () => !!scope.active;
    scope.isLoading = () => !scope.isLoaded;
    scope.hasConfig = () => !!scope.config;
    scope.getRandomPrefix = () => Math.random().toString(36).slice(2);
    scope.isEnabled = () => scope.hasConfig() && !!scope.config.enabled;
    scope.saveConfig = (config) => {
        // For now the bounceEmail is not configurable directly the user
        // the api currently does not even support it in the input schema
        const payload = { ...config };
        delete payload.bounceEmail;
        return imailerRest
            .setupOnBoard(scope.board.board.location, payload)
            .then((config) => {
            scope.config = config;
            return config;
        });
    };
    scope.getConfigTemplate = () => {
        return {
            enabled: false,
            emailPrefix: null,
            bounceEmail: auth.getUser().email,
        };
    };
    scope.setBoard = (board) => {
        if (!$rootScope.isAdmin) {
            return;
        }
        scope.board = board;
        scope.isLoaded = false;
        imailerRest.getBoardEmail(board.board.location)
            .then((config) => {
            setConfig(config);
        })
            // no config for that board
            .catch(() => {
            setConfig(null);
        });
    };
    scope.copyToClipboard = (e, emailPrefix) => {
        e.stopPropagation();
        emailPrefix || (emailPrefix = scope.config.emailPrefix);
        Clipboard
            .copy(scope.getFullEmail(emailPrefix))
            .then(() => {
            e.target.classList.add('copied');
            $timeout(() => {
                e.target.classList.remove('copied');
            }, 2000);
        });
    };
    scope.openConfigurationModal = () => {
        boardIntegrationModal
            .open('imailer', scope.board);
    };
    return scope;
}
