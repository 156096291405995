class FileUtils {
    static toFileName(arg, extension) {
        const short = arg
            .split(/\n/)[0]
            .match(/\w+/g)
            .slice(0, 5)
            .join(' ');
        return `${short}${extension}`;
    }
    static isImage(fileName) {
        return (/\.(gif|jpg|jpeg|tiff|png)$/i).test(fileName);
    }
    static markdownFor(fileName, path) {
        const prefix = FileUtils.isImage(fileName) ? '!' : '';
        return `${prefix}[${fileName}](${path})`;
    }
    static fileSize(bytes, precision) {
        if (isNaN(parseFloat(bytes)) || !isFinite(bytes) || bytes === 0) {
            return '-';
        }
        if (typeof precision === 'undefined') {
            precision = 1;
        }
        const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'], number = Math.floor(Math.log(bytes) / Math.log(1024));
        return `${(bytes / Math.pow(1024, Math.floor(number))).toFixed(precision)} ${units[number]}`;
    }
}
export default FileUtils;
