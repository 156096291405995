export class MemoryPatchStorage {
    constructor() {
        this.map = new Map();
    }
    async store(patches) {
        patches.forEach(patch => this.map.set(patch.id, patch));
    }
    async delete(patches) {
        patches.forEach(patch => this.map.delete(patch.id));
    }
    async deleteByID(id) {
        this.map.delete(id);
    }
    async has(patch) {
        return this.map.has(patch.id);
    }
    async get(id) {
        return this.map.get(id);
    }
    async clear() {
        this.map.clear();
    }
    async entries() {
        return [...this.map.values()];
    }
}
