angular
    .module('klaro')
    .directive('sameAs', sameAs);
function sameAs() {
    return {
        require: 'ngModel',
        scope: {
            otherModelValue: '=sameAs',
        },
        link: function (scope, element, attributes, ngModel) {
            ngModel.$validators.sameAs = function (modelValue) {
                return modelValue == scope.otherModelValue;
            };
            scope.$watch('otherModelValue', () => {
                ngModel.$validate();
            });
        },
    };
}
