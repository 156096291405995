import { DataObject, ArrayUtils } from '../utils';
import Factory from './Dimensions/Factory';
class Dimension extends DataObject {
    static dress(raw) {
        if (raw.constructor && raw.constructor == this) {
            return raw;
        }
        if (raw.constructor && raw instanceof Dimension) {
            return raw;
        }
        const method = Factory[raw.datatype] ? Factory[raw.datatype] : Factory.Default;
        return method(raw);
    }
    static newOne(datatype = 'UserDefined', override = {}) {
        return Dimension.dress({
            required: false,
            multiple: false,
            tagging: true,
            supportsColor: true,
            values: [],
            semanticsType: null,
            datatype: datatype ? datatype : 'UserDefined',
            datatypeOptions: {},
            ...override,
        });
    }
    get category() {
        return 'base';
    }
    get labelInDimensionList() {
        return this.label;
    }
    get requiredFlagLabel() {
        return 'Required in whole project';
    }
    get multipleFlagLabel() {
        return 'Allow multiple values on cards';
    }
    /**
     * A Phantom dimension is hidden to the user in the dimension list/screen.
     */
    isPhantomDimension() {
        return false;
    }
    isCoverImage() {
        return this.datatype === 'CoverImage';
    }
    /**
      * Returns whether the dimension is colored, that is, whether at least one
      * color has been set.
      */
    get colored() {
        if (this._colored === undefined) {
            this._colored = this.values && !!this.values.find((v) => {
                return v.color && v.color !== '#fff' && v.color !== '#ffffff' && v.color !== 'white';
            });
        }
        return this._colored;
    }
    /**
      * Returns the sorter to be used for values.
      *
      * By default the value `ordering` field is used, but this behavior can be
      * overriden if another, more natural, ordering exists for the dimension
      * values.
      */
    getValuesSorter() {
        return (a, b) => {
            return a.ordering - b.ordering;
        };
    }
    /**
      * Returns the various values, if any.
      *
      * When `withNull` is not specified or set to true, the null value is returned
      * provided that the dimension has it, that is, provided the dimension is not required.
      *
      * When `withNull` is set to false, the null value is skipped even if present in
      * the (non required) dimension values.
      */
    getValues(withNull) {
        let values;
        if (withNull === false || (withNull === undefined && this.required)) {
            values = this.values.filter((x) => { return x.id !== null; });
        }
        else {
            values = this.values.slice();
        }
        return values.sort(this.getValuesSorter());
    }
    /**
      * Returns the subset of `dimensions` that act as determinants for filtering
      * the candidates of this one.
      */
    getDeterminants() {
        return [];
    }
    /**
      * Given the determinants and their values, returns the filtered candidated
      * for this dimension.
      */
    getCandidateValuesFor(determinants, theirValues, withNull = undefined) {
        return this.getValues(withNull);
    }
    /**
      * Returns a dimension value given an id.
      */
    getValueById(id) {
        if (this.attribute) {
            return { id: id, label: id };
        }
        else {
            this._valuesById = this._valuesById || this.values.reduce((h, v) => {
                // TODO: fix typescript
                // @ts-expect-error this is also true, id could be null
                h[v.id] = v;
                return h;
            }, {});
            return this._valuesById[id];
        }
    }
    /**
      * Returns a dimension value given a label.
      */
    getValueByLabel(label) {
        if (this.attribute) {
            return { id: label, label: label };
        }
        else {
            this._valuesByLabel = this._valuesByLabel || this.values.reduce((h, v) => {
                h[v.label] = v;
                return h;
            }, {});
            return this._valuesByLabel[label];
        }
    }
    /**
     * Returns the sorter to use for sorting stories by this dimension.
     *
     * The default implementation sorts according to the order of dimension
     * values, but this can be overriden if another strategy is more natural
     * for the dimension.
     *
     * TODO: use getValuesSorter above instead of hardcoding ordering.
     */
    getStorySorter(reverse, globalContext) {
        if (this.attribute) {
            return { name: this.code, reverse: !!reverse };
        }
        return (a, b) => {
            const as = this.matchingValuesOf(a, globalContext);
            const bs = this.matchingValuesOf(b, globalContext);
            let result;
            if (as.length === 0 || bs.length === 0) {
                // at least one has no value at all, and is less than the other
                result = as.length - bs.length;
            }
            else if (as.length === 1 && bs.length === 1) {
                // most probably a non multiple dimension, let take the shortcut way
                // @ts-expect-error fix typescript
                result = as[0].ordering - bs[0].ordering;
            }
            else {
                // possibly multiple values each (multiple), let compare maxs
                const reducer = (min, value) => {
                    if (min === undefined) {
                        return value;
                    }
                    return min.ordering < value.ordering ? min : value;
                };
                const aMin = as.reduce(reducer, undefined);
                const bMin = bs.reduce(reducer, undefined);
                result = aMin.ordering - bMin.ordering;
            }
            return result * (reverse ? -1 : 1);
        };
    }
    getNewValueData() {
        return {
            color: '#ffffff',
            ordering: 1000000 + this.getMaxValueOrdering(),
        };
    }
    /**
      * Returns this dimension, but with an additional value.
      */
    addValue(value) {
        return this.clone({ values: ArrayUtils.dup(this.values, [value]) });
    }
    /**
      * Returns this dimension, but with values overriden.
      */
    withValues(values) {
        return this.clone({ values: values });
    }
    /**
      * Returns this dimension, but without deprecated values.
      *
      * The `context` parameter can be set to allow some deprecated values to be included
      * if they are used in that context.
      * `context` may be a Story, Filters, or BoardState instance.
      */
    withoutDeprecatedValues(context, globalContext) {
        if (this.values.some((v) => v.deprecated)) {
            const values = this.values
                .filter((v) => {
                if (context) {
                    return !v.deprecated || context.isUsingValueOfDimension(v, this, globalContext);
                }
                return !v.deprecated;
            });
            return this.clone({ values: values });
        }
        else {
            return this;
        }
    }
    /**
      * Returns this dimension, but without an given value.
      */
    withoutValue(value) {
        const wt = (v) => { return v.id === value.id; };
        return this.clone({ values: ArrayUtils.without(this.values, undefined, wt) });
    }
    withChangedValue(newValue, oldValue = newValue) {
        const values = ArrayUtils.replace(this.values, (v) => {
            return v.id === oldValue.id;
        }, newValue);
        return this.clone({ values: values });
    }
    /**
      * Returns the maximum ordering currently used on values.
      */
    getMaxValueOrdering() {
        if (this.values && this.values.length > 0) {
            return this.values.reduce((acc, value) => {
                return acc > value.ordering ? acc : value.ordering;
            }, 0);
        }
        else {
            return 1000000;
        }
    }
    /**
      * Returns whether this dimension can be used on decks.
      *
      * Default implementation simply checks that the dimension is not an
      * attribute and is not deleted, but that can be overriden by subclasses.
      */
    canBeUsedOnDecks() {
        return !this.attribute && !this.deleted;
    }
    /**
     * Whether the dimension should be shown on decks when the user did not
     * choose himself which ones to include.
     *
     * The default implementation returns canBeUsedOnDecks
     */
    shouldBeShownOnDecks(_board) {
        return this.canBeUsedOnDecks();
    }
    /**
      * Returns whether this dimension can be used on cards.
      *
      * Default implementation returns true, but that can be overriden by
      * subclasses.
      */
    canBeUsedOnCards() {
        return true;
    }
    /**
     * Used to indicate that this kind of dimension is pretty complex for the
     * user to understand, and that we should avoid showing them too early in
     * his learning curve.
     */
    shouldBeHiddenOnCardsByDefault() {
        return false;
    }
    /**
     * Returns whether this dimension can be used as a Date boundary
     * (e.g. in Gantt and/or Calendar exports).
     *
     * This method returns false by default, and can be overriden by
     * subclasses.
     */
    canBeUsedAsDateBoundary() {
        return false;
    }
    /**
      * Returns whether this dimension is an editable attribute on stories.
      *
      * Default implementation returns the `userEditable` flag returned by the
      * dimensions metadata. This may be overriden by subclasses.
      */
    canBeEdited() {
        return this.userEditable;
    }
    /**
     * Returns whether this dimension can be included in the list of dimensions
     * in story forms. By default returns true for editable dimensions that are
     * not phantom. This may be overriden by subclasses.
     */
    canBeEditedAsCardDimension() {
        return this.canBeEdited() && !this.isPhantomDimension();
    }
    /**
      * Returns whether this dimension can be used for ordering.
      *
      * Default implementation returns true.
      * This should not be overriden by subclasses.
      */
    canBeUsedForOrdering() {
        return true;
    }
    /**
     * Returns whether this dimension can be used for building a
     * Hierarchy.
     *
     * Returns false by default. Should be overriden by subclasses.
     */
    canBeUsedForHierarchy() {
        return false;
    }
    /**
     * Returns whether this dimension can be used at anchor `anchor`.
     *
     * Default implementation handles general cases. Subclasses may
     * override the behavior for special cases.
     */
    canBeUsedForAnchor(anchor) {
        if (anchor === 'hierarchyBy') {
            return this.canBeUsedForHierarchy();
        }
        return true;
    }
    /**
     * Returns whether the values of this dimension can be edited on the fly,
     * e.g. whether their name & description can be freely updated or not.
     *
     * Default implementation returns true, but can be overriden.
     */
    canValuesBeEdited() {
        return true;
    }
    /**
     * Returns whether the values can be ordered manually.
     *
     * Default implementation returns true, but can be overriden.
     */
    canValuesBeOrdered() {
        return this.tagging;
    }
    /**
     * Returns whether the values can be safely deleted, or whether they come
     * from another place that should be used to delete them.
     */
    canValuesBeDeleted() {
        return this.canValuesBeEdited();
    }
    /**
     * Returns a patch to apply on a story to get it having the specific value.
     */
    getPatchForStoryValueTo(value, _globalContext) {
        const update = {};
        update[this.code] = this.multiple ? [value.id] : value.id;
        return update;
    }
    /**
     * Returns a patch to apply on a story to get it having some specific values.
     */
    getPatchForStoryValuesTo(values, _globalContext) {
        const update = {};
        update[this.code] = values.map(v => v.id);
        return update;
    }
    /**
      * Given a story and a dimension value, returns a story clone such that
      * the story value is the one given, according to the dimension semantics.
      *
      * This method is used to enable the kanban drag & drop, even on complex
      * dimensions. It should be overriden by dimensions that make a special
      * treatments for values.
      */
    withStoryValueTo(story, value, globalContext) {
        const update = this.getPatchForStoryValueTo(value, globalContext);
        return story.clone(update);
    }
    /**
     * Same as above, in plural form.
     */
    withStoryValuesTo(story, values, globalContext) {
        const update = this.getPatchForStoryValuesTo(values, globalContext);
        return story.clone(update);
    }
    /**
     * Return a story clone reflecting a swipe right along this dimension.
     *
     * The default implementation does nothing and returns the original story.
     */
    swipeRight(story) {
        return story;
    }
    /**
     * Return a story clone reflecting a swipe left along this dimension.
     *
     * The default implementation does nothing and returns the original story.
     */
    swipeLeft(story) {
        return story;
    }
    /**
     * Given a story, return the raw value for this dimension, that is, the
     * actual representation used for the dimension on stories.
     */
    getStoryRawValue(story) {
        return story[this.code];
    }
    /**
      * Given a story, return the high-level value for this dimension,
      * represented by the raw one.
      *
      * The default implementation returns the value(s) (plural if the dimension
      * is tagged multiple), as of `matchingValuesOf`. This can however be
      * overriden for dimensions implementing something more specific;
      */
    getStoryHighLevelValue(story, globalContext) {
        const values = this.matchingValuesOf(story, globalContext);
        return this.multiple ? values : values[0];
    }
    /**
     * Given a story returns a human-friendly representation of the dimension
     * values it has.
     *
     * Used in toHtml and toPdf.
     */
    getStoryHumanValue(story, globalContext) {
        const values = this.matchingValuesOf(story, globalContext);
        return values.filter(v => v !== undefined && v !== null).map(v => v.label).join(',');
    }
    /**
      * Returns the color of a story, according to its current value along this
      * dimension, and the colors of those values themselves.
      */
    getStoryColor(story, globalContext) {
        const values = this.matchingValuesOf(story, globalContext);
        if (values.length > 0) {
            return values[0].color;
        }
    }
    /**
     * Extract the dimension values that actually match a given story.
     *
     * This method is provided to abstract the link between the story raw value
     * (representation) and the dimension values themselves and can be overriden
     * by subclasses.
     *
     * The default implementation returns the values whose id correspond to the
     * one(s) kept as raw value on the story.
     */
    matchingValuesOf(story, _globalContext) {
        const rawValue = this.getStoryRawValue(story);
        if (rawValue === null || rawValue === undefined || (Array.isArray(rawValue) && rawValue.length == 0)) {
            const nilValue = this.getValueById(null);
            return nilValue ? [nilValue] : [];
        }
        return ArrayUtils.dress(rawValue).map((rv) => {
            return this.getValueById(rv);
        });
    }
    /**
     * Extract the first matching value that actually match a given story.
     *
     * Similar to `matchingValuesOf` but returns a single value, if any matches.
     */
    firstMatchingValueOf(story) {
        const rawValue = this.getStoryRawValue(story);
        if (rawValue === null || rawValue === undefined || (Array.isArray(rawValue) && rawValue.length == 0)) {
            if (this.required) {
                return null;
            }
            const nilValue = this.getValueById(null);
            return nilValue;
        }
        return this.getValueById(rawValue);
    }
    /**
     * Returns whether a story matches a dimension value.
     *
     * The method follows the semantics of `matchingValuesOf` and must be
     * overriden if the latter is overriden too. It is provided for performance
     * reasons, to avoid computing all matching values to find if `dimValue` is
     * among them.
     */
    storyValueMatches(story, dimValue, _globalContext) {
        const storyValue = this.getStoryRawValue(story);
        return Array.isArray(storyValue) ?
            (storyValue.indexOf(dimValue.id) >= 0 || (dimValue.id == null && storyValue.length == 0)) :
            storyValue == dimValue.id;
    }
    /**
     * Returns whether the matching values along this dimension on `story`
     * matches a given regular expression.
     */
    searchMatchesOn(search, regexp, story, globalContext) {
        if (this.participatesToTextSearch()) {
            return this.matchingValuesOf(story, globalContext).some((v) => {
                return v && v.label && v.label.match(regexp);
            });
        }
        else {
            return false;
        }
    }
    /**
     * This method returns false by default and can be overriden by subclasses
     * implementing dimensions participating in text search.
     */
    participatesToTextSearch() {
        return false;
    }
    /**
      * Returns the input widget (code) to use to set a value on the story.
      *
      * The default implementation returns `select` or `multiselect` according
      * to the fact that the dimension is multiple or not. It can be overriden
      * by subclasses having special widgets to use.
      */
    getInputWidgetCode() {
        return 'default';
    }
    /**
      * Returns whether the user may himself choose whether a semantics may be
      * set on dimension values.
      *
      * The default implementation returns false, but can be overriden.
      *
      * This method is currently used to make the 'Numbers' option appear when
      * editing a dimension in frontend.
      */
    isUserDefinedSemantics() {
        return false;
    }
    /**
      * Returns whether the dimension uses the semantics field on values.
      *
      * The default implementation returns false, but can be overriden.
      */
    usesSemantics() {
        return false;
    }
    /**
     * Returns whether the dimension uses a datatype formaldef.
      *
      * The default implementation returns false, but can be overriden.
     */
    requiresFormaldef() {
        return false;
    }
    /**
     * Returns the CSS class to use to align values of this dimension
     * in a table cell (e.g. the story list).
     *
     * By default this methods returns "align-left", but this can be
     * overriden. Valid values are "align-left", "align-center" and
     * "align-right".
     */
    getTableAlignmentClass() {
        return 'align-left';
    }
    /**
      * Returns whether this dimension is relevant inside a given board, given
      * some story data.
      *
      * This method should not be overriden.
      */
    isRelevant(storyData, board) {
        if (!this.relevantKinds || this.relevantKinds.length === 0) {
            return true;
        }
        const kindDim = board.getKindDimension();
        if (!kindDim) {
            return true;
        }
        return !!this.relevantKinds.find((k) => {
            return kindDim.storyValueMatches(storyData, { id: k });
        });
    }
    /**
     * Filters `dimensions` to only those that are relevant for
     * cards of relevant kinds for this dimension.
     */
    getCoRelevantDimensions(dimensions) {
        if ((this.relevantKinds || []).length == 0) {
            return dimensions;
        }
        return dimensions.filter(d => {
            if ((d.relevantKinds || []).length == 0) {
                return true;
            }
            return ArrayUtils.intersects(this.relevantKinds, d.relevantKinds);
        });
    }
    /**
     * Returns the default value to propose, if any.
     */
    defaultValue(_globalContext) {
        if (!this.required) {
            return undefined;
        }
        const values = this.getValues();
        if (values.length !== 1) {
            return undefined;
        }
        return values[0].id;
    }
    /**
     * Returns the unit used on datatype options.
     */
    getUnit() {
        const unit = this.datatypeOptions && this.datatypeOptions.unit;
        return unit ? unit : undefined;
    }
    usesTime() {
        return false;
    }
    /**
     * Returns the original installer that corresponds to this
     * dimension;
     */
    getBestInstallerAmong(installers) {
        return installers.find((i) => {
            return i.datatype == this.datatype;
        });
    }
    /**
     *
     * Returns whether this dimension supports a placeholder field.
     */
    suppportsPlaceholder() {
        return false;
    }
    /**
      * Clones the dimension, with some overrides.
      *
      * This method should not be overriden.
      */
    clone(override) {
        const method = Factory[this.__raw.datatype] ? Factory[this.__raw.datatype] : Factory.Default;
        const data = Object.assign({}, this.__raw, override);
        return method(data);
    }
}
export default Dimension;
