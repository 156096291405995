angular
    .module('klaro')
    .filter('capitalize', capitalize);
function capitalize() {
    return function (str) {
        if (!str)
            return str;
        return str[0].toUpperCase() + str.substring(1);
    };
}
