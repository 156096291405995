angular
    .module('klaro')
    .directive('projects', projects);
function projects(MyProjects, Project, navigation, Config, projectSwitcherModal) {
    return {
        restrict: 'E',
        template: require('@/layout/asides/projects.html'),
        link: function (scope) {
            let currentProject;
            scope.projectsShown = [];
            scope.moreProjects = false;
            scope.hasTemplates = false;
            MyProjects.list().then((projects) => {
                const allProjects = projects.filter((p) => { return !p.isTemplate; });
                scope.moreProjects = (allProjects.length > Config.asideProjectsMaxDisplay);
                scope.hasTemplates = !!projects.find((p) => { return p.isTemplate; });
                scope.projectsShown = allProjects.slice(0, Config.asideProjectsMaxDisplay);
            });
            Project.get().then((p) => {
                currentProject = p;
            });
            scope.isCurrent = function (p) {
                return currentProject && p.id === currentProject.id;
            };
            scope.gotoNewProject = function () {
                navigation.gotoNewProject();
            };
            scope.toggleProjectBoards = function ($event) {
                if ($event.currentTarget.classList.contains('current')) {
                    $event.preventDefault();
                    scope.toggleBoards($event);
                }
            };
            scope.openProjectSwitcher = function () {
                projectSwitcherModal.open();
            };
        },
    };
}
