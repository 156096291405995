angular
    .module('klaro')
    .service('seshat', seshat);
function seshat($q, $location, Upload, navigation) {
    const default_options = {
        url: '/s/',
    };
    return function (options) {
        const defer = $q.defer();
        options = options ? options : {};
        options = angular.extend({}, default_options, options);
        if (options.noRename) {
            options.url = `${options.url}?noRename=true`;
        }
        Upload
            .upload(options)
            .then((response) => {
            defer.resolve(getLocation(response));
        }, (err) => {
            defer.reject(err);
        }, navigation.noop);
        return defer.promise;
    };
    /**
     * Converts the location returned by the server so as to use a relative URL
     * if the host of the located file is the same as the host of the current app.
     *
     * This aims at avoiding harcoding absolute URLs in markdown when it's not necessarily.
     * In particular, it allows moving a project to another subdomain without too much
     * pain.
     */
    function getLocation(response) {
        const [object] = response.data;
        const relative = object.name;
        const absolute = `${$location.protocol()}://${$location.host()}/s/${relative}`;
        const fileName = object.originalname;
        const info = {
            absolute: absolute,
            relative: `/s/${relative}?n=${encodeURI(fileName)}`,
            fileName,
        };
        return info;
    }
}
