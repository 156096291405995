import { DataObject, Thumbnails } from '../utils';
class Template extends DataObject {
    static dress(raw) {
        if (raw.constructor && raw.constructor == this) {
            return raw;
        }
        return new Template(raw);
    }
    get logoThumbUrl() {
        return Thumbnails(this.logoUrl, 'logo');
    }
}
export default Template;
