import Gantt from './Gantt';
import { ArrayUtils, equals } from '../../../utils';
import AbstractBoardListener from '../../AbstractBoardListener';
export default class BoardGanttViewListener extends AbstractBoardListener {
    stateChanged(subject, newState, oldState) {
        return new Promise((resolve, reject) => {
            try {
                // TODO: newState.board.isMode should be changed to newState.isMode
                // after rebase including commit 3d40d8e0.
                if (!newState.board.isMode('gantt')) {
                    // No longer a gantt
                    // => remove GanttView
                    resolve(newState.clone({ ganttView: undefined }));
                }
                else if (!oldState || !oldState.board.isMode('gantt')) {
                    // First board state, in gantt mode
                    // OR was not a gantt and is now
                    // => Create a fresh new Gantt instance
                    const view = new Gantt({
                        board: newState.board,
                        stories: newState.stories,
                        uiMode: 'dnd',
                        uiModeOptions: {
                            minWidthInPixels: this.inferGanttMinWidth(),
                        },
                    });
                    resolve(newState.clone({
                        ganttView: view,
                    }));
                }
                else if (this.hasChanged(newState, oldState)) {
                    // Was a Gantt, still a Gantt but gantt major parameters
                    // have changed
                    // => Keep the instance, but remove the cache
                    resolve(newState.clone({
                        ganttView: oldState.ganttView.clone({
                            board: newState.board,
                            stories: newState.stories,
                        }, false),
                    }));
                }
                else {
                    // Was a Gantt, still a Gantt but gantt parameters are
                    // not changed
                    // => Keep the instance, and its cache
                    resolve(newState.clone({
                        ganttView: oldState.ganttView.clone({
                            board: newState.board,
                        }, true),
                    }));
                }
            }
            catch (ex) {
                console.log(ex);
                reject(ex);
            }
        });
    }
    hasChanged(newState, oldState) {
        // When the compactDisplay flag changes, the gantt needs to be reinstalled
        // since the category rows then change
        if (newState.compactDisplay !== oldState.compactDisplay) {
            return true;
        }
        // When the stories change, the gantt needs to be reinstalled
        // since it takes them as constructor argument
        if (newState.stories.filtered !== oldState.stories.filtered) {
            return true;
        }
        // When limitTo is changed, that changes the visible stories,
        // which are used on storyRows and categoryViews, that must be
        // reset
        if (newState.stories.limitTo !== oldState.stories.limitTo) {
            return true;
        }
        // When limitTo is changed, that changes the visible stories,
        // which are used on storyRows and categoryViews, that must be
        // reset
        if (newState.ganttScale !== oldState.ganttScale) {
            return true;
        }
        // When summaries change, we need to update them, so must be
        // reset.
        if (!equals(newState.summaries, oldState.summaries)) {
            return true;
        }
        // When the parameters of the gantt change, we need to reinstall
        // it
        const toCode = (d) => d.code;
        const hasChanged = ['gantt', 'displayBy'].some((code) => {
            const oldAt = oldState.getDimensionsAt(code).map(toCode);
            const newAt = newState.getDimensionsAt(code).map(toCode);
            return !ArrayUtils.isSameset(oldAt, newAt);
        });
        return hasChanged;
    }
    inferGanttMinWidth() {
        if (typeof (window) !== 'undefined') {
            return (window.innerWidth || 1600) - 400;
        }
        else {
            return 1200;
        }
    }
}
