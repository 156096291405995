angular
    .module('klaro')
    .directive('boardFilters', boardFilters);
function boardFilters($filter) {
    return {
        restrict: 'EA',
        template: require('@/core/board/boardFilters.html'),
        link: function (scope, elm) {
            scope.filterDimensions = $filter('orderBy')(scope.board.dimensions.filter((d) => { return d.canBeUsedOnDecks(); }), '+label');
            // Expand all / Collapse all
            scope.openAll = true;
            scope.toggleView = function () {
                scope.$broadcast('toggleOpeningAll', scope.openAll);
                scope.openAll = !scope.openAll;
            };
            scope.collapseAllLabel = function () {
                return (!scope.openAll || scope.openAll === undefined) ? 'Collapse all' : 'Expand all';
            };
        },
    };
}
