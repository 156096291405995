import * as MarkdownIt from 'markdown-it';
import hljs from 'highlight.js/lib/core';
// Languages supported for highlighting
import hljsJavascript from 'highlight.js/lib/languages/javascript';
import hljsRuby from 'highlight.js/lib/languages/ruby';
import hljsHtml from 'highlight.js/lib/languages/xml';
import hljsPython from 'highlight.js/lib/languages/python';
import hljsCss from 'highlight.js/lib/languages/css';
import hljsSql from 'highlight.js/lib/languages/sql';
import hljsJava from 'highlight.js/lib/languages/java';
import hljsShell from 'highlight.js/lib/languages/shell';
import hljsTypescript from 'highlight.js/lib/languages/typescript';
import hljsC from 'highlight.js/lib/languages/c';
import hljsCpp from 'highlight.js/lib/languages/cpp';
import hljsCsharp from 'highlight.js/lib/languages/csharp';
import hljsPhp from 'highlight.js/lib/languages/php';
import hljsGo from 'highlight.js/lib/languages/go';
import hljsKotlin from 'highlight.js/lib/languages/kotlin';
import hljsScala from 'highlight.js/lib/languages/scala';
import hljsRust from 'highlight.js/lib/languages/rust';
import hljsSwift from 'highlight.js/lib/languages/swift';
import hljsGherkin from 'highlight.js/lib/languages/gherkin';
import hljsVue from './markdowner/vue';
const hljsLanguages = {
    javascript: hljsJavascript,
    ruby: hljsRuby,
    html: hljsHtml,
    xml: hljsHtml,
    python: hljsPython,
    css: hljsCss,
    sql: hljsSql,
    java: hljsJava,
    shell: hljsShell,
    typescript: hljsTypescript,
    c: hljsC,
    cpp: hljsCpp,
    csharp: hljsCsharp,
    php: hljsPhp,
    go: hljsGo,
    kotlin: hljsKotlin,
    scala: hljsScala,
    rust: hljsRust,
    swift: hljsSwift,
    gherkin: hljsGherkin,
    vue: hljsVue
};
Object.entries(hljsLanguages).forEach(([code, highlighter]) => {
    hljs.registerLanguage(code, highlighter);
});
import MarkdownCardReference from '../ext/markdown-it-card-reference';
import MarkdownVideo from '../ext/markdown-it-video';
import MarkdownMaps from '../ext/markdown-it-maps';
import MarkdownCheckbox from '../ext/markdown-it-tasks';
import MarkdownExternalLinks from '../ext/markdown-it-external-links';
import * as MarkdownEmbed from 'markdown-it-html5-embed';
const Markdowner = (function () {
    const markdownIt = (typeof MarkdownIt.default === 'function') ? MarkdownIt.default : MarkdownIt;
    const markdownEmbed = (typeof MarkdownEmbed.default === 'function') ? MarkdownEmbed.default : MarkdownEmbed;
    const instance = () => {
        const i = markdownIt({
            html: false,
            linkify: true,
            breaks: true,
            typographer: false,
            // syntax highlighting with highlight.js
            highlight: function (str, lang) {
                if (lang && hljs.getLanguage(lang)) {
                    try {
                        return hljs.highlight(str, { language: lang }).value;
                    }
                    catch (__) {
                        //
                    }
                }
                return ''; // use external default escaping
            },
        })
            .use(MarkdownExternalLinks)
            .enable(['table', 'strikethrough']);
        return i;
    };
    const light = instance();
    const full = instance()
        .use(MarkdownCardReference)
        .use(markdownEmbed, {
        html5embed: {
            useImageSyntax: true,
            useLinkSyntax: false,
        },
    })
        .use(MarkdownVideo)
        .use(MarkdownMaps)
        .use(MarkdownCheckbox, {
        disabled: false,
        textTrack: true,
        divWrap: true,
    });
    function toggleTask(src, task) {
        const wasUnchecked = task.substring(0, 3) === '[ ]';
        const escapeRegExp = (string) => {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        };
        const toReplace = wasUnchecked
            ? task.replace(/\[ \]/, '[x]')
            : task.replace(/\[x\]/i, '[ ]');
        const match = new RegExp(`${escapeRegExp(task)}[ \t]*(\\n|$)`, 'mg');
        return src.replace(match, `${toReplace}$1`);
    }
    return {
        light: light,
        full: full,
        render: light.render.bind(light),
        toggleTask: toggleTask,
    };
})();
export default Markdowner;
