angular
    .module('klaro')
    .directive('dimensionFilter', dimensionFilter);
function dimensionFilter() {
    return {
        restrict: 'EA',
        template: require('@/core/dimension/dimensionFilter.html'),
        scope: {
            dimension: '=',
        },
        require: '^board',
        link: function (scope, elm, attrs, board) {
            elm.addClass('dimension-filter');
            // Will be populated with selected value
            scope.model = {};
            // When the widget changes, update the corresponding filter
            scope.$watch('model.selected', (newVal, oldVal) => {
                if (!scope.dimension) {
                    return;
                }
                if (newVal === undefined && oldVal !== undefined) {
                    board.removeDimensionFilters(scope.dimension);
                }
                else {
                    board.addFilter(scope.dimension, newVal);
                }
            });
            // When the filters change, update the corresponding model
            board.$watch('board.filters', (filters) => {
                if (!scope.dimension || !filters) {
                    return;
                }
                const val = board.filterValuesAlong(scope.dimension);
                if (val === undefined || val === null) {
                    scope.model = {};
                }
                else {
                    scope.model.selected = val;
                }
            }, scope);
        },
    };
}
