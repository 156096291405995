function ConnectorRestHandler(api) {
    const KLARO_HEADER_PREFIX = 'x-klaro-';
    const rest = function (...args) {
        return rest.get(...args);
    };
    const handle = function (res) {
        const headers = res.headers || {};
        const klaroHeaders = Object.keys(headers)
            .reduce((klaroHeaders, k) => {
            const name = k.toLowerCase();
            if (name.indexOf(KLARO_HEADER_PREFIX) === 0) {
                klaroHeaders[name.replace(KLARO_HEADER_PREFIX, '')] = headers[k];
            }
            return klaroHeaders;
        }, {});
        if (res.data) {
            res.data.__metadata = klaroHeaders;
        }
        return res.data;
    };
    // Base API
    rest.get = function (...args) {
        return api.get(...args).then(handle);
    };
    rest.post = function (...args) {
        return api.post(...args).then(handle);
    };
    rest.patch = function (...args) {
        return api.patch(...args).then(handle);
    };
    rest.delete = function (url, data) {
        return api.delete(url, {
            data: data,
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(handle);
    };
    rest.action = function (..._args) {
        throw new Error('Not implemented');
    };
    return rest;
}
export default ConnectorRestHandler;
