import { User } from '../../../model';
import { Board, Project } from '../../../model';
import { ApiPinnedStoriesConnector } from './ApiPinnedStoriesConnector';
export class ApiMyConnector {
    constructor(client) {
        this.client = client;
        this.projects = {
            async get() {
                const res = await client.api.get('/my/projects/');
                return res.data.map(Project.dress);
            }
        };
        this.boards = {
            async get() {
                const boardRes = await client.api.get('/my/boards/');
                return boardRes.data.map(Board.dress);
            }
        };
        this.preferences = {
            async patch(preferences) {
                const res = await client.api.patch('/auth/me/', preferences);
                return User.dress(res.data);
            }
        };
        this.stories = {
            pinned: new ApiPinnedStoriesConnector(client)
        };
    }
}
