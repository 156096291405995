import { Integer } from '../../model';
import { DimensionCandidate } from './DimensionCandidate';
import { ckmeans } from 'simple-statistics';
export class IntegerCandidate extends DimensionCandidate {
    _deliver() {
        const values = [
            ...this.getClustersFilters(),
        ];
        // @ts-expect-error migrate all dimensions to typescript
        return [Integer, {
                datatype: 'Integer',
                values,
            }];
    }
    placeBet() {
        const values = [...this.values.keys()].filter(v => {
            return v !== undefined && v !== null;
        });
        let valid = 0;
        values.forEach(value => {
            if (typeof value === 'number') {
                valid++;
            }
            else if (typeof value === 'string' && value.match(/^\d+([\,\.]\d+)?$/)) {
                valid += 0.8;
            }
        });
        return valid / values.length;
    }
    getClustersFilters() {
        const k = Math.min(Math.sqrt(this.allValues.length / 4), 20, this.values.size);
        // ckmeans sometimes returns duplicates
        const clusters = ckmeans(this.allValues.filter(Boolean), k);
        return clusters.map((cluster, i) => {
            const min = cluster[0];
            const max = cluster[cluster.length - 1];
            let label;
            if (min === max) {
                label = min;
            }
            else {
                label = `${min} - ${max}`;
            }
            return {
                id: label,
                label: label,
                ordering: 10000,
                color: null,
                semantics: `${min} .. ${max}`,
                description: null,
                deprecated: false,
            };
        });
    }
}
