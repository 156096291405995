var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Dsl_options;
import PrintArticle from './PrintArticle';
import PrintList from './PrintList';
import PrintTable from './PrintTable';
import PrintHtml5 from './PrintHtml5';
import PrintGroupBy from './PrintGroupBy';
import PrintLinkedStories from './PrintLinkedStories';
import PrintBoard from './PrintBoard';
const PRINTERS = {
    article: PrintArticle,
    list: PrintList,
    table: PrintTable,
    board: PrintBoard,
    html5: PrintHtml5,
};
class Dsl {
    constructor(options) {
        _Dsl_options.set(this, void 0);
        __classPrivateFieldSet(this, _Dsl_options, options, "f");
    }
    get board() {
        return __classPrivateFieldGet(this, _Dsl_options, "f").board;
    }
    get globalContext() {
        return __classPrivateFieldGet(this, _Dsl_options, "f").globalContext;
    }
    groupBy(options) {
        const opts = this.buildOptions(options);
        return this.dup({
            root: new PrintGroupBy(opts),
        });
    }
    linkedStories(options) {
        const opts = this.buildOptions(options);
        return this.dup({
            root: new PrintLinkedStories(opts),
        });
    }
    print(options) {
        const opts = this.buildOptions(options);
        if (typeof (options) === 'function') {
            return { toHtml: options };
        }
        else if (PRINTERS[options.as]) {
            return this.dup({
                root: new PRINTERS[options.as](opts),
            });
        }
        else {
            throw `Invalid option as: '${options.as}'`;
        }
    }
    buildOptions(options) {
        return {
            ...__classPrivateFieldGet(this, _Dsl_options, "f"),
            ...options,
            yield: this.buildCallback(options.yield),
            before: this.buildCallback(options.before),
            after: this.buildCallback(options.after),
        };
    }
    buildCallback(callback) {
        if (!callback) {
            return;
        }
        const subDsl = new Dsl(__classPrivateFieldGet(this, _Dsl_options, "f"));
        const node = callback(subDsl);
        return (...args) => node.toHtml(...args);
    }
    toHtml(stories, args) {
        return __classPrivateFieldGet(this, _Dsl_options, "f").root.toHtml(stories, args || { hLevel: 1 }).trim();
    }
    dup(override) {
        return new Dsl({
            ...__classPrivateFieldGet(this, _Dsl_options, "f"),
            ...override,
        });
    }
}
_Dsl_options = new WeakMap();
export default Dsl;
