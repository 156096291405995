angular
    .module('klaro')
    .directive('boardPermissions', boardPermissions);
function boardPermissions() {
    return {
        restrict: 'E',
        template: require('@/core/board/boardPermissions.html'),
        windowClass: 'klaro-modal board-permissions pane-modal',
    };
}
