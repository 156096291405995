angular
    .module('klaro.playground')
    .config(['$routeProvider', '$locationProvider',
    function ($routeProvider, $locationProvider) {
        const KernelClient = angular.injector(['ng', 'klaro.kernel']).get('KernelClient');
        $routeProvider
            .when('/', {
            isPublic: true,
            template: require('@/apps/playground/home/view.html'),
            controller: 'BoardViewController',
            reloadOnSearch: false,
            resolve: {
                project: async () => KernelClient.store.project,
                board: async () => KernelClient.store.board,
            },
        })
            .otherwise({
            redirectTo: '/',
        });
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false,
        });
    }]);
