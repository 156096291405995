import TogglableSectionsList from './support/TogglableSectionsList';
const SECTIONS = [
    {
        code: 'kind',
        name: 'Card kind',
        uncollapsable: true,
        description: 'What do your cards represent in the real world?',
        filter: (d) => d.category === 'kind',
    },
    {
        code: 'base',
        name: 'Base dimensions',
        description: 'Data attributes that you can edit on cards (buckets, dates, numbers, members, ...)',
        filter: (d) => d.category === 'base',
    },
    {
        code: 'advanced',
        name: 'Advanced dimensions',
        description: 'Computed data attributes for more organization power (formulas, results of polls, when cards changed, ...)',
        filter: (d) => d.category === 'advanced',
    },
    {
        code: 'links',
        name: 'Card relationships',
        description: 'Define parent/child relationships among cards',
        filter: (d) => d.category === 'links',
    },
    {
        code: 'system',
        name: 'System dimensions',
        description: 'Data attributes automatically available for all cards',
        filter: (d) => d.category === 'system',
    },
];
class DimensionList extends TogglableSectionsList {
    get sectionsInfo() {
        return SECTIONS;
    }
    get items() {
        return this.memoize('items', '', () => {
            return this._filterDimensions(this.dimensions);
        });
    }
    hasDisabledDimensionsOrValues() {
        const has = !!this.dimensions.find(d => d.deleted);
        if (has) {
            return true;
        }
        return !!this.dimensions
            .reduce((acc, dim) => acc.concat(dim.values), [])
            .find(v => v.deprecated);
    }
    // private tools
    _filterDimensions(dimensions) {
        let dims = dimensions.filter(d => !d.isPhantomDimension());
        if (!this.showDisabled) {
            dims = dims
                .filter(d => !d.deleted)
                .map(d => d.withoutDeprecatedValues());
        }
        if (this.searchText) {
            const rx = new RegExp(this.searchText, 'i');
            const match = (d) => {
                if (d.label && d.label.match(rx)) {
                    return true;
                }
                return d.getValues().some(v => v.label && v.label.match(rx));
            };
            dims = dims.filter(match);
        }
        return dims;
    }
}
export default DimensionList;
