function RestHandler(baseUrl, httpHandler) {
    const rest = function (...args) {
        return rest.get(...args);
    };
    const join = function (...args) {
        return args.map(arg => arg.replace(/\/$/, '')).join('/');
    };
    // Base API
    rest.get = function (...args) {
        return httpHandler.get(baseUrl, ...args);
    };
    rest.post = function (...args) {
        return httpHandler.post(baseUrl, ...args);
    };
    rest.patch = function (...args) {
        return httpHandler.patch(baseUrl, ...args);
    };
    rest.delete = function (...args) {
        return httpHandler.delete(baseUrl, ...args);
    };
    rest.action = function (name) {
        return RestHandler(join(baseUrl, 'actions', name), httpHandler);
    };
    return rest;
}
export default RestHandler;
