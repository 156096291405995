class DomainUtils {
    static normalizeSubdomain(subdomain) {
        if (!subdomain) {
            subdomain = '';
        }
        subdomain = subdomain
            .toLowerCase()
            .trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(new RegExp(/[^a-z0-9\-]+/, 'g'), '-')
            .replace(new RegExp(/[\-]+$/), '')
            .replace(new RegExp(/^[\-]+/), '')
            .substring(0, 25);
        if (subdomain.length > 0 && subdomain.length < 5) {
            const possible = '0123456789';
            subdomain += '-';
            for (let i = 0; i < 5; i++) {
                subdomain += possible.charAt(Math.floor(Math.random() * possible.length));
            }
        }
        return subdomain;
    }
}
export default DomainUtils;
