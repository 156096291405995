angular
    .module('klaro')
    .directive('formAutofocus', formAutofocus);
function formAutofocus($timeout) {
    function link($scope, $element, $attrs) {
        function focus(condition) {
            const fieldName = Object.keys(condition).filter(k => condition[k]);
            if (fieldName) {
                $timeout(() => {
                    $element.find(`input[name='${fieldName[0]}']`).focus();
                }, $scope.$eval($attrs.focusDelay) || 0);
            }
        }
        function refresh() {
            focus($scope.$eval($attrs.formAutofocus));
        }
        refresh();
        $scope.$on('form-visibility-changed', refresh);
    }
    return {
        restrict: 'A',
        link: link,
    };
}
