export class KlaroStoreProvider {
    constructor(getter, setter) {
        this.getter = getter;
        this.setter = setter;
        this.observers = [];
    }
    get() {
        return this.getter();
    }
    set(store) {
        const oldStore = this.get();
        this.setter(store);
        this.observers.forEach(obs => obs(store, oldStore));
    }
    subscribe(observer) {
        this.observers.push(observer);
    }
    unsubscribe(observer) {
        this.observers = this.observers.filter(o => o !== observer);
    }
}
