angular
    .module('klaro')
    .service('boardShareModal', boardShareModal)
    .controller('BoardShareModalController', BoardShareModalController);
function boardShareModal(klaroModal) {
    return {
        open: function (link, footer) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/board/boardShareModal.html'),
                controller: 'BoardShareModalController',
                windowClass: 'klaro-modal board-share-modal',
                size: 'medium',
                resolve: {
                    link: function () {
                        return link;
                    },
                    footer: function () {
                        return footer;
                    },
                },
            });
        },
    };
}
function BoardShareModalController($scope, $uibModalInstance, link, footer, Clipboard) {
    $scope.link = link;
    $scope.footer = footer;
    $scope.copyLink = function ($event) {
        Clipboard
            .copy(new URL($scope.link).href)
            .then(() => {
            $event.target.firstChild.data = 'Link copied!';
        });
    };
    $scope.close = function () {
        $uibModalInstance.dismiss();
    };
}
