import { v4 } from 'uuid';
export const blank = async () => {
    return {
        board: {
            id: v4(),
            label: 'Blank',
            mode: 'grid',
            filters: {},
            displayDimensionsOnCards: true,
            displayDimensionLabels: false,
            displayNoneOnCards: false,
            decksOpen: true,
            background: 'default',
            anchors: {},
            coloredDimension: null,
            dimensions: [],
            extraSettings: {
                onCardClick: {
                    type: 'quickEdit',
                    options: {},
                },
            },
        },
        stories: [],
    };
};
