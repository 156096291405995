angular
    .module('klaro')
    .factory('integrationService', integrationService);
function integrationService($rootScope, integrationRest) {
    const scope = $rootScope.$new();
    scope.getList = () => {
        return scope.integrations;
    };
    scope.ensureList = async (force = true) => {
        if (!scope.integrations || force) {
            scope.integrations = await integrationRest.getList();
        }
        return scope.integrations;
    };
    scope.isAvailable = (id) => {
        if (!scope.integrations) {
            throw new Error('Call ensureList before using integrationService.isAvailable');
        }
        if (!scope.integrations[id]) {
            throw new Error(`Unknown integration: ${id}`);
        }
        return scope.integrations[id].available;
    };
    return scope;
}
