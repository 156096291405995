import AbstractBoardListener from '../AbstractBoardListener';
import Breadcrumb from './Breadcrumb';
export default class BoardBreadcrumbListener extends AbstractBoardListener {
    registerOn(subject) {
        if (subject.user?.preferences?.labs?.breadcrumb?.enabled) {
            return super.registerOn(subject);
        }
        else {
            return subject;
        }
    }
    stateChanged(subject, newState, oldState) {
        return new Promise((resolve, _reject) => {
            if (!oldState || (oldState.original !== newState.original)) {
                // Very fresh board, hence breadcrumb
                let breadcrumb = Breadcrumb.for(newState.original);
                if (newState.original !== newState.board) {
                    // board already changed (because of preparer)
                    // ... typically because of filters from url
                    breadcrumb = breadcrumb.givenNewBoard(newState.original, newState.board);
                }
                resolve(newState.clone({ breadcrumb }));
            }
            else if (newState.breadcrumb !== oldState.breadcrumb) {
                // Breadcrumb did not change, skip
                // ... it's a breadcrumb controller who did
                resolve(newState);
            }
            else if (newState.board === oldState.board) {
                // Breadcrumb did not change, skip
                // ... it's not a board change, but something else
                resolve(newState);
            }
            else if (newState.breadcrumb) {
                // When the breadcrumb did not change
                // (otherwise it's a breadcrumb controller who did)
                const newBreadcrumb = newState.breadcrumb.givenNewBoard(newState.original, newState.board);
                if (newBreadcrumb === newState.breadcrumb) {
                    // Breadcrumb did not think it's worth changing
                    resolve(newState);
                }
                else {
                    // got a new one
                    resolve(newState.clone({
                        breadcrumb: newBreadcrumb,
                    }));
                }
            }
        });
    }
}
