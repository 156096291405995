import { Title } from '../../model';
import { DimensionCandidate } from './DimensionCandidate';
const TYPICAL_NAMES = [
    /name/i,
    /title/i,
    /summary/i,
];
const MIN_LENGTH = 10;
const MAX_LENGTH = 250;
export class TitleCandidate extends DimensionCandidate {
    _deliver() {
        return [Title, {}];
    }
    placeBet() {
        if (this.isMultiple || !this.isRequired) {
            return 0.0;
        }
        let tooLong = 0.0;
        let tooShort = 0.0;
        let invalidType = 0.0;
        for (const value of this.values.keys()) {
            if (typeof value !== 'string') {
                invalidType += 1.0;
                continue;
            }
            if (value.length > MAX_LENGTH) {
                tooLong += 1.0;
            }
            if (value.length < MIN_LENGTH) {
                tooShort += 1.0;
            }
        }
        const hasATitleName = !!TYPICAL_NAMES.find(r => this.dimName.match(r));
        const initialBet = (1.0 * this.values.size) / this.valuesSeen
            - (invalidType / this.valuesSeen)
            - (tooLong / this.valuesSeen)
            - (tooShort / this.valuesSeen);
        return hasATitleName
            ? initialBet + 1.0
            : initialBet;
    }
}
