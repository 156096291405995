import ProjectMember from './ProjectMember';
class CreatedBy extends ProjectMember {
    get category() {
        return 'system';
    }
    getBestInstallerAmong(installers) {
        return installers.find((i) => {
            return i.datatype == 'CreatedBy';
        });
    }
    canBeUsedOnDecks() {
        return !this.deleted;
    }
}
export default CreatedBy;
