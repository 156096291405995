angular
    .module('klaro')
    .directive('workspaceList', workspaceList);
function workspaceList($location, auth, navigation, confirm, workspaceRest, spaceEditionModal) {
    return {
        scope: {
            workspaces: '=',
            dimensions: '=',
            onClick: '&',
        },
        restrict: 'E',
        template: require('@/core/workspace/workspaceList.html'),
        link: function (scope) {
            scope.ordering = 'name';
            scope.reverse = false;
            scope.orderBy = function (by) {
                if (scope.ordering == by) {
                    scope.reverse = !scope.reverse;
                }
                else {
                    scope.ordering = by;
                    scope.reverse = false;
                }
            };
            scope.canDelete = function (t) {
                return t.code != 'admins';
            };
            scope.deleteWorkspace = function (t, $event) {
                $event.preventDefault();
                $event.stopPropagation();
                confirm(`You will remove workspace *${t.name}*. This action cannot be cancelled.`, () => {
                    workspaceRest
                        .drop(t)
                        .then(navigation.reload)
                        .catch(navigation.failed);
                });
            };
            scope.canEditPermissions = function (t) {
                return t.code != 'admins';
            };
            scope.editPermissions = function (t, $event) {
                $event.preventDefault();
                $event.stopPropagation();
                const data = {
                    dimensionsMode: t.space.dimensionsMode,
                    filtersMode: t.space.filtersMode,
                    dimensions: t.space.dimensions,
                    filters: t.space.filters,
                };
                spaceEditionModal
                    .open(data, scope.dimensions)
                    .result
                    .then((spaceData) => {
                    return workspaceRest
                        .saveWorkspaceSpace(t, spaceData)
                        .then(navigation.reload)
                        .catch(navigation.failed);
                });
            };
        },
    };
}
