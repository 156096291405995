angular
    .module('klaro')
    .directive('detailsAutoclose', detailsAutoclose)
    .directive('detailsAutoopen', detailsAutoopen);
function detailsAutoclose() {
    return {
        link: function (scope, elm) {
            elm.on('click', (evt) => {
                elm.closest('details')[0].removeAttribute('open');
            });
        },
    };
}
function detailsAutoopen() {
    return {
        link: function (scope, elm) {
            elm.on('mouseover', (evt) => {
                elm[0].setAttribute('open', true);
            });
        },
    };
}
