import * as LiveScript from 'livescript';
import * as Prelude from 'prelude-ls';
import DateTime from '../ext/livescript/DateTime';
import Interval from '../ext/livescript/Interval';
const LS_TPL = `
{ List, Obj, Str, Func, Num, DateTime, Interval } = @
{ max, min } = @.Num
{ map, filter, reject, head, tail, find, any, all } = @.List
{ now, today } = @.DateTime
return (_) ->
`;
const Context = {};
Context.DateTime = DateTime;
Context.Interval = Interval;
// Evaluation context
// basically all Prelude + extra stuff that we don't want
// to add the Prelude itself.
const from = [Prelude, DateTime.Statics];
from.forEach((coll) => {
    Object.getOwnPropertyNames(coll).forEach((prop) => {
        Context[prop] = coll[prop];
    });
});
class Evaluator {
    compile(expr) {
        expr = LS_TPL + expr.replace(/^/gm, '  ');
        const compiled = LiveScript.compile(expr, {
            bare: true,
            header: false,
            const: true,
        });
        return Function(compiled).call(Context).bind(Context);
    }
    evaluate(expr, story) {
        return this.compile(expr)(story);
    }
}
export default Evaluator;
