angular
    .module('klaro')
    .factory('integrationRest', integrationRest);
function integrationRest($http) {
    return {
        getList,
    };
    function getList() {
        return $http
            .get('/api/integrations')
            .then((response) => {
            return response.data;
        });
    }
}
