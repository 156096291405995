import { examples } from '@klaro/corejs/examples';
import { jsonToState } from '@klaro/corejs/state';
import { DisplayModes, Project, Session } from '@klaro/corejs/model';
import { createKlaroStore, factorConnector, KlaroClient, KlaroConnector, KlaroStoreProvider, MemoryStore, } from '@klaro/corejs/client';
export const PlaygroundProject = new Project({
    id: '589c3324-6574-400a-8361-3c8d9df6a96e',
    name: 'Playground',
    subdomain: 'playground',
    isPublic: true,
    isTemplate: false,
    isBlocked: false,
    isLocked: false,
    timezone: 'Europe/Brussels',
    locale: 'en-BE',
    url: 'https://playground.klaro.cards',
    logoUrl: null,
    revision: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    logoThumbUrl: undefined,
});
angular
    .module('klaro.kernel')
    .constant('KernelConstants', {})
    .service('KernelPlayground', KernelPlayground);
/**
 * This service encapsulates the kernel logic of loading the current project
 * through a backend call and handling any error response.
 *
 * It is aimed at being `init`ed *before* starting the application, since the
 * initialization process requires an async call to the backend.
 */
function KernelPlayground($log, $http, KernelConstants) {
    async function init() {
        $log.info('Booting the Klaro Playground...');
        const example = await examples.onboarding();
        let state = await jsonToState(example);
        state = await state.with({
            board: state.board.withAvailableDisplayModes(DisplayModes.filter(m => m.includeInPlayground)),
        });
        const storeProvider = new KlaroStoreProvider(() => KernelConstants.store, (newStore) => { KernelConstants.store = newStore; });
        KernelConstants.client = new KlaroClient(storeProvider)
            .withPreferencesStore(new MemoryStore())
            .withDefaultEventually();
        const user = {
            workspaces: [{ code: 'admins' }],
        };
        KernelConstants.store = createKlaroStore(KernelConstants.client, {
            connector: factorConnector(KernelConstants.client, KlaroConnector.local),
            baseImgUrl: 'https://blog.klaro.cards',
            projects: [],
            boards: [],
            board: state,
            project: PlaygroundProject,
            session: new Session({
                user: user,
                viewAs: 'admins',
            }),
        });
        KernelConstants.client.init();
        installEvents(KernelConstants.client);
        await KernelConstants.client.boot();
        return Promise.resolve(KernelConstants.client);
    }
    function get() {
        return KernelConstants.client;
    }
    function installEvents(client) {
    }
    // This is the service API
    return {
        init: init,
        get: get,
    };
}
