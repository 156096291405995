import Dimension from '../Dimension';
class Workspace extends Dimension {
    get category() {
        return 'advanced';
    }
    participatesToTextSearch() {
        return true;
    }
    canValuesBeDeleted() {
        return false;
    }
    getValueDeletionPlace() {
        return 'workspaces';
    }
}
export default Workspace;
