import { UserDefined } from '../../model';
import { DimensionCandidate } from './DimensionCandidate';
import { toId } from './types';
const TYPICAL_BINOMES = [
    [/^y(es)?$/i, /^n(o)?$/i],
    [/^1$/, /^0$/],
    [/^V$/i, /^X$/i],
    [/^true$/i, /^false$/i],
];
export class ToggleCandidate extends DimensionCandidate {
    _deliver() {
        return [UserDefined, {
                values: [
                    {
                        id: toId(this.getTrue()),
                        label: 'Yes',
                        semantics: true,
                        color: '#ffffff',
                        ordering: 1000000,
                        deprecated: false
                    },
                    {
                        id: toId(this.getFalse()),
                        label: 'No',
                        semantics: false,
                        color: '#ffffff',
                        ordering: 2000000,
                        deprecated: false
                    },
                ],
            }];
    }
    placeBet() {
        if (this.isMultiple) {
            return 0.0;
        }
        // We only accept a max of 2 values
        const nonNulls = [...this.values.keys()].filter(Boolean);
        if (nonNulls.length > 2) {
            return 0.0;
        }
        return !!this.hasTrue() && !!this.hasFalse()
            ? 1.0
            : 0.0;
    }
    get realm() {
        return [...this.values.keys()];
    }
    hasTrue() {
        return this.getTrue() !== undefined;
    }
    hasFalse() {
        return this.getFalse() !== undefined;
    }
    getTrue() {
        return this.realm.find((v) => {
            return TYPICAL_BINOMES.find(([trueRx, _falseRx]) => {
                return v?.toString().match(trueRx);
            });
        });
    }
    getFalse() {
        return this.realm.find((v) => {
            return TYPICAL_BINOMES.find(([_trueRx, falseRx]) => {
                return v?.toString().match(falseRx);
            });
        });
    }
}
