angular
    .module('klaro')
    .directive('expressionInput', expressionInput);
function expressionInput() {
    return {
        restrict: 'E',
        require: '^ngModel',
        template: require('@/core/dimension/dimensionSemanticsWidget/expressionInput.html'),
        link: function (scope, elm, attrs, ngModelController) {
            const input = elm.find('textarea');
            // Specify how UI should be updated
            ngModelController.$render = function () {
                input.val(ngModelController.$viewValue);
            };
            ngModelController.$render();
            // Listen to input change and update the model accordingly
            input.on('change', () => {
                scope.$evalAsync(() => {
                    const val = input.val();
                    ngModelController.$setViewValue(val);
                });
            });
        },
    };
}
