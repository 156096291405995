import TogglableSectionsList from './support/TogglableSectionsList';
const SECTIONS = [
    {
        code: 'active',
        name: 'Active members',
        filter: (m) => {
            return m.memberType === 'user' && !m.deletedAt && !!m.joinedAt;
        },
    },
    {
        code: 'invited',
        name: 'Invited members',
        filter: (m) => {
            return m.memberType === 'user' && !m.deletedAt && m.joinedAt === null && !!m.invitedAt;
        },
    },
    {
        code: 'toInvite',
        name: 'Members to invite',
        description: 'Link and reference cards',
        filter: (m) => {
            return m.memberType === 'user' && !m.deletedAt && m.joinedAt === null && m.invitedAt === null;
        },
    },
    {
        code: 'apps',
        name: 'Third party apps',
        filter: (m) => {
            return m.memberType === 'app' && !m.deletedAt;
        },
    },
    {
        code: 'deleted',
        name: 'Former members',
        filter: (m) => {
            return m.memberType === 'user' && m.deletedAt;
        },
    },
];
class MemberList extends TogglableSectionsList {
    get items() {
        return this.memoize('items', '', () => {
            return this._filterMembers(this.members);
        });
    }
    get sectionsInfo() {
        return SECTIONS;
    }
    _filterMembers(members) {
        if (!this.searchText) {
            return members;
        }
        const rx = new RegExp(this.searchText, 'i');
        const mmatch = (member) => {
            return [
                member.nickname,
                member.user ? member.user.firstname : null,
                member.user ? member.user.lastname : null,
                member.user ? member.user.email : null,
            ].some(x => x && x.match(rx));
        };
        const wsmatch = (member) => {
            return member.workspaces.some(w => w.name.match(rx));
        };
        return members.filter(m => mmatch(m) || wsmatch(m));
    }
}
export default MemberList;
