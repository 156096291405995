var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Node_options;
const entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    '\'': '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;',
};
class Node {
    constructor(options) {
        _Node_options.set(this, void 0);
        __classPrivateFieldSet(this, _Node_options, options || {}, "f");
    }
    get options() {
        return __classPrivateFieldGet(this, _Node_options, "f");
    }
    get board() {
        return __classPrivateFieldGet(this, _Node_options, "f").board;
    }
    get globalContext() {
        return __classPrivateFieldGet(this, _Node_options, "f").globalContext;
    }
    escapeHtml(string) {
        return String(string).replace(/[&<>"'`=/]/g, (s) => entityMap[s]);
    }
    hLevel(args) {
        return args && args.hLevel || 1;
    }
    withNextHLevel(args, inc = 1) {
        return {
            ...args,
            hLevel: this.hLevel(args) + inc,
        };
    }
    yield(stories, args) {
        if (!__classPrivateFieldGet(this, _Node_options, "f").yield) {
            return '';
        }
        return __classPrivateFieldGet(this, _Node_options, "f").yield(stories, args);
    }
    before(stories, args) {
        if (!__classPrivateFieldGet(this, _Node_options, "f").before) {
            return '';
        }
        return __classPrivateFieldGet(this, _Node_options, "f").before(stories, args);
    }
    after(stories, args) {
        if (!__classPrivateFieldGet(this, _Node_options, "f").after) {
            return '';
        }
        return __classPrivateFieldGet(this, _Node_options, "f").after(stories, args);
    }
}
_Node_options = new WeakMap();
export default Node;
