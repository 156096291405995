angular
    .module('klaro')
    .directive('storyGallery', storyGallery);
function storyGallery() {
    return {
        restrict: 'E',
        template: require('@/core/story/storyGallery.html'),
        scope: {
            'onClick': '&',
            'canWrite': '=',
        },
        require: '^board',
        link: function (scope, elm, attrs, board) {
            function onBoardChanged() {
                scope.stories = board.getBoardState().stories.visible;
            }
            board.$watch('board', onBoardChanged, scope);
        },
    };
}
