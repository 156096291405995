import { ProjectMember } from '@klaro/corejs/model';
angular
    .module('klaro')
    .factory('memberRest', memberRest);
function memberRest($http, auth) {
    const dress = ProjectMember.dress;
    return {
        list: list,
        create: create,
        update: update,
        remove: remove,
        restore: restore,
        invite: invite,
        inviteWithoutNickname: inviteWithoutNickname,
        setPassword: setPassword,
    };
    function list() {
        return $http
            .get('/api/members/')
            .then((response) => {
            return response.data.map(dress);
        });
    }
    function create(member) {
        return $http
            .post('/api/members/', member.toRaw ? member.toRaw() : member)
            .then((response) => {
            return dress(response.data);
        });
    }
    function update(member, update) {
        return $http
            .patch(`/api/members/${member.nickname}`, update.toRaw ? update.toRaw() : update)
            .then((response) => {
            // in case we updated the current member
            return auth.refreshProfile().then(() => {
                return dress(response.data);
            });
        });
    }
    function remove(member) {
        return $http
            .delete(`/api/members/${member.nickname}`);
    }
    function restore(member) {
        return $http
            .post(`/api/members/${member.nickname}/actions/restore`, {});
    }
    function invite(member, invitation, workspace) {
        const data = workspace && workspace.code
            ? angular.extend({}, invitation, { workspace: { code: workspace.code } })
            : invitation;
        return $http
            .post(`/api/members/${member.nickname}/invitations/`, data)
            .then((response) => {
            return dress(response.data);
        });
    }
    function inviteWithoutNickname(invitationData) {
        return $http
            .post('/api/members/invitations/', invitationData)
            .then((response) => {
            return dress(response.data);
        });
    }
    function setPassword(member, passwordData) {
        return $http
            .post(`/api/members/${member.nickname}/password`, passwordData)
            .then((response) => {
            return dress(response.data);
        });
    }
}
