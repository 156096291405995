import Dimension from '../Dimension';
class Progress extends Dimension {
    isUserDefinedSemantics() {
        return true;
    }
    usesSemantics() {
        return !!this.semanticsType;
    }
    participatesToTextSearch() {
        return true;
    }
}
export default Progress;
