angular
    .module('klaro')
    .directive('blackholeExplain', blackholeExplain);
function blackholeExplain() {
    return {
        restrict: 'E',
        template: require('@/support/blackholeExplain.html'),
        scope: {
            cell: '=',
            class: '@',
        },
        replace: true,
        link: function (scope, _elm, _attrs) {
            scope.blackHoleTooltipText = '';
            function init(cell) {
                if (!cell) {
                    return;
                }
                scope.blackHoleTooltipText = cell.blackHoleTooltipText;
            }
            init();
            scope.$watch('cell', init);
        },
    };
}
