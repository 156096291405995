angular
    .module('klaro')
    .directive('rightHeader', rightHeader);
function rightHeader($rootScope, navigation, Project, recentNewsService) {
    return {
        template: require('@/layout/header/rightHeader.html'),
        scope: true,
        replace: true,
        link: function (scope) {
            scope.projectIsLocked = true;
            function refreshNews() {
                recentNewsService.getOne().then((news) => {
                    scope.hasNews = recentNewsService.hasNews();
                    scope.unreadNewsCount = recentNewsService.unreadCount();
                    scope.hasUnreadNews = scope.unreadNewsCount > 0;
                    scope.news = news;
                    scope.newsHref = scope.news ? scope.news.url : 'https://www.klaro.cards/en/blog#filter=news-updates';
                });
            }
            refreshNews();
            scope.trackNewsClicked = function () {
                recentNewsService.trackNewsClicked(scope.news).then(refreshNews);
                return true;
            };
            function refreshProjectInfo() {
                Project.get().then((p) => {
                    scope.projectIsTemplate = p.isTemplate;
                    scope.projectIsLocked = p.isLocked;
                    scope.projectTemplate = p.template;
                });
            }
            refreshProjectInfo();
            $rootScope.$on('klaro.project.changed', refreshProjectInfo);
            scope.websiteUrl = navigation.getWebsiteUrl();
            scope.gotoWebsite = function () {
                return navigation.gotoWebsite(true);
            };
        },
    };
}
