import { Summary } from '@klaro/corejs/model';
angular
    .module('klaro')
    .factory('summaryRest', summaryRest);
function summaryRest($http) {
    const dress = Summary.dress;
    return {
        dress: dress,
        newOne: newOne,
        list: list,
        save: save,
    };
    function newOne() {
        return dress({});
    }
    function list() {
        return $http
            .get('/api/summaries/')
            .then((response) => {
            return response.data.map(dress);
        });
    }
    function save(summary) {
        const data = summary.toRaw();
        return $http
            .post('/api/summaries/', data)
            .then((response) => {
            return dress(response.data);
        });
    }
}
