angular
    .module('klaro')
    .directive('profileModalPart', profileModalPart);
function profileModalPart() {
    return {
        restrict: 'E',
        scope: {
            user: '=',
            userData: '=',
            ctrl: '=',
        },
        template: require('@/auth/profileModal/profileModalPart.html'),
        link: function (scope) {
            scope.canSave = function () {
                return isDirty();
            };
            scope.save = function () {
                scope
                    .ctrl
                    .patchUser()
                    .then(() => {
                    if (scope.profileForm) {
                        scope.profileForm.$setPristine();
                    }
                    return scope.ctrl.load('menu');
                })
                    .catch(scope.ctrl.onError);
            };
            function isDirty() {
                return scope.profileForm.$dirty;
            }
        },
    };
}
