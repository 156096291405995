import { LocalBoardConnector } from './LocalBoardConnector';
import { LocalStoriesConnector } from './LocalStoriesConnector';
import { MemoryPatchStorage } from './MemoryPatchStorage';
import { LocalAuthenticationConnector } from './LocalAuthenticationConnector';
import { LocalMyConnector } from './LocalMyConnector';
import { LocalProjectConnector } from './LocalProjectConnector';
import { PatchableStorage } from './PatchableStorage';
import { LocalSeshatConnector } from './LocalSeshatConnector';
import { LocalSummariesConnector } from './LocalSummariesConnector';
import { LocalDimensionsConnector } from './LocalDimensionsConnector';
import { LocalInstallersConnector } from './LocalInstallersConnector';
export class LocalConnector {
    constructor(client, patchStorage) {
        this.capabilities = {
            cards: {
                fullDetails: false,
                coverImage: false,
                attachments: false,
                actions: {
                    archive: false,
                    pin: false
                },
            },
            boards: {
                actions: {
                    save: false,
                    delete: false,
                },
            },
        };
        patchStorage = patchStorage || {
            storyPatches: new MemoryPatchStorage(),
            dimensionPatches: new MemoryPatchStorage(),
            boardsPatches: new MemoryPatchStorage(),
            projectsPatches: new MemoryPatchStorage(),
        };
        this.store = {
            stories: new PatchableStorage(new Map(), patchStorage.storyPatches),
            boards: new PatchableStorage(new Map(), patchStorage.boardsPatches),
            projects: new PatchableStorage(new Map(), patchStorage.projectsPatches),
            dimensions: new PatchableStorage(new Map(), patchStorage.dimensionPatches),
            dimensionValues: new Map(),
        };
        this.boards = new LocalBoardConnector(client, this.store);
        this.stories = new LocalStoriesConnector(client, this.store);
        this.dimensions = new LocalDimensionsConnector(client, this.store);
        this.auth = new LocalAuthenticationConnector(client);
        this.my = new LocalMyConnector(client, this.store);
        this.project = new LocalProjectConnector(client, this.store);
        this.summaries = new LocalSummariesConnector(client, this.store);
        this.installers = new LocalInstallersConnector(client, this.store);
        this.seshat = new LocalSeshatConnector(client);
    }
}
