import Computed from './Computed';
import DateDim from './DateDim';
import { DateTime } from 'luxon';
class ComputedDate extends Computed {
    getBase() {
        return DateDim;
    }
    getStoryHumanValue(story, _globalContext) {
        const date = this.getStoryHighLevelValue(story, _globalContext);
        return date ? date.toLocaleString(DateTime.DATE_SHORT) : '';
    }
    getTableAlignmentClass() {
        return 'align-right';
    }
    canBeUsedAsDateBoundary() {
        return true;
    }
}
export default ComputedDate;
