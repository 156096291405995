import { LoadMyBoards } from '@klaro/corejs/client';
import { Refresher } from '@klaro/corejs/utils';
angular
    .module('klaro')
    .service('MyBoards', MyBoards);
function MyBoards($rootScope, auth, KernelClient, $q) {
    const refresher = new Refresher(() => KernelClient.store.boards, () => KernelClient.run(new LoadMyBoards), $q);
    function inCurrentWorkspace() {
        return refresher.get()
            .then((boards) => {
            const viewAs = auth.getViewAs() && auth.getViewAs().code;
            return boards.filter((board) => {
                const workspaces = board.workspacePermissions.getWorkspaceCodes();
                return workspaces.indexOf(viewAs) > -1;
            });
        });
    }
    function defaultOne() {
        const user = auth.getUser();
        if (!user) {
            return $q.resolve(null);
        }
        else {
            return refresher.get()
                .then((boards) => {
                const homeBoard = user && user.preferences && user.preferences.homeBoard;
                const board = boards.find(b => b.location === homeBoard);
                if (board) {
                    return board;
                }
                else if (!auth.isLogged() || !auth.isProjectUser()) {
                    return boards[0];
                }
            });
        }
    }
    const onEvent = refresher.refresh.bind(refresher);
    $rootScope.$on('klaro.user.changed', onEvent);
    $rootScope.$on('klaro.project.changed', onEvent);
    $rootScope.$on('klaro.application.reload', onEvent);
    $rootScope.$on('klaro.board.changed', onEvent);
    $rootScope.$on('klaro.boards.changed', onEvent);
    return {
        list: refresher.get.bind(refresher),
        defaultOne: defaultOne,
        inCurrentWorkspace: inCurrentWorkspace,
    };
}
