export const onboarding = async () => {
    return {
        board: {
            id: '321806e1-e957-4457-8f90-ce8cf55e2a56',
            label: 'Onboarding',
            mode: 'kanban',
            filters: {},
            displayDimensionsOnCards: true,
            displayDimensionLabels: false,
            displayNoneOnCards: false,
            decksOpen: true,
            background: 'default',
            anchors: {
                displayBy: ['color'],
                rowsBy: ['size'],
            },
            coloredDimension: 'color',
            dimensions: [
                {
                    id: 'd4712b75-9ff9-4154-b8f8-b3e57e462a53',
                    code: 'color',
                    label: 'Color',
                    datatype: 'UserDefined',
                    required: true,
                    userEditable: true,
                    tagging: true,
                    supportsColor: true,
                    values: [
                        { id: null, label: 'No value', color: '#ffffff', ordering: 0 },
                        { id: 'navy', label: 'Navy', color: '#006aba', ordering: 100000 },
                        { id: 'blood', label: 'Blood', color: '#e01514', ordering: 300000 },
                        { id: 'green', label: 'Green', color: '#00b048', ordering: 400000 },
                    ],
                },
                {
                    id: 'd4712b75-9ff9-4154-b8f8-b3e57e462a54',
                    code: 'size',
                    label: 'Size',
                    datatype: 'UserDefined',
                    required: false,
                    userEditable: true,
                    tagging: true,
                    supportsColor: true,
                    values: [
                        { id: null, label: 'No value', color: '#ffffff', ordering: 0 },
                        { id: 's', label: 'Small', color: '#ffffff', ordering: 100000 },
                        { id: 'm', label: 'Medium', color: '#ffffff', ordering: 200000 },
                        { id: 'l', label: 'Large', color: '#ffffff', ordering: 300000 },
                    ],
                },
            ],
            extraSettings: {
                onCardClick: {
                    type: 'quickEdit',
                    options: {},
                },
            },
        },
        stories: [
            {
                id: 'a20cff04-ef33-4581-a515-22c8cd8b7a95',
                title: 'Welcome to Klaro Cards\nthe ultimate tool for managing information in a complex, multi-dimensional world.',
                subtitle: '',
                color: 'navy',
                size: null,
            },
        ],
    };
};
