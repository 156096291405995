import { DataObject } from '../utils';
class User extends DataObject {
    get syncMode() {
        return (this.preferences || {}).syncMode;
    }
    static dress(raw) {
        if (raw.constructor && raw.constructor === this) {
            return raw;
        }
        return new User(raw);
    }
}
export default User;
