angular
    .module('klaro')
    .directive('pinnedCards', pinnedCards);
function pinnedCards(pinnnedStoriesRest, MyPinnedStories) {
    return {
        template: require('@/core/dashboard/pinnedCards.html'),
        scope: true,
        require: '^board',
        link: function (scope, elm, attrs, boardCtrl) {
            const unlisten = MyPinnedStories.listen((pinned) => {
                scope.pinnedStories = pinned;
            });
            scope.$on('$destroy', unlisten);
            scope.storyClicked = (story, $event) => {
                boardCtrl.storyClicked(story, $event);
            };
        },
    };
}
