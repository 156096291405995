angular
    .module('klaro')
    .directive('boardRequiredDimensions', boardRequiredDimensions);
function boardRequiredDimensions($filter) {
    return {
        restrict: 'EA',
        template: require('@/core/board/boardRequiredDimensions.html'),
        link: function (scope) {
            scope.requiredDimensionCandidates = $filter('orderBy')(scope.board.dimensions.filter((d) => {
                return !d.required && d.canBeEditedAsCardDimension();
            }), '+label');
        },
    };
}
