angular
    .module('klaro')
    .directive('boardSummariesSelector', boardSummariesSelector);
function boardSummariesSelector(ArrayUtils) {
    return {
        restrict: 'EA',
        scope: {
            sums: '=',
        },
        transclude: true,
        require: '^ngModel',
        template: require('@/core/board/boardSummariesSelector.html'),
        link: function (scope, elm, attrs, ngModel) {
            elm.addClass('board-summaries-selector');
            // Widget model
            function getBoardSummaries() {
                return ngModel.$modelValue ? ngModel.$modelValue : [];
            }
            // Widget view
            function installView(sums) {
                scope.candidates = scope
                    .sums
                    .map((s) => {
                    const sum = sums.find((ss) => {
                        return ss.code == s.code;
                    });
                    return Object.assign({}, s.toRaw(), {
                        selected: !!sum,
                        ordering: (sum && sum.ordering) || 10000000,
                    });
                })
                    .sort((s1, s2) => {
                    return s1.ordering - s2.ordering;
                });
            }
            function format() {
                installView(getBoardSummaries());
            }
            ngModel.$formatters.push(format);
            scope.$watch('sums', () => {
                installView(getBoardSummaries());
            });
            // ngModel sync
            function syncNgModel() {
                const boardSummaries = scope.candidates
                    .filter((c) => {
                    return c.selected;
                })
                    .map((c) => {
                    return {
                        code: c.code,
                        label: c.label,
                        ordering: c.ordering,
                        expr: c.expr,
                    };
                });
                ngModel.$setViewValue(boardSummaries);
                installView(boardSummaries);
            }
            // Widget operations
            scope.switchToggle = function (candidate) {
                candidate.selected = !candidate.selected;
                syncNgModel();
            };
            scope.onCandidateDrop = function (index, candidate) {
                scope.candidates = ArrayUtils.moveInOrdering(scope.candidates, candidate, index);
                syncNgModel();
            };
        },
    };
}
