angular
    .module('klaro')
    .directive('dimensionSemanticsWidget', dimensionSemanticsWidget);
function dimensionSemanticsWidget($compile) {
    return {
        restrict: 'E',
        scope: {
            dimension: '=',
            ngModel: '=',
        },
        link: function (scope, elm, attrs) {
            if (scope.dimension.usesSemantics() && scope.dimension.semanticsType) {
                const templateCode = `${scope.dimension.semanticsType.toLowerCase()}-input`;
                elm.html(`<${templateCode} />`);
                $compile(elm.contents())(scope);
            }
        },
    };
}
