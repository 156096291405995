angular
    .module('klaro')
    .directive('newWorkspaceLink', newWorkspaceLink);
function newWorkspaceLink($location, newWorkspaceModal, robust) {
    return {
        scope: {
            onSubmit: '&',
        },
        restrict: 'EA',
        template: require('@/core/workspace/newWorkspaceLink.html'),
        link: function (scope, elm, attrs) {
            scope.newWorkspace = function () {
                newWorkspaceModal
                    .open()
                    .result
                    .then((workspace) => {
                    scope.onSubmit({ workspace: workspace });
                })
                    .catch(robust.noop);
            };
        },
    };
}
