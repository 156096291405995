import DataObject from '../../utils/DataObject';
export default class TogglableSection extends DataObject {
    get items() {
        return this.memoize('items', '', () => {
            return this.list.items.filter(this.filter);
        });
    }
    get size() {
        return this.items.length;
    }
    isCollapsed() {
        return this.list.isCollapsed(this);
    }
}
