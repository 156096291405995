angular
    .module('klaro')
    .directive('reloadNeededModalPart', reloadNeededModalPart);
function reloadNeededModalPart(navigation) {
    return {
        restrict: 'E',
        template: require('@/auth/profileModal/reloadNeededModalPart.html'),
        link: function (scope) {
            scope.refresh = function () {
                navigation.refresh();
            };
        },
    };
}
