angular
    .module('klaro')
    .controller('DashboardController', DashboardController);
function DashboardController($scope, board, auth, Title, navigation) {
    $scope.board = board;
    $scope.user = auth.getUser();
    $scope.initLoadHandled = false;
    $scope.$watch('board', (b) => {
        if (!b) {
            return;
        }
        Title.setCurrentPage('Dashboard');
        if ($scope.initLoadHandled) {
            return;
        }
        $scope.boardCtrl.shouldCreateKindValue({
            andSwitch: false,
        });
        const hash = navigation.getHash('');
        if (hash.match(/^\d+/)) {
            $scope.boardCtrl
                .openFullEditStoryModal(parseInt(hash), {
                thenHash: 'clearHash',
            });
        }
        $scope.hasSearch = function () {
            return $scope.board.hasSearch();
        };
        $scope.initLoadHandled = true;
    });
}
