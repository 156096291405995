angular
    .module('klaro')
    .directive('newStoryHover', newStoryHover);
/**
 * <div new-story-hover defaults="{...}"> directive.
 *
 * Aimed at being used on a story container that allows adding a new card
 * (when the board is writable).
 *
 * The directive tracks mouse clicks and opens the new story dialog, with
 * defaults provided. Main elements of the container, especially cards themselves
 * must prevent click events from being propagated up to the container, to avoid
 * suprious opening of the dialog.
 *
 * The directive also follows the user mouse and adds a 'mouseover' CSS class on
 * the container element. That CSS class can be used to highlight a 'New card'
 * link or whateber. The controller also exposes `startHover` and `dropHover`
 * methods, that allow finer control of the CSS class appearance.
 *
 * This directive's CSS supposes that a .new-story-link element is present in
 * the container and provides a base styling for it.
 */
function newStoryHover() {
    return {
        restrict: 'A',
        require: ['^board', 'newStoryHover'],
        scope: {
            newStoryHover: '=',
        },
        controller: ['$scope', function ($scope) {
                // Adds the mouseover CSS class through a scope variable.
                this.startHover = function () {
                    $scope.$apply(() => {
                        $scope.mouseover = true;
                    });
                };
                // Removes the mouseover CSS class a scope variable.
                this.dropHover = function () {
                    $scope.$apply(() => {
                        $scope.mouseover = false;
                    });
                };
            }],
        link: function (scope, elm, attrs, controllers) {
            const board = controllers[0];
            const myself = controllers[1];
            elm.on('mouseover', () => {
                myself.startHover();
            });
            elm.on('mouseleave', () => {
                myself.dropHover();
            });
            // Actually puts the class when the associated scope variable changes.
            scope.$watch('mouseover', (yes) => {
                if (yes) {
                    elm.addClass('mouseover');
                }
                else {
                    elm.removeClass('mouseover');
                }
            });
            function getDefaults() {
                if (attrs.defaults) {
                    return scope.$parent.$eval(attrs.defaults);
                }
                else {
                    return scope.newStoryHover;
                }
            }
            // When the container is clicked, open the new story dialog on defaults.
            elm.click((event) => {
                if (!board.canWrite() || board.hasStorySelection()) {
                    return;
                }
                if (event.target.closest('.collapsed')) {
                    return;
                }
                const defaults = getDefaults() || {};
                board.openNewStoryModal(defaults, event.ctrlKey || event.metaKey);
            });
        },
    };
}
