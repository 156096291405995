import { Board } from '@klaro/corejs/model';
angular
    .module('klaro')
    .service('newBoardModal', newBoardModal)
    .controller('NewBoardModalController', NewBoardModalController);
function newBoardModal(klaroModal) {
    return {
        open: function (options) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/board/newBoardModal.html'),
                controller: 'NewBoardModalController',
                windowClass: 'klaro-modal new-board-modal',
                size: 'small',
                resolve: {
                    options: function () { return options; },
                },
            });
        },
    };
}
function NewBoardModalController($scope, $uibModalInstance, options, Config, $filter) {
    $scope.title = options.title;
    $scope.fastTrack = options.fastTrack;
    $scope.formData = {
        mode: options.mode,
        startFrom: options.isDashboard ? 'scratch' : null,
    };
    $scope.isDashboard = options.isDashboard ?? false;
    $scope.boardLabelMaxLength = Config.boardLabelMaxLength;
    const defaultBoardNames = {
        current: `Copy of ${options.name}`,
        scratch: 'New board',
    };
    $scope.getDisplayModes = Board.getDisplayModes().map((mode) => {
        const capitalizedName = $filter('capitalize')(mode.code);
        mode.icon = `mode-icon icon-View-${capitalizedName}`;
        return mode;
    });
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.save = function () {
        $uibModalInstance.close($scope.formData);
    };
    $scope.$watch('formData.label', (label) => {
        if (label) {
            const rx = new RegExp('[^a-z0-9-]+', 'g');
            $scope.formData.location = label.toLowerCase().replace(rx, '-');
        }
    });
    $scope.$watch('formData.startFrom', (startFrom) => {
        if (startFrom) {
            $scope.formData.label = defaultBoardNames[startFrom];
        }
    });
    $scope.canSave = function () {
        return $scope.newBoardForm.$valid && $scope.formData.label.length <= $scope.boardLabelMaxLength;
    };
    $scope.saveButtonLabel = $scope.fastTrack ? 'Save board' : 'Next';
    $scope.displayBoardObjectiveFormGroup = false;
    $scope.toggleBoardObjectiveFormGroup = function () {
        $scope.displayBoardObjectiveFormGroup = !$scope.displayBoardObjectiveFormGroup;
    };
    $scope.modeNeedsDisplayBy = function () {
        return Board.getDisplayMode($scope.formData.mode).supportsDisplayByAnchor;
    };
    options.dimensions ?? (options.dimensions = []);
    $scope.displayByDimensions = $filter('orderBy')(options.dimensions.filter((d) => {
        return !d.attribute;
    }), '+label');
}
