import { Story } from '@klaro/corejs/model';
angular
    .module('klaro')
    .factory('searchStoriesRest', searchStoriesRest);
function searchStoriesRest($http) {
    return {
        searchStories: searchStories,
    };
    function searchStories(q, kinds, world) {
        const getParams = {};
        if (kinds) {
            getParams['kind[]'] = kinds;
        }
        if (q) {
            getParams.q = q;
        }
        return $http
            .get('/api/stories/search/', { params: getParams })
            .then((response) => {
            return response.data.map((data) => {
                return Story.dress(data, world);
            });
        });
    }
}
