import DateDim from './DateDim';
import { DateTime } from 'luxon';
import TimeUtils from '../../utils/TimeUtils';
class DateTimeDim extends DateDim {
    getNow(globalContext) {
        globalContext ?? (globalContext = {});
        return globalContext.now || TimeUtils.now();
    }
    usesTime() {
        return true;
    }
    getStoryHumanValue(story, _globalContext) {
        const date = this.getStoryHighLevelValue(story, _globalContext);
        return date ? date.toLocaleString(DateTime.DATETIME_SHORT) : '';
    }
}
export default DateTimeDim;
