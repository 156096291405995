angular
    .module('klaro')
    .service('AuthGlobals', AuthGlobals);
function AuthGlobals(auth, $rootScope, KernelClient) {
    function setGlobals() {
        $rootScope.ks = auth.getSession();
        $rootScope.ksp = $rootScope.ks.permissions;
        $rootScope.capabilities = KernelClient.capabilities;
        $rootScope.labs = $rootScope.ks.user?.preferences?.labs;
        $rootScope.isAdmin = auth.isAdmin();
        $rootScope.isOwner = auth.isOwner();
        $rootScope.isAnonymous = auth.isAnonymous();
        $rootScope.isProjectUser = auth.isProjectUser();
    }
    setGlobals();
    auth.$on('klaro.user.changed', setGlobals);
    auth.$on('klaro.viewAs.changed', setGlobals);
    auth.$on('klaro.application.reload', setGlobals);
}
