import TreeNode from './TreeNode';
export default class Forest {
    constructor() {
        this._root = new TreeNode({});
        this._nodes = {};
    }
    static build(sources, callbacks) {
        const forest = new Forest();
        const index = sources.reduce((idx, source) => {
            const id = callbacks.idOf(source);
            idx[id] = source;
            return idx;
        }, {});
        const ensure = (source) => {
            const id = callbacks.idOf(source);
            if (forest.knows(id)) {
                return forest.findNodes(id);
            }
            else {
                const parents = [];
                callbacks.parentIdsOf(source).forEach((parentId) => {
                    const parent = index[parentId];
                    if (parent) {
                        parents.push(...ensure(parent));
                    }
                });
                const data = callbacks.dataOf(source);
                if (parents.length === 0) {
                    return [forest.addNode(id, data)];
                }
                else {
                    return parents.map((parent) => {
                        const child = forest.addNode(id, data);
                        forest.connect(parent, child);
                        return child;
                    });
                }
            }
        };
        sources.forEach(ensure);
        return forest;
    }
    get roots() {
        return this._root.children;
    }
    get maxDepth() {
        return this._root.treeDepth;
    }
    knows(id) {
        return this._nodes[id] !== undefined;
    }
    findNodes(id) {
        return this._nodes[id];
    }
    addNode(id, data) {
        var _a;
        const node = new TreeNode({ id, ...data });
        (_a = this._nodes)[id] || (_a[id] = []);
        this._nodes[id].push(node);
        this._root.addChild(node);
        return node;
    }
    connect(parent, child) {
        this._root.removeChild(child);
        parent.addChild(child);
        return this;
    }
    dfs(callback, memo) {
        return this._root.dfs(callback, memo, false);
    }
    getLinearization() {
        return this._root.getLinearization(false);
    }
}
