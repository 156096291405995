angular
    .module('klaro')
    .directive('btnShortcut', btnShortcut);
function btnShortcut($rootScope, hotkeys) {
    return {
        restrict: 'A',
        link: function (scope, elm, attrs) {
            const letter = attrs.btnShortcut;
            const callback = attrs.btnShortcutCallback || attrs.ngClick;
            const combo = letter === 'esc' ? 'esc' : `mod+${letter.toLowerCase()}`;
            hotkeys.bindTo(scope).add({
                combo: combo,
                allowIn: ['INPUT', 'SELECT', 'TEXTAREA'],
                callback: (event) => {
                    event.preventDefault();
                    scope.$eval(callback);
                },
            });
            $rootScope.$watch('ctrlKeyIsPressed', (value) => {
                const label = elm.html();
                if (value) {
                    const index = label.indexOf(letter);
                    if (index >= 0) {
                        const newLabel = `${label.substring(0, index)}<u>${letter}</u>${label.substring(index + 1)}`;
                        elm.html(newLabel);
                        return;
                    }
                }
                else {
                    elm.html(label.replace('<u>', '').replace('</u>', ''));
                }
            });
        },
    };
}
