angular
    .module('klaro')
    .service('summaryEditModal', summaryEditModal)
    .controller('SummaryEditModalController', SummaryEditModalController);
function summaryEditModal(klaroModal) {
    return {
        open: function (summary) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/summary/summaryEditModal.html'),
                controller: 'SummaryEditModalController',
                windowClass: 'klaro-modal center-modal',
                size: 'small',
                resolve: {
                    summary: function () {
                        return summary;
                    },
                },
            });
        },
    };
}
function SummaryEditModalController($scope, $uibModalInstance, summaryRest, summary, robust) {
    $scope.formData = summary.toRaw();
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.close = function (summary) {
        $uibModalInstance.close(summary);
    };
    $scope.save = function () {
        summaryRest
            .save(summaryRest.dress($scope.formData))
            .then($scope.close)
            .catch(onError);
    };
    function onError(err) {
        $scope.error = robust.message(err.data);
    }
}
