angular
    .module('klaro')
    .service('boardImportModal', boardImportModal)
    .controller('BoardImportModalController', BoardImportModalController);
function boardImportModal(klaroModal) {
    return {
        open: function (boardCtrl, fileType, importer, fileInfo) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/board/import/boardImportModal.html'),
                controller: 'BoardImportModalController',
                windowClass: 'klaro-modal import-board-modal',
                size: 'medium',
                resolve: {
                    boardCtrl: function () {
                        return boardCtrl;
                    },
                    fileType: function () {
                        return fileType;
                    },
                    importer: function () {
                        return importer;
                    },
                    fileInfo: function () {
                        return fileInfo;
                    },
                },
            });
        },
    };
}
function BoardImportModalController($scope, $uibModalInstance, boardCtrl, importer, fileType, boardImportService, robust, $route, fileInfo, confirm) {
    $scope.modalCtrl = this;
    $scope.boardName = boardCtrl.getBoardState().label;
    $scope.fileType = fileType;
    $scope.importer = importer;
    $scope.importInput = {
        source: 'seshat',
    };
    $scope.importOptions = {};
    if (importer) {
        $scope.importInput.importer = importer.code;
        $scope.importOptions = Object.assign({}, importer.options);
    }
    $scope.reset = function () {
        $scope.modeStack = [];
        $scope.mode = 'dryRun';
        $scope.error = null;
        $scope.errorSeverity = 'danger';
        $scope.causes = null;
        if (fileInfo) {
            $scope.importInput.url = fileInfo.url;
            fileInfo = null;
            doImport(true);
        }
    };
    $scope.pushMode = function (mode) {
        if (mode == $scope.mode) {
            return;
        }
        $scope.error = null;
        $scope.errorSeverity = 'danger';
        $scope.modeStack.push($scope.mode);
        $scope.mode = mode;
    };
    $scope.back = function () {
        $scope.error = null;
        $scope.errorSeverity = 'danger';
        $scope.mode = $scope.modeStack.pop();
        if ($scope.importInput.url && $scope.mode == 'ready') {
            doImport(true);
        }
    };
    this.getBoard = function () {
        return boardCtrl.getBoardState();
    };
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.close = $scope.cancel;
    $scope.fileUploaded = function (promise) {
        if ($scope.mode == 'options') {
            return promise.then((fileInfo) => {
                $scope.importOptions.prelens = {
                    name: fileInfo.filename,
                    source: 'seshat',
                    url: fileInfo.url,
                };
                $scope.$apply();
            }).catch(onError);
        }
        else {
            return promise.then((fileInfo) => {
                $scope.importInput.url = fileInfo.url;
            }).then(doImport).catch(onError);
        }
    };
    $scope.removePreLens = function () {
        delete $scope.importOptions.prelens;
    };
    $scope.downloadPrelens = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        boardImportService
            .downloadPrelens($scope.importOptions)
            .catch(onError);
    };
    function doImport(dryRun = true) {
        $scope.error = null;
        $scope.errorSeverity = 'danger';
        const input = Object.assign({
            dryRun: dryRun,
        }, $scope.importInput);
        return boardImportService
            .call(boardCtrl.getBoardState(), input, $scope.importOptions)
            .then(dryRun ? onDryRunSuccess : onSuccess)
            .catch(onError);
    }
    $scope.importAnyway = function () {
        return doImport(false);
    };
    function onDryRunSuccess(response) {
        $scope.pushMode('ready');
        const result = $scope.result = response.data;
        $scope.hasErrors = result.errors.length > 0;
        $scope.hasManyErrors = result.errors.length > 5;
        $scope.hasSuccesses = result.successes > 0;
        if ($scope.hasErrors) {
            $scope.error = 'Some lines are invalid';
            $scope.errorSeverity = 'warning';
        }
    }
    function onSuccess() {
        $scope.pushMode('done');
    }
    $scope.lineNbs = function (error) {
        const lines = error.lines.slice(0, 3);
        if (error.lines.length == 1) {
            return `Line ${lines[0]}`;
        }
        else if (error.lines.length > 3) {
            return `Lines ${lines.join(', ')} ...`;
        }
        else {
            return `Lines ${lines.join(', ')}`;
        }
    };
    function onError(res) {
        console.log(res);
        $scope.savingImporter = false;
        $scope.errorSeverity = 'error';
        if (res.status === 400) {
            $scope.error = robust.message(res.data, false);
        }
        else {
            $scope.error = robust.message('Something went wrong :-( Please check your file or contact support', false);
        }
    }
    $scope.refresh = function () {
        $uibModalInstance.dismiss();
        $route.reload();
    };
    /// Saving as importer
    $scope.saveAsImporterData = {
        label: importer ? importer.label : null,
    };
    $scope.savingImporter = false;
    $scope.savedAsImporter = false;
    $scope.couldSaveAsImporter = function () {
        if (importer) {
            const labelChanged = importer.label != $scope.saveAsImporterData.label;
            const optionsChanged = !angular.equals($scope.importOptions, importer.options);
            return labelChanged || optionsChanged;
        }
        else {
            return Object.keys($scope.importOptions).length > 0;
        }
    };
    $scope.canSaveAsImporter = function () {
        return $scope.couldSaveAsImporter() && !!$scope.saveAsImporterData.label;
    };
    $scope.saveAsImporter = function () {
        $scope.savingImporter = true;
        const configuration = Object.assign({}, $scope.saveAsImporterData, {
            options: $scope.importOptions,
        });
        return boardImportService
            .saveAsImporter(boardCtrl.getBoardState(), configuration)
            .then(() => {
            $scope.savedAsImporter = true;
            $scope.savingImporter = false;
        })
            .catch(onError);
    };
    $scope.saveImporter = function () {
        const onSuccess = () => {
            $scope.savingImporter = true;
            $scope.refresh();
        };
        if (!importer) {
            return $scope.saveAsImporter().then(onSuccess);
        }
        else {
            $scope.savingImporter = true;
            const configuration = Object.assign({}, $scope.saveAsImporterData, {
                options: $scope.importOptions,
            });
            boardImportService
                .saveImporter(boardCtrl.getBoardState(), importer, configuration)
                .then(onSuccess)
                .catch(onError);
        }
    };
    $scope.removeImporter = function () {
        confirm('Are you sure you want to remove this configuration?', () => {
            boardImportService
                .dropImporter(boardCtrl.getBoardState(), $scope.importer)
                .then($scope.refresh)
                .catch(onError);
        });
    };
    /// info tips
    $scope.shownInfo = null;
    $scope.showInfo = function (info, $event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.shownInfo = info;
    };
    $scope.reset();
}
