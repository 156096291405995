angular
    .module('klaro')
    .service('StoryAutosaver', StoryAutosaver);
function StoryAutosaver(debounce) {
    function Autosaver(scope) {
        this.savingFreq = 60 * 1000;
        this.typingFreq = 5 * 1000;
        this.scope = scope;
        this.autosaveTimer = null;
        this.lastType = null;
        this.reset();
        scope.$watch(scope.canSave, this.reset.bind(this));
        scope.$watchGroup([
            'storyData.title',
            'storyData.specification',
        ], debounce(this.trackTyping.bind(this), 100));
        scope.$on('$destroy', this.cancel.bind(this));
    }
    Autosaver.prototype.reset = function () {
        this.cancel();
        this.autosaveTimer = setInterval(this.save.bind(this), this.savingFreq);
        this.error = null;
    };
    Autosaver.prototype.canSave = function () {
        return this.scope.canSave() && this.isLastTypeLongAgo();
    };
    Autosaver.prototype.save = function () {
        if (this.canSave()) {
            this.scope.save();
        }
    };
    Autosaver.prototype.saved = function () {
        this.reset();
    };
    Autosaver.prototype.failed = function (error) {
        this.error = error;
    };
    Autosaver.prototype.cancel = function () {
        if (this.autosaveTimer) {
            clearInterval(this.autosaveTimer);
        }
    };
    Autosaver.prototype.trackTyping = function () {
        this.lastType = new Date();
    };
    Autosaver.prototype.isLastTypeLongAgo = function () {
        if (!this.lastType) {
            return true;
        }
        const now = new Date();
        const diff = now.getTime() - this.lastType.getTime();
        return diff > this.typingFreq;
    };
    return {
        build: function ($scope) {
            return new Autosaver($scope);
        },
    };
}
