import { Dimension } from '../../../model';
export class CreateDimension {
    async run(client, args) {
        const raw = args.dimension.toRaw();
        if (raw.values) {
            // when sending System dimension instances, we don't want the null value
            // to be send, as the backend will send it back later.
            raw.values = raw.values.filter(v => v.id !== null);
        }
        const data = await client.store.connector.dimensions.post(raw);
        this._result = Dimension.dress(data);
    }
    get result() {
        return this._result;
    }
}
;
