angular
    .module('klaro')
    .service('spaceEditionModal', spaceEditionModal)
    .controller('SpaceEditionModalController', SpaceEditionModalController);
function spaceEditionModal(klaroModal) {
    return {
        open: function (space, dimensions) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/space/spaceEditionModal.html'),
                controller: 'SpaceEditionModalController',
                windowClass: 'klaro-modal space-edition-modal',
                size: 'medium',
                resolve: {
                    space: function () { return space; },
                    dimensions: function () { return dimensions; },
                },
            });
        },
    };
}
function SpaceEditionModalController($scope, $filter, $uibModalInstance, space, dimensions) {
    // Main state
    $scope.spaceData = angular.extend({}, space);
    $scope.dimensions = $filter('orderBy')(dimensions.filter((d) => { return d.canBeUsedOnDecks(); }), '+label');
    // Panels
    $scope.panel = 'filters';
    $scope.isDimensionsPanel = function () {
        return $scope.panel == 'dimensions';
    };
    $scope.isFiltersPanel = function () {
        return $scope.panel == 'filters';
    };
    $scope.setPanel = function (panel) {
        $scope.panel = panel;
    };
    // Buttons
    $scope.close = function () {
        $uibModalInstance.dismiss();
    };
    $scope.save = function () {
        $uibModalInstance.close($scope.spaceData);
    };
}
