import { LocalStoriesConnector } from './LocalStoriesConnector';
import { ApiBoardConnector } from '../api/ApiBoardConnector';
export class LocalBoardConnector extends ApiBoardConnector {
    constructor(client, data) {
        super(client);
        this.client = client;
        this.data = data;
    }
    single(id) {
        const client = this.client;
        const data = this.data;
        const superBoardSingle = super.single(id);
        return {
            stories: new LocalStoriesConnector(client, data, `/boards/${id}`),
            exports: {
                async post() {
                    throw new Error('not implemented');
                }
            },
            async get(params) {
                const board = await superBoardSingle.get(params);
                data.boards.addOriginals([board]);
                const patched = await data.boards.get(board.id);
                return patched;
            },
            patch() {
                throw new Error('Method not implemented.');
            },
            delete() {
                throw new Error('Method not implemented.');
            }
        };
    }
    search(_filters) {
        throw new Error('Method not implemented.');
    }
    get(_params) {
        throw new Error('Method not implemented.');
    }
    create(_resources) {
        throw new Error('Method not implemented.');
    }
    delete(_params) {
        throw new Error('Method not implemented.');
    }
    patch(_patch) {
        throw new Error('Method not implemented.');
    }
}
