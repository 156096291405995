import QRCode from 'qrcode';
angular
    .module('klaro')
    .directive('qrCode', qrCode);
function qrCode() {
    return {
        scope: {
            data: '=',
        },
        template: require('@/support/qrCode.html'),
        link: function (scope, element) {
            const canvas = element.find('canvas')[0];
            QRCode.toCanvas(canvas, scope.data, {
                width: 200,
                height: 200,
                errorCorrectionLevel: 'H',
            }, (error) => {
                if (error) {
                    scope.error = error.message;
                }
            });
        },
    };
}
