angular
    .module('klaro')
    .directive('paneModal', paneModal);
function paneModal(robust) {
    return {
        restrict: 'A',
        link: function (scope, iElement, iAttrs) {
        },
        controller: ['$scope', function ($scope) {
                this.onError = function (err) {
                    $scope.error = robust.message(err.data);
                    $scope.working = false;
                    $scope.success = false;
                };
                this.resetError = function () {
                    $scope.error = null;
                };
            }],
    };
}
