/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import Profiler from './Profiler';
import Cache from './Cache';
class DataObject {
    constructor(raw, world = undefined, propertyFilter) {
        this.__world = world;
        this.__propertyFilter = propertyFilter;
        this.__cache = new Cache();
        this.__installRaw(raw);
    }
    __installRaw(raw) {
        const self = this;
        self.__raw = raw;
        Object.getOwnPropertyNames(raw).forEach((prop) => {
            if (!Object.prototype.hasOwnProperty.call(self, prop) && (!self.__propertyFilter || self.__propertyFilter(prop))) {
                Object.defineProperty(self, prop, {
                    get: function () { return self.__raw[prop]; },
                });
            }
        });
        return this;
    }
    toRaw() {
        const rawOne = (value) => {
            if (value === null) {
                return null;
            }
            else if (Array.isArray(value)) {
                return value.map(rawOne);
            }
            else if (value instanceof Object && value.toRaw) {
                return value.toRaw();
            }
            else if (value instanceof Object) {
                const copy = {};
                for (const key in value) {
                    copy[key] = rawOne(value[key]);
                }
                return copy;
            }
            else {
                return value;
            }
        };
        const raw = {};
        for (const property in this.__raw) {
            const value = this.__raw[property];
            raw[property] = rawOne(value);
        }
        return raw;
    }
    memoize(name, key, computer) {
        if (!this.__cache.isValid(name, key)) {
            this.__cache.set(name, key, Profiler.track(`Memoize[${name}]`, computer));
        }
        return this.__cache.get(name);
    }
    memoize2(name, computer, invalidatingKeys = []) {
        const key = invalidatingKeys.reduce((memo, k) => {
            memo[k] = this[k];
            return memo;
        }, {});
        return this.memoize(name, key, computer);
    }
    clone(overrides, keepCache = true) {
        const raw = Object.assign({}, this.__raw, overrides);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const dup = new this.constructor(raw, this.__world, this.__propertyFilter);
        if (keepCache) {
            dup.__cache = this.__cache.dup();
        }
        return dup;
    }
}
export default DataObject;
