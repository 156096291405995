angular
    .module('klaro')
    .controller('OnboardController', OnboardController);
function OnboardController($scope, projectRest, templates, project, projectCategories, navigation, MarkdownIt, hotkeys, $filter) {
    const noDescription = 'No description available. Document your template in the documentation tab of the project settings.';
    // Prepate templates for this screen
    templates = templates.map((t) => {
        t.markdowned = MarkdownIt.render(t.description || noDescription);
        t.siteUrl = navigation.getTemplateWebsiteUrl(t);
        return t;
    });
    templates = $filter('orderBy')(templates, '+name');
    $scope.project = project;
    $scope.projectCategories = [
        { id: null, name: 'All', color: 'white' },
    ].concat(projectCategories);
    $scope.uiState = {
        // currently selected category
        category: null,
        // currently selected template
        template: project.template ? templates.find((t) => {
            return t.id == project.template.id;
        }) : null,
    };
    function installTemplates() {
        $scope.privateTemplates = templates.filter((t) => {
            return !t.isPublic;
        });
        $scope.hasOwnTemplates = $scope.privateTemplates.length > 0;
        $scope.publicTemplates = templates.filter((t) => {
            const cCateg = $scope.uiState.category;
            return t.isPublic && (!cCateg || t.category.id === cCateg);
        });
    }
    installTemplates();
    $scope.$watch('uiState.category', installTemplates);
    $scope.selectTemplate = function (t) {
        $scope.uiState.template = t;
    };
    $scope.bim = function () {
        if (!$scope.uiState.template) {
            return;
        }
        projectRest
            .reset({
            template: $scope.uiState.template.id,
            replaceCards: true,
            replaceMembers: false,
        })
            .then(() => navigation.gotoHome(true))
            .catch(navigation.failed);
    };
    $scope.bimLabel = function () {
        const t = $scope.uiState.template;
        if (t) {
            return `Install ${t.name}`;
        }
        else {
            return 'Install template';
        }
    };
    $scope.back = project.template ? navigation.back : navigation.gotoWebsite;
    hotkeys.bindTo($scope)
        .add({
        combo: 'esc',
        description: 'Cancel',
        callback: function ($event) {
            if ($scope.project.template) {
                $event.preventDefault();
                $scope.back();
            }
        },
    });
}
