export class SetBoardState {
    constructor() {
        this.preconditions = [];
    }
    async run(client, args) {
        return {
            board: args.board
        };
    }
}
;
