import DateTime from './DateTime';
let _instance = null;
class RecentlyUpdated extends DateTime {
    static instance() {
        if (!_instance) {
            _instance = new RecentlyUpdated({
                fakeDimension: true,
                id: '696e8aa0-f933-48c2-a130-04f5fce098e9',
                code: 'updatedAt',
                label: 'Updated at',
                datatype: 'RecentlyUpdated',
                datatypeOptions: {},
                userEditable: false,
                attribute: true,
                tagging: false,
                required: true,
                supportsMultiple: false,
                supportsRequired: false,
                supportsTagging: false,
                multiple: false,
                supportsColor: false,
                ordering: -1,
                relevantKinds: [],
                values: [],
            });
        }
        return _instance;
    }
    get category() {
        return 'system';
    }
}
export default RecentlyUpdated;
