angular
    .module('klaro')
    .directive('boardFilterPanel', boardFilterPanel);
function boardFilterPanel($filter) {
    return {
        restrict: 'EA',
        template: require('@/core/board/boardFilterPanel.html'),
        link: function (scope, navCtrl) {
            scope.deckDimensions = $filter('orderBy')(scope.board.dimensions.filter((d) => { return d.canBeUsedOnDecks(); }), '+label');
            scope.switchTab = function (tab, $event) {
                $event.stopPropagation();
                navCtrl.switchTab(tab);
            };
        },
    };
}
