angular
    .module('klaro')
    .factory('savingInterceptor', savingInterceptor)
    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push(savingInterceptor);
    }]);
function savingInterceptor($rootScope, $timeout, $q) {
    const ngSpinOps = {
        delay: 250,
        extend: 0,
    };
    // A counter of the number of concurrent pending requests
    let trackers = 0;
    // A timeout promise to start the pending state
    let pending = null;
    function urlOf(reqOrRes) {
        let url = reqOrRes.url ? reqOrRes.url : (reqOrRes.config ? reqOrRes.config.url : '');
        if (!url) {
            url = '';
        }
        return url;
    }
    function track(url) {
        return !url.match(/\.(html|ico|png|jpg|bmp|jpeg|css|js|ttf|woff2)$/);
    }
    function oneMore(reqOrRes) {
        const url = urlOf(reqOrRes);
        if (track(url)) {
            trackers += 1;
            if (!pending) {
                pending = $timeout(() => {
                    $rootScope.$emit('$ngSpinStart');
                }, ngSpinOps.delay);
            }
        }
    }
    function oneLess(reqOrRes) {
        const url = urlOf(reqOrRes);
        if (track(url)) {
            trackers -= 1;
            if (trackers == 0) {
                const cancelled = $timeout.cancel(pending);
                pending = null;
                if (!cancelled) {
                    $timeout(() => {
                        $rootScope.$emit('$ngSpinStop');
                    }, ngSpinOps.extend);
                }
            }
        }
    }
    return {
        request: function (request) {
            oneMore(request);
            return request;
        },
        requestError: function (request) {
            oneLess(request);
            return $q.reject(request);
        },
        response: function (response) {
            oneLess(response);
            return response;
        },
        responseError: function (response) {
            oneLess(response);
            return $q.reject(response);
        },
    };
}
