import Node from './Node';
import { Markdowner } from '../utils';
class PrintArticle extends Node {
    toHtml(stories, args) {
        const html = stories
            .map(s => this.storyToHtml(s, args))
            .join('\n');
        const beforeHtml = this.before(stories, args);
        const afterHtml = this.after(stories, args);
        return `
      ${beforeHtml}
      ${html}
      ${afterHtml}
    `;
    }
    storyToHtml(story, args) {
        const hLevel = this.hLevel(args);
        const body = [
            `# ${story.rawTitle}`,
            story.rawSummary,
            this.options.includeCardDescription ? story.specification : '',
        ].filter(s => s && s.trim().length > 0)
            .join('\n\n')
            .replaceAll(/^#/mg, Array(hLevel + 1).join('#'));
        const yieldHtml = this.yield(story, this.withNextHLevel(args));
        const bodyHtml = Markdowner.full.render(body);
        const cssClass = this.articleCssClass();
        return `
      <article${cssClass}>
        ${bodyHtml}
        ${yieldHtml}
      </article>
    `;
    }
    articleCssClass() {
        if (this.options.pageBreaks) {
            return ' class="page-break"';
        }
        else {
            return '';
        }
    }
}
export default PrintArticle;
