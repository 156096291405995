angular
    .module('klaro')
    .directive('userMenu', userMenu);
function userMenu() {
    return {
        restrict: 'EA',
        scope: true,
        replace: true,
        template: require('@/auth/userMenu.html'),
        controller: UserMenuController,
    };
}
function UserMenuController($scope, auth, profileModal) {
    $scope.user = auth.getUser();
    $scope.openMenu = function () {
        profileModal.open();
    };
}
