angular
    .module('klaro')
    .service('editMemberModal', editMemberModal)
    .controller('EditMemberModalController', EditMemberModalController);
function editMemberModal(centerModal, workspaceRest) {
    return centerModal.factor({
        template: require('@/core/member/editMemberModal.html'),
        controller: 'EditMemberModalController',
        resolve: {
            workspaces: function () {
                return workspaceRest.list();
            },
        },
    });
}
function EditMemberModalController($scope, $uibModalInstance, memberRest, robust, workspaces, entity, options, Clipboard, $timeout) {
    $scope.workspaces = workspaces;
    $scope.entity = entity;
    $scope.memberData = entity.toRaw();
    $scope.success = false;
    $scope.isNew = options.isNew;
    $scope.memberWorkspaces = {};
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.close = function (result) {
        const workspace = (result) ? result.map(r => {
            return $scope.workspaces.find((w) => {
                return w.code === r.code;
            });
        }) : null;
        $uibModalInstance.close(workspace);
    };
    $scope.save = function () {
        angular.forEach($scope.editMemberForm.$error, (field) => {
            angular.forEach(field, (errorField) => {
                errorField.$setDirty();
            });
        });
        if ($scope.editMemberForm.$valid) {
            $scope.memberData.workspaces = $scope.memberWorkspaces;
            if ($scope.isNew) {
                return memberRest
                    .create($scope.memberData)
                    .then(notify)
                    .then($scope.close)
                    .catch(onError);
            }
            else {
                return memberRest
                    .update($scope.entity, $scope.memberData)
                    .then(notify)
                    .then($scope.close)
                    .catch(onError);
            }
        }
    };
    $scope.saveButtonLabel = function () {
        return $scope.isNew ? 'Create member' : 'Save member';
    };
    $scope.titleLabel = function () {
        return $scope.isNew ? 'Create a member' : `Save "${$scope.entity.nickname}" member`;
    };
    $scope.copyToClipboard = (e) => {
        Clipboard
            .copy($scope.memberData.user.email)
            .then(() => {
            e.target.classList.add('copied');
            $timeout(() => {
                e.target.classList.remove('copied');
            }, 2000);
        });
    };
    function notify(member) {
        if (options.onSubmit) {
            options.onSubmit(member);
        }
    }
    function onError(err) {
        $scope.error = robust.message(err.data);
    }
}
