angular
    .module('klaro')
    .service('createKindValueModal', createKindValueModal)
    .controller('CreateKindValueController', CreateKindValueController);
function createKindValueModal(klaroModal) {
    return {
        open: function (boardCtrl, options) {
            return klaroModal.open({
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/board/createKindValueModal.html'),
                controller: 'CreateKindValueController',
                windowClass: 'klaro-modal create-kind-value-modal',
                size: 'small full-mobile',
                resolve: {
                    boardCtrl: function () {
                        return boardCtrl;
                    },
                    options: function () {
                        return options;
                    },
                },
            });
        },
    };
}
function CreateKindValueController($scope, boardCtrl, Colors, $uibModalInstance, robust, options) {
    $scope.cardColors = Colors.cards();
    $scope.kind = {
        value: '',
    };
    $scope.cancel = function () {
        $uibModalInstance.close();
    };
    $scope.aThing = function (value) {
        if (!value) {
            return 'a .........';
        }
        if (value.match(/^[aeiuo]/i)) {
            return `an ${value}`;
        }
        else {
            return `a ${value}`;
        }
    };
    $scope.canSubmit = function () {
        return ($scope.kind.value && $scope.kind.value.trim() !== '') && $scope.kind.color;
    };
    $scope.submit = function () {
        $scope.error = null;
        boardCtrl.onboardWithFirstKind({
            label: $scope.kind.value,
            color: $scope.kind.color,
        }, options.andSwitch)
            .then(() => {
            $uibModalInstance.close();
        })
            .catch((err) => {
            $scope.error = robust.message(err.data);
        });
    };
}
