angular
    .module('klaro')
    .controller('ValidateEmailModalController', ValidateEmailModalController);
function ValidateEmailModalController($scope, $timeout, $routeParams, auth, $uibModalInstance, navigation) {
    $scope.working = true;
    $scope.error = null;
    $scope.success = false;
    $timeout(() => {
        auth
            .validateEmail($routeParams.token)
            .then(onSuccess)
            .catch(onError);
    }, 100);
    $scope.gotoHome = function () {
        navigation.gotoHome(true);
    };
    function onSuccess() {
        $scope.working = false;
        $scope.error = null;
        $scope.success = true;
    }
    function onError(response) {
        const hasDescription = response.data && response.data.description;
        if (hasDescription) {
            $scope.error = response.data.description;
        }
        else {
            console.log(response);
            $scope.error = 'Sorry, an error occured';
        }
        $scope.working = false;
    }
}
