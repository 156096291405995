import { SetBoardState } from '../Board';
export class DeleteStories {
    constructor() {
        this.preconditions = [
            async (client) => {
                return !!client.store.board?.board.id && !!client.store.board?.stories;
            }
        ];
    }
    async run(client, args) {
        const { connector } = client.store;
        const before = client.store.board;
        const boardId = before.board.isAllCards() ? 'all' : before.board.id;
        await connector.boards.single(boardId).stories.delete(args);
        const after = await before.with({
            stories: before.stories.storiesDeleted(args.stories, false),
            storySelection: before.storySelection.clear(),
        });
        client.run(new SetBoardState(), { board: after });
    }
}
;
