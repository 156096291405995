import { Hierarchy } from '../../../views';
import { ArrayUtils } from '../../../utils';
import AbstractBoardListener from '../../AbstractBoardListener';
export default class BoardListViewListener extends AbstractBoardListener {
    stateChanged(subject, newState, oldState) {
        return new Promise((resolve, reject) => {
            try {
                if (!newState.board.isMode('list')) {
                    // No longer a list
                    // => remove ListView
                    resolve(newState.clone({ listView: undefined }));
                }
                else if (!oldState || !oldState.board.isMode('list')) {
                    // First board state, in list mode
                    // OR was not a list and is now
                    // => Create a fresh new ListView instance
                    const listView = new Hierarchy({
                        canWrite: newState.permissions.canWrite,
                        board: newState.board,
                        stories: newState.stories,
                        storySelection: newState.storySelection,
                    }).collapseAll();
                    resolve(newState.clone({
                        listView: listView,
                    }));
                }
                else if (this.hierarchyChanged(newState, oldState)) {
                    // Was a List, still a List but major parameters changed
                    // => Keep the instance, but remove the closedRows
                    const listView = this.getListView(newState, oldState);
                    resolve(newState.clone({
                        listView: listView.clone({
                            canWrite: newState.permissions.canWrite,
                            board: newState.board,
                            stories: newState.stories,
                            storySelection: newState.storySelection,
                        }).collapseAll(),
                    }));
                }
                else {
                    // => Keep the instance, and its closedRows
                    const listView = this.getListView(newState, oldState);
                    resolve(newState.clone({
                        listView: listView.clone({
                            canWrite: newState.permissions.canWrite,
                            board: newState.board,
                            stories: newState.stories,
                            storySelection: newState.storySelection,
                        }),
                    }));
                }
            }
            catch (ex) {
                console.log(ex);
                reject(ex);
            }
        });
    }
    getListView(newState, oldState) {
        return newState.listView || oldState.listView;
    }
    hierarchyChanged(newState, oldState) {
        const toCode = (d) => d.code;
        const hasChanged = ['hierarchyBy'].some((code) => {
            const oldAt = oldState.getDimensionsAt(code).map(toCode);
            const newAt = newState.getDimensionsAt(code).map(toCode);
            return !ArrayUtils.isSameset(oldAt, newAt);
        });
        return hasChanged;
    }
}
