angular
    .module('klaro')
    .directive('boardCardsSummaries', boardCardsSummaries);
function boardCardsSummaries() {
    return {
        restrict: 'E',
        template: require('@/core/board/boardCardsSummaries.html'),
        link: function (scope) {
            scope.thereAreSummaries = function () {
                return scope.boardData.summaries
                    && scope.boardData.summaries.length > 0;
            };
            scope.activateSummariesBar = scope.thereAreSummaries();
            function emptySummaries(activated) {
                if (!activated) {
                    scope.boardData.summaries = [];
                }
            }
            scope.$watch('activateSummariesBar', emptySummaries);
        },
    };
}
