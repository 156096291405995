import { Session } from '../model';
import { KlaroConnector, factorConnector } from './connectors';
export const createKlaroStore = (client, overrides) => {
    const store = {
        session: new Session({}),
        connector: factorConnector(client, KlaroConnector.api),
        ...overrides,
        async with(patch) {
            return createKlaroStore(client, {
                ...this,
                ...patch
            });
        },
    };
    return store;
};
