angular
    .module('klaro')
    .directive('projectSettingsHeader', projectSettingsHeader);
function projectSettingsHeader(navigation, $rootScope, Project) {
    return {
        restrict: 'EA',
        scope: true,
        template: require('@/core/project/projectSettingsHeader.html'),
        link: function (scope, iElement, iAttrs) {
            scope.isActive = navigation.isActive.bind(navigation);
            function refresh() {
                Project.get().then((p) => {
                    scope.isTemplate = p.isTemplate;
                    scope.isPublic = p.isPublic;
                });
            }
            refresh();
            $rootScope.$on('klaro.project.changed', refresh);
        },
    };
}
