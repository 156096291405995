import { createApiClient } from './api';
import { Boot, LoadSummaries, OperationRunner } from './operations';
import { createPreferenceStore } from './preferences';
import { DefaultEventuallizer } from './eventually';
import { LoadInstallers } from './operations/Installer';
export class KlaroClient {
    constructor(storeProvider, overrides) {
        this.api = overrides?.api || createApiClient(this);
        this.preferencesStore = overrides?.preferencesStore || createPreferenceStore();
        this.eventually = overrides?.eventually?.bind(this) || new DefaultEventuallizer({});
        this.storeProvider = storeProvider;
        this.observers = new Map();
    }
    get store() {
        return this.storeProvider.get();
    }
    withApi(api) {
        return new KlaroClient(this.storeProvider, {
            api,
            preferencesStore: this.preferencesStore,
            eventually: this.eventually,
        });
    }
    withPreferencesStore(store) {
        return new KlaroClient(this.storeProvider, {
            api: this.api,
            preferencesStore: store,
            eventually: this.eventually,
        });
    }
    withEventually(eventually) {
        return new KlaroClient(this.storeProvider, {
            api: this.api,
            preferencesStore: this.preferencesStore,
            eventually: new DefaultEventuallizer(eventually),
        });
    }
    withDefaultEventually() {
        return this.withEventually({
            summaries: () => new LoadSummaries(),
            installers: () => new LoadInstallers(),
        });
    }
    /**
     * TODO: refactor this, and maybe change the pattern for vue
     *
     * Explanation: for the vue context, everything that holds a pointer
     * to the client instance needs to be setup/initialized after reactive()
     * hacks the whole object. That means the services and operation runner
     * either need to receive `this` as argument of every method call
     * or they need to be created after the reactive() call
     */
    async init() {
        this.opRunner = new OperationRunner(this);
    }
    async boot(init = false) {
        if (init)
            this.init();
        await this.run(new Boot, {});
    }
    async load(operation, args = undefined) {
        return await this.opRunner.load(operation, args);
    }
    async run(operation, args = undefined) {
        const store = await this.opRunner.run(operation, args);
        if (store) {
            this.storeProvider.set(store);
        }
        const observers = [
            ...this.observers.get(operation.constructor) || [],
            ...this.observers.get('*') || []
        ];
        await observers.reduce((p, op) => {
            return p.then(() => op(operation, args));
        }, Promise.resolve());
        return Promise.resolve(operation);
    }
    subscribe(subscriptionKey, callback) {
        if (!Array.isArray(subscriptionKey)) {
            subscriptionKey = [subscriptionKey];
        }
        const installed = subscriptionKey.map((key) => {
            if (!this.observers.has(key)) {
                this.observers.set(key, []);
            }
            const opObservers = this.observers.get(key);
            if (!opObservers?.includes(callback)) {
                opObservers.push(callback);
                return key;
            }
        }).filter(Boolean);
        return () => {
            installed.forEach(key => {
                const opObservers = this.observers.get(key);
                if (!opObservers)
                    return;
                const index = opObservers.indexOf(callback);
                if (index && index !== -1) {
                    opObservers.splice(index, 1);
                }
            });
        };
    }
    addErrorHandler(handler) {
        return this.opRunner.addErrorHandler(handler);
    }
}
