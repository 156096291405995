import { FileUtils } from '@klaro/corejs/utils';
import CodeMirror from 'codemirror';
import 'codemirror/mode/gfm/gfm';
angular
    .module('klaro')
    .directive('codemirror', codemirror);
function codemirror($timeout, navigation, seshat, $rootScope) {
    return {
        restrict: 'E',
        require: '^ngModel',
        template: '<textarea></textarea>',
        scope: {
            ngShow: '=',
            onBlur: '&',
        },
        link: function (scope, iElement, iAttrs, ngModelController) {
            iElement.css('height', '100%');
            const cm = CodeMirror.fromTextArea(iElement.find('textarea')[0], {
                lineWrapping: true,
                lineNumbers: false,
                mode: { name: 'gfm', gitHubSpice: false },
                autofocus: true,
                scrollbarStyle: 'null',
                viewportMargin: Infinity,
            });
            cm.setSize('100%', '100%');
            function setFocus(withCursor) {
                cm.refresh();
                cm.focus();
                if (withCursor && iAttrs.focusAt === 'end') {
                    cm.setCursor(cm.lineCount(), 0);
                }
            }
            $timeout(() => setFocus(true));
            scope.$watch('ngShow', (shown) => {
                if (!shown) {
                    return;
                }
                $timeout(() => setFocus(false));
            });
            ngModelController.$render = function () {
                if (cm.getValue() !== '') {
                    return;
                }
                cm.setValue(ngModelController.$viewValue || '');
            };
            ngModelController.$render();
            cm.on('change', () => {
                const text = cm.getValue();
                ngModelController.$setViewValue(text);
            });
            cm.on('paste', (arg, e) => {
                if (e.clipboardData !== null) {
                    const files = e.clipboardData.files;
                    if (files.length > 0) {
                        const fileName = files[0].name;
                        e.preventDefault();
                        seshat({ data: { file: files[0] } })
                            .then(location => onSuccess(location, fileName))
                            .catch(navigation.failed);
                    }
                }
            });
            /*
             * When the file has been uploaded, adds the markdown tag according to the
             * type of file it is:
             * - For images, ensures that the tag is on a line by itself,
             *    with no other text on the same line.
             * - For all other file types, adds the tag in the cursor position.
             */
            function onSuccess(location, insertedFileName) {
                let text = FileUtils.markdownFor(insertedFileName, location.relative);
                const doc = cm.getDoc();
                const cursor = doc.getCursor();
                const currentLineText = doc.getLine(cursor.line);
                let targetPosition = cursor;
                if (FileUtils.isImage(insertedFileName)) {
                    if (currentLineText.length > 0) {
                        targetPosition = {
                            line: cursor.line + 1,
                            ch: 0,
                        };
                    }
                    const textInTheTargetLine = doc.getLine(targetPosition.line);
                    if (textInTheTargetLine === undefined) { // it's the last line
                        text = `\n${text}`;
                    }
                    else if (textInTheTargetLine.length > 0) {
                        text = `${text}\n`;
                    }
                }
                doc.replaceRange(text, targetPosition);
            }
        },
    };
}
