import Dimension from '../Dimension';
class ProjectMember extends Dimension {
    getBestInstallerAmong(installers) {
        return installers.find((i) => {
            return i.datatype == 'ProjectMember';
        });
    }
    participatesToTextSearch() {
        return true;
    }
    canValuesBeDeleted() {
        return false;
    }
    getValueDeletionPlace() {
        return 'members';
    }
}
export default ProjectMember;
