angular
    .module('klaro')
    .directive('storyHistory', storyHistory)
    .directive('body', closeHistory);
function storyHistory(storyRest, auth) {
    return {
        restrict: 'E',
        template: require('@/core/story/storyHistory.html'),
        link: function (scope, elm) {
            // Initially, we use available information from story
            // without involving the backend
            scope.$watch('storyData', (storyData) => {
                if (!storyData) {
                    return;
                }
                if (scope.created) {
                    return;
                }
                installFromStoryData(storyData);
            });
            function installFromStoryData(storyData) {
                scope.created = {
                    at: storyData.createdAt,
                    by: {
                        nickname: storyData.createdBy,
                        email: null,
                    },
                };
                scope.updated = {
                    at: storyData.updatedAt,
                    by: {
                        nickname: storyData.updatedBy,
                        email: null,
                    },
                };
            }
            // When the user click to know the details, we fetch
            // the history from the backend and enhance the information
            // we have
            if (!auth.isAnonymous()) {
                elm.find('details.history')[0].addEventListener('toggle', (event) => {
                    if (event.target.open) {
                        storyRest.getHistoryBoundaries(scope.storyData).then(installFromHistory);
                    }
                });
            }
            function installFromHistory(storyHistory) {
                if (storyHistory.length === 0) {
                    return;
                }
                const first = storyHistory[0];
                const last = storyHistory[storyHistory.length - 1];
                scope.created = {
                    at: first.createdAt,
                    by: {
                        nickname: first.createdBy.nickname,
                        email: first.createdBy.email,
                    },
                };
                scope.updated = {
                    at: last.createdAt,
                    by: {
                        nickname: last.createdBy.nickname,
                        email: last.createdBy.email,
                    },
                };
            }
        },
    };
}
/**
 * This directive closes the history when the user click outsides of it,
 * or when s/he uses the escape key.
 */
function closeHistory($document) {
    return {
        restrict: 'E',
        link: function (scope, elm) {
            const closeHistory = function () {
                const history = $document[0]
                    .querySelector('.history[open]');
                if (history) {
                    history.removeAttribute('open');
                }
            };
            const container = '.history[open]';
            const bodyClick = (event) => {
                if (!event.target.closest(container)) {
                    closeHistory();
                }
            };
            const escapeKey = (event) => {
                if (event.target.closest(container) && (event.key === 'Escape' || event.key === 'Esc')) {
                    closeHistory();
                    event.stopPropagation();
                }
            };
            const clickHandler = (event) => bodyClick(event, scope);
            const keyHandler = (event) => escapeKey(event, scope);
            elm.on('click', clickHandler);
            elm.on('keydown', keyHandler);
            scope.$on('$destroy', () => {
                elm.off('click', clickHandler);
                elm.off('keydown', keyHandler);
            });
        },
    };
}
