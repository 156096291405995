angular
    .module('klaro')
    .directive('boardContent', boardContent);
function boardContent() {
    return {
        restrict: 'EA',
        template: require('@/core/board/boardContent.html'),
        require: '^board',
        scope: {
            mode: '=',
        },
        link: function (scope, elm, attrs, board) {
            scope.isList = function () {
                return board.isMode('list');
            };
            scope.isGrid = function () {
                return board.isMode('grid');
            };
            scope.isKanban = function () {
                return board.isMode('kanban');
            };
            scope.isGallery = function () {
                return board.isMode('gallery');
            };
            scope.isGantt = function () {
                return board.isMode('gantt');
            };
            scope.isCasino = function () {
                return board.isMode('casino');
            };
            scope.isChart = function () {
                return board.isMode('chart');
            };
            scope.isMatrix = function () {
                return board.isMode('matrix');
            };
            scope.canWrite = function () {
                return board.canWrite();
            };
            scope.storyClicked = function (story, $event) {
                // Event is considered entirely consumed here since we open a dialog.
                // In particular, we don't want <div new-story-hover> to open the new
                // story dialog, so the click may not reach it.
                $event.stopPropagation();
                board.storyClicked(story, $event);
            };
            scope.noStories = function () {
                return board.getBoardState().stories.visible.length == 0;
            };
            scope.supportsEmptyNotice = function () {
                return false; //scope.isGrid();
            };
        },
    };
}
