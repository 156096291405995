export class ApiAttachmentsConnector {
    constructor(client, baseUrl) {
        this.client = client;
        this.baseUrl = baseUrl;
    }
    async delete(id) {
        await this.client.api.delete(`${this.baseUrl}/${id}`);
    }
    async create(attachments) {
        const promises = attachments.map((att) => {
            return this.client.api.post(this.baseUrl, {
                description: '',
                filename: att.originalname,
                isCover: false,
                sizeInBytes: att.contentLength,
                url: `/s/${att.name}`,
            });
        });
        await Promise.all(promises);
    }
}
